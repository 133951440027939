import DashboardIcon from '@mui/icons-material/Dashboard';
import { routes } from 'app/routes';
import {
  contactsCustomersRoutes,
  contactsNamesRoutes,
  contactsVendorsRoutes,
  contactsRoutes,
} from 'app/routes/contacts';
import { FC, useContext } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import List from '@mui/material/List';
import { AppContext } from 'app/AppContext';
import MenuItem from '../Sidebar/MenuItem';

export const Contacts: FC = () => {
  const { canViewCustomers, canViewEmployees, canViewNames, canViewVendors } =
    useContext(AppContext);

  if (
    !canViewCustomers &&
    !canViewEmployees &&
    !canViewNames &&
    !canViewVendors
  )
    return null;

  return (
    <MenuItem
      group={contactsRoutes}
      label="Contacts"
      icon={<PeopleIcon />}
      isParent
    >
      <List disablePadding dense>
        <MenuItem
          label="Dashboard"
          icon={<DashboardIcon />}
          to={routes.contactsDashboard()}
        />
        {canViewCustomers && (
          <MenuItem
            label="Customers"
            icon={<PeopleIcon />}
            to={routes.contactsCustomersDashboard()}
            group={contactsCustomersRoutes}
          />
        )}
        {canViewVendors && (
          <MenuItem
            label="Vendors"
            icon={<BusinessIcon />}
            to={routes.contactsVendorsDashboard()}
            group={contactsVendorsRoutes}
          />
        )}
        {canViewNames && (
          <MenuItem
            label="Names"
            icon={<RecentActorsIcon />}
            to={routes.contactsNamesDashboard()}
            group={contactsNamesRoutes}
          />
        )}
      </List>
    </MenuItem>
  );
};

export default Contacts;
