import { WidgetInfo } from '../types/widget';

export interface WidgetGroup {
  id: WidgetGroupIds | string;
  title: string;
  description?: string;
}
export interface WidgetGroupDetails extends WidgetGroup {
  widgetInfo: WidgetInfo[];
}

export enum WidgetGroupIds {
  Misc = 'misc',
  Financial = 'financial',
  Customer = 'customer',
  Statistics = 'stats',
  WorkOrders = 'work_orders',
  Sales = 'sales',
  Bills = 'bills',
  Jobs = 'jobs',
  Inventory = 'inventory',
  Purchases = 'purchases',
}

export const WidgetGroups: readonly WidgetGroup[] = [
  {
    id: WidgetGroupIds.Misc,
    title: 'Miscellaneous',
  },
  {
    id: WidgetGroupIds.Financial,
    title: 'Financial',
    description: 'Financial related widgets.',
  },
  {
    id: WidgetGroupIds.Statistics,
    title: 'Statistics',
    description: 'Statistics and info about your company.',
  },
  {
    id: WidgetGroupIds.Customer,
    title: 'Customers',
  },
  {
    id: WidgetGroupIds.WorkOrders,
    title: 'Work Orders',
  },
  {
    id: WidgetGroupIds.Sales,
    title: 'Sales',
  },
  {
    id: WidgetGroupIds.Bills,
    title: 'Bills',
  },
  {
    id: WidgetGroupIds.Jobs,
    title: 'Jobs',
  },
  {
    id: WidgetGroupIds.Inventory,
    title: 'Inventory',
  },
  {
    id: WidgetGroupIds.Purchases,
    title: 'Purchases',
  }
];
