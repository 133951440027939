import { lazy } from 'react';
import { WidgetGroupIds } from 'modules/dashboards/widgetManager/widgetGroups';
import { widgetManager } from 'modules/dashboards/widgetManager/widgetManager';

const OpenInvoices = lazy(() => import('./dashboardWidgets/Invoices'));
const InvoiceConfig = lazy(
  () => import('./dashboardWidgets/Invoices/ConfigPage')
);

const CustomersTop = lazy(() => import('./dashboardWidgets/CustomersTop'));
const DailySalesSnapshot = lazy(
  () => import('./dashboardWidgets/DailySalesSnapshot')
);

const CustomersTopConfig = lazy(
  () => import('./dashboardWidgets/CustomersTop/ConfigPage')
);
const SalesMap = lazy(() => import('./dashboardWidgets/SalesMap'));
const SalesMapConfig = lazy(
  () => import('./dashboardWidgets/SalesMap/SalesMapConfigPage')
);

const SalesVolume = lazy(() => import('./dashboardWidgets/SalesVolume'));
const SalesVolumeConfig = lazy(
  () => import('./dashboardWidgets/SalesVolume/Config')
);

widgetManager.addWidgetLoader((e) => {
  e.addWidget(
    {
      id: 'Invoices',
      title: 'Invoices',
      groupId: WidgetGroupIds.Sales,
    },
    OpenInvoices,
    { minW: 6, minH: 4 },
    InvoiceConfig
  );

  e.addWidget(
    {
      id: 'CustomersTop',
      title: 'Top Customers',
      groupId: WidgetGroupIds.Sales,
      description: 'Top customers by sales.',
    },
    CustomersTop,
    {
      minW: 6,
      minH: 6,
    },
    CustomersTopConfig
  );

  e.addWidget(
    {
      id: 'SalesMap',
      title: 'Sales Map',
      description: 'Invoices or work orders grouped by customer.',
      groupId: WidgetGroupIds.Sales,
    },
    SalesMap,
    {
      minW: 4,
      minH: 6,
    },
    SalesMapConfig
  );

  e.addWidget(
    {
      id: 'SalesVolume',
      title: 'Sales Volume',
      description: 'Sales totals grouped by week/month/year.',
      groupId: WidgetGroupIds.Sales,
    },
    SalesVolume,
    { minW: 4, minH: 5 },
    SalesVolumeConfig
  );

  e.addWidget(
    {
      id: 'DailySalesSnapshot',
      title: 'Sales Snapshot',
      groupId: WidgetGroupIds.Sales,
      description: 'Daily sales overview.',
    },
    DailySalesSnapshot,
    {
      minW: 4,
      minH: 8,
    }
  );
});
