import { ListItemButton, useTheme } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { FC, ReactNode, useContext, useEffect } from 'react';
import { Route, createGroup } from 'type-route';
import { ErrorBoundary } from 'lib/components/ErrorBoundary';
import { routes, useRoute } from 'app/routes';
import { useOnComponentMounted } from '../../../lib/hooks/useOnComponentMounted';
import { SidebarContext } from './SidebarContext';
import { iconStyle, listItemHeight } from './styles';

export enum Selected {
  Automatic,
  Selected,
  NotSelected,
}

interface MenuItemProps {
  label: string;
  to?: Route<typeof routes>;
  group?: ReturnType<typeof createGroup>;
  isParent?: boolean;
  icon: any;
  reload?: boolean;
  /**
   * Overrides the default functionality of marking an item selected based on route name or group.
   */
  selected?: (route: Route<typeof routes>) => Selected;
  children?: ReactNode;
}

export const MenuItem: FC<MenuItemProps> = ({
  label,
  to,
  group,
  icon,
  reload,
  selected,
  children,
  isParent = false,
}) => {
  const route = useRoute();
  const theme = useTheme();

  const selectedValue = selected ? selected(route) : Selected.Automatic;

  const isSelected =
    selectedValue !== Selected.Automatic
      ? selectedValue === Selected.Selected
      : (to && to.name === route.name) ||
        (group !== undefined ? group.has(route) : false);

  const { addModule, setActive, activeModule } = useContext(SidebarContext);

  const isFocused = !isSelected && isParent && activeModule === label;

  useEffect(() => {
    if (children) {
      addModule(label, children);
    }
  }, [addModule, children, label]);

  useOnComponentMounted(() => {
    if (isParent && isSelected) {
      // Open the menu on page load.
      setActive(label);
    }
  });

  return (
    <ErrorBoundary>
      <Tooltip title={label} placement="right" arrow>
        <ListItemButton
          sx={{
            height: listItemHeight,
            p: 1,
            ml: '4px',
            mr: '4px',
            backgroundColor: isFocused ? theme.palette.grey['A200'] : undefined,
            '&.MuiListItemButton-root': {
              marginTop: 0.5,
              borderRadius: 1,
            },
          }}
          selected={isSelected}
          component="a"
          href={to?.href}
          onClick={(e: any) => {
            if (to && reload) window.location.assign(to.href);
            else {
              e.preventDefault();
              to?.push();
              if (isParent) {
                setActive(label);
              }
            }
          }}
        >
          <ListItemIcon sx={iconStyle}>{icon}</ListItemIcon>
          <ListItemText primary={label} sx={{ whiteSpace: 'nowrap' }} />
        </ListItemButton>
      </Tooltip>
    </ErrorBoundary>
  );
};

export default MenuItem;
