import { ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { notificationBus } from 'app/Providers/NotificationHandler';
import { QueryContext } from './index';

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    let networkErrorResolved = false;
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (extensions?.code === 'AUTH_NOT_AUTHORIZED') {
          // We want to just swallow not authorized errors.
          return;
        }
        const context = operation.getContext() as QueryContext;
        const hideError = !!context.hideErrors;

        if (!hideError)
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );

        if (
          (extensions?.message + message).includes(
            'The current company needs data migrations'
          )
        ) {
          notificationBus.emit('migrationError', true);
        } else if (!hideError) {
          notificationBus.emit('error', extensions?.message || message);
        }
      });
    }

    if (networkError && !networkErrorResolved) {
      let serverError = networkError as ServerError;
      switch (serverError.statusCode) {
        case 401:
          handleNotAuthorized();
          break;
        case 500:
          // When a non-nullable field is access denied, it will return a 500 response.
          break;
        default:
          break;
      }

      console.log(`[Network error]: ${networkError}`);
    }
  }
);

function handleNotAuthorized() {
  if (
    !window.location.href.includes('/login') &&
    !window.location.href.includes('/register')
  ) {
    const returnUrl = window.location
      .toString()
      .replace(window.location.origin, '');
    let href = '/login';

    if (returnUrl.length > 0 && returnUrl !== '/logout') {
      href += '?returnUrl=' + returnUrl;
    }

    window.location.href = href;
  }
}
