import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import { FC } from 'react';
import Page from './Page';

export const NotFound: FC = () => {
  return (
    <Page title="Not Found">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexGrow: 1,
          justifyContent: 'center',
          padding: 3,
        }}
      >
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={8} md={6}>
            <Card
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              <CardHeader
                avatar={<ErrorOutlineIcon fontSize="large" />}
                title="Oops..."
              />
              <Divider />
              <CardContent>
                We're sorry, but there is no page at this address.
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default NotFound;
