import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Paper, Box, IconButton, Divider } from '@mui/material';
import { FC, useContext } from 'react';
import { AppContext } from '../../AppContext';
import { SidebarContext } from './SidebarContext';
import {
  childMenuWidth,
  collapseTransition,
  drawerWidthMini,
  drawerWidthOpen,
  listItemHeight,
} from './styles';

const ChildContainer: FC = () => {
  const { sidebarState } = useContext(AppContext);

  const { moduleItems, setActive, activeModule } = useContext(SidebarContext);

  return (
    <Paper
      sx={{
        pt: '5px',
        position: 'absolute',
        top: -5,
        // Width needs to be correct so that the tooltips are positioned correctly.
        width: sidebarState === 'mini' ? drawerWidthMini : childMenuWidth,
        left: !moduleItems
          ? drawerWidthOpen + 2
          : sidebarState === 'mini'
          ? 0
          : drawerWidthOpen - childMenuWidth,
        // Calc so that the top and bottom rounded corners are hidden.
        height: 'calc(100% + 10px)',
        backgroundColor: 'background.default',
        // Transition the width so that we don't see a flash of the main menu underneath when transitioning to/from 'mini' state.
        transition: `left ${collapseTransition}, width ${collapseTransition}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          // justifyContent: 'space-between',
          p: 1,
          alignItems: 'center',
          height: listItemHeight + 3,
        }}
      >
        <IconButton onClick={() => setActive('')} size="small">
          <ChevronRightIcon />
        </IconButton>
        <Box
          sx={{
            ml: 2,
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {activeModule}
        </Box>
      </Box>
      <Divider sx={{ top: '-1px' }} />
      {moduleItems}
    </Paper>
  );
};

export default ChildContainer;
