import {
  AccountBalanceWallet,
  AutoFixNormal,
  CurrencyExchange,
} from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Check from '@mui/icons-material/Article';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import List from '@mui/material/List';
import { AppContext } from 'app/AppContext';
import { routes } from 'app/routes';
import {
  financesLedgerEntries,
  financesRoutes,
  financesStatementReconciliationRoutes,
} from 'app/routes/finances';
import { FC, useContext } from 'react';
import TransactionTypes from 'modules/finances/LedgerEntries/TransactionTypes';
import MenuItem, { Selected } from '../Sidebar/MenuItem';

export const Finances: FC = () => {
  const { canViewBankStatementReconciliation, canViewLedgerEntries } =
    useContext(AppContext);

  if (!canViewBankStatementReconciliation && !canViewLedgerEntries) return null;

  return (
    <MenuItem
      group={financesRoutes}
      label="Finances"
      icon={<AccountBalanceIcon />}
      isParent
    >
      <List disablePadding dense>
        <MenuItem
          label="Dashboard"
          icon={<DashboardIcon />}
          to={routes.financesDashboard()}
        />
        {canViewBankStatementReconciliation && (
          <MenuItem
            label="Accounts"
            icon={<AccountBalanceWallet />}
            to={routes.financesAccountsDashboard()}
          />
        )}

        {canViewLedgerEntries && (
          <>
            <MenuItem
              label="Checks"
              to={routes.financesLedgerEntryNew({
                type: TransactionTypes.Check,
              })}
              icon={<Check />}
              selected={(route) =>
                financesLedgerEntries.has(route) &&
                route.params.type === TransactionTypes.Check
                  ? Selected.Selected
                  : Selected.NotSelected
              }
            />
            <MenuItem
              label="Bank Transfer"
              to={routes.financesLedgerEntryNew({
                type: TransactionTypes.BankTransfer,
              })}
              icon={<CurrencyExchange />}
              selected={(route) =>
                financesLedgerEntries.has(route) &&
                route.params.type === TransactionTypes.BankTransfer
                  ? Selected.Selected
                  : Selected.NotSelected
              }
            />
            <MenuItem
              label="Adjustments"
              to={routes.financesLedgerEntryNew({
                type: TransactionTypes.Adjustment,
              })}
              icon={<AutoFixNormal />}
              selected={(route) =>
                financesLedgerEntries.has(route) &&
                route.params.type === TransactionTypes.Adjustment
                  ? Selected.Selected
                  : Selected.NotSelected
              }
            />
          </>
        )}

        {canViewBankStatementReconciliation && (
          <MenuItem
            label="Statement Reconciliation"
            icon={<DoneAllIcon />}
            to={routes.financesStatementReconciliation()}
            group={financesStatementReconciliationRoutes}
          />
        )}
      </List>
    </MenuItem>
  );
};

export default Finances;
