import { WidgetGroupIds } from 'modules/dashboards/widgetManager/widgetGroups';
import { widgetManager } from 'modules/dashboards/widgetManager/widgetManager';
import { lazy } from 'react';

const ByBillCode = lazy(
  () => import('./dashboardWidgets/WorkOrdersByBillCode')
);
const ByBillCodeConfig = lazy(
  () => import('./dashboardWidgets/WorkOrdersByBillCode/ConfigPage')
);

const ByCustomerType = lazy(
  () => import('./dashboardWidgets/WorkOrdersByCustomerType')
);

const UpcomingDeliveries = lazy(
  () => import('./dashboardWidgets/UpcomingDeliveries')
);

const OpenWorkOrders = lazy(() => import('./dashboardWidgets/OpenWorkOrders'));
const OpenWorkOrdersConfig = lazy(
  () => import('./dashboardWidgets/OpenWorkOrders/ConfigPage')
);

const VendorsTop = lazy(() => import('./dashboardWidgets/VendorsTop'));
const VendorsTopConfig = lazy(
  () => import('./dashboardWidgets/VendorsTop/ConfigPage')
);

widgetManager.addWidgetLoader((e) => {
  e.addWidget(
    {
      id: 'WorkOrdersByBillCode',
      title: 'Work Orders By Bill Code',
      groupId: WidgetGroupIds.WorkOrders,
    },
    ByBillCode,
    { minH: 5, minW: 4 },
    ByBillCodeConfig
  );

  e.addWidget(
    {
      id: 'WorkOrdersByCustomerType',
      title: 'Work Orders By Customer Type',
      groupId: WidgetGroupIds.WorkOrders,
    },
    ByCustomerType,
    { minH: 5, minW: 4 },
    // Notice that the by customer type and by bill code widgets share a config page!
    ByBillCodeConfig
  );

  e.addWidget(
    {
      id: 'WorkOrdersUpcomingDeliveries',
      title: 'Upcoming Deliveries',
      groupId: WidgetGroupIds.WorkOrders,
    },
    UpcomingDeliveries,
    { minW: 6, minH: 4 }
  );

  e.addWidget(
    {
      id: 'WorkOrdersOpen',
      title: 'Open Work Orders',
      groupId: WidgetGroupIds.WorkOrders,
    },
    OpenWorkOrders,
    { minW: 6, minH: 4 },
    OpenWorkOrdersConfig
  );

  e.addWidget(
    {
      id: 'VendorsTop',
      title: 'Top Vendors',
      groupId: WidgetGroupIds.Bills,
      description: 'Top vendors by bills.',
    },
    VendorsTop,
    {
      minW: 6,
      minH: 6,
    },
    VendorsTopConfig
  );
});
