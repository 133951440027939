import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { AppContext } from 'app/AppContext';
import { routes } from 'app/routes';
import { EventEmitter } from 'lib/eventEmitter';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState, FC } from 'react';

export const notificationBus = new EventEmitter<{
  error: string;
  migrationError: boolean;
  info: string;
}>();

const NotificationHandler: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [migrations, setMigrations] = useState<boolean>(false);
  const context = useContext(AppContext);

  useEffect(() => {
    const errorOff = notificationBus.on('error', (data: string) =>
      enqueueSnackbar(data, {
        variant: 'error',
        preventDuplicate: true,
      })
    );

    const infoOff = notificationBus.on('info', (data: string) => {
      enqueueSnackbar(data, {
        variant: 'info',
        preventDuplicate: true,
      });
    });
    return () => {
      errorOff();
      infoOff();
    };
  }, [enqueueSnackbar]);

  useEffect(() => {
    return notificationBus.on('migrationError', (data) => setMigrations(true));
  }, []);

  return (
    <Dialog open={migrations}>
      <DialogTitle>Un-applied Migrations</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The company you are trying to access needs data migrations applied to
          it.
          {context.isSystemAdmin
            ? ' Click below to go to the company configuration page.'
            : ' Please contact your system administrator.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setMigrations(false)}>Dismiss</Button>
        <Button
          variant="contained"
          onClick={() => {
            setMigrations(false);
            routes.configurationCompaniesDashboard().push();
          }}
        >
          Company Configuration
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationHandler;
