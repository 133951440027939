import { NotFound } from 'lib/components/NotFound';
import { FC, lazy, Suspense } from 'react';
import { Loading } from '../lib/components/Loading';
import MainUi from './MainUi';
import DefaultProviders from './Providers';
import { routes, useRoute } from './routes';
import { configurationRoutes } from './routes/configuration';
import { contactsRoutes } from './routes/contacts';
import { dashboardsRoutes } from './routes/dashboards';
import { financesRoutes } from './routes/finances';
import { inventoryRoutes } from './routes/inventory';
import { miscAppsRoutes } from './routes/miscApps';
import { payrollRoutes } from './routes/payroll';
import { signedOut } from './routes/signedOut';
import { profileRoutes } from './routes/profile';
import { purchasesRoutes } from './routes/purchases';
import { reportsRoutes } from './routes/reports';
import { salesRoutes } from './routes/sales';
import { jobRoutes } from './routes/jobs';
import { Logout } from './identity/LogOut';
import { debugRoutes } from './routes/debug';

const Register = lazy(() => import('./identity/Register'));
const Login = lazy(() => import('./identity/LogIn'));
const ForgotPassword = lazy(() => import('./identity/ForgotPassword'));
const ForgotPasswordReset = lazy(
  () => import('./identity/ForgotPasswordReset')
);
const Welcome = lazy(() => import('./identity/Welcome'));

const ProfileModule = lazy(() => import('modules/profile/Module'));
const DashboardModule = lazy(() => import('modules/dashboards/Module'));

const ContactsModule = lazy(() => import('modules/contacts/Module'));
const ConfigurationModule = lazy(() => import('modules/configuration/Module'));
const FinancesModule = lazy(() => import('modules/finances/Module'));
const InventoryModule = lazy(() => import('modules/inventory/Module'));
const PurchasesModule = lazy(() => import('modules/purchases/Module'));
const PayrollModule = lazy(() => import('modules/payroll/Module'));
const ReportsModule = lazy(() => import('modules/reports/Module'));
const SalesApp = lazy(() => import('modules/sales/Module'));
const JobsModule = lazy(() => import('modules/jobs/Module'));
const DebugModule = lazy(() => import('modules/debug/Module'));
const MiscAppsModule = lazy(() => import('modules/miscApps/Module'));

const App: FC = () => {
  return (
    <DefaultProviders>
      <ClientApp />
    </DefaultProviders>
  );
};

const ClientApp: FC = () => {
  const route = useRoute();

  return route.name === routes.logOut.name ? (
    <Logout />
  ) : signedOut.has(route) ? (
    <Suspense fallback={<Loading />}>
      {route.name === routes.logIn.name ? (
        <Login />
      ) : route.name === routes.logInForgotPassword.name ? (
        <ForgotPassword />
      ) : route.name === routes.logInForgotPasswordReset.name ? (
        <ForgotPasswordReset route={route} />
      ) : route.name === routes.register.name ? (
        <Register />
      ) : route.name === routes.welcome.name ? (
        <Welcome />
      ) : null}
    </Suspense>
  ) : (
    <MainUi>
      {dashboardsRoutes.has(route) ? (
        <DashboardModule route={route} />
      ) : contactsRoutes.has(route) ? (
        <ContactsModule route={route} />
      ) : configurationRoutes.has(route) ? (
        <ConfigurationModule />
      ) : financesRoutes.has(route) ? (
        <FinancesModule />
      ) : inventoryRoutes.has(route) ? (
        <InventoryModule route={route} />
      ) : purchasesRoutes.has(route) ? (
        <PurchasesModule />
      ) : payrollRoutes.has(route) ? (
        <PayrollModule route={route} />
      ) : reportsRoutes.has(route) ? (
        <ReportsModule />
      ) : salesRoutes.has(route) ? (
        <SalesApp route={route} />
      ) : jobRoutes.has(route) ? (
        <JobsModule />
      ) : profileRoutes.has(route) ? (
        <ProfileModule />
      ) : miscAppsRoutes.has(route) ? (
        <MiscAppsModule route={route} />
      ) : debugRoutes.has(route) ? (
        <DebugModule route={route} />
      ) : (
        <NotFound />
      )}
    </MainUi>
  );
};

export default App;
