import { PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { typography } from './Blue';

const Plum = (mode: PaletteMode) =>
  createTheme({
    palette: {
      mode: mode,
      primary: {
        light: '#925391',
        main: '#803380',
        dark: '#4a004d',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#b8cc8a',
        main: '#879b5c',
        dark: '#596d31',
        contrastText: '#ffffff',
      },
    },
    typography: typography,
  });

export default Plum;
