import { ApolloClient, ApolloError, InMemoryCache } from '@apollo/client';
import { Check, Clear, OpenInNew, Warning } from '@mui/icons-material';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { FC, useContext, useEffect, useRef } from 'react';
import { client } from 'lib/com/GraphQL';
import { clearSentryUser } from 'lib/com/Sentry';
import { useForceRerender } from 'lib/hooks/useForceRerender';
import { AppContext } from 'app/AppContext';
import { routes } from '../../routes';
import Layout from '../Layout';
import { logoutMutation } from './queries';
import { Logout as LogoutModel } from './types/Logout';

enum LogoutStatus {
  Progress,
  Done,
  NotLoggedIn,
  Error,
}

export const Logout: FC = () => {
  const isLoggingOut = useRef(false);

  const { companies } = useContext(AppContext);

  const status = useRef<Record<string, LogoutStatus>>(
    (() => {
      const data: Record<string, LogoutStatus> = {};
      companies.forEach((c) => (data[c.id] = LogoutStatus.Progress));
      return data;
    })()
  );

  const rerender = useForceRerender();

  useEffect(() => {
    (async () => {
      if (isLoggingOut.current) return;
      isLoggingOut.current = true;

      if (!companies || companies.length === 0) {
        // When there are no companies configured, just log out and forward to the login screen.
        await client.mutate({ mutation: logoutMutation });

        routes.logIn().push();
        return;
      }

      const promises = companies.map(async (c) => {
        const client = new ApolloClient({
          uri: c.publicUrl + '/graphql',
          cache: new InMemoryCache(),
          credentials: 'include',
        });

        let result;
        try {
          result = await client.mutate<LogoutModel>({
            mutation: logoutMutation,
          });
        } catch (e) {
          const er = e as ApolloError;
          if (
            er.graphQLErrors.find(
              (e) => e.extensions.code === 'AUTH_NOT_AUTHORIZED'
            )
          ) {
            // If we aren't authorized, we weren't logged in.
            status.current[c.id] = LogoutStatus.NotLoggedIn;
            return;
          }

          status.current[c.id] = LogoutStatus.Error;
          return;
        }

        if (result?.data?.logout === true) {
          status.current[c.id] = LogoutStatus.Done;
        } else {
          status.current[c.id] = LogoutStatus.NotLoggedIn;
        }
      });

      rerender();
      await Promise.all(promises);
      rerender();
      await client.clearStore();
      clearSentryUser();
      window.location.href = '/login';
    })();
  }, [companies, rerender]);

  return (
    <Layout title="Log Out">
      <Table size="small">
        <TableBody>
          {companies.map((c) => (
            <TableRow key={c.id}>
              <TableCell>
                <Tooltip title="Log in">
                  <a href={c.publicUrl + '/login'}>
                    <OpenInNew fontSize="inherit" />
                  </a>
                </Tooltip>
                &nbsp;{c.name}
              </TableCell>
              <TableCell>
                {status.current[c.id] === LogoutStatus.Progress ? (
                  <Tooltip title="Logging out">
                    <CircularProgress size={12} />
                  </Tooltip>
                ) : status.current[c.id] === LogoutStatus.Done ? (
                  <Tooltip title="Logged out">
                    <Check color="success" fontSize="inherit" />
                  </Tooltip>
                ) : status.current[c.id] === LogoutStatus.NotLoggedIn ? (
                  <Tooltip title="Not Logged in">
                    <Clear color="disabled" fontSize="inherit" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Error logging out">
                    <Warning color="disabled" fontSize="inherit" />
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Layout>
  );
};
