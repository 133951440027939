import { routes } from 'app/routes';
import { jobRoutes } from 'app/routes/jobs';
import { FC } from 'react';
import FoundationIcon from '@mui/icons-material/Foundation';
import MenuItem from '../Sidebar/MenuItem';

export const Inventory: FC = () => {
  return (
    <MenuItem
      to={routes.jobsDashboard()}
      group={jobRoutes}
      label="Jobs"
      icon={<FoundationIcon />}
      isParent
    />
  );
};

export default Inventory;
