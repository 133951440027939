import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { routes, session } from 'app/routes';
import { configurationCompaniesEditRoutes } from 'app/routes/configuration';
import { Route } from 'type-route';
import { errorLink } from './errorLink';
import PossibleTypes from './possibleTypes.json';
import { typePolicies } from './typePolicies';

let queryTypeCache = [] as any[];

const httpLink = createHttpLink({
  uri: '/graphql/',
});

const wsLink = new GraphQLWsLink(
  createClient({
    url:
      (window.location.protocol === 'https:' ? 'wss://' : 'ws://') +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '') +
      '/graphql/',
    // Don't connect until it is actually needed.
    lazy: true,
    retryAttempts: 5,
  })
);

const uploadLink = createUploadLink({
  headers: {
    // Required for uploads to work since Hot Chocolate 13.2
    'GraphQL-preflight': '1',
  },
  uri: '/graphql/',
});

const logQueries = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    let nested = data!.data ?? {};
    let array = nested[Object.keys(nested)[0]] ?? [];

    if (array.length > 0) {
      const query = operation.query.loc?.source.body;
      const type = array[0].__typename;

      const any =
        queryTypeCache.filter((q) => q.query === query && q.type === type)
          .length > 0;

      if (!any) {
        queryTypeCache.push({ query, variables: operation.variables, type });
      }
    }

    return data;
  });
});

export interface QueryContext {
  hideErrors?: boolean;
}

const uploadVsNonUploadLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition!.variableDefinitions!.filter(
        (v) => (v.type as any)?.type?.name?.value === 'Upload'
      ).length > 0
    );
  },
  uploadLink,
  httpLink
);

export const cache = new InMemoryCache({
  possibleTypes: PossibleTypes,
  typePolicies: typePolicies,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  uploadVsNonUploadLink
);

let route: Route<typeof routes>;

const authLink = setContext((_, { headers }) => {
  if (!route) route = session.getInitialRoute();

  session.listen((nextRoute) => {
    route = nextRoute;
  });

  return {
    headers: {
      ...headers,
      ...(configurationCompaniesEditRoutes.has(route) && {
        'company-editing-id': route?.params.companyId ?? '',
      }),
      ...(routes.configurationCompaniesDashboard.name === route.name && {
        'company-editing-id': route?.params.companyId ?? '',
      }),
    },
  };
});

export const client = new ApolloClient({
  link: from([authLink, logQueries, errorLink, splitLink]),
  connectToDevTools: true,
  cache: cache,
  credentials: 'include',
});
