import { lazy } from 'react';
import { widgetManager } from 'modules/dashboards/widgetManager/widgetManager';
import { WidgetGroupIds } from '../../dashboards/widgetManager/widgetGroups';

const WebOrders = lazy(() => import('./dashboardWidgets/WebOrders'));

widgetManager.addWidgetLoader((e) => {
  e.addWidget(
    {
      id: 'WebOrders',
      title: 'Web Order',
      groupId: WidgetGroupIds.Sales,
    },
    WebOrders,
    { minW: 6, minH: 4 }
  );
});
