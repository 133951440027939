import { lazy } from 'react';
import { WidgetGroupIds } from 'modules/dashboards/widgetManager/widgetGroups';
import { widgetManager } from 'modules/dashboards/widgetManager/widgetManager';

const PaymentsWidget = lazy(() => import('./dashboardWidgets/Payments'));
const PaymentsConfig = lazy(
  () => import('./dashboardWidgets/Payments/ConfigPage')
);

widgetManager.addWidgetLoader((e) => {
  e.addWidget(
    {
      id: 'Payments',
      title: 'Payments',
      groupId: WidgetGroupIds.Sales,
    },
    PaymentsWidget,
    { minW: 6, minH: 4 },
    PaymentsConfig
  );
});
