import * as Sentry from '@sentry/react';

const enabled =
  import.meta.env.VITE_SENTRY_ENABLED !== undefined
    ? import.meta.env.VITE_SENTRY_ENABLED === 'true'
    : import.meta.env.MODE === 'production';

if (enabled) {
  Sentry.init({
    dsn: 'https://92df01b909ef0a58de281263f0e45abd@sentry.abc.software/2',
    tunnel: '/api/sentry/event',
    enabled,
    environment: import.meta.env.MODE,
  });
}

interface UserInfo {
  username?: string | null;
  companyName?: string | null;
}

export function setSentryUser(user: UserInfo) {
  if (!enabled) return;
  Sentry.setUser({
    username: user.username ?? undefined,
    Company: user.companyName ?? undefined,
    ip_address: '{{auto}}',
  });
  Sentry.setTag('company', user.companyName);
}

export function clearSentryUser() {
  if (!enabled) return;
  Sentry.setUser(null);
}
