import { FC, useContext } from 'react';
import { routes } from 'app/routes';
import { purchasesRoutes } from 'app/routes/purchases';
import { AppContext } from 'app/AppContext';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuItem from '../Sidebar/MenuItem';

export const Purchases: FC = () => {
  const { canViewBills, canViewPurchaseOrders } = useContext(AppContext);

  if (!canViewBills && !canViewPurchaseOrders) return null;

  return (
    <MenuItem
      to={routes.purchasesDashboard()}
      group={purchasesRoutes}
      label="Purchases"
      icon={<InboxIcon />}
      isParent
    />
  );
};

export default Purchases;
