import { createGroup } from 'type-route';
import { routes } from './index';

export const signedOut = createGroup([
  routes.logIn,
  routes.logInForgotPassword,
  routes.logInForgotPasswordReset,
  routes.register,
  routes.welcome,
]);
