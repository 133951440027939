import DashboardIcon from '@mui/icons-material/Dashboard';
import { FC, useContext } from 'react';
import { routes } from 'app/routes';
import {
  inventoryItemRoutes,
  inventoryRoutes,
  inventorySerialNumbersRoutes,
} from 'app/routes/inventory';
import { AppContext } from 'app/AppContext';
import List from '@mui/material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import MenuItem from '../Sidebar/MenuItem';

export const Inventory: FC = () => {
  const { canViewItems } = useContext(AppContext);

  if (!canViewItems) return null;

  return (
    <MenuItem
      group={inventoryRoutes}
      label="Inventory"
      icon={<ListAltIcon />}
      isParent
    >
      <List disablePadding dense>
        <MenuItem
          label="Dashboard"
          icon={<DashboardIcon />}
          to={routes.inventoryDashboard()}
        />
        <MenuItem
          label="Items"
          to={routes.inventoryItemsEdit()}
          icon={<ListAltIcon />}
          group={inventoryItemRoutes}
        />
        <MenuItem
          label="Serial Numbers"
          icon={<QrCode2Icon />}
          to={routes.inventorySerialNumbersDashboard()}
          group={inventorySerialNumbersRoutes}
        />
      </List>
    </MenuItem>
  );
};
export default Inventory;
