import {
  mergeConnection,
  mergeConnectionUnique,
  mergeImportStatus,
} from './customMerges';
import { StrictTypedTypePolicies } from './queryTypePolicies';
import { companyConfigurationValidation } from './typePolicies/companyConfigurationValidation';

export const typePolicies: StrictTypedTypePolicies = {
  WorkOrderShipment: {
    keyFields: ['id'],
  },
  DashboardWidget: { keyFields: false },
  DashboardLayout: { keyFields: false },
  StatisticValue: { keyFields: false },
  LabeledStatisticValue: { keyFields: false },
  Migration: {
    keyFields: ['id', 'companyId'],
  },
  Query: {
    keyFields: false,
    fields: {
      userWhoAmI: {
        keyArgs: false,
      },
      customer: {
        keyArgs: ['id'],
      },
      customerSearch: {
        keyArgs: ['searchBy'],
        merge: mergeConnection,
      },
      vendorSearch: {
        keyArgs: ['searchBy'],
        merge: mergeConnection,
      },
      nameSearch: {
        keyArgs: ['searchBy'],
        merge: mergeConnection,
      },
      employeeSearch: {
        keyArgs: ['searchBy'],
        merge: mergeConnection,
      },
      invoices: {
        keyArgs: ['where', 'order'],
        merge: mergeConnectionUnique,
      },
      invoiceSearch: {
        keyArgs: ['searchBy', 'where', 'order'],
        merge: mergeConnectionUnique,
      },
      workOrders: {
        keyArgs: ['where', 'order'],
        merge: mergeConnectionUnique,
      },
      workOrderSearch: {
        keyArgs: ['searchBy', 'where', 'order'],
        merge: mergeConnectionUnique,
      },
      items: {
        keyArgs: ['where', 'order', 'searchBy'],
        merge: mergeConnectionUnique,
      },
      serialNumbers: {
        keyArgs: ['where', 'order'],
        merge: mergeConnectionUnique,
      },
      jobs: {
        keyArgs: ['where', 'order'],
        merge: mergeConnectionUnique,
      },
      purchaseOrders: {
        keyArgs: ['where', 'order'],
        merge: mergeConnectionUnique,
      },
      reports: {
        keyArgs: ['where', 'order', 'companyId'],
        merge: mergeConnection,
      },
      payments: {
        keyArgs: ['where', 'order'],
        merge: mergeConnectionUnique,
      },
      ledgerEntryDetails: {
        keyArgs: ['where', 'order'],
        merge: mergeConnectionUnique,
      },
    },
  },
  Subscription: {
    keyFields: false,
    fields: {
      searchIndexQueueLength: {
        keyArgs: false,
        merge: (existing, incoming) => incoming,
      },
    },
  },
  CustomerTop: {
    // If we allow them to be cached by ID, if there are multiple widgets loaded, different date ranges where the
    // data is actually different for the same ID will cause erroneous data.
    keyFields: false,
  },
  VendorTop: {
    // See note on CustomerTop.
    keyFields: false,
  },
  CompanyAuthorizationPolicy: {
    keyFields: ['companyId', 'userId', 'policyId'],
  },
  InvoiceDetail: {
    keyFields: ['id', 'lineNum'],
  },
  PurchaseOrderDetail: {
    keyFields: ['id', 'lineNum'],
  },
  IntegrationEnvironment: {
    keyFields: ['id', 'integrationId'],
  },
  Integration: { keyFields: false },
  IntegrationConfigField: { keyFields: false },
  ...companyConfigurationValidation,
  CompanyBackup: {
    keyFields: ['name', 'companyId'],
  },
  WorkOrderDetail: {
    keyFields: ['id', 'lineNum'],
  },
  ParentalPolicies: {
    keyFields: ['id', 'parentId'],
  },
  CompanyImportStatus: {
    keyFields: ['name'],
    fields: {
      dataImportStatus: {
        merge(existing, incoming) {
          return mergeImportStatus(existing, incoming);
        },
      },
      databaseCreationStatus: {
        merge(existing, incoming) {
          return mergeImportStatus(existing, incoming);
        },
      },
      migrationStatus: {
        merge(existing, incoming) {
          return mergeImportStatus(existing, incoming);
        },
      },
    },
  },
  Report: {
    keyFields: ['id', 'isCustom'],
  },
  CompanyStylesheetPreference: {
    keyFields: ['companyId'],
  },
  LedgerTransaction: {
    keyFields: ['id', 'ledger', 'ledgerType'],
  },
  DepositDetail: {
    keyFields: ['id', 'lineNum'],
  },
  LicenseKey: {
    keyFields: ['galaxyId'],
  },
  SearchIndexField: {
    // We really need to use the companyId and entityId in the cache, but there isn't an easy way to do so.
    keyFields: false,
  },
  DocumentGenerator: {
    keyFields: ['entityId', 'generatorId'],
  },
};
