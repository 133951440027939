import { useReducer } from 'react';

/**
 * If you are tempted to use this, you're probably doing something wrong. The use case that validated this hook's
 * existence is a case where we needed to keep "state" in a ref so that it wouldn't ever be stale. When we updated
 * the ref, we needed a way to force the UI to update.
 * @returns
 */
export function useForceRerender(): () => void {
  const [, force] = useReducer((x) => x + 1, 0);

  return force;
}
