import { StoreObject } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { Connection } from './pagingTypes';

export function mergeConnection<T = unknown>(
  existing: Connection<T> | undefined,
  incoming: Connection<T>,
): Connection<T> {
  if (!existing) {
    return incoming;
  }

  return {
    ...incoming,
    pageInfo: {
      ...incoming.pageInfo,
    },
    nodes: [...(existing.nodes || []), ...(incoming.nodes || [])],
  };
}

export type ExtendedConnection<IdType, T> = Connection<T> & {
  idCache: IdType[];
};

/**
 * Merges a connection but filters out duplicates.
 * @param existing
 * @param incoming
 * @param options
 */
export function mergeConnectionUnique<IdType, T extends { id: IdType }>(
  existing: ExtendedConnection<IdType, T> | undefined,
  incoming: Connection<T>,
  { readField }: { readField: ReadFieldFunction },
): ExtendedConnection<IdType, T> {
  if (!existing) {
    return {
      ...incoming,
      idCache:
        incoming.nodes?.map(
          (n) => readField('id', n as StoreObject) as IdType,
        ) || [],
    };
  }

  if (!incoming.nodes)
    return {
      idCache: [],
      ...incoming,
    };

  const nodes: T[] = existing.nodes ? [...existing.nodes] : [],
    idCache: IdType[] = [...existing.idCache];

  for (const node of incoming.nodes!) {
    const id = readField<IdType>('id', node as StoreObject) as IdType;
    if (!idCache.includes(id)) {
      nodes.push(node);
      idCache.push(id);
    } else if (process.env.NODE_ENV === 'development') {
      console.warn(
        `Duplicate node ID '${id}' when merging results. Do you need to be more explicit with your sorting?`,
      );
    }
  }

  return {
    ...incoming,
    pageInfo: {
      ...incoming.pageInfo,
    },
    nodes,
    idCache,
  };
}

export const mergeImportStatus = (incoming: any, existing: any) =>
  (incoming !== null &&
  incoming !== undefined &&
  incoming !== '' &&
  !existing.includes('finished') &&
  !incoming.includes('progress')
    ? incoming
    : existing) ?? '';
