import { Box } from '@mui/system';
import { AppContext } from 'app/AppContext';
import { routes, useRoute } from 'app/routes';
import { configurationRoutes } from 'app/routes/configuration';
import { ErrorBoundary } from 'lib/components/ErrorBoundary';
import { Loading } from 'lib/components/Loading';
import React, {
  CSSProperties,
  FC,
  PropsWithChildren,
  Suspense,
  useContext,
} from 'react';
import { useNavDrawerWidth } from 'lib/hooks/useNavDrawerWidth';
import Sidebar from './Sidebar';
import { appBarHeight, collapseTransition } from './Sidebar/styles';

const rootStyle: CSSProperties = {
  position: 'fixed',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'row',
};

const MainUi: FC<PropsWithChildren> = ({ children }) => {
  const { appBarVisible, companies, isSystemAdmin, sidebarDefaultState } =
    useContext(AppContext);

  const left = useNavDrawerWidth();

  const route = useRoute();
  if (companies?.length < 1 && isSystemAdmin && !configurationRoutes.has(route))
    routes.configurationCompaniesDashboard().push();

  return (
    <div style={rootStyle}>
      <Sidebar />
      <Box
        sx={{
          ...rootStyle,
          left: sidebarDefaultState === 'closed' ? 0 : left,
          transition: `left ${collapseTransition}`,
          overflow: 'hidden',
          top: appBarVisible ? appBarHeight : 0,
          flexDirection: 'column',
        }}
      >
        <ErrorBoundary>
          <Suspense fallback={<Loading />}>{children || <Loading />}</Suspense>
        </ErrorBoundary>
      </Box>
    </div>
  );
};
export default MainUi;
