import { Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, ReactNode } from 'react';
import logo from 'assets/logo-dark.svg';
import Page from 'lib/components/Page';

interface Props {
  title: string;
  children: ReactNode;
}

const Layout: FC<Props> = ({ title, children }) => {
  const theme = useTheme(),
    isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page title={title} disableAppBar>
      <Box
        sx={{
          display: 'flex',
          // center horizontally
          justifyContent: 'center',
          // center vertically
          alignContent: 'center',
          // Needed so that vertical centering works.
          height: '100%',
          flexWrap: 'wrap',
        }}
      >
        <Card
          variant={isSmall ? 'outlined' : 'elevation'}
          sx={{ border: isSmall ? 'none' : undefined }}
          elevation={15}
        >
          <CardContent>
            <Box sx={{ width: 325, margin: '0 30px' }}>
              <Box
                sx={{
                  width: '100%',
                  height: 160,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img style={{ height: 90 }} src={logo} alt="ABC Logo" />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {children}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

export default Layout;
