import { gql } from '@apollo/client';

export const getAppDataQuery = gql`
  query appData {
    canViewBankStatementReconciliation
    canViewLedgerEntries
    canViewBills
    canViewCustomers
    canViewDispatcher
    canViewEmployees
    canViewInvoices
    canViewItems
    canViewNames
    canViewPurchaseOrders
    canViewPayments
    canViewRentals
    canViewVendors
    canViewSalespeople
    canViewWorkOrders
    isSystemAdmin
    isCompanyAdmin
    companies {
      id
      legacyServerNumber
      name
      number
      publicUrl
      publicUrlInfo {
        host
      }
    }
    userWhoAmI {
      id
      userName
      theme {
        theme
        mode
      }
    }
    serverVersion {
      major
      minor
      build
      revision
    }
    featureFlags {
      id
    }
  }
`;
