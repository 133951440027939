import { useContext } from 'react';
import { AppContext } from 'app/AppContext';
import { drawerWidthMini, drawerWidthOpen } from 'app/MainUi/Sidebar/styles';

export function useNavDrawerWidth(): number {
  const { validCompanySelected, sidebarState } = useContext(AppContext);

  return validCompanySelected && sidebarState === 'mini'
    ? drawerWidthMini
    : sidebarState === 'open'
    ? drawerWidthOpen
    : 0;
}
