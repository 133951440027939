export class LocalStorageManager<TData> {
  private isLoaded: boolean = false;
  private data: Partial<TData> = {};

  constructor(private keyName: string, private defaultValues: TData) {}

  public get<K extends keyof TData>(key: K): TData[K] {
    if (!this.isLoaded) {
      this.data = JSON.parse(localStorage.getItem(this.keyName) || '{}');
      this.isLoaded = true;
    }

    if (this.data[key] !== undefined) {
      return this.data[key]!;
    }

    return this.defaultValues[key];
  }

  public set<K extends keyof TData>(key: K, value: TData[K]): void {
    this.data[key] = value;
    localStorage.setItem(this.keyName, JSON.stringify(this.data));
  }

  public clear(): void {
    localStorage.removeItem(this.keyName);
    this.data = {};
  }
}
