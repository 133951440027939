import { PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';

export const typography = {
  h1: {
    fontSize: '2.25rem',
  },
  h2: {
    fontSize: '1.9rem',
  },
  h3: {
    fontSize: '1.7rem',
  },
  h4: {
    fontSize: '1.5rem',
  },
  h5: {
    fontSize: '1.25rem',
  },
};

const Blue = (mode: PaletteMode) =>
  createTheme({
    palette: {
      mode: mode,
    },
    typography: typography,
  });

export default Blue;
