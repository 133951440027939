import { lazy } from 'react';
import { WidgetGroupIds } from 'modules/dashboards/widgetManager/widgetGroups';
import { widgetManager } from 'modules/dashboards/widgetManager/widgetManager';
const JobsWidget = lazy(() => import('./dashboardWidgets/Jobs'));
const JobsConfig = lazy(() => import('./dashboardWidgets/Jobs/ConfigPage'));

const JobsVehiclesWidget = lazy(() => import('./dashboardWidgets/VehicleJobs'));
const VehicleJobsConfig = lazy(
  () => import('./dashboardWidgets/VehicleJobs/ConfigPage')
);

widgetManager.addWidgetLoader((e) => {
  e.addWidget(
    {
      id: 'Jobs',
      title: 'Jobs',
      groupId: WidgetGroupIds.Jobs,
    },
    JobsWidget,
    { minW: 6, minH: 4 },
    JobsConfig
  );
  e.addWidget(
    {
      id: 'Vehicle Jobs',
      title: 'Vehicle Jobs',
      groupId: WidgetGroupIds.Jobs,
    },
    JobsVehiclesWidget,
    { minW: 6, minH: 4 },
    VehicleJobsConfig
  );
});
