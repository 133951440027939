import { createGroup } from 'type-route';
import { routes } from './index';

export const financesStatementReconciliationRoutes = createGroup([
  routes.financesStatementReconciliation,
  routes.financesStatementReconciliationEdit,
  routes.financesStatementReconciliationReview,
]);

export const financesAccountsRoutes = createGroup([
  routes.financesAccountsDashboard,
  routes.financesAccountsEdit,
]);

export const financesLedgerEntries = createGroup([
  routes.financesLedgerEntryEdit,
  routes.financesLedgerEntryNew,
]);

export const financesRoutes = createGroup([
  routes.financesDashboard,
  { ...financesLedgerEntries },
  { ...financesAccountsRoutes },
  { ...financesStatementReconciliationRoutes },
  routes.financesLedgerEntryRegister,
]);
