import { useQuery } from '@apollo/client';
import { useMediaQuery, useTheme } from '@mui/material';
import { QueryContext } from 'lib/com/GraphQL';
import { LocalStorageManager } from 'lib/localStorage';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ThemeMode } from 'lib/com/GraphQL/queryTypes';
import { clearSentryUser, setSentryUser } from 'lib/com/Sentry';
import { AppDataQuery, AppDataQueryVariables } from './gqlTypes/queries';
import { getAppDataQuery } from './queries';
import { Theme, ThemeColors } from './Themes/types';

type SidebarState = 'open' | 'mini' | 'closed';

export type State = RecursiveOmit<AppDataQuery, '__typename'> & {
  appBarVisible: boolean;
  companyId: string;
  isDevelopMode: boolean;
  theme: Theme;
  setTheme: (theme: Theme) => void;
  sidebarState: SidebarState;
  setSidebarState: (state: SidebarState) => void;
  sidebarDefaultState: SidebarState;
  validCompanySelected: boolean;
};

const defaultState: State = {
  appBarVisible: false,
  canViewBills: false,
  canViewBankStatementReconciliation: false,
  canViewLedgerEntries: false,
  canViewCustomers: false,
  canViewDispatcher: false,
  canViewEmployees: false,
  canViewInvoices: false,
  canViewItems: false,
  canViewNames: false,
  canViewPayments: false,
  canViewPurchaseOrders: false,
  canViewRentals: false,
  canViewSalespeople: false,
  canViewVendors: false,
  canViewWorkOrders: false,
  isCompanyAdmin: false,
  isDevelopMode:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  isSystemAdmin: false,
  companies: [],
  companyId: '',
  validCompanySelected: false,
  setTheme: () => {},
  sidebarState: 'closed',
  setSidebarState: () => {},
  sidebarDefaultState: 'closed',
  userWhoAmI: {
    id: '',
    userName: '',
    theme: {
      theme: 'blue',
      mode: ThemeMode.Light,
    },
  },
  theme: {
    color: 'blue',
    mode: 'light',
    inputs: 'standard',
  },
  serverVersion: {
    major: 0,
    minor: 0,
    revision: 0,
    build: 0,
  },
  featureFlags: [],
};

interface LocalStorageValues {
  sidebarState?: SidebarState;
}

const appStorage = new LocalStorageManager<LocalStorageValues>('app', {});

export const AppContext = createContext<State>(defaultState);

export const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const sidebarDefault: SidebarState = isMdUp
    ? 'open'
    : isSm
    ? 'mini'
    : 'closed';
  const [state, setState] = useState<State>(defaultState);
  const [sidebarState, setSidebarStateState] = useState<SidebarState>(
    sidebarDefault === 'open'
      ? appStorage.get('sidebarState') || sidebarDefault
      : sidebarDefault
  );

  const setSidebarState = useCallback(
    (state: SidebarState) => {
      setSidebarStateState(state);
      if (isMdUp) {
        // We only remember the state on md.
        appStorage.set('sidebarState', state);
      }
    },
    [isMdUp]
  );

  useEffect(() => {
    if (isMdUp) {
      setSidebarStateState(appStorage.get('sidebarState') || sidebarDefault);
    } else {
      setSidebarStateState(sidebarDefault);
    }
  }, [sidebarDefault, isMdUp]);

  const setTheme = useCallback(
    (theme: Theme) => {
      setState({ ...state, theme: { ...theme } });
    },
    [state]
  );

  const selectedCompanyId =
    state.companies.find(
      (c) => c.publicUrlInfo?.host === window.location.hostname
    )?.id ?? '';

  const { loading } = useQuery<AppDataQuery, AppDataQueryVariables>(
    getAppDataQuery,
    {
      // Not needed because UI errors won't be shown anyway, but is helpful to keep the console clean.
      context: { hideErrors: true } as QueryContext,
      errorPolicy: 'all',
      onCompleted: (data) => {
        if (!data.userWhoAmI) {
          clearSentryUser();
        } else {
          setSentryUser({
            companyName: data.companies.find((c) => c.id === selectedCompanyId)
              ?.name,
            username: data.userWhoAmI?.userName,
          });
        }
        setState((s) => ({
          ...s,
          ...data,
          userInfo: { ...data.userWhoAmI },
          theme: {
            ...s.theme,
            color: data.userWhoAmI?.theme.theme as ThemeColors,
            mode:
              data.userWhoAmI?.theme.mode === ThemeMode.Light
                ? 'light'
                : 'dark',
          },
        }));

        document.getElementById('loading-msg')?.remove();
      },
      onError: () => {
        document.getElementById('loading-msg')?.remove();
      },
    }
  );

  if (loading) return null;

  return (
    <AppContext.Provider
      value={{
        ...state,
        appBarVisible: sidebarDefault === 'closed',
        setTheme,
        companyId: selectedCompanyId,
        validCompanySelected: state.companies.some(
          (c) => c.id === selectedCompanyId
        ),
        sidebarState,
        setSidebarState,
        sidebarDefaultState: sidebarDefault,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
