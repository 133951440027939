import {
  ConfigPage,
  LayoutConfig,
  WidgetInfo,
  WidgetType,
} from '../types/widget';
import {
  WidgetGroupDetails,
  WidgetGroupIds,
  WidgetGroups,
} from './widgetGroups';
import { EventEmitter } from 'lib/eventEmitter';
import { LoadWidgetsEvent } from './LoadWidgetsEvent';

type Events = {
  loadWidgets: LoadWidgetsEvent;
};

type WidgetLoader = (event: LoadWidgetsEvent) => void;
type WidgetResult = [widget: WidgetType | false, config?: LayoutConfig];

export class WidgetManager {
  private widgets = new Map<string, WidgetResult>();
  private configPages = new Map<string, ConfigPage>();
  private info = new Map<string, WidgetInfo>();
  private groupedInfo = new Map<WidgetGroupIds | string, string[]>();

  private eventEmitter = new EventEmitter<Events>();

  constructor() {
    for (let widgetGroup of WidgetGroups) {
      this.groupedInfo.set(widgetGroup.id, []);
    }
  }

  public addWidgetLoader(loader: WidgetLoader): void {
    this.eventEmitter.on('loadWidgets', loader);
  }

  public getWidget(id: string): WidgetResult {
    this.initWidgets();
    if (this.widgets.has(id)) {
      return this.widgets.get(id)!;
    }
    return [false];
  }

  public getConfigPage(widgetId: string): ConfigPage | false {
    this.initWidgets();
    if (this.configPages.has(widgetId)) {
      return this.configPages.get(widgetId)!;
    }
    return false;
  }

  public getInfo(widgetId: string): WidgetInfo | false {
    this.initWidgets();
    return this.info.get(widgetId) || false;
  }

  public getGroups(): WidgetGroupDetails[] {
    this.initWidgets();
    const list: WidgetGroupDetails[] = [];

    for (let group of WidgetGroups) {
      const groupInfo = this.groupedInfo.get(group.id);
      if (!groupInfo || groupInfo.length === 0) {
        // This group has no widgets.
        continue;
      }
      const g: WidgetGroupDetails = {
        ...group,
        widgetInfo: [],
      };
      for (let widgetId of groupInfo) {
        const info = this.info.get(widgetId);
        info && g.widgetInfo.push(info);
      }
      g.widgetInfo.sort((a, b) => a.title.localeCompare(b.title));
      list.push(g);
    }

    list.sort((a, b) => a.title.localeCompare(b.title));

    return list;
  }

  private initWidgets(): void {
    if (this.widgets.size > 0) {
      return;
    }

    this.eventEmitter.emit(
      'loadWidgets',
      new LoadWidgetsEvent(this.addWidget.bind(this))
    );
  }

  private addWidget(
    info: WidgetInfo,
    widget: WidgetType,
    layoutConfig?: LayoutConfig,
    configPage?: ConfigPage
  ): void {
    if (this.widgets.has(info.id)) {
      throw new Error(`Duplicate widget ID: '${info.id}'`);
    }
    this.widgets.set(info.id, [widget, layoutConfig]);

    if (!this.groupedInfo.has(info.groupId)) {
      throw new Error(
        `Invalid groupId '${info.groupId}'. Add the group to the widgetGroups.ts->WidgetGroups const.`
      );
    }
    this.info.set(info.id, info);
    this.groupedInfo.get(info.groupId)!.push(info.id);

    if (configPage) {
      this.configPages.set(info.id, configPage);
    }
  }
}

export const widgetManager = new WidgetManager();
