import { createRouter, defineRoute, param } from 'type-route';
import { dashboardParams, dashboardPath } from './routeHelpers/dashboardRoute';
import { listParams, listPath } from './routeHelpers/listRoute';

const logIn = defineRoute('/login');
const register = defineRoute(
  {
    returnUrl: param.query.optional.string.default(''),
    signin: param.query.optional.boolean.default(true),
  },
  () => '/register',
);
const logOut = defineRoute('/logout');

const dashboardsHome = defineRoute(dashboardParams, (p) => [
  `/dashboard/${p.id}`,
  '/',
]);

const sales = defineRoute('/sales');
const dispatcher = sales.extend('/dispatcher');
const rentals = defineRoute('/rentals/notifications');
const invoices = sales.extend('/invoices');
const payments = sales.extend('/payments');
const workOrders = sales.extend('/work-orders');
const salespeople = sales.extend('/salespeople');

const jobs = defineRoute('/jobs');

const purchases = defineRoute('/purchases');

const inventory = defineRoute('/inventory');
const items = inventory.extend('/items');
const serialNumbers = inventory.extend('/serial-numbers');

const contacts = defineRoute('/contacts');
const customers = contacts.extend('/customers');
const names = contacts.extend('/names');
const vendors = contacts.extend('/vendors');

const payroll = defineRoute('/payroll');
const employees = payroll.extend('/employees');

const finances = defineRoute('/finances');
const financesStatementReconciliation = finances.extend(
  '/statement-reconcilation',
);
const financesStatementReconciliationEdit =
  financesStatementReconciliation.extend(
    { statementId: param.path.string },
    (p) => `/${p.statementId}/edit`,
  );
const financesStatementReconciliationReview =
  financesStatementReconciliation.extend(
    { statementId: param.path.string },
    (p) => `/${p.statementId}/review`,
  );

const reports = defineRoute('/reports');
const reportsLegacy = defineRoute('/reports');

const systemConfig = defineRoute('/companies');
const configuration = defineRoute('/configuration');
const configurationDashboard = configuration.extend(['/dashboard', '/']);
const configurationCompanies = configuration.extend('/companies');
const configurationCompaniesWizards = configurationCompanies.extend('/wizards');
const configurationCompaniesWizardsDashboard =
  configurationCompaniesWizards.extend(
    { legacyServerId: param.query.string },
    () => ['/dashboard', '/'],
  );
const configurationUsers = configuration.extend('/users');

const configurationDevices = configuration.extend('/devices');

const profile = defineRoute('/profile');

const debug = defineRoute('/_debug');

export const { RouteProvider, useRoute, routes, session } = createRouter({
  dashboardsHome,

  salesDashboard: sales.extend(dashboardParams, dashboardPath),
  salesDispatcherDashboard: dispatcher.extend(['/dashboard', '/']),
  salesDispatcherBoard: dispatcher.extend('/board'),
  salesInvoicesDashboard: invoices.extend(dashboardParams, dashboardPath),
  salesInvoicesEdit: invoices.extend(
    { id: param.path.string, section: param.query.optional.string },
    (p) => `/${p.id}/edit`,
  ),
  salesInvoicesOpen: invoices.extend('/open'),
  salesPaymentsDashboard: payments.extend(dashboardParams, dashboardPath),
  salesPaymentsEdit: payments.extend(
    { id: param.path.string, section: param.query.optional.string },
    (p) => `/${p.id}/edit`,
  ),
  salesVirtualTerminal: sales.extend(
    {
      transactionId: param.query.string,
      amount: param.query.number,
    },
    () => `/virtual-terminal`,
  ),
  rentals,
  salesWorkOrdersDashboard: workOrders.extend(dashboardParams, dashboardPath),
  salesWorkOrdersEdit: workOrders.extend(
    { id: param.path.string, section: param.query.optional.string },
    (p) => `/${p.id}/edit`,
  ),
  salesSalespeopleEdit: salespeople.extend(
    { id: param.query.optional.string },
    () => '',
  ),
  salesSalespeopleNew: salespeople.extend('/new'),

  purchasesDashboard: purchases.extend(dashboardParams, dashboardPath),
  purchasesEdit: purchases.extend(
    { id: param.path.string, section: param.query.optional.string },
    (p) => `/${p.id}/edit`,
  ),
  inventoryDashboard: inventory.extend(dashboardParams, dashboardPath),
  inventorySerialNumbersDashboard: serialNumbers.extend(
    dashboardParams,
    dashboardPath,
  ),
  inventoryItemsNew: items.extend('/new'),
  inventoryItemsEdit: items.extend(
    { id: param.query.optional.string },
    () => '',
  ),

  payrollDashboard: payroll.extend(dashboardParams, dashboardPath),
  payrollEmployeesList: employees.extend(listParams, listPath),
  payrollEmployeesEdit: employees.extend(
    { id: param.query.optional.string },
    () => `/edit`,
  ),
  payrollEmployeesNew: employees.extend('/new'),

  contactsDashboard: contacts.extend(dashboardParams, dashboardPath),
  contactsCustomersDashboard: customers.extend(dashboardParams, dashboardPath),
  contactsCustomersList: customers.extend(listParams, listPath),
  contactsCustomersEdit: customers.extend(
    { id: param.query.optional.string },
    () => `/edit`,
  ),
  contactsCustomersNew: customers.extend('/new'),

  contactsNamesDashboard: names.extend(dashboardParams, dashboardPath),
  contactsNamesEdit: names.extend(
    { id: param.query.optional.string },
    (p) => `/edit`,
  ),
  contactsNamesNew: names.extend('/new'),
  contactsNamesList: names.extend(listParams, listPath),

  contactsVendorsDashboard: vendors.extend(dashboardParams, dashboardPath),
  contactsVendorsEdit: vendors.extend(
    { id: param.query.optional.string },
    (p) => `/edit`,
  ),
  contactsVendorsNew: vendors.extend('/new'),
  contactsVendorsList: vendors.extend(listParams, listPath),

  financesDashboard: finances.extend(dashboardParams, dashboardPath),
  financesAccountsDashboard: finances.extend('/accounts'),
  financesAccountsEdit: configuration.extend(
    { accountId: param.path.string },
    (p) => `/accounts/${p.accountId}`,
  ),
  financesStatementReconciliation,
  financesStatementReconciliationEdit,
  financesStatementReconciliationReview,

  financesLedgerEntryRegister: finances.extend(
    {
      glAccountId: param.path.string,
      id: param.path.optional.string,
    },
    (p) => `/register/${p.glAccountId}/${p.id}`,
  ),
  financesLedgerEntryNew: finances.extend(
    { type: param.path.string },
    (p) => `/ledger/${p.type}/new`,
  ),
  financesLedgerEntryEdit: finances.extend(
    { type: param.path.string, id: param.path.string },
    (p) => `/ledger/${p.type}/${p.id}`,
  ),

  reportsDashboard: reports.extend(
    {
      categoryId: param.query.optional.number,
    },
    () => ['/dashboard', '/'],
  ),
  reportsViewer: reports.extend(
    {
      id: param.path.string,
      defaultParams: param.query.optional.array.string,
    },
    (p) => `/viewer/${p.id}`,
  ),
  jobsDashboard: jobs.extend(dashboardParams, dashboardPath),
  jobsEdit: jobs.extend(
    { id: param.path.string, section: param.query.optional.string },
    (p) => `/${p.id}/edit`,
  ),
  jobsVehicleEdit: jobs.extend(
    { id: param.path.string, section: param.query.optional.string },
    (p) => `/${p.id}/vehicle-edit`,
  ),
  jobsOpen: jobs.extend('/open'),

  reportsLegacy,

  client5: defineRoute('/client'),

  systemConfig,

  configurationDashboard,
  configurationBackups: configuration.extend(
    { sectionId: param.path.optional.string },
    (p) => `/backups/${p.sectionId}`,
  ),
  configurationNlog: configuration.extend('/nlog'),
  configurationUsersDashboard: configurationUsers.extend(['/dashboard', '/']),
  configurationUsersEdit: configurationUsers.extend(
    { userId: param.path.string },
    (p) => `/${p.userId}/edit`,
  ),
  configurationUsersEditProfile: configurationUsers.extend(
    { userId: param.path.string },
    (p) => `/${p.userId}/edit/profile`,
  ),
  configurationUsersEditReports: configurationUsers.extend(
    { userId: param.path.string },
    (p) => `/${p.userId}/edit/reports`,
  ),
  configurationUsersEditPermissions: configurationUsers.extend(
    { userId: param.path.string },
    (p) => `/${p.userId}/edit/permissions`,
  ),
  configurationUsersEditAuthorization: configurationUsers.extend(
    { userId: param.path.string },
    (p) => `/${p.userId}/edit/authorization`,
  ),

  configurationDevicesDashboard: configurationDevices.extend(
    dashboardParams,
    dashboardPath,
  ),

  configurationDevicesEdit: configurationDevices.extend(
    { id: param.query.optional.string },
    () => '',
  ),
  configurationDevicesNew: configurationDevices.extend('/new'),

  configurationCompaniesDashboard: configurationCompanies.extend(
    { companyId: param.query.optional.string },
    (p) => ['/dashboard', '/'],
  ),
  configurationLicenseKeys: configurationCompanies.extend(['/licensekeys']),
  configurationCompaniesDatabase: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/database`,
  ),

  configurationCompaniesIntegrations: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/integrations`,
  ),

  configurationCompaniesIntegrationsEdit: configurationCompanies.extend(
    {
      companyId: param.path.string,
      featureId: param.path.string,
      integrationId: param.path.string,
    },
    (p) => `/${p.companyId}/integrations/${p.featureId}/${p.integrationId}`,
  ),
  configurationCompaniesConnectionsEdit: configurationCompanies.extend(
    { companyId: param.path.string, connectionId: param.path.string },
    (p) => `/${p.companyId}/connections/${p.connectionId}`,
  ),
  configurationCompaniesConnectionsNew: configurationCompanies.extend(
    {
      companyId: param.path.string,
      step: param.query.string,
      type: param.query.string,
    },
    (p) => `/${p.companyId}/connections/new`,
  ),

  configurationCompaniesProfile: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/profile`,
  ),

  configurationCompaniesReportsStylesheets: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/reports/stylesheets`,
  ),

  configurationCompaniesReportsStylesheetsEdit: configurationCompanies.extend(
    { companyId: param.path.string, stylesheetId: param.path.string },
    (p) => `/${p.companyId}/reports/stylesheets/${p.stylesheetId}`,
  ),

  configurationCompaniesReportsCategories: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/reports/categories`,
  ),

  configurationCompaniesReportsCategoriesEdit: configurationCompanies.extend(
    { companyId: param.path.string, categoryId: param.path.string },
    (p) => `/${p.companyId}/reports/categories/${p.categoryId}`,
  ),

  configurationCompaniesReportsReports: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/reports/reports`,
  ),

  configurationCompaniesReportsReportsEdit: configurationCompanies.extend(
    {
      companyId: param.path.string,
      reportId: param.path.string,
      isCustom: param.query.boolean,
    },
    (p) => `/${p.companyId}/reports/reports/${p.reportId}`,
  ),
  configurationCompaniesUserGroups: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/usergroups`,
  ),

  configurationCompaniesSystemConfig: configurationCompanies.extend(
    {
      companyId: param.path.string,
      tabId: param.path.optional.string,
    },
    (p) => `/${p.companyId}/system-config${p.tabId ? '/' + p.tabId : ''}`,
  ),
  configurationCompaniesValidation: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/validation`,
  ),
  configurationCompaniesValidationList: configurationCompanies.extend(
    { companyId: param.path.string, entityId: param.path.string },
    (p) => `/${p.companyId}/validation/${p.entityId}`,
  ),
  configurationCompaniesValidationEdit: configurationCompanies.extend(
    {
      companyId: param.path.string,
      entityId: param.path.string,
      validatorId: param.path.string,
    },
    (p) => `/${p.companyId}/validation/${p.entityId}/${p.validatorId}`,
  ),
  configurationCompaniesSearch: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/search`,
  ),
  configurationCompaniesSearchEdit: configurationCompanies.extend(
    {
      companyId: param.path.string,
      entityId: param.path.string,
    },
    (p) => `/${p.companyId}/search/${p.entityId}`,
  ),

  configurationPrintChannels: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/print-channels`,
  ),
  configurationPrintChannelEdit: configurationCompanies.extend(
    { companyId: param.path.string, printChannelId: param.path.number },
    (p) => `/${p.companyId}/print-channels/${p.printChannelId}`,
  ),

  configurationDocumentGeneration: configurationCompanies.extend(
    {
      companyId: param.path.string,
    },
    (p) => `/${p.companyId}/docgen`,
  ),
  configurationDocumentGenerationEntity: configurationCompanies.extend(
    { companyId: param.path.string, entityId: param.path.string },
    (p) => `/${p.companyId}/docgen/${p.entityId}`,
  ),
  configurationDocumentGenerationGenerator: configurationCompanies.extend(
    {
      companyId: param.path.string,
      entityId: param.path.string,
      generatorId: param.path.string,
    },
    (p) => `/${p.companyId}/docgen/${p.entityId}/${p.generatorId}`,
  ),

  configurationCompaniesUserGroupsEdit: configurationCompanies.extend(
    { companyId: param.path.string, groupId: param.path.string },
    (p) => `/${p.companyId}/usergroups/${p.groupId}`,
  ),
  configurationCompaniesWebStores: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/web-stores`,
  ),
  configurationCompaniesWebStoresEdit: configurationCompanies.extend(
    { companyId: param.path.string, webStoreId: param.path.string },
    (p) => `/${p.companyId}/web-stores/${p.webStoreId}`,
  ),

  configurationCompaniesFormTemplates: configurationCompanies.extend(
    { companyId: param.path.string },
    (p) => `/${p.companyId}/form-templates`,
  ),
  configurationCompaniesFormTemplatesNew: configurationCompanies.extend(
    { companyId: param.path.string, step: param.query.string },
    (p) => `/${p.companyId}/form-templates/new`,
  ),
  configurationCompaniesFormTemplatesEdit: configurationCompanies.extend(
    { companyId: param.path.string, templateId: param.path.string },
    (p) => `/${p.companyId}/form-templates/${p.templateId}/edit`,
  ),

  configurationCompaniesLegacyServerEdit: configurationCompanies.extend(
    { legacyServerId: param.path.string },
    (p) => `/legacy-servers/${p.legacyServerId}/edit`,
  ),
  configurationCompaniesLegacyServerLogs: configurationCompanies.extend(
    { serverId: param.path.string },
    (p) => `/legacy-servers/${p.serverId}/logs`,
  ),
  configurationCompaniesLegacyServerAdd: configurationCompanies.extend(
    {
      step: param.query.string,
    },
    (p) => '/legacy-servers/add',
  ),

  configurationCompaniesWizardsDashboard,
  configurationCompaniesWizardsDuplicate: configurationCompaniesWizards.extend(
    {
      legacyServerId: param.query.string,
      step: param.query.string,
    },
    (p) => `/duplicate`,
  ),
  configurationCompaniesWizardsRestore: configurationCompaniesWizards.extend(
    {
      legacyServerId: param.query.string,
      step: param.query.string,
    },
    (p) => `/restore`,
  ),
  configurationCompaniesImport: configurationCompaniesWizards.extend(
    {
      legacyServerId: param.query.string,
    },
    (p) => '/import',
  ),
  configurationCompaniesTaxFormsImport: configurationCompaniesWizards.extend(
    {
      legacyServerId: param.query.string,
    },
    (p) => '/tax-forms-import',
  ),
  configurationCompaniesWizardsNew: configurationCompaniesWizards.extend(
    {
      legacyServerId: param.query.string,
      step: param.query.string,
      name: param.query.optional.string,
      licenseKeyId: param.query.optional.string,
      keyCompanyId: param.query.optional.string,
    },
    (p) => `/new`,
  ),

  profile,
  profileSecurity: profile.extend('/security'),
  profileConfirmEmail: profile.extend(
    { userId: param.query.string, code: param.query.string },
    (p) => '/confirm-email',
  ),

  logIn: logIn.extend(
    {
      returnUrl: param.query.optional.string.default(''),
      code: param.query.optional.string,
      iss: param.query.optional.string,
      state: param.query.optional.string,
    },
    () => '',
  ),
  logInForgotPassword: logIn.extend('/forgot-password'),
  logInForgotPasswordReset: logIn.extend(
    { code: param.query.string },
    (p) => `/forgot-password`,
  ),
  logOut: logOut,
  register: register,
  welcome: logIn.extend('/welcome'),

  debug,
  debugTheme: debug.extend('/theme'),
  debugGeocoding: debug.extend('/geocoding'),
  debugSMS: debug.extend(
    {
      number: param.query.optional.string,
    },
    (p) => '/sms',
  ),
  debugThrowErrors: debug.extend('/throw-errors'),
  debugUpgrades: debug.extend('/upgrades'),
  debugDatabase: debug.extend('/database'),
  debugC9VTerm: debug.extend('/c9vterm'),
  debugSearchIndex: debug.extend('/search-queue'),
});
