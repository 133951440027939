import { createGroup } from 'type-route';
import { routes } from './index';

export const salesDispatcherRoutes = createGroup([
  routes.salesDispatcherDashboard,
  routes.salesDispatcherBoard,
]);

export const salesInvoicesRoutes = createGroup([
  routes.salesInvoicesDashboard,
  routes.salesInvoicesEdit,
  routes.salesInvoicesOpen,
]);

export const salesPaymentsRoutes = createGroup([
  routes.salesPaymentsDashboard,
  routes.salesPaymentsEdit,
]);

export const salesWorkOrdersRoutes = createGroup([
  routes.salesWorkOrdersDashboard,
  routes.salesWorkOrdersEdit,
]);

export const salesSalespeopleRoutes = createGroup([
  routes.salesSalespeopleNew,
  routes.salesSalespeopleEdit,
]);

export const salesRoutes = createGroup([
  routes.salesDashboard,
  { ...salesDispatcherRoutes },
  { ...salesInvoicesRoutes },
  { ...salesPaymentsRoutes },
  { ...salesWorkOrdersRoutes },
  { ...salesSalespeopleRoutes },
]);
