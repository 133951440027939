import { Component, ErrorInfo, ReactNode } from 'react';
import { Alert, Box } from '@mui/material';

export interface Props {
  children: ReactNode;
  errorComponent?: ReactNode;
  message?: string;
}

export interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.errorComponent || (
          <Box>
            <Alert severity="error">
              {this.props.message ||
                "We're sorry! An error has occurred while loading this component."}
            </Alert>
          </Box>
        )
      );
    }

    return this.props.children;
  }
}
