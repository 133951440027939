import {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';

interface SidebarContextState {
  addModule: (name: string, children: ReactNode) => void;
  setActive: (name: string) => void;
  activeModule: string;
  moduleItems?: ReactNode;
}

export const SidebarContext = createContext<SidebarContextState>({
  addModule: (name: string, children: ReactNode) => {},
  activeModule: '',
  setActive: (name: string) => {},
});

export const SidebarContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const modules = useRef<Map<string, ReactNode>>(new Map());
  const [activeModule, setActiveModule] = useState<string>('');

  const addModule = useCallback((name: string, children: ReactNode) => {
      modules.current.set(name, children);
    }, []),
    setActive = useCallback(
      (name: string) => {
        if (activeModule === name) {
          setActiveModule('');
        } else {
          setActiveModule(name);
        }
      },
      [activeModule]
    );

  const moduleItems =
    activeModule && modules.current.has(activeModule)
      ? modules.current.get(activeModule)
      : undefined;

  return (
    <SidebarContext.Provider
      value={{ addModule, setActive, moduleItems, activeModule }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
