import { PunchClock } from '@mui/icons-material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DashboardIcon from '@mui/icons-material/Dashboard';

import List from '@mui/material/List';
import { FC, useContext } from 'react';
import { AppContext } from 'app/AppContext';
import { payrollRoutes } from 'app/routes/payroll';
import { routes } from 'app/routes';
import MenuItem from '../Sidebar/MenuItem';

interface Props {}

const Payroll: FC<Props> = () => {
  const { canViewEmployees } = useContext(AppContext);

  if (!canViewEmployees) {
    return null;
  }
  return (
    <MenuItem
      group={payrollRoutes}
      label="Payroll"
      icon={<PunchClock />}
      isParent
    >
      <List disablePadding dense>
        <MenuItem
          label="Dashboard"
          icon={<DashboardIcon />}
          to={routes.payrollDashboard()}
        />
        {canViewEmployees && (
          <MenuItem
            label="Employees"
            icon={<AssignmentIndIcon />}
            to={routes.payrollEmployeesList()}
          />
        )}
      </List>
    </MenuItem>
  );
};

export default Payroll;
