import { createGroup } from 'type-route';
import { routes } from './index';

export const jobRoutes = createGroup([
  routes.jobsDashboard,
  routes.jobsEdit,
  routes.jobsVehicleEdit,
  routes.jobsOpen,
  routes.jobsDashboard,
]);
