import { createGroup } from 'type-route';
import { routes } from './index';

export const debugRoutes = createGroup([
  routes.debug,
  routes.debugTheme,
  routes.debugGeocoding,
  routes.debugSMS,
  routes.debugThrowErrors,
  routes.debugUpgrades,
  routes.debugDatabase,
  routes.debugC9VTerm,
  routes.debugSearchIndex,
]);
