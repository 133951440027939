import React, { FC, ReactNode } from 'react';
import { Box, SxProps, Theme, Toolbar } from '@mui/material';
import { useSpacing } from 'lib/hooks/useSpacing';
import { ErrorBoundary } from './ErrorBoundary';

interface PageSectionProps {
  direction?: 'column' | 'row';
  toolbar?: React.ReactNode;
  disableGutters?: boolean;
  noScroll?: boolean;
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

const PageSection: FC<PageSectionProps> = ({
  disableGutters,
  direction,
  children,
  noScroll,
  sx,
  toolbar,
}) => {
  const { spacing } = useSpacing();
  const padding = disableGutters ? '0' : spacing;

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        overflow: toolbar || noScroll ? 'hidden' : 'auto',
        ...sx,
      }}
    >
      <ErrorBoundary message="Error rendering page component.">
        {toolbar && (
          <ErrorBoundary message="Error rendering toolbar.">
            <Toolbar disableGutters>{toolbar}</Toolbar>
          </ErrorBoundary>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: direction ?? 'column',
            flexGrow: 1,
            padding: padding,
            overflowX: 'auto',
            height: '100%',
            ...sx,
          }}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </Box>
      </ErrorBoundary>
    </Box>
  );
};

export default PageSection;
