import { Zoom } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { VFC } from 'react';

interface LoadingProps {
  title?: string;
  delay?: number;
}
export const Loading: VFC<LoadingProps> = ({ title, delay = 500 }) => {
  return (
    <Zoom in={true} style={{ transitionDelay: `${delay}ms` }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 20,
        }}
      >
        Downloading {title}
        <LinearProgress sx={{ width: 300, mt: 2 }} />
      </div>
    </Zoom>
  );
};
