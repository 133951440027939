/**
 * Enum for the transaction types instead of string constants scattered everywhere.
 */
enum TransactionTypes {
  Check = 'Check',
  Adjustment = 'Adjustment',
  BankTransfer = 'Bank Transfer',
}

export default TransactionTypes;
