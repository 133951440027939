export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: { input: any; output: any; }
  /** A coordinate is an array of positions. */
  Coordinates: { input: any; output: any; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: any; output: any; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: any; output: any; }
  Geometry: { input: any; output: any; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any; }
  /** A position is an array of numbers. There MUST be two or more elements. The first two elements are longitude and latitude, or easting and northing, precisely in that order and using decimal numbers. Altitude or elevation MAY be included as an optional third element. */
  Position: { input: any; output: any; }
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: { input: any; output: any; }
  URL: { input: any; output: any; }
  UUID: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type AccountBalance = {
  readonly __typename: 'AccountBalance';
  readonly available?: Maybe<Scalars['Decimal']['output']>;
  readonly current?: Maybe<Scalars['Decimal']['output']>;
  readonly isoCurrencyCode?: Maybe<Scalars['String']['output']>;
  readonly lastUpdatedDatetime?: Maybe<Scalars['DateTime']['output']>;
  readonly limit?: Maybe<Scalars['Decimal']['output']>;
  readonly unofficialCurrencyCode?: Maybe<Scalars['String']['output']>;
};

export enum AccountType {
  Credit = 'CREDIT',
  Depository = 'DEPOSITORY',
  Investment = 'INVESTMENT',
  Loan = 'LOAN',
  Other = 'OTHER',
  Undefined = 'UNDEFINED'
}

export enum AccountVerificationStatusEnum {
  AutomaticallyVerified = 'AUTOMATICALLY_VERIFIED',
  ManuallyVerified = 'MANUALLY_VERIFIED',
  PendingAutomaticVerification = 'PENDING_AUTOMATIC_VERIFICATION',
  PendingManualVerification = 'PENDING_MANUAL_VERIFICATION',
  Undefined = 'UNDEFINED',
  VerificationExpired = 'VERIFICATION_EXPIRED',
  VerificationFailed = 'VERIFICATION_FAILED'
}

export type ActionResultOfBoard = {
  readonly __typename: 'ActionResultOfBoard';
  readonly data?: Maybe<Board>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type ActionResultOfBoolean = {
  readonly __typename: 'ActionResultOfBoolean';
  readonly data: Scalars['Boolean']['output'];
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type ActionResultOfDevice = {
  readonly __typename: 'ActionResultOfDevice';
  readonly data?: Maybe<Device>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type ActionResultOfEmployee = {
  readonly __typename: 'ActionResultOfEmployee';
  readonly data?: Maybe<Employee>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type ActionResultOfLedgerEntry = {
  readonly __typename: 'ActionResultOfLedgerEntry';
  readonly data?: Maybe<LedgerEntry>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type ActionResultOfPrintChannel = {
  readonly __typename: 'ActionResultOfPrintChannel';
  readonly data?: Maybe<PrintChannel>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type ActionResultOfSalesperson = {
  readonly __typename: 'ActionResultOfSalesperson';
  readonly data?: Maybe<Salesperson>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type ActionResultOfUser = {
  readonly __typename: 'ActionResultOfUser';
  readonly data?: Maybe<User>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type Address = {
  readonly __typename: 'Address';
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly line1?: Maybe<Scalars['String']['output']>;
  readonly line2?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly zipCode?: Maybe<ZipCode>;
  readonly zipCodeId?: Maybe<Scalars['String']['output']>;
};

export type AddressFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<AddressFilterInput>>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly line1?: InputMaybe<StringOperationFilterInput>;
  readonly line2?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<AddressFilterInput>>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
  readonly zipCode?: InputMaybe<ZipCodeFilterInput>;
  readonly zipCodeId?: InputMaybe<StringOperationFilterInput>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type AuthenticatorAppSharedKeyInfo = {
  readonly __typename: 'AuthenticatorAppSharedKeyInfo';
  readonly key: Scalars['String']['output'];
  readonly qrCodeUri: Scalars['String']['output'];
};

export type BackupFileInfo = {
  readonly __typename: 'BackupFileInfo';
  readonly created: Scalars['DateTime']['output'];
  readonly name: Scalars['String']['output'];
  readonly size: Scalars['Long']['output'];
};

export type BankStatement = {
  readonly __typename: 'BankStatement';
  readonly credits: Scalars['Decimal']['output'];
  readonly date: Scalars['DateTime']['output'];
  readonly debits: Scalars['Decimal']['output'];
  readonly endBalance: Scalars['Decimal']['output'];
  readonly glAccountId: Scalars['ID']['output'];
  readonly id: Scalars['ID']['output'];
  readonly startBalance: Scalars['Decimal']['output'];
  readonly transactions?: Maybe<ReadonlyArray<Maybe<LedgerBankTransaction>>>;
};

export type BankStatementInput = {
  readonly credits: Scalars['Decimal']['input'];
  readonly date: Scalars['DateTime']['input'];
  readonly debits: Scalars['Decimal']['input'];
  readonly endBalance: Scalars['Decimal']['input'];
  readonly glAccountId: Scalars['ID']['input'];
  readonly startBalance: Scalars['Decimal']['input'];
};

export type BankStatementReportUrLs = {
  readonly __typename: 'BankStatementReportURLs';
  readonly pdf: Scalars['String']['output'];
  readonly screen: Scalars['String']['output'];
};

export enum BankStatementResetMode {
  All = 'ALL',
  Credits = 'CREDITS',
  Debits = 'DEBITS'
}

export type BankStatementSetTransactionInternalInput = {
  readonly bankStatementId: Scalars['ID']['input'];
  readonly id: Scalars['Decimal']['input'];
  readonly isInternal: Scalars['Boolean']['input'];
  readonly ledger: Scalars['Int']['input'];
  readonly ledgerType?: InputMaybe<Scalars['String']['input']>;
};

export type BankStatementSetTransactionReturnedInput = {
  readonly bankStatementId?: InputMaybe<Scalars['ID']['input']>;
  readonly id: Scalars['Decimal']['input'];
  readonly ledger: Scalars['Int']['input'];
  readonly ledgerType: Scalars['String']['input'];
};

export type BankStatementTransaction = {
  readonly __typename: 'BankStatementTransaction';
  readonly event: Scalars['String']['output'];
  readonly transaction: LedgerTransaction;
};

export type BankStatementUpdateInput = {
  readonly credits: Scalars['Decimal']['input'];
  readonly date: Scalars['DateTime']['input'];
  readonly debits: Scalars['Decimal']['input'];
  readonly endBalance: Scalars['Decimal']['input'];
  readonly glAccountId: Scalars['ID']['input'];
  readonly id: Scalars['ID']['input'];
  readonly startBalance: Scalars['Decimal']['input'];
};

export type BankStatementWithBalances = {
  readonly __typename: 'BankStatementWithBalances';
  readonly credits: Scalars['Decimal']['output'];
  readonly creditsDifference: Scalars['Decimal']['output'];
  readonly creditsInternal: Scalars['Decimal']['output'];
  readonly creditsReturned: Scalars['Decimal']['output'];
  readonly date: Scalars['DateTime']['output'];
  readonly debits: Scalars['Decimal']['output'];
  readonly debitsDifference: Scalars['Decimal']['output'];
  readonly debitsInternal: Scalars['Decimal']['output'];
  readonly debitsReturned: Scalars['Decimal']['output'];
  readonly endBalance: Scalars['Decimal']['output'];
  readonly glAccount: GlAccount;
  readonly glAccountId: Scalars['ID']['output'];
  readonly hasPrevious: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly isVerified: Scalars['Boolean']['output'];
  readonly startBalance: Scalars['Decimal']['output'];
};

export type BankStatementWithBalancesFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<BankStatementWithBalancesFilterInput>>;
  readonly credits?: InputMaybe<DecimalOperationFilterInput>;
  readonly creditsDifference?: InputMaybe<DecimalOperationFilterInput>;
  readonly creditsInternal?: InputMaybe<DecimalOperationFilterInput>;
  readonly creditsReturned?: InputMaybe<DecimalOperationFilterInput>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly debits?: InputMaybe<DecimalOperationFilterInput>;
  readonly debitsDifference?: InputMaybe<DecimalOperationFilterInput>;
  readonly debitsInternal?: InputMaybe<DecimalOperationFilterInput>;
  readonly debitsReturned?: InputMaybe<DecimalOperationFilterInput>;
  readonly endBalance?: InputMaybe<DecimalOperationFilterInput>;
  readonly glAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly hasPrevious?: InputMaybe<BooleanOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<BankStatementWithBalancesFilterInput>>;
  readonly startBalance?: InputMaybe<DecimalOperationFilterInput>;
};

export type BankStatementWithBalancesSortInput = {
  readonly credits?: InputMaybe<SortEnumType>;
  readonly creditsDifference?: InputMaybe<SortEnumType>;
  readonly creditsInternal?: InputMaybe<SortEnumType>;
  readonly creditsReturned?: InputMaybe<SortEnumType>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly debits?: InputMaybe<SortEnumType>;
  readonly debitsDifference?: InputMaybe<SortEnumType>;
  readonly debitsInternal?: InputMaybe<SortEnumType>;
  readonly debitsReturned?: InputMaybe<SortEnumType>;
  readonly endBalance?: InputMaybe<SortEnumType>;
  readonly glAccountId?: InputMaybe<SortEnumType>;
  readonly hasPrevious?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly startBalance?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type BankStatementsConnection = {
  readonly __typename: 'BankStatementsConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<BankStatementsEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<BankStatementWithBalances>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BankStatementsEdge = {
  readonly __typename: 'BankStatementsEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: BankStatementWithBalances;
};

export type BarcodeMatch = {
  readonly __typename: 'BarcodeMatch';
  readonly entityType: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
};

export type BarcodeResult = {
  readonly __typename: 'BarcodeResult';
  readonly hasMatch: Scalars['Boolean']['output'];
  readonly matches: ReadonlyArray<BarcodeMatch>;
};

export type BaseDate = {
  readonly endDate: Scalars['DateTime']['output'];
  readonly startDate: Scalars['DateTime']['output'];
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly weight?: Maybe<Scalars['Decimal']['output']>;
};

export type Board = {
  readonly __typename: 'Board';
  readonly boardId: Scalars['ID']['output'];
  readonly bundleNumber?: Maybe<Scalars['String']['output']>;
  readonly footage: Scalars['Decimal']['output'];
  readonly grade?: Maybe<Scalars['String']['output']>;
  readonly kilnDried?: Maybe<Scalars['String']['output']>;
  readonly length?: Maybe<Scalars['String']['output']>;
  readonly loadNumber: Scalars['ID']['output'];
  readonly productionRunNumber?: Maybe<Scalars['String']['output']>;
  readonly purchaseOrder?: Maybe<PurchaseOrder>;
  readonly purchaseOrderId: Scalars['ID']['output'];
  readonly species?: Maybe<Scalars['String']['output']>;
  readonly thickness?: Maybe<Scalars['String']['output']>;
  readonly vendorName?: Maybe<Scalars['String']['output']>;
  readonly width?: Maybe<Scalars['String']['output']>;
};

export type BoardAddInput = {
  readonly boardId: Scalars['ID']['input'];
  readonly bundleNumber?: InputMaybe<Scalars['String']['input']>;
  readonly footage: Scalars['Decimal']['input'];
  readonly grade?: InputMaybe<Scalars['String']['input']>;
  readonly kilnDried?: InputMaybe<Scalars['String']['input']>;
  readonly length?: InputMaybe<Scalars['String']['input']>;
  readonly loadNumber: Scalars['ID']['input'];
  readonly productionRunNumber: Scalars['String']['input'];
  readonly purchaseOrderId: Scalars['ID']['input'];
  readonly species?: InputMaybe<Scalars['String']['input']>;
  readonly thickness?: InputMaybe<Scalars['String']['input']>;
  readonly vendorName?: InputMaybe<Scalars['String']['input']>;
  readonly width?: InputMaybe<Scalars['String']['input']>;
};

export type BoardFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<BoardFilterInput>>;
  readonly boardId?: InputMaybe<IdOperationFilterInput>;
  readonly bundleNumber?: InputMaybe<StringOperationFilterInput>;
  readonly footage?: InputMaybe<DecimalOperationFilterInput>;
  readonly grade?: InputMaybe<StringOperationFilterInput>;
  readonly kilnDried?: InputMaybe<StringOperationFilterInput>;
  readonly length?: InputMaybe<StringOperationFilterInput>;
  readonly loadNumber?: InputMaybe<IdOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<BoardFilterInput>>;
  readonly productionRunNumber?: InputMaybe<StringOperationFilterInput>;
  readonly purchaseOrderId?: InputMaybe<IdOperationFilterInput>;
  readonly species?: InputMaybe<StringOperationFilterInput>;
  readonly thickness?: InputMaybe<StringOperationFilterInput>;
  readonly vendorName?: InputMaybe<StringOperationFilterInput>;
  readonly width?: InputMaybe<StringOperationFilterInput>;
};

export type BoardSortInput = {
  readonly boardId?: InputMaybe<SortEnumType>;
  readonly bundleNumber?: InputMaybe<SortEnumType>;
  readonly footage?: InputMaybe<SortEnumType>;
  readonly grade?: InputMaybe<SortEnumType>;
  readonly kilnDried?: InputMaybe<SortEnumType>;
  readonly length?: InputMaybe<SortEnumType>;
  readonly loadNumber?: InputMaybe<SortEnumType>;
  readonly productionRunNumber?: InputMaybe<SortEnumType>;
  readonly purchaseOrderId?: InputMaybe<SortEnumType>;
  readonly species?: InputMaybe<SortEnumType>;
  readonly thickness?: InputMaybe<SortEnumType>;
  readonly vendorName?: InputMaybe<SortEnumType>;
  readonly width?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type BoardsConnection = {
  readonly __typename: 'BoardsConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<BoardsEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Board>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BoardsEdge = {
  readonly __typename: 'BoardsEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Board;
};

export type BooleanOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Boolean']['input']>;
  readonly neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ByteOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Byte']['input']>;
  readonly gt?: InputMaybe<Scalars['Byte']['input']>;
  readonly gte?: InputMaybe<Scalars['Byte']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Byte']['input']>>>;
  readonly lt?: InputMaybe<Scalars['Byte']['input']>;
  readonly lte?: InputMaybe<Scalars['Byte']['input']>;
  readonly neq?: InputMaybe<Scalars['Byte']['input']>;
  readonly ngt?: InputMaybe<Scalars['Byte']['input']>;
  readonly ngte?: InputMaybe<Scalars['Byte']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Byte']['input']>>>;
  readonly nlt?: InputMaybe<Scalars['Byte']['input']>;
  readonly nlte?: InputMaybe<Scalars['Byte']['input']>;
};

export type CashProjectionCustomer = {
  readonly __typename: 'CashProjectionCustomer';
  readonly customerId: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly periods: ReadonlyArray<CashProjectionCustomerInvoicePeriod>;
  readonly totalDue: Scalars['Decimal']['output'];
  readonly totalPrepayAmount: Scalars['Decimal']['output'];
};

export type CashProjectionCustomerInvoice = {
  readonly __typename: 'CashProjectionCustomerInvoice';
  readonly balance?: Maybe<Scalars['Decimal']['output']>;
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly dueDate?: Maybe<Scalars['DateTime']['output']>;
  readonly invoiceId?: Maybe<Scalars['Int']['output']>;
  readonly paid?: Maybe<Scalars['Decimal']['output']>;
  readonly totalInvoice?: Maybe<Scalars['Decimal']['output']>;
};

export type CashProjectionCustomerInvoicePeriod = {
  readonly __typename: 'CashProjectionCustomerInvoicePeriod';
  readonly maxDays: Scalars['Int']['output'];
  readonly minDays: Scalars['Int']['output'];
  readonly openInvoices: ReadonlyArray<CashProjectionCustomerInvoice>;
  readonly period: Scalars['Int']['output'];
  readonly totalInvoiceBalance: Scalars['Decimal']['output'];
};

export type Category = {
  readonly __typename: 'Category';
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly isCustom: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly parentPolicyId?: Maybe<Scalars['String']['output']>;
  readonly policy?: Maybe<Scalars['String']['output']>;
  readonly reportLinks?: Maybe<ReadonlyArray<Maybe<ReportCategory>>>;
  readonly updated: Scalars['DateTime']['output'];
};

export type CategoryFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<CategoryFilterInput>>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly isCustom?: InputMaybe<BooleanOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<CategoryFilterInput>>;
  readonly parentPolicyId?: InputMaybe<StringOperationFilterInput>;
  readonly policy?: InputMaybe<StringOperationFilterInput>;
  readonly reportLinks?: InputMaybe<ListFilterInputTypeOfReportCategoryFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type CategoryLinkInput = {
  readonly categoryId?: InputMaybe<Scalars['String']['input']>;
  readonly isCustom: Scalars['Boolean']['input'];
  readonly reportId?: InputMaybe<Scalars['String']['input']>;
  readonly reportIsCustom: Scalars['Boolean']['input'];
};

export type CategorySortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly isCustom?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly parentPolicyId?: InputMaybe<SortEnumType>;
  readonly policy?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

export type CodeGenerationConfig = {
  readonly __typename: 'CodeGenerationConfig';
  readonly company?: Maybe<CodeGenerationConfigType>;
  readonly disabled: Scalars['Boolean']['output'];
  readonly individual: CodeGenerationConfigType;
};

export type CodeGenerationConfigEntities = {
  readonly __typename: 'CodeGenerationConfigEntities';
  readonly customer: CodeGenerationConfig;
  readonly employee: CodeGenerationConfig;
  readonly name: CodeGenerationConfig;
  readonly salesperson: CodeGenerationConfig;
  readonly vendor: CodeGenerationConfig;
};

export type CodeGenerationConfigInput = {
  readonly company?: InputMaybe<CodeGenerationConfigTypeInput>;
  readonly disabled: Scalars['Boolean']['input'];
  readonly individual: CodeGenerationConfigTypeInput;
  readonly type: SystemConfigCodeGenerationEntities;
};

export type CodeGenerationConfigType = {
  readonly __typename: 'CodeGenerationConfigType';
  readonly format: Scalars['String']['output'];
  readonly ignoreAfter: Scalars['String']['output'];
  /** Comma separated string of words to be ignored. */
  readonly ignoreWords: Scalars['String']['output'];
  readonly pad: Scalars['Boolean']['output'];
  /** String of characters to be removed before generating the code. */
  readonly removeCharacters: Scalars['String']['output'];
  /** String of characters to split on. */
  readonly splitOn: Scalars['String']['output'];
};

export type CodeGenerationConfigTypeInput = {
  readonly format: Scalars['String']['input'];
  readonly ignoreAfter: Scalars['String']['input'];
  readonly ignoreWords: Scalars['String']['input'];
  readonly pad: Scalars['Boolean']['input'];
  readonly removeCharacters: Scalars['String']['input'];
  readonly splitOn: Scalars['String']['input'];
};

export type CodeGenerationInput = {
  readonly company?: InputMaybe<CodeGenerationConfigTypeInput>;
  readonly disabled: Scalars['Boolean']['input'];
  readonly individual: CodeGenerationConfigTypeInput;
};

export type Company = {
  readonly __typename: 'Company';
  readonly added: Scalars['DateTime']['output'];
  readonly clientAppUrl?: Maybe<Scalars['String']['output']>;
  readonly databaseConnection: PostgresConnectionInfo;
  readonly defaultUsers?: Maybe<ReadonlyArray<User>>;
  readonly fmtVersion?: Maybe<Scalars['String']['output']>;
  readonly galaxyCompanyId?: Maybe<Scalars['String']['output']>;
  readonly icon: Scalars['String']['output'];
  readonly iconFilename: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly initials: Scalars['String']['output'];
  readonly legacyServerNumber: Scalars['Int']['output'];
  readonly logo: Scalars['String']['output'];
  readonly logoFilename: Scalars['String']['output'];
  readonly migrations: ReadonlyArray<Migration>;
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  /** CRAM database number */
  readonly number: Scalars['Int']['output'];
  readonly publicUrl?: Maybe<Scalars['String']['output']>;
  /** Parsed public url for easily getting the host name, scheme, etc */
  readonly publicUrlInfo?: Maybe<UrlInfo>;
  readonly reportsNeedUpdates?: Maybe<Scalars['Boolean']['output']>;
  readonly shortName: Scalars['String']['output'];
  readonly themeMode: ThemeMode;
  readonly themeName: Scalars['String']['output'];
  readonly updated: Scalars['DateTime']['output'];
};


export type CompanyMigrationsArgs = {
  order?: InputMaybe<ReadonlyArray<MigrationSortInput>>;
  where?: InputMaybe<MigrationFilterInput>;
};

export type CompanyAddStatus = {
  readonly __typename: 'CompanyAddStatus';
  readonly companyId: Scalars['String']['output'];
  readonly eventId: Scalars['String']['output'];
  readonly log: Scalars['String']['output'];
};

export type CompanyAuthorizationPoliciesInput = {
  readonly companyId?: InputMaybe<Scalars['String']['input']>;
  readonly enabled: Scalars['Boolean']['input'];
  readonly policyId?: InputMaybe<Scalars['String']['input']>;
  readonly type: PolicyType;
  readonly userId?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyAuthorizationPolicy = {
  readonly __typename: 'CompanyAuthorizationPolicy';
  readonly companyId: Scalars['String']['output'];
  readonly enabled: Scalars['Boolean']['output'];
  readonly groupIds?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly parentId?: Maybe<Scalars['String']['output']>;
  readonly policyId: Scalars['String']['output'];
  readonly type: PolicyType;
  readonly userId?: Maybe<Scalars['String']['output']>;
};

export type CompanyAuthorizationPolicyInput = {
  readonly companyId: Scalars['String']['input'];
  readonly enabled: Scalars['Boolean']['input'];
  readonly groupIds?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly parentId?: InputMaybe<Scalars['String']['input']>;
  readonly policyId: Scalars['String']['input'];
  readonly type: PolicyType;
  readonly userId?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyBackup = {
  readonly __typename: 'CompanyBackup';
  readonly companyId?: Maybe<Scalars['String']['output']>;
  readonly created: Scalars['DateTime']['output'];
  readonly name: Scalars['String']['output'];
  readonly size: Scalars['Long']['output'];
  readonly status: Scalars['String']['output'];
};

export type CompanyBackupLog = {
  readonly __typename: 'CompanyBackupLog';
  readonly companyId: Scalars['String']['output'];
  readonly log?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
};

export type CompanyConnectionStringVerificationResult = {
  readonly __typename: 'CompanyConnectionStringVerificationResult';
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly success: Scalars['Boolean']['output'];
};

export type CompanyDeleteStatus = {
  readonly __typename: 'CompanyDeleteStatus';
  readonly companyId: Scalars['String']['output'];
  readonly log: Scalars['String']['output'];
};

export enum CompanyDuplicateEventStatus {
  Error = 'ERROR',
  Finished = 'FINISHED',
  Started = 'STARTED'
}

export type CompanyDuplicateStatus = {
  readonly __typename: 'CompanyDuplicateStatus';
  readonly companyId: Scalars['UUID']['output'];
  readonly eventId: CompanyDuplicateEventStatus;
  readonly log: Scalars['String']['output'];
};

export type CompanyFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<CompanyFilterInput>>;
  readonly clientAppUrl?: InputMaybe<StringOperationFilterInput>;
  readonly databaseConnection?: InputMaybe<PostgresConnectionInfoFilterInput>;
  readonly defaultUsers?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  readonly fmtVersion?: InputMaybe<StringOperationFilterInput>;
  readonly galaxyCompanyId?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly legacyServerNumber?: InputMaybe<IntOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly number?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<CompanyFilterInput>>;
  readonly publicUrl?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type CompanyImportCreateStatus = {
  readonly __typename: 'CompanyImportCreateStatus';
  readonly companyId?: Maybe<Scalars['String']['output']>;
  readonly log?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly status: CompanyImportCreateStatuses;
};

export enum CompanyImportCreateStatuses {
  Error = 'ERROR',
  Finished = 'FINISHED',
  Progress = 'PROGRESS'
}

export type CompanyImportDataStatus = {
  readonly __typename: 'CompanyImportDataStatus';
  readonly companyId?: Maybe<Scalars['String']['output']>;
  readonly log?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly status: CompanyImportDataStatuses;
};

export enum CompanyImportDataStatuses {
  Error = 'ERROR',
  Finished = 'FINISHED',
  Progress = 'PROGRESS'
}

export type CompanyImportMigrateStatus = {
  readonly __typename: 'CompanyImportMigrateStatus';
  readonly companyId?: Maybe<Scalars['String']['output']>;
  readonly folderName: Scalars['String']['output'];
  readonly log?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly size?: Maybe<Scalars['Long']['output']>;
  readonly status: CompanyImportMigrateStatuses;
};

export enum CompanyImportMigrateStatuses {
  Error = 'ERROR',
  Finished = 'FINISHED',
  Progress = 'PROGRESS'
}

export type CompanyImportStatus = {
  readonly __typename: 'CompanyImportStatus';
  readonly dataImportStatus?: Maybe<CompanyImportDataStatuses>;
  readonly databaseCreationStatus?: Maybe<CompanyImportCreateStatuses>;
  readonly folderName: Scalars['String']['output'];
  readonly migrationStatus?: Maybe<CompanyImportMigrateStatuses>;
  readonly name: Scalars['String']['output'];
  readonly size?: Maybe<Scalars['Long']['output']>;
};

export type CompanyInfo = {
  readonly __typename: 'CompanyInfo';
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly fax?: Maybe<Scalars['String']['output']>;
  readonly lattitude?: Maybe<Scalars['Decimal']['output']>;
  readonly longitude?: Maybe<Scalars['Decimal']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  readonly website?: Maybe<Scalars['String']['output']>;
  readonly zipCode?: Maybe<ZipCode>;
  readonly zipCodeId?: Maybe<Scalars['String']['output']>;
};

export type CompanyMigrationStatus = {
  readonly __typename: 'CompanyMigrationStatus';
  readonly log: Scalars['String']['output'];
  readonly migration: Migration;
};

export type CompanyNamesInput = {
  readonly initials: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly shortName: Scalars['String']['input'];
  readonly themeMode: ThemeMode;
  readonly themeName: Scalars['String']['input'];
};

export type CompanyNextNumber = {
  readonly __typename: 'CompanyNextNumber';
  readonly number: Scalars['Int']['output'];
};

export type CompanyRestoreStatus = {
  readonly __typename: 'CompanyRestoreStatus';
  readonly companyId: Scalars['String']['output'];
  readonly log: Scalars['String']['output'];
  readonly status: CompanyRestoreStatuses;
};

export enum CompanyRestoreStatuses {
  Error = 'ERROR',
  Finished = 'FINISHED',
  Progress = 'PROGRESS',
  Started = 'STARTED'
}

export type CompanySeedDataOptionInput = {
  readonly items?: InputMaybe<ReadonlyArray<CompanySeedDataOptionInput>>;
  readonly key: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanySortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly clientAppUrl?: InputMaybe<SortEnumType>;
  readonly databaseConnection?: InputMaybe<PostgresConnectionInfoSortInput>;
  readonly fmtVersion?: InputMaybe<SortEnumType>;
  readonly galaxyCompanyId?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly legacyServerNumber?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly number?: InputMaybe<SortEnumType>;
  readonly publicUrl?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

export type CompanyStylesheetPreference = {
  readonly __typename: 'CompanyStylesheetPreference';
  readonly companyId?: Maybe<Scalars['String']['output']>;
  readonly stylesheetId?: Maybe<Scalars['String']['output']>;
};

export type CompanyUpdateInput = {
  readonly clientAppUrl: Scalars['String']['input'];
  readonly id: Scalars['String']['input'];
  readonly initials: Scalars['String']['input'];
  readonly legacyServerNumber: Scalars['Int']['input'];
  readonly name: Scalars['String']['input'];
  readonly number: Scalars['Int']['input'];
  readonly publicUrl: Scalars['String']['input'];
  readonly shortName: Scalars['String']['input'];
  readonly themeMode: ThemeMode;
  readonly themeName: Scalars['String']['input'];
};

export type CoordinateEqualityComparerFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<CoordinateEqualityComparerFilterInput>>;
  readonly or?: InputMaybe<ReadonlyArray<CoordinateEqualityComparerFilterInput>>;
};

export type CoordinateFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<CoordinateFilterInput>>;
  readonly coordinateValue?: InputMaybe<CoordinateFilterInput>;
  readonly isValid?: InputMaybe<BooleanOperationFilterInput>;
  readonly m?: InputMaybe<FloatOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<CoordinateFilterInput>>;
  readonly x?: InputMaybe<FloatOperationFilterInput>;
  readonly y?: InputMaybe<FloatOperationFilterInput>;
  readonly z?: InputMaybe<FloatOperationFilterInput>;
};

export type CoordinateSequenceFactoryFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<CoordinateSequenceFactoryFilterInput>>;
  readonly or?: InputMaybe<ReadonlyArray<CoordinateSequenceFactoryFilterInput>>;
  readonly ordinates?: InputMaybe<OrdinatesOperationFilterInput>;
};

export type CoordinateSequenceFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<CoordinateSequenceFilterInput>>;
  readonly count?: InputMaybe<IntOperationFilterInput>;
  readonly dimension?: InputMaybe<IntOperationFilterInput>;
  readonly first?: InputMaybe<CoordinateFilterInput>;
  readonly hasM?: InputMaybe<BooleanOperationFilterInput>;
  readonly hasZ?: InputMaybe<BooleanOperationFilterInput>;
  readonly last?: InputMaybe<CoordinateFilterInput>;
  readonly mOrdinateIndex?: InputMaybe<IntOperationFilterInput>;
  readonly measures?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<CoordinateSequenceFilterInput>>;
  readonly ordinates?: InputMaybe<OrdinatesOperationFilterInput>;
  readonly spatial?: InputMaybe<IntOperationFilterInput>;
  readonly zOrdinateIndex?: InputMaybe<IntOperationFilterInput>;
};

export type Coords = {
  readonly __typename: 'Coords';
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly date: Scalars['DateTime']['output'];
  readonly x: Scalars['Float']['output'];
  readonly y: Scalars['Float']['output'];
};

export type Customer = {
  readonly __typename: 'Customer';
  readonly advertiser?: Maybe<Scalars['String']['output']>;
  readonly alert?: Maybe<Scalars['String']['output']>;
  readonly allowLateCharge?: Maybe<Scalars['Boolean']['output']>;
  readonly allowLateChargeDb?: Maybe<Scalars['String']['output']>;
  readonly attention?: Maybe<Scalars['String']['output']>;
  readonly autoAddItemsToInvoice?: Maybe<Scalars['String']['output']>;
  readonly billing?: Maybe<Scalars['String']['output']>;
  readonly churchGroup?: Maybe<Scalars['String']['output']>;
  readonly commission?: Maybe<Scalars['String']['output']>;
  readonly contactPerson?: Maybe<Scalars['String']['output']>;
  readonly contactType?: Maybe<Scalars['String']['output']>;
  readonly creditLimit: Scalars['Decimal']['output'];
  readonly custom1?: Maybe<Scalars['String']['output']>;
  readonly customerPosted?: Maybe<CustomerPosted>;
  readonly customerType?: Maybe<CustomerType>;
  readonly customerTypeId?: Maybe<Scalars['String']['output']>;
  readonly discountLevel?: Maybe<Scalars['String']['output']>;
  readonly driversLicenseId?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly fax?: Maybe<Scalars['String']['output']>;
  readonly field51?: Maybe<Scalars['String']['output']>;
  readonly field52?: Maybe<Scalars['String']['output']>;
  readonly field53?: Maybe<Scalars['String']['output']>;
  readonly field54?: Maybe<Scalars['String']['output']>;
  readonly field55?: Maybe<Scalars['String']['output']>;
  readonly field56?: Maybe<Scalars['String']['output']>;
  readonly homePhone?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly locations: ReadonlyArray<Location>;
  readonly message1?: Maybe<Scalars['String']['output']>;
  readonly message2?: Maybe<Scalars['String']['output']>;
  readonly message3?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly newCustomerId?: Maybe<Scalars['String']['output']>;
  readonly officePhone?: Maybe<Scalars['String']['output']>;
  readonly oldId?: Maybe<Scalars['String']['output']>;
  readonly prefix?: Maybe<Scalars['String']['output']>;
  readonly printCode?: Maybe<Scalars['String']['output']>;
  readonly recurringCharges1?: Maybe<Scalars['String']['output']>;
  readonly recurringCharges2: Scalars['ID']['output'];
  readonly remainOnFile?: Maybe<Scalars['String']['output']>;
  readonly requirePoNumber?: Maybe<Scalars['Boolean']['output']>;
  readonly requirePoNumberDb?: Maybe<Scalars['String']['output']>;
  readonly reviewDate?: Maybe<Scalars['DateTime']['output']>;
  readonly salesTaxExpire?: Maybe<Scalars['DateTime']['output']>;
  readonly salespersonId?: Maybe<Scalars['String']['output']>;
  readonly scanWords?: Maybe<Scalars['String']['output']>;
  readonly secondaryAddress?: Maybe<Scalars['String']['output']>;
  readonly setupDate?: Maybe<Scalars['DateTime']['output']>;
  readonly shipAddress2?: Maybe<Scalars['String']['output']>;
  readonly shipAddress3?: Maybe<Scalars['String']['output']>;
  readonly shipAddress4?: Maybe<Scalars['String']['output']>;
  readonly shipResidentialCommercial?: Maybe<ResidentialCommercial>;
  readonly shipToName?: Maybe<Name>;
  readonly shipToNameId?: Maybe<Scalars['String']['output']>;
  readonly shipVia?: Maybe<Scalars['String']['output']>;
  readonly statementBalance: Scalars['Decimal']['output'];
  readonly statementDate?: Maybe<Scalars['DateTime']['output']>;
  readonly statementPeriod?: Maybe<Scalars['String']['output']>;
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly taxNumber?: Maybe<Scalars['String']['output']>;
  readonly taxStatus?: Maybe<Scalars['String']['output']>;
  readonly terms?: Maybe<Scalars['String']['output']>;
  readonly updated?: Maybe<Scalars['DateTime']['output']>;
  readonly zipCode?: Maybe<ZipCode>;
  readonly zipCodeId?: Maybe<Scalars['String']['output']>;
  readonly zipExt?: Maybe<Scalars['String']['output']>;
  readonly zone?: Maybe<Scalars['String']['output']>;
};

export type CustomerAddInput = {
  readonly advertiser?: InputMaybe<Scalars['String']['input']>;
  readonly alert?: InputMaybe<Scalars['String']['input']>;
  readonly allowLateCharge?: InputMaybe<Scalars['Boolean']['input']>;
  readonly attention?: InputMaybe<Scalars['String']['input']>;
  readonly autoAddItemsToInvoice?: InputMaybe<Scalars['String']['input']>;
  readonly billing?: InputMaybe<Scalars['String']['input']>;
  readonly churchGroup?: InputMaybe<Scalars['String']['input']>;
  readonly commission?: InputMaybe<Scalars['String']['input']>;
  readonly contactPerson?: InputMaybe<Scalars['String']['input']>;
  readonly contactType?: InputMaybe<Scalars['String']['input']>;
  readonly creditLimit?: InputMaybe<Scalars['Decimal']['input']>;
  readonly custom1?: InputMaybe<Scalars['String']['input']>;
  readonly customerTypeId?: InputMaybe<Scalars['String']['input']>;
  readonly discountLevel?: InputMaybe<Scalars['String']['input']>;
  readonly driversLicenseId?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly fax?: InputMaybe<Scalars['String']['input']>;
  readonly field51?: InputMaybe<Scalars['String']['input']>;
  readonly field52?: InputMaybe<Scalars['String']['input']>;
  readonly field53?: InputMaybe<Scalars['String']['input']>;
  readonly field54?: InputMaybe<Scalars['String']['input']>;
  readonly field55?: InputMaybe<Scalars['String']['input']>;
  readonly field56?: InputMaybe<Scalars['String']['input']>;
  readonly homePhone?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly message1?: InputMaybe<Scalars['String']['input']>;
  readonly message2?: InputMaybe<Scalars['String']['input']>;
  readonly message3?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly newCustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly officePhone?: InputMaybe<Scalars['String']['input']>;
  readonly oldId?: InputMaybe<Scalars['String']['input']>;
  readonly prefix?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly recurringCharges1?: InputMaybe<Scalars['String']['input']>;
  readonly recurringCharges2?: InputMaybe<Scalars['ID']['input']>;
  readonly remainOnFile?: InputMaybe<Scalars['String']['input']>;
  readonly requirePoNumber?: InputMaybe<Scalars['Boolean']['input']>;
  readonly reviewDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly salesTaxExpire?: InputMaybe<Scalars['DateTime']['input']>;
  readonly salespersonId?: InputMaybe<Scalars['String']['input']>;
  readonly scanWords?: InputMaybe<Scalars['String']['input']>;
  readonly secondaryAddress?: InputMaybe<Scalars['String']['input']>;
  readonly shipAddress2?: InputMaybe<Scalars['String']['input']>;
  readonly shipAddress3?: InputMaybe<Scalars['String']['input']>;
  readonly shipAddress4?: InputMaybe<Scalars['String']['input']>;
  readonly shipResidentialCommercial?: InputMaybe<ResidentialCommercial>;
  /** Adds a new name record for this customer. */
  readonly shipToNameAdd?: InputMaybe<NameAddInput>;
  /** Use an existing name record. */
  readonly shipToNameId?: InputMaybe<Scalars['String']['input']>;
  readonly shipVia?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly taxNumber?: InputMaybe<Scalars['String']['input']>;
  readonly taxStatus?: InputMaybe<Scalars['String']['input']>;
  readonly terms?: InputMaybe<Scalars['String']['input']>;
  /** When populated with a new zip code, it will be created. Existing zip codes will not be modified. */
  readonly zipCode?: InputMaybe<ZipCodeInput>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
  readonly zone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerFilterInput = {
  readonly advertiser?: InputMaybe<StringOperationFilterInput>;
  readonly alert?: InputMaybe<StringOperationFilterInput>;
  readonly allowLateCharge?: InputMaybe<BooleanOperationFilterInput>;
  readonly allowLateChargeDb?: InputMaybe<StringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<CustomerFilterInput>>;
  readonly attention?: InputMaybe<StringOperationFilterInput>;
  readonly autoAddItemsToInvoice?: InputMaybe<StringOperationFilterInput>;
  readonly billing?: InputMaybe<StringOperationFilterInput>;
  readonly churchGroup?: InputMaybe<StringOperationFilterInput>;
  readonly commission?: InputMaybe<StringOperationFilterInput>;
  readonly contactPerson?: InputMaybe<StringOperationFilterInput>;
  readonly contactType?: InputMaybe<StringOperationFilterInput>;
  readonly creditLimit?: InputMaybe<DecimalOperationFilterInput>;
  readonly custom1?: InputMaybe<StringOperationFilterInput>;
  readonly customerPosted?: InputMaybe<CustomerPostedFilterInput>;
  readonly customerType?: InputMaybe<CustomerTypeFilterInput>;
  readonly customerTypeId?: InputMaybe<StringOperationFilterInput>;
  readonly discountLevel?: InputMaybe<StringOperationFilterInput>;
  readonly driversLicenseId?: InputMaybe<StringOperationFilterInput>;
  readonly email?: InputMaybe<StringOperationFilterInput>;
  readonly fax?: InputMaybe<StringOperationFilterInput>;
  readonly field51?: InputMaybe<StringOperationFilterInput>;
  readonly field52?: InputMaybe<StringOperationFilterInput>;
  readonly field53?: InputMaybe<StringOperationFilterInput>;
  readonly field54?: InputMaybe<StringOperationFilterInput>;
  readonly field55?: InputMaybe<StringOperationFilterInput>;
  readonly field56?: InputMaybe<StringOperationFilterInput>;
  readonly homePhone?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly locations?: InputMaybe<ListFilterInputTypeOfLocationFilterInput>;
  readonly message1?: InputMaybe<StringOperationFilterInput>;
  readonly message2?: InputMaybe<StringOperationFilterInput>;
  readonly message3?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly newCustomerId?: InputMaybe<StringOperationFilterInput>;
  readonly officePhone?: InputMaybe<StringOperationFilterInput>;
  readonly oldId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<CustomerFilterInput>>;
  readonly prefix?: InputMaybe<StringOperationFilterInput>;
  readonly printCode?: InputMaybe<StringOperationFilterInput>;
  readonly recurringCharges1?: InputMaybe<StringOperationFilterInput>;
  readonly recurringCharges2?: InputMaybe<IdOperationFilterInput>;
  readonly remainOnFile?: InputMaybe<StringOperationFilterInput>;
  readonly requirePoNumber?: InputMaybe<BooleanOperationFilterInput>;
  readonly requirePoNumberDb?: InputMaybe<StringOperationFilterInput>;
  readonly reviewDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly salesTaxExpire?: InputMaybe<DateTimeOperationFilterInput>;
  readonly salespersonId?: InputMaybe<StringOperationFilterInput>;
  readonly scanWords?: InputMaybe<StringOperationFilterInput>;
  readonly secondaryAddress?: InputMaybe<StringOperationFilterInput>;
  readonly setupDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly shipAddress2?: InputMaybe<StringOperationFilterInput>;
  readonly shipAddress3?: InputMaybe<StringOperationFilterInput>;
  readonly shipAddress4?: InputMaybe<StringOperationFilterInput>;
  readonly shipResidentialCommercial?: InputMaybe<NullableOfResidentialCommercialOperationFilterInput>;
  readonly shipToName?: InputMaybe<NameFilterInput>;
  readonly shipToNameId?: InputMaybe<StringOperationFilterInput>;
  readonly shipVia?: InputMaybe<StringOperationFilterInput>;
  readonly statementBalance?: InputMaybe<DecimalOperationFilterInput>;
  readonly statementDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly statementPeriod?: InputMaybe<StringOperationFilterInput>;
  readonly streetAddress?: InputMaybe<StringOperationFilterInput>;
  readonly taxNumber?: InputMaybe<StringOperationFilterInput>;
  readonly taxStatus?: InputMaybe<StringOperationFilterInput>;
  readonly terms?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
  readonly zipCode?: InputMaybe<ZipCodeFilterInput>;
  readonly zipCodeId?: InputMaybe<StringOperationFilterInput>;
  readonly zipExt?: InputMaybe<StringOperationFilterInput>;
  readonly zone?: InputMaybe<StringOperationFilterInput>;
};

export type CustomerPosted = {
  readonly __typename: 'CustomerPosted';
  readonly balance: Scalars['Decimal']['output'];
  readonly creditAmount: Scalars['Decimal']['output'];
  readonly currentCost: Scalars['Decimal']['output'];
  readonly currentPay: Scalars['Decimal']['output'];
  readonly currentSales: Scalars['Decimal']['output'];
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly group1LabData?: Maybe<Scalars['String']['output']>;
  readonly group2LabData?: Maybe<Scalars['String']['output']>;
  readonly group3LabData?: Maybe<Scalars['String']['output']>;
  readonly group4LabData?: Maybe<Scalars['String']['output']>;
  readonly group5LabData?: Maybe<Scalars['String']['output']>;
  readonly group6LabData?: Maybe<Scalars['String']['output']>;
  readonly group7LabData?: Maybe<Scalars['String']['output']>;
  readonly group8LabData?: Maybe<Scalars['String']['output']>;
  readonly group9LabData?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly insuranceBalance: Scalars['Decimal']['output'];
  readonly messageScreen?: Maybe<Scalars['String']['output']>;
  readonly nonInvoiceAmount: Scalars['Decimal']['output'];
  readonly orderAmount: Scalars['Decimal']['output'];
  readonly orderDeposits: Scalars['Decimal']['output'];
  readonly priorBalance: Scalars['Decimal']['output'];
  readonly priorCost: Scalars['Decimal']['output'];
  readonly priorSales: Scalars['Decimal']['output'];
  readonly sales1YearAgo: Scalars['Decimal']['output'];
  readonly sales2YearAgo: Scalars['Decimal']['output'];
  readonly specialDepositAmount: Scalars['Decimal']['output'];
  readonly telephoneLog?: Maybe<Scalars['String']['output']>;
  readonly yesterYear?: Maybe<Scalars['String']['output']>;
};

export type CustomerPostedFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<CustomerPostedFilterInput>>;
  readonly balance?: InputMaybe<DecimalOperationFilterInput>;
  readonly billPtr?: InputMaybe<StringOperationFilterInput>;
  readonly creditAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly currentCost?: InputMaybe<DecimalOperationFilterInput>;
  readonly currentPay?: InputMaybe<DecimalOperationFilterInput>;
  readonly currentSales?: InputMaybe<DecimalOperationFilterInput>;
  readonly customer?: InputMaybe<CustomerFilterInput>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly group1LabData?: InputMaybe<StringOperationFilterInput>;
  readonly group2LabData?: InputMaybe<StringOperationFilterInput>;
  readonly group3LabData?: InputMaybe<StringOperationFilterInput>;
  readonly group4LabData?: InputMaybe<StringOperationFilterInput>;
  readonly group5LabData?: InputMaybe<StringOperationFilterInput>;
  readonly group6LabData?: InputMaybe<StringOperationFilterInput>;
  readonly group7LabData?: InputMaybe<StringOperationFilterInput>;
  readonly group8LabData?: InputMaybe<StringOperationFilterInput>;
  readonly group9LabData?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly insuranceBalance?: InputMaybe<DecimalOperationFilterInput>;
  readonly majorComponentPtr?: InputMaybe<StringOperationFilterInput>;
  readonly messageScreen?: InputMaybe<StringOperationFilterInput>;
  readonly nonInvoiceAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly openInvoicePtr?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<CustomerPostedFilterInput>>;
  readonly orderAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly orderDeposits?: InputMaybe<DecimalOperationFilterInput>;
  readonly orderPtr?: InputMaybe<StringOperationFilterInput>;
  readonly paidInvoicePtr?: InputMaybe<StringOperationFilterInput>;
  readonly paymentPtr?: InputMaybe<StringOperationFilterInput>;
  readonly priorBalance?: InputMaybe<DecimalOperationFilterInput>;
  readonly priorCost?: InputMaybe<DecimalOperationFilterInput>;
  readonly priorSales?: InputMaybe<DecimalOperationFilterInput>;
  readonly ptrs1?: InputMaybe<StringOperationFilterInput>;
  readonly ptrs2?: InputMaybe<StringOperationFilterInput>;
  readonly quotePtr?: InputMaybe<StringOperationFilterInput>;
  readonly sales1YearAgo?: InputMaybe<DecimalOperationFilterInput>;
  readonly sales2YearAgo?: InputMaybe<DecimalOperationFilterInput>;
  readonly specialDepositAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly specialOrderPtr?: InputMaybe<StringOperationFilterInput>;
  readonly telephoneLog?: InputMaybe<StringOperationFilterInput>;
  readonly yesterYear?: InputMaybe<StringOperationFilterInput>;
};

export type CustomerPostedSortInput = {
  readonly balance?: InputMaybe<SortEnumType>;
  readonly billPtr?: InputMaybe<SortEnumType>;
  readonly creditAmount?: InputMaybe<SortEnumType>;
  readonly currentCost?: InputMaybe<SortEnumType>;
  readonly currentPay?: InputMaybe<SortEnumType>;
  readonly currentSales?: InputMaybe<SortEnumType>;
  readonly customer?: InputMaybe<CustomerSortInput>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly group1LabData?: InputMaybe<SortEnumType>;
  readonly group2LabData?: InputMaybe<SortEnumType>;
  readonly group3LabData?: InputMaybe<SortEnumType>;
  readonly group4LabData?: InputMaybe<SortEnumType>;
  readonly group5LabData?: InputMaybe<SortEnumType>;
  readonly group6LabData?: InputMaybe<SortEnumType>;
  readonly group7LabData?: InputMaybe<SortEnumType>;
  readonly group8LabData?: InputMaybe<SortEnumType>;
  readonly group9LabData?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly insuranceBalance?: InputMaybe<SortEnumType>;
  readonly majorComponentPtr?: InputMaybe<SortEnumType>;
  readonly messageScreen?: InputMaybe<SortEnumType>;
  readonly nonInvoiceAmount?: InputMaybe<SortEnumType>;
  readonly openInvoicePtr?: InputMaybe<SortEnumType>;
  readonly orderAmount?: InputMaybe<SortEnumType>;
  readonly orderDeposits?: InputMaybe<SortEnumType>;
  readonly orderPtr?: InputMaybe<SortEnumType>;
  readonly paidInvoicePtr?: InputMaybe<SortEnumType>;
  readonly paymentPtr?: InputMaybe<SortEnumType>;
  readonly priorBalance?: InputMaybe<SortEnumType>;
  readonly priorCost?: InputMaybe<SortEnumType>;
  readonly priorSales?: InputMaybe<SortEnumType>;
  readonly ptrs1?: InputMaybe<SortEnumType>;
  readonly ptrs2?: InputMaybe<SortEnumType>;
  readonly quotePtr?: InputMaybe<SortEnumType>;
  readonly sales1YearAgo?: InputMaybe<SortEnumType>;
  readonly sales2YearAgo?: InputMaybe<SortEnumType>;
  readonly specialDepositAmount?: InputMaybe<SortEnumType>;
  readonly specialOrderPtr?: InputMaybe<SortEnumType>;
  readonly telephoneLog?: InputMaybe<SortEnumType>;
  readonly yesterYear?: InputMaybe<SortEnumType>;
};

export enum CustomerRegionOptions {
  City = 'CITY',
  State = 'STATE',
  Zip = 'ZIP'
}

export enum CustomerRepeatNewCountMode {
  New = 'NEW',
  Repeat = 'REPEAT'
}

export enum CustomerRepeatNewCountPeriod {
  Month = 'MONTH',
  Year = 'YEAR'
}

export type CustomerSalesStatistic = {
  readonly __typename: 'CustomerSalesStatistic';
  readonly coordinateX: Scalars['Float']['output'];
  readonly coordinateY: Scalars['Float']['output'];
  readonly customerName: Scalars['String']['output'];
  readonly invoices: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
};

export type CustomerSaveResult = {
  readonly __typename: 'CustomerSaveResult';
  readonly data?: Maybe<Customer>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

/** A connection to a list of items. */
export type CustomerSearchConnection = {
  readonly __typename: 'CustomerSearchConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomerSearchEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Customer>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerSearchEdge = {
  readonly __typename: 'CustomerSearchEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Customer;
};

export type CustomerSortInput = {
  readonly advertiser?: InputMaybe<SortEnumType>;
  readonly alert?: InputMaybe<SortEnumType>;
  readonly allowLateCharge?: InputMaybe<SortEnumType>;
  readonly allowLateChargeDb?: InputMaybe<SortEnumType>;
  readonly attention?: InputMaybe<SortEnumType>;
  readonly autoAddItemsToInvoice?: InputMaybe<SortEnumType>;
  readonly billing?: InputMaybe<SortEnumType>;
  readonly churchGroup?: InputMaybe<SortEnumType>;
  readonly commission?: InputMaybe<SortEnumType>;
  readonly contactPerson?: InputMaybe<SortEnumType>;
  readonly contactType?: InputMaybe<SortEnumType>;
  readonly creditLimit?: InputMaybe<SortEnumType>;
  readonly custom1?: InputMaybe<SortEnumType>;
  readonly customerPosted?: InputMaybe<CustomerPostedSortInput>;
  readonly customerType?: InputMaybe<CustomerTypeSortInput>;
  readonly customerTypeId?: InputMaybe<SortEnumType>;
  readonly discountLevel?: InputMaybe<SortEnumType>;
  readonly driversLicenseId?: InputMaybe<SortEnumType>;
  readonly email?: InputMaybe<SortEnumType>;
  readonly fax?: InputMaybe<SortEnumType>;
  readonly field51?: InputMaybe<SortEnumType>;
  readonly field52?: InputMaybe<SortEnumType>;
  readonly field53?: InputMaybe<SortEnumType>;
  readonly field54?: InputMaybe<SortEnumType>;
  readonly field55?: InputMaybe<SortEnumType>;
  readonly field56?: InputMaybe<SortEnumType>;
  readonly homePhone?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly message1?: InputMaybe<SortEnumType>;
  readonly message2?: InputMaybe<SortEnumType>;
  readonly message3?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly newCustomerId?: InputMaybe<SortEnumType>;
  readonly officePhone?: InputMaybe<SortEnumType>;
  readonly oldId?: InputMaybe<SortEnumType>;
  readonly prefix?: InputMaybe<SortEnumType>;
  readonly printCode?: InputMaybe<SortEnumType>;
  readonly recurringCharges1?: InputMaybe<SortEnumType>;
  readonly recurringCharges2?: InputMaybe<SortEnumType>;
  readonly remainOnFile?: InputMaybe<SortEnumType>;
  readonly requirePoNumber?: InputMaybe<SortEnumType>;
  readonly requirePoNumberDb?: InputMaybe<SortEnumType>;
  readonly reviewDate?: InputMaybe<SortEnumType>;
  readonly salesTaxExpire?: InputMaybe<SortEnumType>;
  readonly salespersonId?: InputMaybe<SortEnumType>;
  readonly scanWords?: InputMaybe<SortEnumType>;
  readonly secondaryAddress?: InputMaybe<SortEnumType>;
  readonly setupDate?: InputMaybe<SortEnumType>;
  readonly shipAddress2?: InputMaybe<SortEnumType>;
  readonly shipAddress3?: InputMaybe<SortEnumType>;
  readonly shipAddress4?: InputMaybe<SortEnumType>;
  readonly shipResidentialCommercial?: InputMaybe<SortEnumType>;
  readonly shipToName?: InputMaybe<NameSortInput>;
  readonly shipToNameId?: InputMaybe<SortEnumType>;
  readonly shipVia?: InputMaybe<SortEnumType>;
  readonly statementBalance?: InputMaybe<SortEnumType>;
  readonly statementDate?: InputMaybe<SortEnumType>;
  readonly statementPeriod?: InputMaybe<SortEnumType>;
  readonly streetAddress?: InputMaybe<SortEnumType>;
  readonly taxNumber?: InputMaybe<SortEnumType>;
  readonly taxStatus?: InputMaybe<SortEnumType>;
  readonly terms?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
  readonly zipCode?: InputMaybe<ZipCodeSortInput>;
  readonly zipCodeId?: InputMaybe<SortEnumType>;
  readonly zipExt?: InputMaybe<SortEnumType>;
  readonly zone?: InputMaybe<SortEnumType>;
};

export type CustomerTop = {
  readonly __typename: 'CustomerTop';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly qtyTransactions: Scalars['Int']['output'];
  readonly totalSales: Scalars['Decimal']['output'];
};

export type CustomerType = {
  readonly __typename: 'CustomerType';
  readonly arDiscountGlAccountId: Scalars['ID']['output'];
  readonly arPrepaymentGlAccountId: Scalars['ID']['output'];
  readonly dealerCustomerId?: Maybe<Scalars['String']['output']>;
  readonly defaultSaleGlAccountId: Scalars['ID']['output'];
  readonly defaultTerms?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly glAdder: Scalars['Int']['output'];
  readonly holdBackOrderAmount?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly privilegeCodes?: Maybe<Scalars['String']['output']>;
  readonly spare0: Scalars['Decimal']['output'];
  readonly spare1?: Maybe<Scalars['String']['output']>;
  readonly specialDiscountLevel?: Maybe<Scalars['String']['output']>;
};

export type CustomerTypeFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<CustomerTypeFilterInput>>;
  readonly arDiscountGlAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly arPrepaymentGlAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly dealerCustomerId?: InputMaybe<StringOperationFilterInput>;
  readonly defaultSaleGlAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly defaultTerms?: InputMaybe<StringOperationFilterInput>;
  readonly description?: InputMaybe<StringOperationFilterInput>;
  readonly glAdder?: InputMaybe<IntOperationFilterInput>;
  readonly holdBackOrderAmount?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<CustomerTypeFilterInput>>;
  readonly privilegeCodes?: InputMaybe<StringOperationFilterInput>;
  readonly spare0?: InputMaybe<DecimalOperationFilterInput>;
  readonly spare1?: InputMaybe<StringOperationFilterInput>;
  readonly specialDiscountLevel?: InputMaybe<StringOperationFilterInput>;
};

export type CustomerTypeSortInput = {
  readonly arDiscountGlAccountId?: InputMaybe<SortEnumType>;
  readonly arPrepaymentGlAccountId?: InputMaybe<SortEnumType>;
  readonly dealerCustomerId?: InputMaybe<SortEnumType>;
  readonly defaultSaleGlAccountId?: InputMaybe<SortEnumType>;
  readonly defaultTerms?: InputMaybe<SortEnumType>;
  readonly description?: InputMaybe<SortEnumType>;
  readonly glAdder?: InputMaybe<SortEnumType>;
  readonly holdBackOrderAmount?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly privilegeCodes?: InputMaybe<SortEnumType>;
  readonly spare0?: InputMaybe<SortEnumType>;
  readonly spare1?: InputMaybe<SortEnumType>;
  readonly specialDiscountLevel?: InputMaybe<SortEnumType>;
};

export type CustomerUpdateInput = {
  readonly advertiser?: InputMaybe<Scalars['String']['input']>;
  readonly alert?: InputMaybe<Scalars['String']['input']>;
  readonly allowLateCharge?: InputMaybe<Scalars['Boolean']['input']>;
  readonly attention?: InputMaybe<Scalars['String']['input']>;
  readonly autoAddItemsToInvoice?: InputMaybe<Scalars['String']['input']>;
  readonly billing?: InputMaybe<Scalars['String']['input']>;
  readonly churchGroup?: InputMaybe<Scalars['String']['input']>;
  readonly commission?: InputMaybe<Scalars['String']['input']>;
  readonly contactPerson?: InputMaybe<Scalars['String']['input']>;
  readonly contactType?: InputMaybe<Scalars['String']['input']>;
  readonly creditLimit?: InputMaybe<Scalars['Decimal']['input']>;
  readonly custom1?: InputMaybe<Scalars['String']['input']>;
  readonly customerTypeId?: InputMaybe<Scalars['String']['input']>;
  readonly discountLevel?: InputMaybe<Scalars['String']['input']>;
  readonly driversLicenseId?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly fax?: InputMaybe<Scalars['String']['input']>;
  readonly field51?: InputMaybe<Scalars['String']['input']>;
  readonly field52?: InputMaybe<Scalars['String']['input']>;
  readonly field53?: InputMaybe<Scalars['String']['input']>;
  readonly field54?: InputMaybe<Scalars['String']['input']>;
  readonly field55?: InputMaybe<Scalars['String']['input']>;
  readonly field56?: InputMaybe<Scalars['String']['input']>;
  readonly homePhone?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly message1?: InputMaybe<Scalars['String']['input']>;
  readonly message2?: InputMaybe<Scalars['String']['input']>;
  readonly message3?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly newCustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly officePhone?: InputMaybe<Scalars['String']['input']>;
  readonly oldId?: InputMaybe<Scalars['String']['input']>;
  readonly prefix?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly recurringCharges1?: InputMaybe<Scalars['String']['input']>;
  readonly recurringCharges2?: InputMaybe<Scalars['ID']['input']>;
  readonly remainOnFile?: InputMaybe<Scalars['String']['input']>;
  readonly requirePoNumber?: InputMaybe<Scalars['Boolean']['input']>;
  readonly reviewDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly salesTaxExpire?: InputMaybe<Scalars['DateTime']['input']>;
  readonly salespersonId?: InputMaybe<Scalars['String']['input']>;
  readonly scanWords?: InputMaybe<Scalars['String']['input']>;
  readonly secondaryAddress?: InputMaybe<Scalars['String']['input']>;
  readonly shipAddress2?: InputMaybe<Scalars['String']['input']>;
  readonly shipAddress3?: InputMaybe<Scalars['String']['input']>;
  readonly shipAddress4?: InputMaybe<Scalars['String']['input']>;
  readonly shipResidentialCommercial?: InputMaybe<ResidentialCommercial>;
  /** Adds a new name record for this customer. */
  readonly shipToNameAdd?: InputMaybe<NameAddInput>;
  /** Use an existing name record. */
  readonly shipToNameId?: InputMaybe<Scalars['String']['input']>;
  readonly shipVia?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly taxNumber?: InputMaybe<Scalars['String']['input']>;
  readonly taxStatus?: InputMaybe<Scalars['String']['input']>;
  readonly terms?: InputMaybe<Scalars['String']['input']>;
  /** When populated with a new zip code, it will be created. Existing zip codes will not be modified. */
  readonly zipCode?: InputMaybe<ZipCodeInput>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
  readonly zone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerValidateInput = {
  readonly advertiser?: InputMaybe<Scalars['String']['input']>;
  readonly alert?: InputMaybe<Scalars['String']['input']>;
  readonly allowLateCharge?: InputMaybe<Scalars['Boolean']['input']>;
  readonly attention?: InputMaybe<Scalars['String']['input']>;
  readonly autoAddItemsToInvoice?: InputMaybe<Scalars['String']['input']>;
  readonly billing?: InputMaybe<Scalars['String']['input']>;
  readonly churchGroup?: InputMaybe<Scalars['String']['input']>;
  readonly commission?: InputMaybe<Scalars['String']['input']>;
  readonly contactPerson?: InputMaybe<Scalars['String']['input']>;
  readonly contactType?: InputMaybe<Scalars['String']['input']>;
  readonly creditLimit?: InputMaybe<Scalars['Decimal']['input']>;
  readonly custom1?: InputMaybe<Scalars['String']['input']>;
  readonly customerTypeId?: InputMaybe<Scalars['String']['input']>;
  readonly discountLevel?: InputMaybe<Scalars['String']['input']>;
  readonly driversLicenseId?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly fax?: InputMaybe<Scalars['String']['input']>;
  readonly field51?: InputMaybe<Scalars['String']['input']>;
  readonly field52?: InputMaybe<Scalars['String']['input']>;
  readonly field53?: InputMaybe<Scalars['String']['input']>;
  readonly field54?: InputMaybe<Scalars['String']['input']>;
  readonly field55?: InputMaybe<Scalars['String']['input']>;
  readonly field56?: InputMaybe<Scalars['String']['input']>;
  readonly homePhone?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly message1?: InputMaybe<Scalars['String']['input']>;
  readonly message2?: InputMaybe<Scalars['String']['input']>;
  readonly message3?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly newCustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly officePhone?: InputMaybe<Scalars['String']['input']>;
  readonly oldId?: InputMaybe<Scalars['String']['input']>;
  readonly prefix?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly recurringCharges1?: InputMaybe<Scalars['String']['input']>;
  readonly recurringCharges2?: InputMaybe<Scalars['ID']['input']>;
  readonly remainOnFile?: InputMaybe<Scalars['String']['input']>;
  readonly requirePoNumber?: InputMaybe<Scalars['Boolean']['input']>;
  readonly reviewDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly salesTaxExpire?: InputMaybe<Scalars['DateTime']['input']>;
  readonly salespersonId?: InputMaybe<Scalars['String']['input']>;
  readonly scanWords?: InputMaybe<Scalars['String']['input']>;
  readonly secondaryAddress?: InputMaybe<Scalars['String']['input']>;
  readonly setupDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly shipAddress2?: InputMaybe<Scalars['String']['input']>;
  readonly shipAddress3?: InputMaybe<Scalars['String']['input']>;
  readonly shipAddress4?: InputMaybe<Scalars['String']['input']>;
  readonly shipResidentialCommercial?: InputMaybe<ResidentialCommercial>;
  readonly shipToName?: InputMaybe<NameValidateInput>;
  readonly shipToNameId?: InputMaybe<Scalars['String']['input']>;
  readonly shipVia?: InputMaybe<Scalars['String']['input']>;
  readonly statementBalance?: InputMaybe<Scalars['Decimal']['input']>;
  readonly statementDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly statementPeriod?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly taxNumber?: InputMaybe<Scalars['String']['input']>;
  readonly taxStatus?: InputMaybe<Scalars['String']['input']>;
  readonly terms?: InputMaybe<Scalars['String']['input']>;
  readonly updated?: InputMaybe<Scalars['DateTime']['input']>;
  readonly zipCode?: InputMaybe<ZipCodeValidateInput>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
  readonly zone?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type CustomersConnection = {
  readonly __typename: 'CustomersConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<CustomersEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Customer>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomersEdge = {
  readonly __typename: 'CustomersEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Customer;
};

export enum CustomersTopPeriod {
  Month = 'MONTH',
  Quarter = 'QUARTER',
  TwoYears = 'TWO_YEARS',
  Year = 'YEAR'
}

export enum CustomersTopSort {
  Count = 'COUNT',
  Total = 'TOTAL'
}

export type DailyLoad = {
  readonly __typename: 'DailyLoad';
  readonly day?: Maybe<Scalars['String']['output']>;
  readonly invoices: Scalars['Int']['output'];
  readonly rentals: Scalars['Int']['output'];
  readonly workOrders: Scalars['Int']['output'];
};

export type DailyLoadFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<DailyLoadFilterInput>>;
  readonly day?: InputMaybe<StringOperationFilterInput>;
  readonly invoices?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<DailyLoadFilterInput>>;
  readonly rentals?: InputMaybe<IntOperationFilterInput>;
  readonly workOrders?: InputMaybe<IntOperationFilterInput>;
};

export type DailyLoadSortInput = {
  readonly day?: InputMaybe<SortEnumType>;
  readonly invoices?: InputMaybe<SortEnumType>;
  readonly rentals?: InputMaybe<SortEnumType>;
  readonly workOrders?: InputMaybe<SortEnumType>;
};

export type DailySalesStatistics = {
  readonly __typename: 'DailySalesStatistics';
  readonly highestAmountGL: QuantityAndAmount;
  readonly highestAmountItem: QuantityAndAmount;
  readonly highestQuantityGL: QuantityAndAmount;
  readonly highestQuantityItem: QuantityAndAmount;
  readonly invoices: Scalars['Int']['output'];
  readonly invoicesSum: Scalars['Decimal']['output'];
  readonly uniqueInvoiceCustomers: Scalars['Int']['output'];
};

export type Dashboard = {
  readonly __typename: 'Dashboard';
  readonly added: Scalars['DateTime']['output'];
  readonly allowEdit: DashboardEditModes;
  readonly group: DashboardGroup;
  readonly id: Scalars['String']['output'];
  readonly isSystem: Scalars['Boolean']['output'];
  readonly sizes: ReadonlyArray<DashboardSize>;
  readonly title: Scalars['String']['output'];
  readonly updated: Scalars['DateTime']['output'];
  readonly userGroupIds: ReadonlyArray<Scalars['String']['output']>;
  readonly userGroups: ReadonlyArray<UserGroup>;
  readonly userIds: ReadonlyArray<Scalars['String']['output']>;
  readonly users: ReadonlyArray<User>;
  readonly widgets: ReadonlyArray<DashboardWidget>;
};

export type DashboardAddInput = {
  readonly group: Scalars['String']['input'];
  /** If the id isn't specified, a GUID will be generated */
  readonly id?: InputMaybe<Scalars['String']['input']>;
  /** The 'xl' breakpoint is required. */
  readonly sizes: ReadonlyArray<DashboardSizeInput>;
  readonly title: Scalars['String']['input'];
  /** Ids of the user groups that this dashboard should be available to */
  readonly userGroups: ReadonlyArray<Scalars['String']['input']>;
  /** Ids of the users that this dashboard should be available to */
  readonly users: ReadonlyArray<Scalars['String']['input']>;
  readonly widgets: ReadonlyArray<DashboardWidgetInput>;
};

export enum DashboardEditModes {
  Full = 'FULL',
  None = 'NONE',
  /** Is a system dashboard where only limited edits are allowed. */
  System = 'SYSTEM'
}

export type DashboardGroup = {
  readonly __typename: 'DashboardGroup';
  /** Other dashboards in the same group which are accessible to the user. */
  readonly dashboards: ReadonlyArray<Dashboard>;
  /** Default dashboard for the current user. */
  readonly defaultDashboardId: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
};

export type DashboardLayout = {
  readonly __typename: 'DashboardLayout';
  readonly h: Scalars['Int']['output'];
  readonly i: Scalars['String']['output'];
  readonly w: Scalars['Int']['output'];
  readonly x: Scalars['Int']['output'];
  readonly y: Scalars['Int']['output'];
};

export type DashboardLayoutInput = {
  readonly h: Scalars['Int']['input'];
  readonly i: Scalars['String']['input'];
  readonly w: Scalars['Int']['input'];
  readonly x: Scalars['Int']['input'];
  readonly y: Scalars['Int']['input'];
};

export type DashboardSize = {
  readonly __typename: 'DashboardSize';
  readonly breakpoint: Scalars['String']['output'];
  readonly canGrow?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly hide?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly layout: ReadonlyArray<DashboardLayout>;
};

export type DashboardSizeInput = {
  readonly breakpoint: Scalars['String']['input'];
  readonly canGrow?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly hide?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly layout: ReadonlyArray<DashboardLayoutInput>;
};

export type DashboardUpdateInput = {
  readonly group: Scalars['String']['input'];
  readonly id: Scalars['String']['input'];
  /** The 'xl' breakpoint is required. */
  readonly sizes: ReadonlyArray<DashboardSizeInput>;
  readonly title: Scalars['String']['input'];
  /** Ids of the user groups that this dashboard should be available to */
  readonly userGroups: ReadonlyArray<Scalars['String']['input']>;
  /** Ids of the users that this dashboard should be available to */
  readonly users: ReadonlyArray<Scalars['String']['input']>;
  readonly widgets: ReadonlyArray<DashboardWidgetInput>;
};

export type DashboardWidget = {
  readonly __typename: 'DashboardWidget';
  readonly id: Scalars['String']['output'];
  readonly instanceConfig?: Maybe<Scalars['String']['output']>;
  readonly typeId: Scalars['String']['output'];
};

export type DashboardWidgetInput = {
  readonly id: Scalars['String']['input'];
  readonly instanceConfig?: InputMaybe<Scalars['String']['input']>;
  readonly typeId: Scalars['String']['input'];
};

export type DatabaseConnectionsState = {
  readonly __typename: 'DatabaseConnectionsState';
  readonly name: Scalars['String']['output'];
  readonly qty: Scalars['Int']['output'];
};

export type DatabaseInfoConnection = {
  readonly __typename: 'DatabaseInfoConnection';
  readonly applicationName: Scalars['String']['output'];
  readonly backendType: Scalars['String']['output'];
  readonly databaseName?: Maybe<Scalars['String']['output']>;
  readonly pid: Scalars['Int']['output'];
  readonly query: Scalars['String']['output'];
  readonly state: Scalars['String']['output'];
  readonly username?: Maybe<Scalars['String']['output']>;
};

export type DatabaseInfoResult = {
  readonly __typename: 'DatabaseInfoResult';
  readonly activeConnections: Scalars['Int']['output'];
  readonly connectionsByDb: ReadonlyArray<DatabaseConnectionsState>;
  readonly connectionsStates: ReadonlyArray<DatabaseConnectionsState>;
  readonly maxConnections: Scalars['Int']['output'];
  readonly rawConnectionInfo: ReadonlyArray<DatabaseInfoConnection>;
  readonly superuserReservedConnections: Scalars['Int']['output'];
};

export type DateOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Date']['input']>;
  readonly gt?: InputMaybe<Scalars['Date']['input']>;
  readonly gte?: InputMaybe<Scalars['Date']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Date']['input']>>>;
  readonly lt?: InputMaybe<Scalars['Date']['input']>;
  readonly lte?: InputMaybe<Scalars['Date']['input']>;
  readonly neq?: InputMaybe<Scalars['Date']['input']>;
  readonly ngt?: InputMaybe<Scalars['Date']['input']>;
  readonly ngte?: InputMaybe<Scalars['Date']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Date']['input']>>>;
  readonly nlt?: InputMaybe<Scalars['Date']['input']>;
  readonly nlte?: InputMaybe<Scalars['Date']['input']>;
};

export type DateRange = {
  readonly __typename: 'DateRange';
  readonly end?: Maybe<Scalars['DateTime']['output']>;
  readonly noEnd: Scalars['Boolean']['output'];
  readonly start: Scalars['DateTime']['output'];
};

export type DateTimeOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['DateTime']['input']>;
  readonly gt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly gte?: InputMaybe<Scalars['DateTime']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>>;
  readonly lt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly lte?: InputMaybe<Scalars['DateTime']['input']>;
  readonly neq?: InputMaybe<Scalars['DateTime']['input']>;
  readonly ngt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly ngte?: InputMaybe<Scalars['DateTime']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>>;
  readonly nlt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Decimal']['input']>;
  readonly gt?: InputMaybe<Scalars['Decimal']['input']>;
  readonly gte?: InputMaybe<Scalars['Decimal']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Decimal']['input']>>>;
  readonly lt?: InputMaybe<Scalars['Decimal']['input']>;
  readonly lte?: InputMaybe<Scalars['Decimal']['input']>;
  readonly neq?: InputMaybe<Scalars['Decimal']['input']>;
  readonly ngt?: InputMaybe<Scalars['Decimal']['input']>;
  readonly ngte?: InputMaybe<Scalars['Decimal']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Decimal']['input']>>>;
  readonly nlt?: InputMaybe<Scalars['Decimal']['input']>;
  readonly nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DeleteResult = {
  readonly __typename: 'DeleteResult';
  readonly deleted: Scalars['Boolean']['output'];
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type Department = {
  readonly __typename: 'Department';
  readonly code: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly updatedBy?: Maybe<Scalars['String']['output']>;
  readonly validRange: DateRange;
};

export type DepositDetail = {
  readonly __typename: 'DepositDetail';
  readonly discountApplied: Scalars['Decimal']['output'];
  readonly discountDate?: Maybe<Scalars['DateTime']['output']>;
  readonly discountEarned?: Maybe<Scalars['String']['output']>;
  readonly discountOrBackorderAmount: Scalars['Decimal']['output'];
  readonly id: Scalars['ID']['output'];
  readonly invoiceAmount: Scalars['Decimal']['output'];
  readonly invoiceBalance: Scalars['Decimal']['output'];
  readonly invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  readonly invoiceId: Scalars['ID']['output'];
  readonly lineNum: Scalars['Int']['output'];
  readonly paymentApplied: Scalars['Decimal']['output'];
  readonly previousPayment: Scalars['Decimal']['output'];
};

export type Device = {
  readonly __typename: 'Device';
  readonly added: Scalars['DateTime']['output'];
  readonly deviceType: DeviceTypes;
  readonly deviceTypeString: Scalars['String']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type DeviceAddInput = {
  readonly deviceType: DeviceTypes;
  readonly name: Scalars['String']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<DeviceFilterInput>>;
  readonly deviceType?: InputMaybe<DeviceTypesOperationFilterInput>;
  readonly deviceTypeString?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<DeviceFilterInput>>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type DeviceSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly deviceType?: InputMaybe<SortEnumType>;
  readonly deviceTypeString?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

export enum DeviceTypes {
  Laptop = 'LAPTOP',
  PrintServer = 'PRINT_SERVER',
  Smartphone = 'SMARTPHONE',
  Tablet = 'TABLET',
  WorkStation = 'WORK_STATION'
}

export type DeviceTypesOperationFilterInput = {
  readonly eq?: InputMaybe<DeviceTypes>;
  readonly in?: InputMaybe<ReadonlyArray<DeviceTypes>>;
  readonly neq?: InputMaybe<DeviceTypes>;
  readonly nin?: InputMaybe<ReadonlyArray<DeviceTypes>>;
};

export type DeviceUpdateInput = {
  readonly deviceType: DeviceTypes;
  readonly id: Scalars['UUID']['input'];
  readonly name: Scalars['String']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type DevicesConnection = {
  readonly __typename: 'DevicesConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<DevicesEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Device>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DevicesEdge = {
  readonly __typename: 'DevicesEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Device;
};

export enum Dimension {
  A = 'A',
  Collapse = 'COLLAPSE',
  Curve = 'CURVE',
  Dontcare = 'DONTCARE',
  P = 'P',
  True = 'TRUE',
  Unknown = 'UNKNOWN'
}

export type DimensionOperationFilterInput = {
  readonly eq?: InputMaybe<Dimension>;
  readonly in?: InputMaybe<ReadonlyArray<Dimension>>;
  readonly neq?: InputMaybe<Dimension>;
  readonly nin?: InputMaybe<ReadonlyArray<Dimension>>;
};

export type DispatcherPinColor = {
  readonly __typename: 'DispatcherPinColor';
  readonly added: Scalars['DateTime']['output'];
  readonly color?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly priority: Scalars['Int']['output'];
  readonly updated: Scalars['DateTime']['output'];
  readonly userId?: Maybe<Scalars['String']['output']>;
};

export type DispatcherPinColorSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly color?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly priority?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
  readonly userId?: InputMaybe<SortEnumType>;
};

export type DispatcherPinConfiguration = {
  readonly __typename: 'DispatcherPinConfiguration';
  readonly added: Scalars['DateTime']['output'];
  readonly color?: Maybe<DispatcherPinColor>;
  readonly colorId?: Maybe<Scalars['String']['output']>;
  readonly expression?: Maybe<Scalars['String']['output']>;
  readonly icon?: Maybe<DispatcherPinIcon>;
  readonly iconId?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly opacity?: Maybe<Scalars['Decimal']['output']>;
  readonly priority: Scalars['Int']['output'];
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly userId?: Maybe<Scalars['String']['output']>;
};

export type DispatcherPinConfigurationSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly color?: InputMaybe<DispatcherPinColorSortInput>;
  readonly colorId?: InputMaybe<SortEnumType>;
  readonly expression?: InputMaybe<SortEnumType>;
  readonly icon?: InputMaybe<DispatcherPinIconSortInput>;
  readonly iconId?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly opacity?: InputMaybe<SortEnumType>;
  readonly priority?: InputMaybe<SortEnumType>;
  readonly type?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
  readonly userId?: InputMaybe<SortEnumType>;
};

export type DispatcherPinIcon = {
  readonly __typename: 'DispatcherPinIcon';
  readonly added: Scalars['DateTime']['output'];
  readonly icon?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly priority: Scalars['Int']['output'];
  readonly updated: Scalars['DateTime']['output'];
  readonly userId?: Maybe<Scalars['String']['output']>;
};

export type DispatcherPinIconSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly icon?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly priority?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
  readonly userId?: InputMaybe<SortEnumType>;
};

export type DocumentGenerationDocumentInfo = {
  readonly __typename: 'DocumentGenerationDocumentInfo';
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly documentId: Scalars['String']['output'];
  readonly fileName: Scalars['String']['output'];
  readonly generatorId: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type DocumentGenerationEntity = {
  readonly __typename: 'DocumentGenerationEntity';
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly generators: ReadonlyArray<DocumentGenerator>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type DocumentGenerator = {
  readonly __typename: 'DocumentGenerator';
  readonly defaultEnabled: Scalars['Boolean']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly enabled: Scalars['Boolean']['output'];
  readonly entityId: Scalars['String']['output'];
  readonly fields: ReadonlyArray<DocumentGeneratorField>;
  readonly generatorId: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type DocumentGeneratorConfigInput = {
  readonly configuration: Scalars['String']['input'];
  readonly enabled: Scalars['Boolean']['input'];
  readonly entityId: Scalars['String']['input'];
  readonly generatorId: Scalars['String']['input'];
};

export type DocumentGeneratorField = {
  readonly __typename: 'DocumentGeneratorField';
  readonly availableValues: ReadonlyArray<Scalars['String']['output']>;
  readonly field: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type Driver = {
  readonly __typename: 'Driver';
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type DriverFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<DriverFilterInput>>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<DriverFilterInput>>;
  readonly phoneNumber?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type DriverSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly phoneNumber?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

export type Employee = {
  readonly __typename: 'Employee';
  readonly attention?: Maybe<Scalars['String']['output']>;
  readonly automaticOverTime?: Maybe<Scalars['String']['output']>;
  readonly automaticVacation?: Maybe<Scalars['String']['output']>;
  readonly birthDate?: Maybe<Scalars['DateTime']['output']>;
  readonly breakItemId?: Maybe<Scalars['String']['output']>;
  readonly classification?: Maybe<Scalars['String']['output']>;
  readonly custom1?: Maybe<Scalars['String']['output']>;
  readonly custom2?: Maybe<Scalars['String']['output']>;
  readonly dedEarn1?: Maybe<Scalars['String']['output']>;
  readonly dedEarn2?: Maybe<Scalars['String']['output']>;
  readonly dedEarn5?: Maybe<Scalars['String']['output']>;
  readonly dedEarn6?: Maybe<Scalars['String']['output']>;
  readonly dedEarn7?: Maybe<Scalars['String']['output']>;
  readonly deductOnce1?: Maybe<Scalars['String']['output']>;
  readonly deductOnce2?: Maybe<Scalars['String']['output']>;
  readonly driversLicenseId?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly employeePosted?: Maybe<EmployeePosted>;
  readonly extraFedWh: Scalars['Decimal']['output'];
  readonly extraLocalWh: Scalars['Decimal']['output'];
  readonly extraOtherStateWh: Scalars['Decimal']['output'];
  readonly extraOtherWh1: Scalars['Decimal']['output'];
  readonly extraOtherWh2: Scalars['Decimal']['output'];
  readonly extraStateWh: Scalars['Decimal']['output'];
  readonly fedDependents: Scalars['Int']['output'];
  readonly fedWhExempt?: Maybe<Scalars['String']['output']>;
  readonly federalStatus?: Maybe<Scalars['String']['output']>;
  readonly federalWageAdjustment: Scalars['Decimal']['output'];
  readonly ficaExempt?: Maybe<Scalars['String']['output']>;
  readonly fringeBenefits: Scalars['Decimal']['output'];
  readonly futaExempt?: Maybe<Scalars['String']['output']>;
  readonly hireDate?: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['String']['output'];
  readonly insurance: Scalars['Decimal']['output'];
  readonly lastRaiseDate?: Maybe<Scalars['DateTime']['output']>;
  readonly lastTimeCardPaidDate?: Maybe<Scalars['DateTime']['output']>;
  readonly localWhExempt?: Maybe<Scalars['String']['output']>;
  readonly localWhId?: Maybe<Scalars['String']['output']>;
  readonly locations: ReadonlyArray<Location>;
  readonly maritalStatus?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly nextTimeCardToPayId: Scalars['ID']['output'];
  readonly note?: Maybe<Scalars['String']['output']>;
  readonly otherDependents?: Maybe<Scalars['String']['output']>;
  readonly otherStateExempt?: Maybe<Scalars['String']['output']>;
  readonly otherStateId?: Maybe<Scalars['String']['output']>;
  readonly otherStatus?: Maybe<Scalars['String']['output']>;
  readonly otherWh1?: Maybe<Scalars['String']['output']>;
  readonly otherWh2?: Maybe<Scalars['String']['output']>;
  readonly otherWhExempt1?: Maybe<Scalars['String']['output']>;
  readonly otherWhExempt2?: Maybe<Scalars['String']['output']>;
  readonly payDedAmt1: Scalars['Decimal']['output'];
  readonly payDedAmt2: Scalars['Decimal']['output'];
  readonly payDedAmt3: Scalars['Decimal']['output'];
  readonly payDedAmt4: Scalars['Decimal']['output'];
  readonly payDedAmt5: Scalars['Decimal']['output'];
  readonly payDedAmt6: Scalars['Decimal']['output'];
  readonly payDedAmt7: Scalars['Decimal']['output'];
  readonly payEarn3?: Maybe<Scalars['String']['output']>;
  readonly payEarn4?: Maybe<Scalars['String']['output']>;
  readonly payFrequency?: Maybe<Scalars['String']['output']>;
  readonly pensionPlan?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly reviewDate?: Maybe<Scalars['DateTime']['output']>;
  readonly ss?: Maybe<Scalars['String']['output']>;
  readonly stateCredit: Scalars['Decimal']['output'];
  readonly stateExempt?: Maybe<Scalars['String']['output']>;
  readonly stateId?: Maybe<Scalars['String']['output']>;
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly suiExempt?: Maybe<Scalars['String']['output']>;
  readonly terminationDate?: Maybe<Scalars['DateTime']['output']>;
  readonly timeClock?: Maybe<Scalars['String']['output']>;
  readonly totalToDeduct1: Scalars['Decimal']['output'];
  readonly totalToDeduct2: Scalars['Decimal']['output'];
  readonly totalToDeduct3: Scalars['Decimal']['output'];
  readonly totalToDeduct4: Scalars['Decimal']['output'];
  readonly totalToDeduct5: Scalars['Decimal']['output'];
  readonly totalToDeduct6: Scalars['Decimal']['output'];
  readonly totalToDeduct7: Scalars['Decimal']['output'];
  readonly workmanCompId?: Maybe<Scalars['String']['output']>;
  readonly zipCode?: Maybe<ZipCode>;
  readonly zipCodeId?: Maybe<Scalars['String']['output']>;
  readonly zipExt?: Maybe<Scalars['String']['output']>;
};

export type EmployeeAddInput = {
  readonly attention?: InputMaybe<Scalars['String']['input']>;
  readonly automaticOverTime?: InputMaybe<Scalars['String']['input']>;
  readonly automaticVacation?: InputMaybe<Scalars['String']['input']>;
  readonly birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly breakItemId?: InputMaybe<Scalars['String']['input']>;
  readonly classification?: InputMaybe<Scalars['String']['input']>;
  readonly custom1?: InputMaybe<Scalars['String']['input']>;
  readonly custom2?: InputMaybe<Scalars['String']['input']>;
  readonly dedEarn1?: InputMaybe<Scalars['String']['input']>;
  readonly dedEarn2?: InputMaybe<Scalars['String']['input']>;
  readonly dedEarn5?: InputMaybe<Scalars['String']['input']>;
  readonly dedEarn6?: InputMaybe<Scalars['String']['input']>;
  readonly dedEarn7?: InputMaybe<Scalars['String']['input']>;
  readonly deductOnce1?: InputMaybe<Scalars['String']['input']>;
  readonly deductOnce2?: InputMaybe<Scalars['String']['input']>;
  readonly driversLicenseId?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly extraFedWh?: InputMaybe<Scalars['Decimal']['input']>;
  readonly extraLocalWh?: InputMaybe<Scalars['Decimal']['input']>;
  readonly extraOtherStateWh?: InputMaybe<Scalars['Decimal']['input']>;
  readonly extraOtherWh1?: InputMaybe<Scalars['Decimal']['input']>;
  readonly extraOtherWh2?: InputMaybe<Scalars['Decimal']['input']>;
  readonly extraStateWh?: InputMaybe<Scalars['Decimal']['input']>;
  readonly fedDependents?: InputMaybe<Scalars['Int']['input']>;
  readonly fedWhExempt?: InputMaybe<Scalars['String']['input']>;
  readonly federalStatus?: InputMaybe<Scalars['String']['input']>;
  readonly federalWageAdjustment?: InputMaybe<Scalars['Decimal']['input']>;
  readonly ficaExempt?: InputMaybe<Scalars['String']['input']>;
  readonly fringeBenefits?: InputMaybe<Scalars['Decimal']['input']>;
  readonly futaExempt?: InputMaybe<Scalars['String']['input']>;
  readonly hireDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly insurance?: InputMaybe<Scalars['Decimal']['input']>;
  readonly lastRaiseDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly lastTimeCardPaidDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly localWhExempt?: InputMaybe<Scalars['String']['input']>;
  readonly localWhId?: InputMaybe<Scalars['String']['input']>;
  readonly maritalStatus?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly nextTimeCardToPayId?: InputMaybe<Scalars['ID']['input']>;
  readonly note?: InputMaybe<Scalars['String']['input']>;
  readonly otherDependents?: InputMaybe<Scalars['String']['input']>;
  readonly otherStateExempt?: InputMaybe<Scalars['String']['input']>;
  readonly otherStateId?: InputMaybe<Scalars['String']['input']>;
  readonly otherStatus?: InputMaybe<Scalars['String']['input']>;
  readonly otherWh1?: InputMaybe<Scalars['String']['input']>;
  readonly otherWh2?: InputMaybe<Scalars['String']['input']>;
  readonly otherWhExempt1?: InputMaybe<Scalars['String']['input']>;
  readonly otherWhExempt2?: InputMaybe<Scalars['String']['input']>;
  readonly payDedAmt1?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt2?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt3?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt4?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt5?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt6?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt7?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payEarn3?: InputMaybe<Scalars['String']['input']>;
  readonly payEarn4?: InputMaybe<Scalars['String']['input']>;
  readonly payFrequency?: InputMaybe<Scalars['String']['input']>;
  readonly pensionPlan?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly reviewDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly ss?: InputMaybe<Scalars['String']['input']>;
  readonly stateCredit?: InputMaybe<Scalars['Decimal']['input']>;
  readonly stateExempt?: InputMaybe<Scalars['String']['input']>;
  readonly stateId?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly suiExempt?: InputMaybe<Scalars['String']['input']>;
  readonly terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly timeClock?: InputMaybe<Scalars['String']['input']>;
  readonly totalToDeduct1?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct2?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct3?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct4?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct5?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct6?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct7?: InputMaybe<Scalars['Decimal']['input']>;
  readonly workmanCompId?: InputMaybe<Scalars['String']['input']>;
  /** When populated with a new zip code, it will be created. Existing zip codes will not be modified. */
  readonly zipCode?: InputMaybe<ZipCodeInput>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
};

export type EmployeeFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<EmployeeFilterInput>>;
  readonly attention?: InputMaybe<StringOperationFilterInput>;
  readonly automaticOverTime?: InputMaybe<StringOperationFilterInput>;
  readonly automaticVacation?: InputMaybe<StringOperationFilterInput>;
  readonly birthDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly breakItemId?: InputMaybe<StringOperationFilterInput>;
  readonly classification?: InputMaybe<StringOperationFilterInput>;
  readonly custom1?: InputMaybe<StringOperationFilterInput>;
  readonly custom2?: InputMaybe<StringOperationFilterInput>;
  readonly dedEarn1?: InputMaybe<StringOperationFilterInput>;
  readonly dedEarn2?: InputMaybe<StringOperationFilterInput>;
  readonly dedEarn5?: InputMaybe<StringOperationFilterInput>;
  readonly dedEarn6?: InputMaybe<StringOperationFilterInput>;
  readonly dedEarn7?: InputMaybe<StringOperationFilterInput>;
  readonly deductOnce1?: InputMaybe<StringOperationFilterInput>;
  readonly deductOnce2?: InputMaybe<StringOperationFilterInput>;
  readonly driversLicenseId?: InputMaybe<StringOperationFilterInput>;
  readonly email?: InputMaybe<StringOperationFilterInput>;
  readonly extraFedWh?: InputMaybe<DecimalOperationFilterInput>;
  readonly extraLocalWh?: InputMaybe<DecimalOperationFilterInput>;
  readonly extraOtherStateWh?: InputMaybe<DecimalOperationFilterInput>;
  readonly extraOtherWh1?: InputMaybe<DecimalOperationFilterInput>;
  readonly extraOtherWh2?: InputMaybe<DecimalOperationFilterInput>;
  readonly extraStateWh?: InputMaybe<DecimalOperationFilterInput>;
  readonly fedDependents?: InputMaybe<IntOperationFilterInput>;
  readonly fedWhExempt?: InputMaybe<StringOperationFilterInput>;
  readonly federalStatus?: InputMaybe<StringOperationFilterInput>;
  readonly federalWageAdjustment?: InputMaybe<DecimalOperationFilterInput>;
  readonly ficaExempt?: InputMaybe<StringOperationFilterInput>;
  readonly fringeBenefits?: InputMaybe<DecimalOperationFilterInput>;
  readonly futaExempt?: InputMaybe<StringOperationFilterInput>;
  readonly hireDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly insurance?: InputMaybe<DecimalOperationFilterInput>;
  readonly lastRaiseDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly lastTimeCardPaidDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly localWhExempt?: InputMaybe<StringOperationFilterInput>;
  readonly localWhId?: InputMaybe<StringOperationFilterInput>;
  readonly locations?: InputMaybe<ListFilterInputTypeOfLocationFilterInput>;
  readonly maritalStatus?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly nextTimeCardToPayId?: InputMaybe<IdOperationFilterInput>;
  readonly note?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<EmployeeFilterInput>>;
  readonly otherDependents?: InputMaybe<StringOperationFilterInput>;
  readonly otherStateExempt?: InputMaybe<StringOperationFilterInput>;
  readonly otherStateId?: InputMaybe<StringOperationFilterInput>;
  readonly otherStatus?: InputMaybe<StringOperationFilterInput>;
  readonly otherWh1?: InputMaybe<StringOperationFilterInput>;
  readonly otherWh2?: InputMaybe<StringOperationFilterInput>;
  readonly otherWhExempt1?: InputMaybe<StringOperationFilterInput>;
  readonly otherWhExempt2?: InputMaybe<StringOperationFilterInput>;
  readonly payDedAmt1?: InputMaybe<DecimalOperationFilterInput>;
  readonly payDedAmt2?: InputMaybe<DecimalOperationFilterInput>;
  readonly payDedAmt3?: InputMaybe<DecimalOperationFilterInput>;
  readonly payDedAmt4?: InputMaybe<DecimalOperationFilterInput>;
  readonly payDedAmt5?: InputMaybe<DecimalOperationFilterInput>;
  readonly payDedAmt6?: InputMaybe<DecimalOperationFilterInput>;
  readonly payDedAmt7?: InputMaybe<DecimalOperationFilterInput>;
  readonly payEarn3?: InputMaybe<StringOperationFilterInput>;
  readonly payEarn4?: InputMaybe<StringOperationFilterInput>;
  readonly payFrequency?: InputMaybe<StringOperationFilterInput>;
  readonly pensionPlan?: InputMaybe<StringOperationFilterInput>;
  readonly phone?: InputMaybe<StringOperationFilterInput>;
  readonly reviewDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly ss?: InputMaybe<StringOperationFilterInput>;
  readonly stateCredit?: InputMaybe<DecimalOperationFilterInput>;
  readonly stateExempt?: InputMaybe<StringOperationFilterInput>;
  readonly stateId?: InputMaybe<StringOperationFilterInput>;
  readonly streetAddress?: InputMaybe<StringOperationFilterInput>;
  readonly suiExempt?: InputMaybe<StringOperationFilterInput>;
  readonly terminationDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly timeClock?: InputMaybe<StringOperationFilterInput>;
  readonly totalToDeduct1?: InputMaybe<DecimalOperationFilterInput>;
  readonly totalToDeduct2?: InputMaybe<DecimalOperationFilterInput>;
  readonly totalToDeduct3?: InputMaybe<DecimalOperationFilterInput>;
  readonly totalToDeduct4?: InputMaybe<DecimalOperationFilterInput>;
  readonly totalToDeduct5?: InputMaybe<DecimalOperationFilterInput>;
  readonly totalToDeduct6?: InputMaybe<DecimalOperationFilterInput>;
  readonly totalToDeduct7?: InputMaybe<DecimalOperationFilterInput>;
  readonly workmanCompId?: InputMaybe<StringOperationFilterInput>;
  readonly zipCode?: InputMaybe<ZipCodeFilterInput>;
  readonly zipCodeId?: InputMaybe<StringOperationFilterInput>;
  readonly zipExt?: InputMaybe<StringOperationFilterInput>;
};

export type EmployeePosted = {
  readonly __typename: 'EmployeePosted';
  readonly automaticVacationPtr?: Maybe<Scalars['String']['output']>;
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly deduction1: Scalars['Decimal']['output'];
  readonly deduction2: Scalars['Decimal']['output'];
  readonly deduction3: Scalars['Decimal']['output'];
  readonly deduction4: Scalars['Decimal']['output'];
  readonly deduction5: Scalars['Decimal']['output'];
  readonly deduction6: Scalars['Decimal']['output'];
  readonly deduction7: Scalars['Decimal']['output'];
  readonly fedWage: Scalars['Decimal']['output'];
  readonly fedWhTax: Scalars['Decimal']['output'];
  readonly ficaWage: Scalars['Decimal']['output'];
  readonly ficaWhTax: Scalars['Decimal']['output'];
  readonly holidayHours: Scalars['Decimal']['output'];
  readonly holidayHoursDue: Scalars['Decimal']['output'];
  readonly holidayPtr?: Maybe<Scalars['String']['output']>;
  readonly holidayWage: Scalars['Decimal']['output'];
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly insurance: Scalars['Decimal']['output'];
  readonly localWage: Scalars['Decimal']['output'];
  readonly localWhTax: Scalars['Decimal']['output'];
  readonly medicareWage: Scalars['Decimal']['output'];
  readonly medicareWhTax: Scalars['Decimal']['output'];
  readonly netPay: Scalars['Decimal']['output'];
  readonly oneTimeDeductionAmount: Scalars['Decimal']['output'];
  readonly oneTimeDeductionDate?: Maybe<Scalars['DateTime']['output']>;
  readonly otherHours1: Scalars['Decimal']['output'];
  readonly otherHours2: Scalars['Decimal']['output'];
  readonly otherHours3: Scalars['Decimal']['output'];
  readonly otherHours4: Scalars['Decimal']['output'];
  readonly otherPtr?: Maybe<Scalars['String']['output']>;
  readonly otherStateWage: Scalars['Decimal']['output'];
  readonly otherStateWhTax: Scalars['Decimal']['output'];
  readonly otherWage1: Scalars['Decimal']['output'];
  readonly otherWage2: Scalars['Decimal']['output'];
  readonly otherWage3: Scalars['Decimal']['output'];
  readonly otherWage4: Scalars['Decimal']['output'];
  readonly otherWhTax: Scalars['Decimal']['output'];
  readonly otherWhWage: Scalars['Decimal']['output'];
  readonly overtimeHours: Scalars['Decimal']['output'];
  readonly overtimeWage: Scalars['Decimal']['output'];
  readonly paycheckPtr?: Maybe<Scalars['String']['output']>;
  readonly priorYtd01: Scalars['Decimal']['output'];
  readonly priorYtd02: Scalars['Decimal']['output'];
  readonly priorYtd03: Scalars['Decimal']['output'];
  readonly priorYtd04: Scalars['Decimal']['output'];
  readonly priorYtd05: Scalars['Decimal']['output'];
  readonly priorYtd06: Scalars['Decimal']['output'];
  readonly priorYtd07: Scalars['Decimal']['output'];
  readonly priorYtd08: Scalars['Decimal']['output'];
  readonly priorYtd09: Scalars['Decimal']['output'];
  readonly priorYtd10: Scalars['Decimal']['output'];
  readonly priorYtd11: Scalars['Decimal']['output'];
  readonly priorYtd12: Scalars['Decimal']['output'];
  readonly priorYtd13: Scalars['Decimal']['output'];
  readonly priorYtd14: Scalars['Decimal']['output'];
  readonly priorYtd15: Scalars['Decimal']['output'];
  readonly priorYtd16: Scalars['Decimal']['output'];
  readonly priorYtd17: Scalars['Decimal']['output'];
  readonly priorYtd18: Scalars['Decimal']['output'];
  readonly priorYtd19: Scalars['Decimal']['output'];
  readonly priorYtd20: Scalars['Decimal']['output'];
  readonly priorYtd21: Scalars['Decimal']['output'];
  readonly priorYtd22: Scalars['Decimal']['output'];
  readonly priorYtd23: Scalars['Decimal']['output'];
  readonly priorYtd24: Scalars['Decimal']['output'];
  readonly priorYtd25: Scalars['Decimal']['output'];
  readonly priorYtd26: Scalars['Decimal']['output'];
  readonly priorYtd27: Scalars['Decimal']['output'];
  readonly priorYtd28: Scalars['Decimal']['output'];
  readonly priorYtd29: Scalars['Decimal']['output'];
  readonly priorYtd30: Scalars['Decimal']['output'];
  readonly priorYtd31: Scalars['Decimal']['output'];
  readonly priorYtd32: Scalars['Decimal']['output'];
  readonly priorYtd33: Scalars['Decimal']['output'];
  readonly priorYtd34: Scalars['Decimal']['output'];
  readonly priorYtd35: Scalars['Decimal']['output'];
  readonly priorYtd36: Scalars['Decimal']['output'];
  readonly priorYtd37: Scalars['Decimal']['output'];
  readonly priorYtd38: Scalars['Decimal']['output'];
  readonly priorYtd39: Scalars['Decimal']['output'];
  readonly priorYtd40: Scalars['Decimal']['output'];
  readonly priorYtd41: Scalars['Decimal']['output'];
  readonly privelegeDeductionAmount: Scalars['Decimal']['output'];
  readonly privelegeDeductionDate?: Maybe<Scalars['DateTime']['output']>;
  readonly regularHours: Scalars['Decimal']['output'];
  readonly regularWage: Scalars['Decimal']['output'];
  readonly reservedAutomaticPtr1?: Maybe<Scalars['String']['output']>;
  readonly reservedAutomaticPtr2?: Maybe<Scalars['String']['output']>;
  readonly sickHoursDue: Scalars['Decimal']['output'];
  readonly sickPtr?: Maybe<Scalars['String']['output']>;
  readonly spare0: Scalars['Decimal']['output'];
  readonly stateWage: Scalars['Decimal']['output'];
  readonly stateWhTax: Scalars['Decimal']['output'];
  readonly temporaryDeduction: Scalars['Decimal']['output'];
  readonly timeCardPtr?: Maybe<Scalars['String']['output']>;
  readonly tips: Scalars['Decimal']['output'];
  readonly vacationHours: Scalars['Decimal']['output'];
  readonly vacationHoursDue: Scalars['Decimal']['output'];
  readonly vacationPtr?: Maybe<Scalars['String']['output']>;
  readonly vacationWage: Scalars['Decimal']['output'];
};

export type EmployeeSaveResult = {
  readonly __typename: 'EmployeeSaveResult';
  readonly data?: Maybe<Name>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

/** A connection to a list of items. */
export type EmployeeSearchConnection = {
  readonly __typename: 'EmployeeSearchConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<EmployeeSearchEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Employee>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EmployeeSearchEdge = {
  readonly __typename: 'EmployeeSearchEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Employee;
};

export type EmployeeSortInput = {
  readonly attention?: InputMaybe<SortEnumType>;
  readonly automaticOverTime?: InputMaybe<SortEnumType>;
  readonly automaticVacation?: InputMaybe<SortEnumType>;
  readonly birthDate?: InputMaybe<SortEnumType>;
  readonly breakItemId?: InputMaybe<SortEnumType>;
  readonly classification?: InputMaybe<SortEnumType>;
  readonly custom1?: InputMaybe<SortEnumType>;
  readonly custom2?: InputMaybe<SortEnumType>;
  readonly dedEarn1?: InputMaybe<SortEnumType>;
  readonly dedEarn2?: InputMaybe<SortEnumType>;
  readonly dedEarn5?: InputMaybe<SortEnumType>;
  readonly dedEarn6?: InputMaybe<SortEnumType>;
  readonly dedEarn7?: InputMaybe<SortEnumType>;
  readonly deductOnce1?: InputMaybe<SortEnumType>;
  readonly deductOnce2?: InputMaybe<SortEnumType>;
  readonly driversLicenseId?: InputMaybe<SortEnumType>;
  readonly email?: InputMaybe<SortEnumType>;
  readonly extraFedWh?: InputMaybe<SortEnumType>;
  readonly extraLocalWh?: InputMaybe<SortEnumType>;
  readonly extraOtherStateWh?: InputMaybe<SortEnumType>;
  readonly extraOtherWh1?: InputMaybe<SortEnumType>;
  readonly extraOtherWh2?: InputMaybe<SortEnumType>;
  readonly extraStateWh?: InputMaybe<SortEnumType>;
  readonly fedDependents?: InputMaybe<SortEnumType>;
  readonly fedWhExempt?: InputMaybe<SortEnumType>;
  readonly federalStatus?: InputMaybe<SortEnumType>;
  readonly federalWageAdjustment?: InputMaybe<SortEnumType>;
  readonly ficaExempt?: InputMaybe<SortEnumType>;
  readonly fringeBenefits?: InputMaybe<SortEnumType>;
  readonly futaExempt?: InputMaybe<SortEnumType>;
  readonly hireDate?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly insurance?: InputMaybe<SortEnumType>;
  readonly lastRaiseDate?: InputMaybe<SortEnumType>;
  readonly lastTimeCardPaidDate?: InputMaybe<SortEnumType>;
  readonly localWhExempt?: InputMaybe<SortEnumType>;
  readonly localWhId?: InputMaybe<SortEnumType>;
  readonly maritalStatus?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly nextTimeCardToPayId?: InputMaybe<SortEnumType>;
  readonly note?: InputMaybe<SortEnumType>;
  readonly otherDependents?: InputMaybe<SortEnumType>;
  readonly otherStateExempt?: InputMaybe<SortEnumType>;
  readonly otherStateId?: InputMaybe<SortEnumType>;
  readonly otherStatus?: InputMaybe<SortEnumType>;
  readonly otherWh1?: InputMaybe<SortEnumType>;
  readonly otherWh2?: InputMaybe<SortEnumType>;
  readonly otherWhExempt1?: InputMaybe<SortEnumType>;
  readonly otherWhExempt2?: InputMaybe<SortEnumType>;
  readonly payDedAmt1?: InputMaybe<SortEnumType>;
  readonly payDedAmt2?: InputMaybe<SortEnumType>;
  readonly payDedAmt3?: InputMaybe<SortEnumType>;
  readonly payDedAmt4?: InputMaybe<SortEnumType>;
  readonly payDedAmt5?: InputMaybe<SortEnumType>;
  readonly payDedAmt6?: InputMaybe<SortEnumType>;
  readonly payDedAmt7?: InputMaybe<SortEnumType>;
  readonly payEarn3?: InputMaybe<SortEnumType>;
  readonly payEarn4?: InputMaybe<SortEnumType>;
  readonly payFrequency?: InputMaybe<SortEnumType>;
  readonly pensionPlan?: InputMaybe<SortEnumType>;
  readonly phone?: InputMaybe<SortEnumType>;
  readonly reviewDate?: InputMaybe<SortEnumType>;
  readonly ss?: InputMaybe<SortEnumType>;
  readonly stateCredit?: InputMaybe<SortEnumType>;
  readonly stateExempt?: InputMaybe<SortEnumType>;
  readonly stateId?: InputMaybe<SortEnumType>;
  readonly streetAddress?: InputMaybe<SortEnumType>;
  readonly suiExempt?: InputMaybe<SortEnumType>;
  readonly terminationDate?: InputMaybe<SortEnumType>;
  readonly timeClock?: InputMaybe<SortEnumType>;
  readonly totalToDeduct1?: InputMaybe<SortEnumType>;
  readonly totalToDeduct2?: InputMaybe<SortEnumType>;
  readonly totalToDeduct3?: InputMaybe<SortEnumType>;
  readonly totalToDeduct4?: InputMaybe<SortEnumType>;
  readonly totalToDeduct5?: InputMaybe<SortEnumType>;
  readonly totalToDeduct6?: InputMaybe<SortEnumType>;
  readonly totalToDeduct7?: InputMaybe<SortEnumType>;
  readonly workmanCompId?: InputMaybe<SortEnumType>;
  readonly zipCode?: InputMaybe<ZipCodeSortInput>;
  readonly zipCodeId?: InputMaybe<SortEnumType>;
  readonly zipExt?: InputMaybe<SortEnumType>;
};

export type EmployeeUpdateInput = {
  readonly attention?: InputMaybe<Scalars['String']['input']>;
  readonly automaticOverTime?: InputMaybe<Scalars['String']['input']>;
  readonly automaticVacation?: InputMaybe<Scalars['String']['input']>;
  readonly birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly breakItemId?: InputMaybe<Scalars['String']['input']>;
  readonly classification?: InputMaybe<Scalars['String']['input']>;
  readonly custom1?: InputMaybe<Scalars['String']['input']>;
  readonly custom2?: InputMaybe<Scalars['String']['input']>;
  readonly dedEarn1?: InputMaybe<Scalars['String']['input']>;
  readonly dedEarn2?: InputMaybe<Scalars['String']['input']>;
  readonly dedEarn5?: InputMaybe<Scalars['String']['input']>;
  readonly dedEarn6?: InputMaybe<Scalars['String']['input']>;
  readonly dedEarn7?: InputMaybe<Scalars['String']['input']>;
  readonly deductOnce1?: InputMaybe<Scalars['String']['input']>;
  readonly deductOnce2?: InputMaybe<Scalars['String']['input']>;
  readonly driversLicenseId?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly extraFedWh?: InputMaybe<Scalars['Decimal']['input']>;
  readonly extraLocalWh?: InputMaybe<Scalars['Decimal']['input']>;
  readonly extraOtherStateWh?: InputMaybe<Scalars['Decimal']['input']>;
  readonly extraOtherWh1?: InputMaybe<Scalars['Decimal']['input']>;
  readonly extraOtherWh2?: InputMaybe<Scalars['Decimal']['input']>;
  readonly extraStateWh?: InputMaybe<Scalars['Decimal']['input']>;
  readonly fedDependents?: InputMaybe<Scalars['Int']['input']>;
  readonly fedWhExempt?: InputMaybe<Scalars['String']['input']>;
  readonly federalStatus?: InputMaybe<Scalars['String']['input']>;
  readonly federalWageAdjustment?: InputMaybe<Scalars['Decimal']['input']>;
  readonly ficaExempt?: InputMaybe<Scalars['String']['input']>;
  readonly fringeBenefits?: InputMaybe<Scalars['Decimal']['input']>;
  readonly futaExempt?: InputMaybe<Scalars['String']['input']>;
  readonly hireDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly id: Scalars['String']['input'];
  readonly insurance?: InputMaybe<Scalars['Decimal']['input']>;
  readonly lastRaiseDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly lastTimeCardPaidDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly localWhExempt?: InputMaybe<Scalars['String']['input']>;
  readonly localWhId?: InputMaybe<Scalars['String']['input']>;
  readonly maritalStatus?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly nextTimeCardToPayId?: InputMaybe<Scalars['ID']['input']>;
  readonly note?: InputMaybe<Scalars['String']['input']>;
  readonly otherDependents?: InputMaybe<Scalars['String']['input']>;
  readonly otherStateExempt?: InputMaybe<Scalars['String']['input']>;
  readonly otherStateId?: InputMaybe<Scalars['String']['input']>;
  readonly otherStatus?: InputMaybe<Scalars['String']['input']>;
  readonly otherWh1?: InputMaybe<Scalars['String']['input']>;
  readonly otherWh2?: InputMaybe<Scalars['String']['input']>;
  readonly otherWhExempt1?: InputMaybe<Scalars['String']['input']>;
  readonly otherWhExempt2?: InputMaybe<Scalars['String']['input']>;
  readonly payDedAmt1?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt2?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt3?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt4?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt5?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt6?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payDedAmt7?: InputMaybe<Scalars['Decimal']['input']>;
  readonly payEarn3?: InputMaybe<Scalars['String']['input']>;
  readonly payEarn4?: InputMaybe<Scalars['String']['input']>;
  readonly payFrequency?: InputMaybe<Scalars['String']['input']>;
  readonly pensionPlan?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly reviewDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly ss?: InputMaybe<Scalars['String']['input']>;
  readonly stateCredit?: InputMaybe<Scalars['Decimal']['input']>;
  readonly stateExempt?: InputMaybe<Scalars['String']['input']>;
  readonly stateId?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly suiExempt?: InputMaybe<Scalars['String']['input']>;
  readonly terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly timeClock?: InputMaybe<Scalars['String']['input']>;
  readonly totalToDeduct1?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct2?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct3?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct4?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct5?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct6?: InputMaybe<Scalars['Decimal']['input']>;
  readonly totalToDeduct7?: InputMaybe<Scalars['Decimal']['input']>;
  readonly workmanCompId?: InputMaybe<Scalars['String']['input']>;
  /** When populated with a new zip code, it will be created. Existing zip codes will not be modified. */
  readonly zipCode?: InputMaybe<ZipCodeInput>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type EmployeesListConnection = {
  readonly __typename: 'EmployeesListConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<EmployeesListEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Employee>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EmployeesListEdge = {
  readonly __typename: 'EmployeesListEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Employee;
};

export type EntityInformation = {
  readonly __typename: 'EntityInformation';
  readonly fields: ReadonlyArray<FieldInformation>;
  readonly hasUserDefined: Scalars['Boolean']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly userDefinedValidators: ReadonlyArray<ValidatorInformation>;
  readonly validators: ReadonlyArray<ValidatorInformation>;
};

export enum EntityState {
  Deleting = 'DELETING',
  Existing = 'EXISTING',
  New = 'NEW'
}

export type EnvelopeFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<EnvelopeFilterInput>>;
  readonly area?: InputMaybe<FloatOperationFilterInput>;
  readonly centre?: InputMaybe<CoordinateFilterInput>;
  readonly diameter?: InputMaybe<FloatOperationFilterInput>;
  readonly height?: InputMaybe<FloatOperationFilterInput>;
  readonly isNull?: InputMaybe<BooleanOperationFilterInput>;
  readonly maxExtent?: InputMaybe<FloatOperationFilterInput>;
  readonly maxX?: InputMaybe<FloatOperationFilterInput>;
  readonly maxY?: InputMaybe<FloatOperationFilterInput>;
  readonly minExtent?: InputMaybe<FloatOperationFilterInput>;
  readonly minX?: InputMaybe<FloatOperationFilterInput>;
  readonly minY?: InputMaybe<FloatOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<EnvelopeFilterInput>>;
  readonly width?: InputMaybe<FloatOperationFilterInput>;
};

export enum ErrorLevel {
  Error = 'ERROR',
  Exception = 'EXCEPTION',
  Success = 'SUCCESS',
  Warning = 'WARNING'
}

export type Feature = {
  readonly __typename: 'Feature';
  readonly allowMultiple: Scalars['Boolean']['output'];
  readonly description: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly integrations: ReadonlyArray<Integration>;
  readonly label: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type FeatureFlag = {
  readonly __typename: 'FeatureFlag';
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
};

export type FieldInformation = {
  readonly __typename: 'FieldInformation';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
};

export type FileDetails = {
  readonly __typename: 'FileDetails';
  readonly exists: Scalars['Boolean']['output'];
  readonly fullName: Scalars['String']['output'];
  readonly lastWriteTime: Scalars['DateTime']['output'];
  readonly name: Scalars['String']['output'];
  readonly version: FileVersionInfo;
};

export type FileVersionInfo = {
  readonly __typename: 'FileVersionInfo';
  readonly comments?: Maybe<Scalars['String']['output']>;
  readonly companyName?: Maybe<Scalars['String']['output']>;
  readonly fileBuildPart: Scalars['Int']['output'];
  readonly fileDescription?: Maybe<Scalars['String']['output']>;
  readonly fileMajorPart: Scalars['Int']['output'];
  readonly fileMinorPart: Scalars['Int']['output'];
  readonly fileName: Scalars['String']['output'];
  readonly filePrivatePart: Scalars['Int']['output'];
  readonly fileVersion?: Maybe<Scalars['String']['output']>;
  readonly internalName?: Maybe<Scalars['String']['output']>;
  readonly isDebug: Scalars['Boolean']['output'];
  readonly isPatched: Scalars['Boolean']['output'];
  readonly isPreRelease: Scalars['Boolean']['output'];
  readonly isPrivateBuild: Scalars['Boolean']['output'];
  readonly isSpecialBuild: Scalars['Boolean']['output'];
  readonly language?: Maybe<Scalars['String']['output']>;
  readonly legalCopyright?: Maybe<Scalars['String']['output']>;
  readonly legalTrademarks?: Maybe<Scalars['String']['output']>;
  readonly originalFilename?: Maybe<Scalars['String']['output']>;
  readonly privateBuild?: Maybe<Scalars['String']['output']>;
  readonly productBuildPart: Scalars['Int']['output'];
  readonly productMajorPart: Scalars['Int']['output'];
  readonly productMinorPart: Scalars['Int']['output'];
  readonly productName?: Maybe<Scalars['String']['output']>;
  readonly productPrivatePart: Scalars['Int']['output'];
  readonly productVersion?: Maybe<Scalars['String']['output']>;
  readonly specialBuild?: Maybe<Scalars['String']['output']>;
};

export type FloatOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Float']['input']>;
  readonly gt?: InputMaybe<Scalars['Float']['input']>;
  readonly gte?: InputMaybe<Scalars['Float']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Float']['input']>>>;
  readonly lt?: InputMaybe<Scalars['Float']['input']>;
  readonly lte?: InputMaybe<Scalars['Float']['input']>;
  readonly neq?: InputMaybe<Scalars['Float']['input']>;
  readonly ngt?: InputMaybe<Scalars['Float']['input']>;
  readonly ngte?: InputMaybe<Scalars['Float']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Float']['input']>>>;
  readonly nlt?: InputMaybe<Scalars['Float']['input']>;
  readonly nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type FormData = {
  readonly __typename: 'FormData';
  readonly name: Scalars['String']['output'];
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type FormDataFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<FormDataFilterInput>>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<FormDataFilterInput>>;
  readonly value?: InputMaybe<StringOperationFilterInput>;
};

export type FormDataGroup = {
  readonly __typename: 'FormDataGroup';
  readonly name: Scalars['String']['output'];
  readonly templateId: Scalars['String']['output'];
  readonly values: ReadonlyArray<FormData>;
};

export type FormDataGroupFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<FormDataGroupFilterInput>>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<FormDataGroupFilterInput>>;
  readonly templateId?: InputMaybe<StringOperationFilterInput>;
  readonly values?: InputMaybe<ListFilterInputTypeOfFormDataFilterInput>;
};

export type FormDataGroupInput = {
  readonly name: Scalars['String']['input'];
  readonly templateId: Scalars['String']['input'];
  readonly values: ReadonlyArray<FormDataInput>;
};

export type FormDataInput = {
  readonly name: Scalars['String']['input'];
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

export type FormTemplate = {
  readonly __typename: 'FormTemplate';
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly schema: ReadonlyArray<FormTemplateFieldDefinition>;
  readonly updated: Scalars['DateTime']['output'];
  readonly validTables: ReadonlyArray<FormTemplateValidTables>;
};

export type FormTemplateFieldDefinition = {
  readonly __typename: 'FormTemplateFieldDefinition';
  readonly availableValues?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly label: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly nullable: Scalars['Boolean']['output'];
  readonly placeholder?: Maybe<Scalars['String']['output']>;
  readonly size: Scalars['Int']['output'];
  readonly type: Scalars['String']['output'];
};

export type FormTemplateFieldDefinitionInput = {
  readonly availableValues?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly label: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly nullable: Scalars['Boolean']['input'];
  readonly placeholder?: InputMaybe<Scalars['String']['input']>;
  readonly size: Scalars['Int']['input'];
  readonly type: Scalars['String']['input'];
};

export type FormTemplateInput = {
  readonly id: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly notes: Scalars['String']['input'];
  readonly schema: ReadonlyArray<FormTemplateFieldDefinitionInput>;
  readonly validTables: ReadonlyArray<FormTemplateValidTables>;
};

export enum FormTemplateValidTables {
  Bills = 'BILLS',
  Invoices = 'INVOICES',
  PurchaseOrders = 'PURCHASE_ORDERS',
  WorkOrders = 'WORK_ORDERS'
}

export type GalaxyConnectionEvent = {
  readonly __typename: 'GalaxyConnectionEvent';
  readonly connected: Scalars['Boolean']['output'];
};

export type GalaxyKeysReceivedEvent = {
  readonly __typename: 'GalaxyKeysReceivedEvent';
  readonly ready: Scalars['Boolean']['output'];
};

export enum GeoJsonGeometryType {
  GeometryCollection = 'GeometryCollection',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon'
}

export type GeoJsonInterface = {
  /** The minimum bounding box around the geometry object */
  readonly bbox?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>;
  /** The coordinate reference system integer identifier */
  readonly crs?: Maybe<Scalars['Int']['output']>;
  /** The geometry type of the GeoJson object */
  readonly type: GeoJsonGeometryType;
};

export type GeoJsonLineStringInput = {
  /** The "coordinates" field is an array of two or more positions. */
  readonly coordinates?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Position']['input']>>>;
  /** The coordinate reference system integer identifier */
  readonly crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  readonly type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonLineStringType = GeoJsonInterface & {
  readonly __typename: 'GeoJSONLineStringType';
  /** The minimum bounding box around the geometry object */
  readonly bbox: ReadonlyArray<Scalars['Float']['output']>;
  /** The "coordinates" field is an array of two or more positions. */
  readonly coordinates?: Maybe<ReadonlyArray<Maybe<Scalars['Position']['output']>>>;
  /** The coordinate reference system integer identifier */
  readonly crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  readonly type: GeoJsonGeometryType;
};

export type GeoJsonMultiLineStringInput = {
  /** The "coordinates" field is an array of LineString coordinate arrays. */
  readonly coordinates?: InputMaybe<ReadonlyArray<InputMaybe<ReadonlyArray<InputMaybe<Scalars['Position']['input']>>>>>;
  /** The coordinate reference system integer identifier */
  readonly crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  readonly type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonMultiLineStringType = GeoJsonInterface & {
  readonly __typename: 'GeoJSONMultiLineStringType';
  /** The minimum bounding box around the geometry object */
  readonly bbox: ReadonlyArray<Scalars['Float']['output']>;
  /** The "coordinates" field is an array of LineString coordinate arrays. */
  readonly coordinates?: Maybe<ReadonlyArray<Maybe<Scalars['Position']['output']>>>;
  /** The coordinate reference system integer identifier */
  readonly crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  readonly type: GeoJsonGeometryType;
};

export type GeoJsonMultiPointInput = {
  /** The "coordinates" field is an array of positions. */
  readonly coordinates?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Position']['input']>>>;
  /** The coordinate reference system integer identifier */
  readonly crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  readonly type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonMultiPointType = GeoJsonInterface & {
  readonly __typename: 'GeoJSONMultiPointType';
  /** The minimum bounding box around the geometry object */
  readonly bbox: ReadonlyArray<Scalars['Float']['output']>;
  /** The "coordinates" field is an array of positions. */
  readonly coordinates?: Maybe<ReadonlyArray<Maybe<Scalars['Position']['output']>>>;
  /** The coordinate reference system integer identifier */
  readonly crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  readonly type: GeoJsonGeometryType;
};

export type GeoJsonMultiPolygonInput = {
  /** The "coordinates" field is an array of Polygon coordinate arrays. */
  readonly coordinates?: InputMaybe<Scalars['Coordinates']['input']>;
  /** The coordinate reference system integer identifier */
  readonly crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  readonly type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonMultiPolygonType = GeoJsonInterface & {
  readonly __typename: 'GeoJSONMultiPolygonType';
  /** The minimum bounding box around the geometry object */
  readonly bbox: ReadonlyArray<Scalars['Float']['output']>;
  /** The "coordinates" field is an array of Polygon coordinate arrays. */
  readonly coordinates?: Maybe<Scalars['Coordinates']['output']>;
  /** The coordinate reference system integer identifier */
  readonly crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  readonly type: GeoJsonGeometryType;
};

export type GeoJsonPointInput = {
  /** The "coordinates" field is a single position. */
  readonly coordinates?: InputMaybe<Scalars['Position']['input']>;
  /** The coordinate reference system integer identifier */
  readonly crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  readonly type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonPointType = GeoJsonInterface & {
  readonly __typename: 'GeoJSONPointType';
  /** The minimum bounding box around the geometry object */
  readonly bbox: ReadonlyArray<Scalars['Float']['output']>;
  /** The "coordinates" field is a single position. */
  readonly coordinates?: Maybe<Scalars['Position']['output']>;
  /** The coordinate reference system integer identifier */
  readonly crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  readonly type: GeoJsonGeometryType;
};

export type GeoJsonPolygonInput = {
  /** The "coordinates" field MUST be an array of linear ring coordinate arrays. For Polygons with more than one of these rings, the first MUST be the exterior ring, and any others MUST be interior rings. The exterior ring bounds the surface, and the interior rings (if present) bound holes within the surface. */
  readonly coordinates?: InputMaybe<ReadonlyArray<InputMaybe<ReadonlyArray<InputMaybe<Scalars['Position']['input']>>>>>;
  /** The coordinate reference system integer identifier */
  readonly crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  readonly type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonPolygonType = GeoJsonInterface & {
  readonly __typename: 'GeoJSONPolygonType';
  /** The minimum bounding box around the geometry object */
  readonly bbox: ReadonlyArray<Scalars['Float']['output']>;
  /** The "coordinates" field MUST be an array of linear ring coordinate arrays. For Polygons with more than one of these rings, the first MUST be the exterior ring, and any others MUST be interior rings. The exterior ring bounds the surface, and the interior rings (if present) bound holes within the surface. */
  readonly coordinates?: Maybe<ReadonlyArray<Maybe<ReadonlyArray<Maybe<Scalars['Position']['output']>>>>>;
  /** The coordinate reference system integer identifier */
  readonly crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  readonly type: GeoJsonGeometryType;
};

export enum GeocodeStatusContactType {
  Customer = 'CUSTOMER',
  Employee = 'EMPLOYEE',
  Name = 'NAME',
  Vendor = 'VENDOR'
}

export type GeocodingStatusUpdate = {
  readonly __typename: 'GeocodingStatusUpdate';
  readonly contactType: GeocodeStatusContactType;
  readonly current: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
};

export type GeometryFactoryFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<GeometryFactoryFilterInput>>;
  readonly coordinateSequenceFactory?: InputMaybe<CoordinateSequenceFactoryFilterInput>;
  readonly geometryServices?: InputMaybe<NtsGeometryServicesFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<GeometryFactoryFilterInput>>;
  readonly precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  readonly srid?: InputMaybe<IntOperationFilterInput>;
};

export type GeometryFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<GeometryFilterInput>>;
  readonly area?: InputMaybe<FloatOperationFilterInput>;
  readonly boundary?: InputMaybe<GeometryFilterInput>;
  readonly boundaryDimension?: InputMaybe<DimensionOperationFilterInput>;
  readonly centroid?: InputMaybe<PointFilterInput>;
  readonly coordinate?: InputMaybe<CoordinateFilterInput>;
  readonly coordinates?: InputMaybe<ListFilterInputTypeOfCoordinateFilterInput>;
  readonly dimension?: InputMaybe<DimensionOperationFilterInput>;
  readonly envelope?: InputMaybe<GeometryFilterInput>;
  readonly envelopeInternal?: InputMaybe<EnvelopeFilterInput>;
  readonly factory?: InputMaybe<GeometryFactoryFilterInput>;
  readonly geometryType?: InputMaybe<StringOperationFilterInput>;
  readonly interiorPoint?: InputMaybe<PointFilterInput>;
  readonly isEmpty?: InputMaybe<BooleanOperationFilterInput>;
  readonly isRectangle?: InputMaybe<BooleanOperationFilterInput>;
  readonly isSimple?: InputMaybe<BooleanOperationFilterInput>;
  readonly isValid?: InputMaybe<BooleanOperationFilterInput>;
  readonly length?: InputMaybe<FloatOperationFilterInput>;
  readonly numGeometries?: InputMaybe<IntOperationFilterInput>;
  readonly numPoints?: InputMaybe<IntOperationFilterInput>;
  readonly ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<GeometryFilterInput>>;
  readonly pointOnSurface?: InputMaybe<PointFilterInput>;
  readonly precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  readonly srid?: InputMaybe<IntOperationFilterInput>;
};

export type GeometryOverlayFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<GeometryOverlayFilterInput>>;
  readonly or?: InputMaybe<ReadonlyArray<GeometryOverlayFilterInput>>;
};

export type GlAccount = {
  readonly __typename: 'GlAccount';
  readonly accountType?: Maybe<Scalars['String']['output']>;
  readonly bankAccessToken?: Maybe<Scalars['String']['output']>;
  readonly bankAccountId?: Maybe<Scalars['String']['output']>;
  readonly financialStatementType: Scalars['String']['output'];
  readonly hasChildren: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly indentLevel: Scalars['Int']['output'];
  readonly ledgerNumber?: Maybe<Scalars['Int']['output']>;
  readonly name: Scalars['String']['output'];
  /** Root level items will have a parentId of 0 */
  readonly parentId: Scalars['ID']['output'];
  readonly statement1Type: Scalars['String']['output'];
};

export type GlAccountEntity = {
  readonly __typename: 'GlAccountEntity';
  readonly accountClassId?: Maybe<Scalars['String']['output']>;
  readonly accountType?: Maybe<Scalars['String']['output']>;
  readonly alternateGl?: Maybe<Scalars['String']['output']>;
  readonly bankAccessToken?: Maybe<Scalars['String']['output']>;
  readonly bankAccountId?: Maybe<Scalars['String']['output']>;
  readonly cashFlowType?: Maybe<Scalars['String']['output']>;
  readonly department?: Maybe<Scalars['String']['output']>;
  readonly enterRemark?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly jobCostType?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly note?: Maybe<Scalars['String']['output']>;
  readonly scheduleCTaxLine?: Maybe<Scalars['String']['output']>;
  readonly scheduleFTaxLine?: Maybe<Scalars['String']['output']>;
  readonly statement1Type?: Maybe<Scalars['String']['output']>;
  readonly statement2Type?: Maybe<Scalars['String']['output']>;
  readonly statement3Type?: Maybe<Scalars['String']['output']>;
  readonly taxLine1065?: Maybe<Scalars['String']['output']>;
  readonly taxLine1120?: Maybe<Scalars['String']['output']>;
  readonly type1099?: Maybe<Scalars['String']['output']>;
  readonly useTaxItemId?: Maybe<Scalars['String']['output']>;
};

export type GlAccountViewFilterInput = {
  readonly accountType?: InputMaybe<StringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<GlAccountViewFilterInput>>;
  readonly bankAccessToken?: InputMaybe<StringOperationFilterInput>;
  readonly bankAccountId?: InputMaybe<StringOperationFilterInput>;
  readonly financialStatementType?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly indentLevel?: InputMaybe<IntOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<GlAccountViewFilterInput>>;
  readonly statement1Type?: InputMaybe<StringOperationFilterInput>;
  readonly subOfLevel1?: InputMaybe<IdOperationFilterInput>;
};

export type GlAccountViewSortInput = {
  readonly accountType?: InputMaybe<SortEnumType>;
  readonly bankAccessToken?: InputMaybe<SortEnumType>;
  readonly bankAccountId?: InputMaybe<SortEnumType>;
  readonly financialStatementType?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly indentLevel?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly statement1Type?: InputMaybe<SortEnumType>;
  readonly subOfLevel1?: InputMaybe<SortEnumType>;
};

export type HereMapsUsage = {
  readonly __typename: 'HereMapsUsage';
  readonly items: ReadonlyArray<HereMapsUsageItem>;
  readonly lastOffset: Scalars['Int']['output'];
  readonly limit: Scalars['Int']['output'];
  readonly nextOffset: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
};

export type HereMapsUsageItem = {
  readonly __typename: 'HereMapsUsageItem';
  readonly billableValue: Scalars['Float']['output'];
  readonly category: Scalars['String']['output'];
  readonly featureId: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly realmId: Scalars['String']['output'];
  readonly usageValue: Scalars['Float']['output'];
  readonly valueDriver: Scalars['String']['output'];
};

export type HistoricLoads = {
  readonly __typename: 'HistoricLoads';
  readonly day: Scalars['Int']['output'];
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly month: Scalars['Int']['output'];
  readonly year: Scalars['Int']['output'];
};

export type HistoricLoadsFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<HistoricLoadsFilterInput>>;
  readonly day?: InputMaybe<IntOperationFilterInput>;
  readonly label?: InputMaybe<StringOperationFilterInput>;
  readonly month?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<HistoricLoadsFilterInput>>;
  readonly year?: InputMaybe<IntOperationFilterInput>;
};

export type HistoricLoadsSortInput = {
  readonly day?: InputMaybe<SortEnumType>;
  readonly label?: InputMaybe<SortEnumType>;
  readonly month?: InputMaybe<SortEnumType>;
  readonly year?: InputMaybe<SortEnumType>;
};

export type ISalesTransactionFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ISalesTransactionFilterInput>>;
  readonly customer?: InputMaybe<CustomerFilterInput>;
  readonly customerId?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ISalesTransactionFilterInput>>;
  readonly salesperson?: InputMaybe<SalespersonFilterInput>;
  readonly salespersonId?: InputMaybe<StringOperationFilterInput>;
  readonly shipToName?: InputMaybe<NameFilterInput>;
  readonly shipToNameId?: InputMaybe<StringOperationFilterInput>;
};

export type IdOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['ID']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  readonly neq?: InputMaybe<Scalars['ID']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};

export type IdentityRole = {
  readonly __typename: 'IdentityRole';
  readonly concurrencyStamp?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly normalizedName?: Maybe<Scalars['String']['output']>;
};

export type IdentityRoleFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<IdentityRoleFilterInput>>;
  readonly concurrencyStamp?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly normalizedName?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<IdentityRoleFilterInput>>;
};

export type IntOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Int']['input']>;
  readonly gt?: InputMaybe<Scalars['Int']['input']>;
  readonly gte?: InputMaybe<Scalars['Int']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']['input']>>>;
  readonly lt?: InputMaybe<Scalars['Int']['input']>;
  readonly lte?: InputMaybe<Scalars['Int']['input']>;
  readonly neq?: InputMaybe<Scalars['Int']['input']>;
  readonly ngt?: InputMaybe<Scalars['Int']['input']>;
  readonly ngte?: InputMaybe<Scalars['Int']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']['input']>>>;
  readonly nlt?: InputMaybe<Scalars['Int']['input']>;
  readonly nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type Integration = {
  readonly __typename: 'Integration';
  readonly description: Scalars['String']['output'];
  readonly fields: ReadonlyArray<IntegrationConfigField>;
  readonly icon?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly isConfigured: Scalars['Boolean']['output'];
  /** This integration will be enabled by default if no others are. */
  readonly isDefault: Scalars['Boolean']['output'];
  readonly isEnabled: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
};

export type IntegrationConfigField = {
  readonly __typename: 'IntegrationConfigField';
  readonly arrayFields?: Maybe<ReadonlyArray<IntegrationConfigField>>;
  readonly availableValues: ReadonlyArray<Scalars['String']['output']>;
  readonly field: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type IntegrationDetails = {
  readonly __typename: 'IntegrationDetails';
  readonly defaultEnvironment?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly featureId?: Maybe<Scalars['String']['output']>;
  readonly icon?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly isEnabled: Scalars['Boolean']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type IntegrationEnvironment = {
  readonly __typename: 'IntegrationEnvironment';
  readonly id: Scalars['String']['output'];
  readonly integrationId: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type Invoice = SalesTransaction & {
  readonly __typename: 'Invoice';
  readonly allowLateCharge?: Maybe<Scalars['String']['output']>;
  readonly backOrderAmountHeld: Scalars['Decimal']['output'];
  readonly billCode?: Maybe<Scalars['String']['output']>;
  readonly customer: Customer;
  readonly customerId?: Maybe<Scalars['String']['output']>;
  readonly customerPoNumber?: Maybe<Scalars['String']['output']>;
  readonly customerType?: Maybe<CustomerType>;
  readonly customerTypeId?: Maybe<Scalars['String']['output']>;
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly dateBackOrdered?: Maybe<Scalars['DateTime']['output']>;
  readonly dates: ReadonlyArray<InvoiceDate>;
  readonly deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  readonly details: ReadonlyArray<InvoiceDetail>;
  readonly discountAmount: Scalars['Decimal']['output'];
  readonly discountDate?: Maybe<Scalars['DateTime']['output']>;
  readonly dueDate?: Maybe<Scalars['DateTime']['output']>;
  readonly forms?: Maybe<ReadonlyArray<FormDataGroup>>;
  readonly freeFreight?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly invoiceType?: Maybe<Scalars['String']['output']>;
  readonly job?: Maybe<Job>;
  readonly jobId?: Maybe<Scalars['String']['output']>;
  readonly operator?: Maybe<Scalars['String']['output']>;
  readonly orderDate?: Maybe<Scalars['DateTime']['output']>;
  readonly origOrderId: Scalars['ID']['output'];
  readonly remark?: Maybe<Scalars['String']['output']>;
  readonly salesperson?: Maybe<Salesperson>;
  readonly salespersonId?: Maybe<Scalars['String']['output']>;
  readonly shipToName?: Maybe<Name>;
  readonly shipToNameId?: Maybe<Scalars['String']['output']>;
  readonly shipVia?: Maybe<Scalars['String']['output']>;
  readonly shipments: ReadonlyArray<InvoiceShipment>;
  readonly slipNumber?: Maybe<Scalars['String']['output']>;
  readonly spare0?: Maybe<Scalars['String']['output']>;
  readonly spare1?: Maybe<Scalars['String']['output']>;
  readonly taxStatus?: Maybe<Scalars['String']['output']>;
  readonly terms?: Maybe<Scalars['String']['output']>;
  readonly totalCost: Scalars['Decimal']['output'];
  readonly totalInvoice: Scalars['Decimal']['output'];
};

export type InvoiceDate = BaseDate & {
  readonly __typename: 'InvoiceDate';
  readonly added: Scalars['DateTime']['output'];
  readonly endDate: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly invoiceDetail?: Maybe<Item>;
  readonly invoiceDetailId?: Maybe<Scalars['String']['output']>;
  readonly startDate: Scalars['DateTime']['output'];
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly weight?: Maybe<Scalars['Decimal']['output']>;
};

export type InvoiceDateFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<InvoiceDateFilterInput>>;
  readonly endDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly invoiceDetail?: InputMaybe<ItemFilterInput>;
  readonly invoiceDetailId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<InvoiceDateFilterInput>>;
  readonly startDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly type?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
  readonly weight?: InputMaybe<DecimalOperationFilterInput>;
};

export type InvoiceDetail = {
  readonly __typename: 'InvoiceDetail';
  readonly amount: Scalars['Decimal']['output'];
  readonly cost: Scalars['Decimal']['output'];
  readonly glAccountId: Scalars['ID']['output'];
  readonly id: Scalars['ID']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly jobClassId?: Maybe<Scalars['String']['output']>;
  readonly lineNum: Scalars['Int']['output'];
  readonly listPrice: Scalars['Decimal']['output'];
  readonly lotNumbers: ReadonlyArray<Scalars['String']['output']>;
  readonly orderedQuantity: Scalars['Decimal']['output'];
  readonly quotePricing?: Maybe<Scalars['String']['output']>;
  readonly sellPrice: Scalars['Decimal']['output'];
  readonly sellQuantity: Scalars['Decimal']['output'];
  readonly serialNumbers: ReadonlyArray<Scalars['String']['output']>;
  readonly taxable: Scalars['Boolean']['output'];
  readonly unit?: Maybe<Scalars['String']['output']>;
  readonly weight: Scalars['Decimal']['output'];
};

export type InvoiceDetailFilterInput = {
  readonly amount?: InputMaybe<DecimalOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<InvoiceDetailFilterInput>>;
  readonly cost?: InputMaybe<DecimalOperationFilterInput>;
  readonly glAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly invoice?: InputMaybe<InvoiceFilterInput>;
  readonly item?: InputMaybe<ItemFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly jobClass?: InputMaybe<JobClassFilterInput>;
  readonly lineNum?: InputMaybe<IntOperationFilterInput>;
  readonly listPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<InvoiceDetailFilterInput>>;
  readonly orderedQuantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly quotePricing?: InputMaybe<StringOperationFilterInput>;
  readonly sellPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly sellQuantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly taxableAndWeight?: InputMaybe<StringOperationFilterInput>;
  readonly unit?: InputMaybe<StringOperationFilterInput>;
};

export type InvoiceFilterInput = {
  readonly allowLateCharge?: InputMaybe<StringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<InvoiceFilterInput>>;
  readonly backOrderAmountHeld?: InputMaybe<DecimalOperationFilterInput>;
  readonly billCode?: InputMaybe<StringOperationFilterInput>;
  readonly customer?: InputMaybe<CustomerFilterInput>;
  readonly customerId?: InputMaybe<StringOperationFilterInput>;
  readonly customerPoNumber?: InputMaybe<StringOperationFilterInput>;
  readonly customerType?: InputMaybe<CustomerTypeFilterInput>;
  readonly customerTypeId?: InputMaybe<StringOperationFilterInput>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly dateBackOrdered?: InputMaybe<DateTimeOperationFilterInput>;
  readonly dates?: InputMaybe<ListFilterInputTypeOfInvoiceDateFilterInput>;
  readonly deliveryDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly details?: InputMaybe<ListFilterInputTypeOfInvoiceDetailFilterInput>;
  readonly discountAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly discountDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly dueDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly forms?: InputMaybe<ListFilterInputTypeOfFormDataGroupFilterInput>;
  readonly freeFreight?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly invoiceType?: InputMaybe<StringOperationFilterInput>;
  readonly job?: InputMaybe<JobFilterInput>;
  readonly jobId?: InputMaybe<StringOperationFilterInput>;
  readonly operator?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<InvoiceFilterInput>>;
  readonly orderDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly origOrderId?: InputMaybe<IdOperationFilterInput>;
  readonly remark?: InputMaybe<StringOperationFilterInput>;
  readonly salesperson?: InputMaybe<SalespersonFilterInput>;
  readonly salespersonId?: InputMaybe<StringOperationFilterInput>;
  readonly shipToName?: InputMaybe<NameFilterInput>;
  readonly shipToNameId?: InputMaybe<StringOperationFilterInput>;
  readonly shipVia?: InputMaybe<StringOperationFilterInput>;
  readonly shipments?: InputMaybe<ListFilterInputTypeOfInvoiceShipmentFilterInput>;
  readonly slipNumber?: InputMaybe<StringOperationFilterInput>;
  readonly spare0?: InputMaybe<StringOperationFilterInput>;
  readonly spare1?: InputMaybe<StringOperationFilterInput>;
  readonly taxStatus?: InputMaybe<StringOperationFilterInput>;
  readonly terms?: InputMaybe<StringOperationFilterInput>;
  readonly totalCost?: InputMaybe<DecimalOperationFilterInput>;
  readonly totalInvoice?: InputMaybe<DecimalOperationFilterInput>;
};

/** A connection to a list of items. */
export type InvoiceSearchConnection = {
  readonly __typename: 'InvoiceSearchConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<InvoiceSearchEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Invoice>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceSearchEdge = {
  readonly __typename: 'InvoiceSearchEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Invoice;
};

export type InvoiceShipment = SalesTransactionShipment & {
  readonly __typename: 'InvoiceShipment';
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly salesTransaction: Invoice;
  readonly shipment?: Maybe<Shipment>;
  readonly shipmentId?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type InvoiceShipmentFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<InvoiceShipmentFilterInput>>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly item?: InputMaybe<ItemFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<InvoiceShipmentFilterInput>>;
  readonly salesTransaction?: InputMaybe<ISalesTransactionFilterInput>;
  readonly shipment?: InputMaybe<ShipmentFilterInput>;
  readonly shipmentId?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type InvoiceSortInput = {
  readonly allowLateCharge?: InputMaybe<SortEnumType>;
  readonly backOrderAmountHeld?: InputMaybe<SortEnumType>;
  readonly billCode?: InputMaybe<SortEnumType>;
  readonly customer?: InputMaybe<CustomerSortInput>;
  readonly customerId?: InputMaybe<SortEnumType>;
  readonly customerPoNumber?: InputMaybe<SortEnumType>;
  readonly customerType?: InputMaybe<CustomerTypeSortInput>;
  readonly customerTypeId?: InputMaybe<SortEnumType>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly dateBackOrdered?: InputMaybe<SortEnumType>;
  readonly deliveryDate?: InputMaybe<SortEnumType>;
  readonly discountAmount?: InputMaybe<SortEnumType>;
  readonly discountDate?: InputMaybe<SortEnumType>;
  readonly dueDate?: InputMaybe<SortEnumType>;
  readonly freeFreight?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly invoiceType?: InputMaybe<SortEnumType>;
  readonly job?: InputMaybe<JobSortInput>;
  readonly jobId?: InputMaybe<SortEnumType>;
  readonly operator?: InputMaybe<SortEnumType>;
  readonly orderDate?: InputMaybe<SortEnumType>;
  readonly origOrderId?: InputMaybe<SortEnumType>;
  readonly remark?: InputMaybe<SortEnumType>;
  readonly salesperson?: InputMaybe<SalespersonSortInput>;
  readonly salespersonId?: InputMaybe<SortEnumType>;
  readonly shipToName?: InputMaybe<NameSortInput>;
  readonly shipToNameId?: InputMaybe<SortEnumType>;
  readonly shipVia?: InputMaybe<SortEnumType>;
  readonly slipNumber?: InputMaybe<SortEnumType>;
  readonly spare0?: InputMaybe<SortEnumType>;
  readonly spare1?: InputMaybe<SortEnumType>;
  readonly taxStatus?: InputMaybe<SortEnumType>;
  readonly terms?: InputMaybe<SortEnumType>;
  readonly totalCost?: InputMaybe<SortEnumType>;
  readonly totalInvoice?: InputMaybe<SortEnumType>;
};

export type InvoiceTotalsByPeriod = {
  readonly __typename: 'InvoiceTotalsByPeriod';
  readonly periodStart: Scalars['DateTime']['output'];
  readonly qtyTransactions: Scalars['Int']['output'];
  readonly totalSales: Scalars['Decimal']['output'];
};

export enum InvoiceTotalsPeriod {
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type InvoiceUpdateInput = {
  readonly forms?: InputMaybe<ReadonlyArray<FormDataGroupInput>>;
  readonly id: Scalars['ID']['input'];
};

export type InvoiceUpdatedEvent = {
  readonly __typename: 'InvoiceUpdatedEvent';
  readonly companyId: Scalars['String']['output'];
  readonly eventId: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type InvoicesConnection = {
  readonly __typename: 'InvoicesConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<InvoicesEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Invoice>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoicesEdge = {
  readonly __typename: 'InvoicesEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Invoice;
};

export type Item = {
  readonly __typename: 'Item';
  readonly activityLocation?: Maybe<Scalars['String']['output']>;
  readonly additionalDescription?: Maybe<Scalars['String']['output']>;
  readonly alternateList: Scalars['Decimal']['output'];
  readonly alternateUnit?: Maybe<Scalars['String']['output']>;
  readonly autoOrderItemId?: Maybe<Scalars['String']['output']>;
  readonly bookChanged?: Maybe<Scalars['DateTime']['output']>;
  readonly bookCost: Scalars['Decimal']['output'];
  readonly buyCaseQuantity: Scalars['Decimal']['output'];
  readonly buyDescription?: Maybe<Scalars['String']['output']>;
  readonly comment?: Maybe<Scalars['String']['output']>;
  readonly commissionCode?: Maybe<Scalars['String']['output']>;
  readonly coreChargeRevision?: Maybe<Scalars['String']['output']>;
  readonly countQuantity?: Maybe<Scalars['String']['output']>;
  readonly custom1?: Maybe<Scalars['String']['output']>;
  readonly custom2?: Maybe<Scalars['String']['output']>;
  readonly custom3?: Maybe<Scalars['String']['output']>;
  readonly custom4?: Maybe<Scalars['String']['output']>;
  readonly custom5?: Maybe<Scalars['String']['output']>;
  readonly custom6?: Maybe<Scalars['String']['output']>;
  readonly custom7?: Maybe<Scalars['String']['output']>;
  readonly custom8?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly discountLevelA?: Maybe<Scalars['String']['output']>;
  readonly discountLevelB?: Maybe<Scalars['String']['output']>;
  readonly discountLevelC?: Maybe<Scalars['String']['output']>;
  readonly discountLevelD?: Maybe<Scalars['String']['output']>;
  readonly externalImageURL?: Maybe<Scalars['String']['output']>;
  readonly forms?: Maybe<ReadonlyArray<FormDataGroup>>;
  readonly freightAdjustment: Scalars['Decimal']['output'];
  readonly groupId?: Maybe<Scalars['String']['output']>;
  readonly height?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly inventoryAdjustItemId?: Maybe<Scalars['String']['output']>;
  readonly inventoryAdjustRatio: Scalars['Decimal']['output'];
  readonly jobClassId?: Maybe<Scalars['String']['output']>;
  readonly leadTime: Scalars['Int']['output'];
  readonly length?: Maybe<Scalars['String']['output']>;
  readonly listChanged?: Maybe<Scalars['DateTime']['output']>;
  readonly listPrice: Scalars['Decimal']['output'];
  readonly location?: Maybe<Scalars['String']['output']>;
  readonly manufacturerAndCode1?: Maybe<Scalars['String']['output']>;
  readonly manufacturerAndCode2?: Maybe<Scalars['String']['output']>;
  readonly manufacturerAndCode3?: Maybe<Scalars['String']['output']>;
  readonly maximum?: Maybe<Scalars['String']['output']>;
  readonly minimum?: Maybe<Scalars['String']['output']>;
  readonly msrp: Scalars['Decimal']['output'];
  readonly multiplier?: Maybe<Scalars['String']['output']>;
  readonly newItemId?: Maybe<Scalars['String']['output']>;
  readonly newPrice: Scalars['Decimal']['output'];
  readonly options?: Maybe<Scalars['String']['output']>;
  readonly orderMultiple?: Maybe<Scalars['String']['output']>;
  readonly payrollCategoryId?: Maybe<Scalars['String']['output']>;
  readonly poDescription?: Maybe<Scalars['String']['output']>;
  readonly preAdjustmentQuantity: Scalars['Decimal']['output'];
  readonly priceChangeDate?: Maybe<Scalars['DateTime']['output']>;
  readonly priceUpdateId?: Maybe<Scalars['String']['output']>;
  readonly pricingFormulas?: Maybe<Scalars['String']['output']>;
  readonly printCode?: Maybe<Scalars['String']['output']>;
  readonly quantityPerLabel: Scalars['Decimal']['output'];
  readonly remark?: Maybe<Scalars['String']['output']>;
  readonly restrictionId?: Maybe<Scalars['String']['output']>;
  readonly saleGlAccountId: Scalars['ID']['output'];
  readonly serializationMode?: Maybe<ItemSerializationModes>;
  readonly shippingClass?: Maybe<Scalars['String']['output']>;
  readonly spare1?: Maybe<Scalars['String']['output']>;
  readonly spare2?: Maybe<Scalars['String']['output']>;
  readonly spare3?: Maybe<Scalars['String']['output']>;
  readonly spare4?: Maybe<Scalars['String']['output']>;
  readonly spare9?: Maybe<Scalars['String']['output']>;
  readonly spare10?: Maybe<Scalars['String']['output']>;
  readonly spare13?: Maybe<Scalars['String']['output']>;
  readonly spare14?: Maybe<Scalars['String']['output']>;
  readonly spare15?: Maybe<Scalars['String']['output']>;
  readonly spare16?: Maybe<Scalars['String']['output']>;
  readonly spare17?: Maybe<Scalars['String']['output']>;
  readonly spare18?: Maybe<Scalars['String']['output']>;
  readonly spare19?: Maybe<Scalars['String']['output']>;
  readonly spare20?: Maybe<Scalars['String']['output']>;
  readonly spare21?: Maybe<Scalars['String']['output']>;
  readonly spare22?: Maybe<Scalars['String']['output']>;
  readonly spare23?: Maybe<Scalars['String']['output']>;
  readonly spare24?: Maybe<Scalars['String']['output']>;
  readonly spare25?: Maybe<Scalars['String']['output']>;
  readonly spare26?: Maybe<Scalars['String']['output']>;
  readonly spare27?: Maybe<Scalars['String']['output']>;
  readonly spare28?: Maybe<Scalars['String']['output']>;
  readonly spare29?: Maybe<Scalars['String']['output']>;
  readonly spare30?: Maybe<Scalars['String']['output']>;
  readonly spare31?: Maybe<Scalars['String']['output']>;
  readonly spare32?: Maybe<Scalars['String']['output']>;
  readonly spare33?: Maybe<Scalars['String']['output']>;
  readonly specialDiscount?: Maybe<Scalars['String']['output']>;
  readonly stockCode?: Maybe<Scalars['String']['output']>;
  readonly taxable: TaxableStatus;
  readonly unit?: Maybe<Scalars['String']['output']>;
  readonly upc?: Maybe<Scalars['String']['output']>;
  readonly vendorId?: Maybe<Scalars['String']['output']>;
  readonly webStoreCategories?: Maybe<Scalars['String']['output']>;
  readonly webStoreItems: ReadonlyArray<WebStoreItem>;
  readonly weight?: Maybe<Scalars['String']['output']>;
  readonly whereUsed?: Maybe<Scalars['String']['output']>;
  readonly width?: Maybe<Scalars['String']['output']>;
};

export type ItemAddInput = {
  readonly additionalDescription?: InputMaybe<Scalars['String']['input']>;
  readonly alternateList?: InputMaybe<Scalars['Decimal']['input']>;
  readonly alternateUnit?: InputMaybe<Scalars['String']['input']>;
  readonly bookChanged?: InputMaybe<Scalars['DateTime']['input']>;
  readonly buyDescription?: InputMaybe<Scalars['String']['input']>;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly countQuantity?: InputMaybe<Scalars['String']['input']>;
  readonly custom1?: InputMaybe<Scalars['String']['input']>;
  readonly custom2?: InputMaybe<Scalars['String']['input']>;
  readonly custom3?: InputMaybe<Scalars['String']['input']>;
  readonly custom4?: InputMaybe<Scalars['String']['input']>;
  readonly custom5?: InputMaybe<Scalars['String']['input']>;
  readonly custom6?: InputMaybe<Scalars['String']['input']>;
  readonly custom7?: InputMaybe<Scalars['String']['input']>;
  readonly custom8?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly externalImageURL?: InputMaybe<Scalars['String']['input']>;
  readonly height?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly inventoryAdjustItemId?: InputMaybe<Scalars['String']['input']>;
  readonly inventoryAdjustRatio?: InputMaybe<Scalars['Decimal']['input']>;
  readonly length?: InputMaybe<Scalars['String']['input']>;
  readonly listChanged?: InputMaybe<Scalars['DateTime']['input']>;
  readonly newItemId?: InputMaybe<Scalars['String']['input']>;
  readonly newPrice?: InputMaybe<Scalars['Decimal']['input']>;
  readonly options?: InputMaybe<Scalars['String']['input']>;
  readonly orderMultiple?: InputMaybe<Scalars['String']['input']>;
  readonly poDescription?: InputMaybe<Scalars['String']['input']>;
  readonly preAdjustmentQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  readonly priceChangeDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly priceUpdateId?: InputMaybe<Scalars['String']['input']>;
  readonly pricingFormulas?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly remark?: InputMaybe<Scalars['String']['input']>;
  readonly serializationMode?: InputMaybe<ItemSerializationModes>;
  readonly shippingClass?: InputMaybe<Scalars['String']['input']>;
  readonly spare1?: InputMaybe<Scalars['String']['input']>;
  readonly spare2?: InputMaybe<Scalars['String']['input']>;
  readonly spare3?: InputMaybe<Scalars['String']['input']>;
  readonly spare4?: InputMaybe<Scalars['String']['input']>;
  readonly spare9?: InputMaybe<Scalars['String']['input']>;
  readonly spare10?: InputMaybe<Scalars['String']['input']>;
  readonly spare13?: InputMaybe<Scalars['String']['input']>;
  readonly spare14?: InputMaybe<Scalars['String']['input']>;
  readonly spare15?: InputMaybe<Scalars['String']['input']>;
  readonly spare16?: InputMaybe<Scalars['String']['input']>;
  readonly spare17?: InputMaybe<Scalars['String']['input']>;
  readonly spare18?: InputMaybe<Scalars['String']['input']>;
  readonly spare19?: InputMaybe<Scalars['String']['input']>;
  readonly spare20?: InputMaybe<Scalars['String']['input']>;
  readonly spare21?: InputMaybe<Scalars['String']['input']>;
  readonly spare22?: InputMaybe<Scalars['String']['input']>;
  readonly spare23?: InputMaybe<Scalars['String']['input']>;
  readonly spare24?: InputMaybe<Scalars['String']['input']>;
  readonly spare25?: InputMaybe<Scalars['String']['input']>;
  readonly spare26?: InputMaybe<Scalars['String']['input']>;
  readonly spare27?: InputMaybe<Scalars['String']['input']>;
  readonly spare28?: InputMaybe<Scalars['String']['input']>;
  readonly spare29?: InputMaybe<Scalars['String']['input']>;
  readonly spare30?: InputMaybe<Scalars['String']['input']>;
  readonly spare31?: InputMaybe<Scalars['String']['input']>;
  readonly spare32?: InputMaybe<Scalars['String']['input']>;
  readonly spare33?: InputMaybe<Scalars['String']['input']>;
  readonly taxable?: InputMaybe<TaxableStatus>;
  readonly unit?: InputMaybe<Scalars['String']['input']>;
  readonly vendorId?: InputMaybe<Scalars['String']['input']>;
  readonly webStoreCategories?: InputMaybe<Scalars['String']['input']>;
  readonly whereUsed?: InputMaybe<Scalars['String']['input']>;
  readonly width?: InputMaybe<Scalars['String']['input']>;
};

export type ItemFilterInput = {
  readonly activityLocation?: InputMaybe<StringOperationFilterInput>;
  readonly additionalDescription?: InputMaybe<StringOperationFilterInput>;
  readonly alternateList?: InputMaybe<DecimalOperationFilterInput>;
  readonly alternateUnit?: InputMaybe<StringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<ItemFilterInput>>;
  readonly autoOrderItemId?: InputMaybe<StringOperationFilterInput>;
  readonly bookChanged?: InputMaybe<DateTimeOperationFilterInput>;
  readonly bookCost?: InputMaybe<DecimalOperationFilterInput>;
  readonly buyCaseQuantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly buyDescription?: InputMaybe<StringOperationFilterInput>;
  readonly comment?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCode?: InputMaybe<StringOperationFilterInput>;
  readonly coreChargeRevision?: InputMaybe<StringOperationFilterInput>;
  readonly countQuantity?: InputMaybe<StringOperationFilterInput>;
  readonly custom1?: InputMaybe<StringOperationFilterInput>;
  readonly custom2?: InputMaybe<StringOperationFilterInput>;
  readonly custom3?: InputMaybe<StringOperationFilterInput>;
  readonly custom4?: InputMaybe<StringOperationFilterInput>;
  readonly custom5?: InputMaybe<StringOperationFilterInput>;
  readonly custom6?: InputMaybe<StringOperationFilterInput>;
  readonly custom7?: InputMaybe<StringOperationFilterInput>;
  readonly custom8?: InputMaybe<StringOperationFilterInput>;
  readonly description?: InputMaybe<StringOperationFilterInput>;
  readonly discountLevelA?: InputMaybe<StringOperationFilterInput>;
  readonly discountLevelB?: InputMaybe<StringOperationFilterInput>;
  readonly discountLevelC?: InputMaybe<StringOperationFilterInput>;
  readonly discountLevelD?: InputMaybe<StringOperationFilterInput>;
  readonly externalImageURL?: InputMaybe<StringOperationFilterInput>;
  readonly forms?: InputMaybe<ListFilterInputTypeOfFormDataGroupFilterInput>;
  readonly freightAdjustment?: InputMaybe<DecimalOperationFilterInput>;
  readonly groupId?: InputMaybe<StringOperationFilterInput>;
  readonly height?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly inventoryAdjustItemId?: InputMaybe<StringOperationFilterInput>;
  readonly inventoryAdjustRatio?: InputMaybe<DecimalOperationFilterInput>;
  readonly jobClassId?: InputMaybe<StringOperationFilterInput>;
  readonly leadTime?: InputMaybe<IntOperationFilterInput>;
  readonly length?: InputMaybe<StringOperationFilterInput>;
  readonly listChanged?: InputMaybe<DateTimeOperationFilterInput>;
  readonly listPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly location?: InputMaybe<StringOperationFilterInput>;
  readonly manufacturerAndCode1?: InputMaybe<StringOperationFilterInput>;
  readonly manufacturerAndCode2?: InputMaybe<StringOperationFilterInput>;
  readonly manufacturerAndCode3?: InputMaybe<StringOperationFilterInput>;
  readonly maximum?: InputMaybe<StringOperationFilterInput>;
  readonly minimum?: InputMaybe<StringOperationFilterInput>;
  readonly msrp?: InputMaybe<DecimalOperationFilterInput>;
  readonly multiplier?: InputMaybe<StringOperationFilterInput>;
  readonly newItemId?: InputMaybe<StringOperationFilterInput>;
  readonly newPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly options?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ItemFilterInput>>;
  readonly orderMultiple?: InputMaybe<StringOperationFilterInput>;
  readonly payrollCategoryId?: InputMaybe<StringOperationFilterInput>;
  readonly poDescription?: InputMaybe<StringOperationFilterInput>;
  readonly preAdjustmentQuantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly priceChangeDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly priceUpdateId?: InputMaybe<StringOperationFilterInput>;
  readonly pricingFormulas?: InputMaybe<StringOperationFilterInput>;
  readonly printCode?: InputMaybe<StringOperationFilterInput>;
  readonly quantityPerLabel?: InputMaybe<DecimalOperationFilterInput>;
  readonly remark?: InputMaybe<StringOperationFilterInput>;
  readonly restrictionId?: InputMaybe<StringOperationFilterInput>;
  readonly saleGlAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly serializationMode?: InputMaybe<NullableOfSerializationModesOperationFilterInput>;
  readonly shippingClass?: InputMaybe<StringOperationFilterInput>;
  readonly spare1?: InputMaybe<StringOperationFilterInput>;
  readonly spare2?: InputMaybe<StringOperationFilterInput>;
  readonly spare3?: InputMaybe<StringOperationFilterInput>;
  readonly spare4?: InputMaybe<StringOperationFilterInput>;
  readonly spare9?: InputMaybe<StringOperationFilterInput>;
  readonly spare10?: InputMaybe<StringOperationFilterInput>;
  readonly spare13?: InputMaybe<StringOperationFilterInput>;
  readonly spare14?: InputMaybe<StringOperationFilterInput>;
  readonly spare15?: InputMaybe<StringOperationFilterInput>;
  readonly spare16?: InputMaybe<StringOperationFilterInput>;
  readonly spare17?: InputMaybe<StringOperationFilterInput>;
  readonly spare18?: InputMaybe<StringOperationFilterInput>;
  readonly spare19?: InputMaybe<StringOperationFilterInput>;
  readonly spare20?: InputMaybe<StringOperationFilterInput>;
  readonly spare21?: InputMaybe<StringOperationFilterInput>;
  readonly spare22?: InputMaybe<StringOperationFilterInput>;
  readonly spare23?: InputMaybe<StringOperationFilterInput>;
  readonly spare24?: InputMaybe<StringOperationFilterInput>;
  readonly spare25?: InputMaybe<StringOperationFilterInput>;
  readonly spare26?: InputMaybe<StringOperationFilterInput>;
  readonly spare27?: InputMaybe<StringOperationFilterInput>;
  readonly spare28?: InputMaybe<StringOperationFilterInput>;
  readonly spare29?: InputMaybe<StringOperationFilterInput>;
  readonly spare30?: InputMaybe<StringOperationFilterInput>;
  readonly spare31?: InputMaybe<StringOperationFilterInput>;
  readonly spare32?: InputMaybe<StringOperationFilterInput>;
  readonly spare33?: InputMaybe<StringOperationFilterInput>;
  readonly specialDiscount?: InputMaybe<StringOperationFilterInput>;
  readonly stockCode?: InputMaybe<StringOperationFilterInput>;
  readonly taxable?: InputMaybe<NullableOfTaxableStatusOperationFilterInput>;
  readonly taxableString?: InputMaybe<StringOperationFilterInput>;
  readonly unit?: InputMaybe<StringOperationFilterInput>;
  readonly upc?: InputMaybe<StringOperationFilterInput>;
  readonly vendorId?: InputMaybe<StringOperationFilterInput>;
  readonly webStoreCategories?: InputMaybe<StringOperationFilterInput>;
  readonly webStoreItems?: InputMaybe<ListFilterInputTypeOfWebStoreItemFilterInput>;
  readonly weight?: InputMaybe<StringOperationFilterInput>;
  readonly whereUsed?: InputMaybe<StringOperationFilterInput>;
  readonly width?: InputMaybe<StringOperationFilterInput>;
};

export type ItemLogImage = {
  readonly __typename: 'ItemLogImage';
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly image?: Maybe<ReadonlyArray<Scalars['Byte']['output']>>;
  readonly log?: Maybe<RentalItemLog>;
  readonly logId?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type ItemLogImageFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<ItemLogImageFilterInput>>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly image?: InputMaybe<ListByteOperationFilterInput>;
  readonly log?: InputMaybe<RentalItemLogFilterInput>;
  readonly logId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ItemLogImageFilterInput>>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ItemSaveResult = {
  readonly __typename: 'ItemSaveResult';
  readonly data?: Maybe<Item>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type ItemScan = {
  readonly __typename: 'ItemScan';
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  readonly isMerged: Scalars['Boolean']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId: Scalars['String']['output'];
  readonly lot?: Maybe<Scalars['String']['output']>;
  readonly quantity: Scalars['Decimal']['output'];
  readonly serial?: Maybe<Scalars['String']['output']>;
  readonly transaction?: Maybe<Transaction>;
  readonly transactionId: Scalars['ID']['output'];
  readonly transactionType: ItemScanTransactionTypes;
  readonly userId: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};

export type ItemScanAddInput = {
  readonly isMerged: Scalars['Boolean']['input'];
  readonly itemId: Scalars['String']['input'];
  readonly quantity: Scalars['Decimal']['input'];
  readonly transactionId: Scalars['ID']['input'];
  readonly transactionType: ItemScanTransactionTypes;
};

export type ItemScanFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<ItemScanFilterInput>>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly isMerged?: InputMaybe<BooleanOperationFilterInput>;
  readonly item?: InputMaybe<ItemFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly lot?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ItemScanFilterInput>>;
  readonly quantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly serial?: InputMaybe<StringOperationFilterInput>;
  readonly transactionId?: InputMaybe<IdOperationFilterInput>;
  readonly transactionType?: InputMaybe<ItemScanTransactionTypesOperationFilterInput>;
  readonly userId?: InputMaybe<StringOperationFilterInput>;
  readonly username?: InputMaybe<StringOperationFilterInput>;
};

/** A connection to a list of items. */
export type ItemScanHistoryByTransactionConnection = {
  readonly __typename: 'ItemScanHistoryByTransactionConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<ItemScanHistoryByTransactionEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<ItemScanTransaction>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ItemScanHistoryByTransactionEdge = {
  readonly __typename: 'ItemScanHistoryByTransactionEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: ItemScanTransaction;
};

export type ItemScanLotAddInput = {
  readonly isMerged: Scalars['Boolean']['input'];
  readonly itemId: Scalars['String']['input'];
  readonly lotNumbers: ReadonlyArray<ItemScanLotDetailsAddInput>;
  readonly transactionId: Scalars['ID']['input'];
  readonly transactionType: ItemScanTransactionTypes;
};

export type ItemScanLotDetailsAddInput = {
  readonly lotNumber: Scalars['String']['input'];
  readonly quantity: Scalars['Int']['input'];
};

export type ItemScanSaveResult = {
  readonly __typename: 'ItemScanSaveResult';
  readonly data?: Maybe<ItemScan>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type ItemScanSerialAddInput = {
  readonly isMerged: Scalars['Boolean']['input'];
  readonly itemId: Scalars['String']['input'];
  readonly serialNumbers: ReadonlyArray<ItemScanSerialDetailsAddInput>;
  readonly transactionId: Scalars['ID']['input'];
  readonly transactionType: ItemScanTransactionTypes;
};

export type ItemScanSerialDetailsAddInput = {
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
  readonly serialNumber: Scalars['String']['input'];
};

export type ItemScanSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly isMerged?: InputMaybe<SortEnumType>;
  readonly item?: InputMaybe<ItemSortInput>;
  readonly itemId?: InputMaybe<SortEnumType>;
  readonly lot?: InputMaybe<SortEnumType>;
  readonly quantity?: InputMaybe<SortEnumType>;
  readonly serial?: InputMaybe<SortEnumType>;
  readonly transactionId?: InputMaybe<SortEnumType>;
  readonly transactionType?: InputMaybe<SortEnumType>;
  readonly userId?: InputMaybe<SortEnumType>;
  readonly username?: InputMaybe<SortEnumType>;
};

export type ItemScanTransaction = {
  readonly __typename: 'ItemScanTransaction';
  readonly transaction?: Maybe<Transaction>;
  readonly transactionId: Scalars['ID']['output'];
  readonly transactionType: ItemScanTransactionTypes;
  readonly updated: Scalars['DateTime']['output'];
  readonly userId: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};

export type ItemScanTransactionFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ItemScanTransactionFilterInput>>;
  readonly or?: InputMaybe<ReadonlyArray<ItemScanTransactionFilterInput>>;
  readonly transactionId?: InputMaybe<IdOperationFilterInput>;
  readonly transactionType?: InputMaybe<ItemScanTransactionTypesOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
  readonly userId?: InputMaybe<StringOperationFilterInput>;
  readonly username?: InputMaybe<StringOperationFilterInput>;
};

export type ItemScanTransactionSortInput = {
  readonly transactionId?: InputMaybe<SortEnumType>;
  readonly transactionType?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
  readonly userId?: InputMaybe<SortEnumType>;
  readonly username?: InputMaybe<SortEnumType>;
};

export enum ItemScanTransactionTypes {
  Invoice = 'INVOICE',
  PurchaseOrder = 'PURCHASE_ORDER',
  WorkOrder = 'WORK_ORDER'
}

export type ItemScanTransactionTypesOperationFilterInput = {
  readonly eq?: InputMaybe<ItemScanTransactionTypes>;
  readonly in?: InputMaybe<ReadonlyArray<ItemScanTransactionTypes>>;
  readonly neq?: InputMaybe<ItemScanTransactionTypes>;
  readonly nin?: InputMaybe<ReadonlyArray<ItemScanTransactionTypes>>;
};

export type ItemScanUpdateInput = {
  readonly id: Scalars['ID']['input'];
  readonly isMerged: Scalars['Boolean']['input'];
  readonly itemId: Scalars['String']['input'];
  readonly lot?: InputMaybe<Scalars['String']['input']>;
  readonly quantity: Scalars['Decimal']['input'];
  readonly serial?: InputMaybe<Scalars['String']['input']>;
  readonly transactionId: Scalars['ID']['input'];
  readonly transactionType: ItemScanTransactionTypes;
};

/** A connection to a list of items. */
export type ItemScansConnection = {
  readonly __typename: 'ItemScansConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<ItemScansEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<ItemScan>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ItemScansEdge = {
  readonly __typename: 'ItemScansEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: ItemScan;
};

export enum ItemSerializationModes {
  Bulk = 'BULK',
  Consignment = 'CONSIGNMENT',
  LotNumber = 'LOT_NUMBER',
  None = 'NONE',
  SerialNumber = 'SERIAL_NUMBER'
}

export type ItemSortInput = {
  readonly activityLocation?: InputMaybe<SortEnumType>;
  readonly additionalDescription?: InputMaybe<SortEnumType>;
  readonly alternateList?: InputMaybe<SortEnumType>;
  readonly alternateUnit?: InputMaybe<SortEnumType>;
  readonly autoOrderItemId?: InputMaybe<SortEnumType>;
  readonly bookChanged?: InputMaybe<SortEnumType>;
  readonly bookCost?: InputMaybe<SortEnumType>;
  readonly buyCaseQuantity?: InputMaybe<SortEnumType>;
  readonly buyDescription?: InputMaybe<SortEnumType>;
  readonly comment?: InputMaybe<SortEnumType>;
  readonly commissionCode?: InputMaybe<SortEnumType>;
  readonly coreChargeRevision?: InputMaybe<SortEnumType>;
  readonly countQuantity?: InputMaybe<SortEnumType>;
  readonly custom1?: InputMaybe<SortEnumType>;
  readonly custom2?: InputMaybe<SortEnumType>;
  readonly custom3?: InputMaybe<SortEnumType>;
  readonly custom4?: InputMaybe<SortEnumType>;
  readonly custom5?: InputMaybe<SortEnumType>;
  readonly custom6?: InputMaybe<SortEnumType>;
  readonly custom7?: InputMaybe<SortEnumType>;
  readonly custom8?: InputMaybe<SortEnumType>;
  readonly description?: InputMaybe<SortEnumType>;
  readonly discountLevelA?: InputMaybe<SortEnumType>;
  readonly discountLevelB?: InputMaybe<SortEnumType>;
  readonly discountLevelC?: InputMaybe<SortEnumType>;
  readonly discountLevelD?: InputMaybe<SortEnumType>;
  readonly externalImageURL?: InputMaybe<SortEnumType>;
  readonly freightAdjustment?: InputMaybe<SortEnumType>;
  readonly groupId?: InputMaybe<SortEnumType>;
  readonly height?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly inventoryAdjustItemId?: InputMaybe<SortEnumType>;
  readonly inventoryAdjustRatio?: InputMaybe<SortEnumType>;
  readonly jobClassId?: InputMaybe<SortEnumType>;
  readonly leadTime?: InputMaybe<SortEnumType>;
  readonly length?: InputMaybe<SortEnumType>;
  readonly listChanged?: InputMaybe<SortEnumType>;
  readonly listPrice?: InputMaybe<SortEnumType>;
  readonly location?: InputMaybe<SortEnumType>;
  readonly manufacturerAndCode1?: InputMaybe<SortEnumType>;
  readonly manufacturerAndCode2?: InputMaybe<SortEnumType>;
  readonly manufacturerAndCode3?: InputMaybe<SortEnumType>;
  readonly maximum?: InputMaybe<SortEnumType>;
  readonly minimum?: InputMaybe<SortEnumType>;
  readonly msrp?: InputMaybe<SortEnumType>;
  readonly multiplier?: InputMaybe<SortEnumType>;
  readonly newItemId?: InputMaybe<SortEnumType>;
  readonly newPrice?: InputMaybe<SortEnumType>;
  readonly options?: InputMaybe<SortEnumType>;
  readonly orderMultiple?: InputMaybe<SortEnumType>;
  readonly payrollCategoryId?: InputMaybe<SortEnumType>;
  readonly poDescription?: InputMaybe<SortEnumType>;
  readonly preAdjustmentQuantity?: InputMaybe<SortEnumType>;
  readonly priceChangeDate?: InputMaybe<SortEnumType>;
  readonly priceUpdateId?: InputMaybe<SortEnumType>;
  readonly pricingFormulas?: InputMaybe<SortEnumType>;
  readonly printCode?: InputMaybe<SortEnumType>;
  readonly quantityPerLabel?: InputMaybe<SortEnumType>;
  readonly remark?: InputMaybe<SortEnumType>;
  readonly restrictionId?: InputMaybe<SortEnumType>;
  readonly saleGlAccountId?: InputMaybe<SortEnumType>;
  readonly serializationMode?: InputMaybe<SortEnumType>;
  readonly shippingClass?: InputMaybe<SortEnumType>;
  readonly spare1?: InputMaybe<SortEnumType>;
  readonly spare2?: InputMaybe<SortEnumType>;
  readonly spare3?: InputMaybe<SortEnumType>;
  readonly spare4?: InputMaybe<SortEnumType>;
  readonly spare9?: InputMaybe<SortEnumType>;
  readonly spare10?: InputMaybe<SortEnumType>;
  readonly spare13?: InputMaybe<SortEnumType>;
  readonly spare14?: InputMaybe<SortEnumType>;
  readonly spare15?: InputMaybe<SortEnumType>;
  readonly spare16?: InputMaybe<SortEnumType>;
  readonly spare17?: InputMaybe<SortEnumType>;
  readonly spare18?: InputMaybe<SortEnumType>;
  readonly spare19?: InputMaybe<SortEnumType>;
  readonly spare20?: InputMaybe<SortEnumType>;
  readonly spare21?: InputMaybe<SortEnumType>;
  readonly spare22?: InputMaybe<SortEnumType>;
  readonly spare23?: InputMaybe<SortEnumType>;
  readonly spare24?: InputMaybe<SortEnumType>;
  readonly spare25?: InputMaybe<SortEnumType>;
  readonly spare26?: InputMaybe<SortEnumType>;
  readonly spare27?: InputMaybe<SortEnumType>;
  readonly spare28?: InputMaybe<SortEnumType>;
  readonly spare29?: InputMaybe<SortEnumType>;
  readonly spare30?: InputMaybe<SortEnumType>;
  readonly spare31?: InputMaybe<SortEnumType>;
  readonly spare32?: InputMaybe<SortEnumType>;
  readonly spare33?: InputMaybe<SortEnumType>;
  readonly specialDiscount?: InputMaybe<SortEnumType>;
  readonly stockCode?: InputMaybe<SortEnumType>;
  readonly taxable?: InputMaybe<SortEnumType>;
  readonly taxableString?: InputMaybe<SortEnumType>;
  readonly unit?: InputMaybe<SortEnumType>;
  readonly upc?: InputMaybe<SortEnumType>;
  readonly vendorId?: InputMaybe<SortEnumType>;
  readonly webStoreCategories?: InputMaybe<SortEnumType>;
  readonly weight?: InputMaybe<SortEnumType>;
  readonly whereUsed?: InputMaybe<SortEnumType>;
  readonly width?: InputMaybe<SortEnumType>;
};

export type ItemTop = {
  readonly __typename: 'ItemTop';
  readonly id: Scalars['String']['output'];
  readonly label: Scalars['String']['output'];
  readonly value: Scalars['Decimal']['output'];
};

export type ItemUpdatePartialInput = {
  readonly additionalDescription?: InputMaybe<Scalars['String']['input']>;
  readonly alternateList?: InputMaybe<Scalars['Decimal']['input']>;
  readonly alternateUnit?: InputMaybe<Scalars['String']['input']>;
  readonly bookChanged?: InputMaybe<Scalars['DateTime']['input']>;
  readonly buyDescription?: InputMaybe<Scalars['String']['input']>;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly countQuantity?: InputMaybe<Scalars['String']['input']>;
  readonly custom1?: InputMaybe<Scalars['String']['input']>;
  readonly custom2?: InputMaybe<Scalars['String']['input']>;
  readonly custom3?: InputMaybe<Scalars['String']['input']>;
  readonly custom4?: InputMaybe<Scalars['String']['input']>;
  readonly custom5?: InputMaybe<Scalars['String']['input']>;
  readonly custom6?: InputMaybe<Scalars['String']['input']>;
  readonly custom7?: InputMaybe<Scalars['String']['input']>;
  readonly custom8?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly externalImageURL?: InputMaybe<Scalars['String']['input']>;
  readonly height?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly inventoryAdjustItemId?: InputMaybe<Scalars['String']['input']>;
  readonly inventoryAdjustRatio?: InputMaybe<Scalars['Decimal']['input']>;
  readonly length?: InputMaybe<Scalars['String']['input']>;
  readonly listChanged?: InputMaybe<Scalars['DateTime']['input']>;
  readonly newItemId?: InputMaybe<Scalars['String']['input']>;
  readonly newPrice?: InputMaybe<Scalars['Decimal']['input']>;
  readonly options?: InputMaybe<Scalars['String']['input']>;
  readonly orderMultiple?: InputMaybe<Scalars['String']['input']>;
  readonly poDescription?: InputMaybe<Scalars['String']['input']>;
  readonly preAdjustmentQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  readonly priceChangeDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly priceUpdateId?: InputMaybe<Scalars['String']['input']>;
  readonly pricingFormulas?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly remark?: InputMaybe<Scalars['String']['input']>;
  readonly serializationMode?: InputMaybe<ItemSerializationModes>;
  readonly shippingClass?: InputMaybe<Scalars['String']['input']>;
  readonly spare1?: InputMaybe<Scalars['String']['input']>;
  readonly spare2?: InputMaybe<Scalars['String']['input']>;
  readonly spare3?: InputMaybe<Scalars['String']['input']>;
  readonly spare4?: InputMaybe<Scalars['String']['input']>;
  readonly spare9?: InputMaybe<Scalars['String']['input']>;
  readonly spare10?: InputMaybe<Scalars['String']['input']>;
  readonly spare13?: InputMaybe<Scalars['String']['input']>;
  readonly spare14?: InputMaybe<Scalars['String']['input']>;
  readonly spare15?: InputMaybe<Scalars['String']['input']>;
  readonly spare16?: InputMaybe<Scalars['String']['input']>;
  readonly spare17?: InputMaybe<Scalars['String']['input']>;
  readonly spare18?: InputMaybe<Scalars['String']['input']>;
  readonly spare19?: InputMaybe<Scalars['String']['input']>;
  readonly spare20?: InputMaybe<Scalars['String']['input']>;
  readonly spare21?: InputMaybe<Scalars['String']['input']>;
  readonly spare22?: InputMaybe<Scalars['String']['input']>;
  readonly spare23?: InputMaybe<Scalars['String']['input']>;
  readonly spare24?: InputMaybe<Scalars['String']['input']>;
  readonly spare25?: InputMaybe<Scalars['String']['input']>;
  readonly spare26?: InputMaybe<Scalars['String']['input']>;
  readonly spare27?: InputMaybe<Scalars['String']['input']>;
  readonly spare28?: InputMaybe<Scalars['String']['input']>;
  readonly spare29?: InputMaybe<Scalars['String']['input']>;
  readonly spare30?: InputMaybe<Scalars['String']['input']>;
  readonly spare31?: InputMaybe<Scalars['String']['input']>;
  readonly spare32?: InputMaybe<Scalars['String']['input']>;
  readonly spare33?: InputMaybe<Scalars['String']['input']>;
  readonly taxable?: InputMaybe<TaxableStatus>;
  readonly unit?: InputMaybe<Scalars['String']['input']>;
  readonly vendorId?: InputMaybe<Scalars['String']['input']>;
  readonly webStoreCategories?: InputMaybe<Scalars['String']['input']>;
  readonly whereUsed?: InputMaybe<Scalars['String']['input']>;
  readonly width?: InputMaybe<Scalars['String']['input']>;
};

export type ItemValidateInput = {
  readonly additionalDescription?: InputMaybe<Scalars['String']['input']>;
  readonly alternateList?: InputMaybe<Scalars['Decimal']['input']>;
  readonly alternateUnit?: InputMaybe<Scalars['String']['input']>;
  readonly bookChanged?: InputMaybe<Scalars['DateTime']['input']>;
  readonly buyDescription?: InputMaybe<Scalars['String']['input']>;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly countQuantity?: InputMaybe<Scalars['String']['input']>;
  readonly custom1?: InputMaybe<Scalars['String']['input']>;
  readonly custom2?: InputMaybe<Scalars['String']['input']>;
  readonly custom3?: InputMaybe<Scalars['String']['input']>;
  readonly custom4?: InputMaybe<Scalars['String']['input']>;
  readonly custom5?: InputMaybe<Scalars['String']['input']>;
  readonly custom6?: InputMaybe<Scalars['String']['input']>;
  readonly custom7?: InputMaybe<Scalars['String']['input']>;
  readonly custom8?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly externalImageURL?: InputMaybe<Scalars['String']['input']>;
  readonly height?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly inventoryAdjustItemId?: InputMaybe<Scalars['String']['input']>;
  readonly inventoryAdjustRatio?: InputMaybe<Scalars['Decimal']['input']>;
  readonly length?: InputMaybe<Scalars['String']['input']>;
  readonly listChanged?: InputMaybe<Scalars['DateTime']['input']>;
  readonly newItemId?: InputMaybe<Scalars['String']['input']>;
  readonly newPrice?: InputMaybe<Scalars['Decimal']['input']>;
  readonly options?: InputMaybe<Scalars['String']['input']>;
  readonly orderMultiple?: InputMaybe<Scalars['String']['input']>;
  readonly poDescription?: InputMaybe<Scalars['String']['input']>;
  readonly preAdjustmentQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  readonly priceChangeDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly priceUpdateId?: InputMaybe<Scalars['String']['input']>;
  readonly pricingFormulas?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly remark?: InputMaybe<Scalars['String']['input']>;
  readonly serializationMode?: InputMaybe<ItemSerializationModes>;
  readonly shippingClass?: InputMaybe<Scalars['String']['input']>;
  readonly spare1?: InputMaybe<Scalars['String']['input']>;
  readonly spare2?: InputMaybe<Scalars['String']['input']>;
  readonly spare3?: InputMaybe<Scalars['String']['input']>;
  readonly spare4?: InputMaybe<Scalars['String']['input']>;
  readonly spare9?: InputMaybe<Scalars['String']['input']>;
  readonly spare10?: InputMaybe<Scalars['String']['input']>;
  readonly spare13?: InputMaybe<Scalars['String']['input']>;
  readonly spare14?: InputMaybe<Scalars['String']['input']>;
  readonly spare15?: InputMaybe<Scalars['String']['input']>;
  readonly spare16?: InputMaybe<Scalars['String']['input']>;
  readonly spare17?: InputMaybe<Scalars['String']['input']>;
  readonly spare18?: InputMaybe<Scalars['String']['input']>;
  readonly spare19?: InputMaybe<Scalars['String']['input']>;
  readonly spare20?: InputMaybe<Scalars['String']['input']>;
  readonly spare21?: InputMaybe<Scalars['String']['input']>;
  readonly spare22?: InputMaybe<Scalars['String']['input']>;
  readonly spare23?: InputMaybe<Scalars['String']['input']>;
  readonly spare24?: InputMaybe<Scalars['String']['input']>;
  readonly spare25?: InputMaybe<Scalars['String']['input']>;
  readonly spare26?: InputMaybe<Scalars['String']['input']>;
  readonly spare27?: InputMaybe<Scalars['String']['input']>;
  readonly spare28?: InputMaybe<Scalars['String']['input']>;
  readonly spare29?: InputMaybe<Scalars['String']['input']>;
  readonly spare30?: InputMaybe<Scalars['String']['input']>;
  readonly spare31?: InputMaybe<Scalars['String']['input']>;
  readonly spare32?: InputMaybe<Scalars['String']['input']>;
  readonly spare33?: InputMaybe<Scalars['String']['input']>;
  readonly taxable?: InputMaybe<TaxableStatus>;
  readonly unit?: InputMaybe<Scalars['String']['input']>;
  readonly vendorId?: InputMaybe<Scalars['String']['input']>;
  readonly webStoreCategories?: InputMaybe<Scalars['String']['input']>;
  readonly whereUsed?: InputMaybe<Scalars['String']['input']>;
  readonly width?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type ItemsConnection = {
  readonly __typename: 'ItemsConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<ItemsEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Item>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ItemsEdge = {
  readonly __typename: 'ItemsEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Item;
};

export enum ItemsTopPeriod {
  NinetyDays = 'NINETY_DAYS',
  ThirtyDays = 'THIRTY_DAYS',
  TwoYears = 'TWO_YEARS',
  Year = 'YEAR'
}

export enum ItemsTopSort {
  Amount = 'AMOUNT',
  Quantity = 'QUANTITY'
}

export type Job = {
  readonly __typename: 'Job';
  readonly advertiser?: Maybe<Scalars['String']['output']>;
  readonly amountChangeOrders: Scalars['Decimal']['output'];
  readonly amountToBill1: Scalars['Decimal']['output'];
  readonly amountToBill2: Scalars['Decimal']['output'];
  readonly amountToBill3: Scalars['Decimal']['output'];
  readonly amountToBill4: Scalars['Decimal']['output'];
  readonly awardDate?: Maybe<Scalars['DateTime']['output']>;
  readonly billCodeDetailCombineSubJobclass?: Maybe<Scalars['String']['output']>;
  readonly billDescription1?: Maybe<Scalars['String']['output']>;
  readonly billDescription2?: Maybe<Scalars['String']['output']>;
  readonly billDescription3?: Maybe<Scalars['String']['output']>;
  readonly billDescription4?: Maybe<Scalars['String']['output']>;
  readonly bookPages?: Maybe<Scalars['String']['output']>;
  readonly building?: Maybe<Scalars['String']['output']>;
  readonly callAnsweredBy?: Maybe<Scalars['String']['output']>;
  readonly changeOrders: Scalars['Int']['output'];
  readonly commissionDollars?: Maybe<Scalars['String']['output']>;
  readonly commissionPercent?: Maybe<Scalars['String']['output']>;
  readonly contactPerson?: Maybe<Scalars['String']['output']>;
  readonly contractedPrice: Scalars['Decimal']['output'];
  readonly costFromScheduling: Scalars['Decimal']['output'];
  readonly customer: Customer;
  readonly customerId?: Maybe<Scalars['String']['output']>;
  readonly customerPo?: Maybe<Scalars['String']['output']>;
  readonly customerTerms?: Maybe<Scalars['String']['output']>;
  readonly customerTypeId?: Maybe<Scalars['String']['output']>;
  readonly dateCompleted1?: Maybe<Scalars['DateTime']['output']>;
  readonly dateCompleted2?: Maybe<Scalars['DateTime']['output']>;
  readonly dateCompleted3?: Maybe<Scalars['DateTime']['output']>;
  readonly dateCompleted4?: Maybe<Scalars['DateTime']['output']>;
  readonly dateStarted?: Maybe<Scalars['DateTime']['output']>;
  readonly departmentId?: Maybe<Scalars['String']['output']>;
  readonly description0?: Maybe<Scalars['String']['output']>;
  readonly description1?: Maybe<Scalars['String']['output']>;
  readonly description2?: Maybe<Scalars['String']['output']>;
  readonly description3?: Maybe<Scalars['String']['output']>;
  readonly description4?: Maybe<Scalars['String']['output']>;
  readonly description5?: Maybe<Scalars['String']['output']>;
  readonly description6?: Maybe<Scalars['String']['output']>;
  readonly description7?: Maybe<Scalars['String']['output']>;
  readonly description8?: Maybe<Scalars['String']['output']>;
  readonly description9?: Maybe<Scalars['String']['output']>;
  readonly endDate?: Maybe<Scalars['DateTime']['output']>;
  readonly estimatedHours: Scalars['Decimal']['output'];
  readonly fixedOty?: Maybe<JobFixedOvertime>;
  readonly fixedOverhead: Scalars['Decimal']['output'];
  readonly foreman?: Maybe<Scalars['String']['output']>;
  readonly groupId?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly inspectionDate?: Maybe<Scalars['DateTime']['output']>;
  readonly inspectionDescription?: Maybe<Scalars['String']['output']>;
  readonly jobClassId?: Maybe<Scalars['String']['output']>;
  readonly jobDates1?: Maybe<Scalars['DateTime']['output']>;
  readonly jobDates2?: Maybe<Scalars['DateTime']['output']>;
  readonly jobDates3?: Maybe<Scalars['DateTime']['output']>;
  readonly jobDates4?: Maybe<Scalars['DateTime']['output']>;
  readonly jobDates5?: Maybe<Scalars['DateTime']['output']>;
  readonly jobDates6?: Maybe<Scalars['DateTime']['output']>;
  readonly jobDates7?: Maybe<Scalars['DateTime']['output']>;
  readonly jobDates8?: Maybe<Scalars['DateTime']['output']>;
  readonly jobDescription1?: Maybe<Scalars['String']['output']>;
  readonly jobDescription2?: Maybe<Scalars['String']['output']>;
  readonly jobDescription3?: Maybe<Scalars['String']['output']>;
  readonly jobDescription4?: Maybe<Scalars['String']['output']>;
  readonly jobDescription5?: Maybe<Scalars['String']['output']>;
  readonly jobPhone?: Maybe<Scalars['String']['output']>;
  readonly laborBillCode?: Maybe<Scalars['String']['output']>;
  readonly laborBillingRate1: Scalars['Decimal']['output'];
  readonly laborBillingRate2: Scalars['Decimal']['output'];
  readonly laborBillingRate3: Scalars['Decimal']['output'];
  readonly laborSaleGlAccountId: Scalars['ID']['output'];
  readonly lastBillRefBilled: Scalars['ID']['output'];
  readonly lastDateBilled?: Maybe<Scalars['DateTime']['output']>;
  readonly lastMatRefBilled: Scalars['ID']['output'];
  readonly lastTimeRefBilled: Scalars['ID']['output'];
  readonly manuscriptPages?: Maybe<Scalars['String']['output']>;
  readonly materialDiscountPercent: Scalars['Decimal']['output'];
  readonly materialSaleGlAccountId: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly nameCont?: Maybe<Scalars['String']['output']>;
  readonly numberOfColors?: Maybe<Scalars['String']['output']>;
  readonly numberOfCopies?: Maybe<Scalars['String']['output']>;
  readonly ohCode1?: Maybe<Scalars['String']['output']>;
  readonly ohCode2?: Maybe<Scalars['String']['output']>;
  readonly ohCode3?: Maybe<Scalars['String']['output']>;
  readonly ohCode4?: Maybe<Scalars['String']['output']>;
  readonly quoteDate?: Maybe<Scalars['DateTime']['output']>;
  readonly quoteNumber?: Maybe<Scalars['String']['output']>;
  readonly retainerPercent?: Maybe<Scalars['String']['output']>;
  readonly salespersonId?: Maybe<Scalars['String']['output']>;
  readonly scaleWage: Scalars['Decimal']['output'];
  readonly shipResidentialCommercial?: Maybe<ResidentialCommercial>;
  readonly spare1?: Maybe<Scalars['String']['output']>;
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly subContractSaleGlAccountId: Scalars['ID']['output'];
  readonly subcontractDisc?: Maybe<Scalars['String']['output']>;
  readonly taxCode?: Maybe<Scalars['String']['output']>;
  readonly trimSize?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly zipCode?: Maybe<ZipCode>;
  readonly zipCodeId?: Maybe<Scalars['String']['output']>;
  readonly zipExt?: Maybe<Scalars['String']['output']>;
};

export type JobAddInput = {
  readonly advertiser?: InputMaybe<Scalars['String']['input']>;
  readonly amountChangeOrders?: InputMaybe<Scalars['Decimal']['input']>;
  readonly amountToBill1?: InputMaybe<Scalars['Decimal']['input']>;
  readonly amountToBill2?: InputMaybe<Scalars['Decimal']['input']>;
  readonly amountToBill3?: InputMaybe<Scalars['Decimal']['input']>;
  readonly amountToBill4?: InputMaybe<Scalars['Decimal']['input']>;
  readonly awardDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly billCodeDetailCombineSubJobclass?: InputMaybe<Scalars['String']['input']>;
  readonly billDescription1?: InputMaybe<Scalars['String']['input']>;
  readonly billDescription2?: InputMaybe<Scalars['String']['input']>;
  readonly billDescription3?: InputMaybe<Scalars['String']['input']>;
  readonly billDescription4?: InputMaybe<Scalars['String']['input']>;
  readonly bookPages?: InputMaybe<Scalars['String']['input']>;
  readonly building?: InputMaybe<Scalars['String']['input']>;
  readonly callAnsweredBy?: InputMaybe<Scalars['String']['input']>;
  readonly changeOrders?: InputMaybe<Scalars['Int']['input']>;
  readonly commissionDollars?: InputMaybe<Scalars['String']['input']>;
  readonly commissionPercent?: InputMaybe<Scalars['String']['input']>;
  readonly contactPerson?: InputMaybe<Scalars['String']['input']>;
  readonly contractedPrice?: InputMaybe<Scalars['Decimal']['input']>;
  readonly costFromScheduling?: InputMaybe<Scalars['Decimal']['input']>;
  readonly customerId?: InputMaybe<Scalars['String']['input']>;
  readonly customerPo?: InputMaybe<Scalars['String']['input']>;
  readonly customerTerms?: InputMaybe<Scalars['String']['input']>;
  readonly customerTypeId?: InputMaybe<Scalars['String']['input']>;
  readonly dateCompleted1?: InputMaybe<Scalars['DateTime']['input']>;
  readonly dateCompleted2?: InputMaybe<Scalars['DateTime']['input']>;
  readonly dateCompleted3?: InputMaybe<Scalars['DateTime']['input']>;
  readonly dateCompleted4?: InputMaybe<Scalars['DateTime']['input']>;
  readonly dateStarted?: InputMaybe<Scalars['DateTime']['input']>;
  readonly departmentId?: InputMaybe<Scalars['String']['input']>;
  readonly description0?: InputMaybe<Scalars['String']['input']>;
  readonly description1?: InputMaybe<Scalars['String']['input']>;
  readonly description2?: InputMaybe<Scalars['String']['input']>;
  readonly description3?: InputMaybe<Scalars['String']['input']>;
  readonly description4?: InputMaybe<Scalars['String']['input']>;
  readonly description5?: InputMaybe<Scalars['String']['input']>;
  readonly description6?: InputMaybe<Scalars['String']['input']>;
  readonly description7?: InputMaybe<Scalars['String']['input']>;
  readonly description8?: InputMaybe<Scalars['String']['input']>;
  readonly description9?: InputMaybe<Scalars['String']['input']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly estimatedHours?: InputMaybe<Scalars['Decimal']['input']>;
  readonly fixedOty?: InputMaybe<JobFixedOvertime>;
  readonly fixedOverhead?: InputMaybe<Scalars['Decimal']['input']>;
  readonly foreman?: InputMaybe<Scalars['String']['input']>;
  readonly groupId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly inspectionDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly inspectionDescription?: InputMaybe<Scalars['String']['input']>;
  readonly jobClassId?: InputMaybe<Scalars['String']['input']>;
  readonly jobDates1?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates2?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates3?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates4?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates5?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates6?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates7?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates8?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDescription1?: InputMaybe<Scalars['String']['input']>;
  readonly jobDescription2?: InputMaybe<Scalars['String']['input']>;
  readonly jobDescription3?: InputMaybe<Scalars['String']['input']>;
  readonly jobDescription4?: InputMaybe<Scalars['String']['input']>;
  readonly jobDescription5?: InputMaybe<Scalars['String']['input']>;
  readonly jobPhone?: InputMaybe<Scalars['String']['input']>;
  readonly laborBillCode?: InputMaybe<Scalars['String']['input']>;
  readonly laborBillingRate1?: InputMaybe<Scalars['Decimal']['input']>;
  readonly laborBillingRate2?: InputMaybe<Scalars['Decimal']['input']>;
  readonly laborBillingRate3?: InputMaybe<Scalars['Decimal']['input']>;
  readonly laborSaleGlAccountId?: InputMaybe<Scalars['ID']['input']>;
  readonly lastBillRefBilled?: InputMaybe<Scalars['ID']['input']>;
  readonly lastDateBilled?: InputMaybe<Scalars['DateTime']['input']>;
  readonly lastMatRefBilled?: InputMaybe<Scalars['ID']['input']>;
  readonly lastTimeRefBilled?: InputMaybe<Scalars['ID']['input']>;
  readonly manuscriptPages?: InputMaybe<Scalars['String']['input']>;
  readonly materialDiscountPercent?: InputMaybe<Scalars['Decimal']['input']>;
  readonly materialSaleGlAccountId?: InputMaybe<Scalars['ID']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly nameCont?: InputMaybe<Scalars['String']['input']>;
  readonly numberOfColors?: InputMaybe<Scalars['String']['input']>;
  readonly numberOfCopies?: InputMaybe<Scalars['String']['input']>;
  readonly ohCode1?: InputMaybe<Scalars['String']['input']>;
  readonly ohCode2?: InputMaybe<Scalars['String']['input']>;
  readonly ohCode3?: InputMaybe<Scalars['String']['input']>;
  readonly ohCode4?: InputMaybe<Scalars['String']['input']>;
  readonly quoteDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly quoteNumber?: InputMaybe<Scalars['String']['input']>;
  readonly retainerPercent?: InputMaybe<Scalars['String']['input']>;
  readonly salespersonId?: InputMaybe<Scalars['String']['input']>;
  readonly scaleWage?: InputMaybe<Scalars['Decimal']['input']>;
  readonly shipResidentialCommercial?: InputMaybe<ResidentialCommercial>;
  readonly spare1?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly subContractSaleGlAccountId?: InputMaybe<Scalars['ID']['input']>;
  readonly subcontractDisc?: InputMaybe<Scalars['String']['input']>;
  readonly taxCode?: InputMaybe<Scalars['String']['input']>;
  readonly trimSize?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly zipCodeId?: InputMaybe<Scalars['String']['input']>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
};

export type JobClassFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<JobClassFilterInput>>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<JobClassFilterInput>>;
};

export type JobClassIdAndSerialNumbers = {
  readonly __typename: 'JobClassIdAndSerialNumbers';
  readonly jobClassId?: Maybe<Scalars['String']['output']>;
  readonly lotNumbers: ReadonlyArray<Scalars['String']['output']>;
  readonly serialNumbers: ReadonlyArray<Scalars['String']['output']>;
};

export type JobFilterInput = {
  readonly advertiser?: InputMaybe<StringOperationFilterInput>;
  readonly amountChangeOrders?: InputMaybe<DecimalOperationFilterInput>;
  readonly amountToBill1?: InputMaybe<DecimalOperationFilterInput>;
  readonly amountToBill2?: InputMaybe<DecimalOperationFilterInput>;
  readonly amountToBill3?: InputMaybe<DecimalOperationFilterInput>;
  readonly amountToBill4?: InputMaybe<DecimalOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<JobFilterInput>>;
  readonly awardDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly billCodeDetailCombineSubJobclass?: InputMaybe<StringOperationFilterInput>;
  readonly billDescription1?: InputMaybe<StringOperationFilterInput>;
  readonly billDescription2?: InputMaybe<StringOperationFilterInput>;
  readonly billDescription3?: InputMaybe<StringOperationFilterInput>;
  readonly billDescription4?: InputMaybe<StringOperationFilterInput>;
  readonly bookPages?: InputMaybe<StringOperationFilterInput>;
  readonly building?: InputMaybe<StringOperationFilterInput>;
  readonly callAnsweredBy?: InputMaybe<StringOperationFilterInput>;
  readonly changeOrders?: InputMaybe<IntOperationFilterInput>;
  readonly commissionDollars?: InputMaybe<StringOperationFilterInput>;
  readonly commissionPercent?: InputMaybe<StringOperationFilterInput>;
  readonly contactPerson?: InputMaybe<StringOperationFilterInput>;
  readonly contractedPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly costFromScheduling?: InputMaybe<DecimalOperationFilterInput>;
  readonly customer?: InputMaybe<CustomerFilterInput>;
  readonly customerId?: InputMaybe<StringOperationFilterInput>;
  readonly customerPo?: InputMaybe<StringOperationFilterInput>;
  readonly customerTerms?: InputMaybe<StringOperationFilterInput>;
  readonly customerTypeId?: InputMaybe<StringOperationFilterInput>;
  readonly dateCompleted1?: InputMaybe<DateTimeOperationFilterInput>;
  readonly dateCompleted2?: InputMaybe<DateTimeOperationFilterInput>;
  readonly dateCompleted3?: InputMaybe<DateTimeOperationFilterInput>;
  readonly dateCompleted4?: InputMaybe<DateTimeOperationFilterInput>;
  readonly dateStarted?: InputMaybe<DateTimeOperationFilterInput>;
  readonly departmentId?: InputMaybe<StringOperationFilterInput>;
  readonly description0?: InputMaybe<StringOperationFilterInput>;
  readonly description1?: InputMaybe<StringOperationFilterInput>;
  readonly description2?: InputMaybe<StringOperationFilterInput>;
  readonly description3?: InputMaybe<StringOperationFilterInput>;
  readonly description4?: InputMaybe<StringOperationFilterInput>;
  readonly description5?: InputMaybe<StringOperationFilterInput>;
  readonly description6?: InputMaybe<StringOperationFilterInput>;
  readonly description7?: InputMaybe<StringOperationFilterInput>;
  readonly description8?: InputMaybe<StringOperationFilterInput>;
  readonly description9?: InputMaybe<StringOperationFilterInput>;
  readonly endDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly estimatedHours?: InputMaybe<DecimalOperationFilterInput>;
  readonly fixedOty?: InputMaybe<NullableOfJobFixedOvertimeOperationFilterInput>;
  readonly fixedOverhead?: InputMaybe<DecimalOperationFilterInput>;
  readonly foreman?: InputMaybe<StringOperationFilterInput>;
  readonly groupId?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly inspectionDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly inspectionDescription?: InputMaybe<StringOperationFilterInput>;
  readonly jobClassId?: InputMaybe<StringOperationFilterInput>;
  readonly jobDates1?: InputMaybe<DateTimeOperationFilterInput>;
  readonly jobDates2?: InputMaybe<DateTimeOperationFilterInput>;
  readonly jobDates3?: InputMaybe<DateTimeOperationFilterInput>;
  readonly jobDates4?: InputMaybe<DateTimeOperationFilterInput>;
  readonly jobDates5?: InputMaybe<DateTimeOperationFilterInput>;
  readonly jobDates6?: InputMaybe<DateTimeOperationFilterInput>;
  readonly jobDates7?: InputMaybe<DateTimeOperationFilterInput>;
  readonly jobDates8?: InputMaybe<DateTimeOperationFilterInput>;
  readonly jobDescription1?: InputMaybe<StringOperationFilterInput>;
  readonly jobDescription2?: InputMaybe<StringOperationFilterInput>;
  readonly jobDescription3?: InputMaybe<StringOperationFilterInput>;
  readonly jobDescription4?: InputMaybe<StringOperationFilterInput>;
  readonly jobDescription5?: InputMaybe<StringOperationFilterInput>;
  readonly jobPhone?: InputMaybe<StringOperationFilterInput>;
  readonly laborBillCode?: InputMaybe<StringOperationFilterInput>;
  readonly laborBillingRate1?: InputMaybe<DecimalOperationFilterInput>;
  readonly laborBillingRate2?: InputMaybe<DecimalOperationFilterInput>;
  readonly laborBillingRate3?: InputMaybe<DecimalOperationFilterInput>;
  readonly laborSaleGlAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly lastBillRefBilled?: InputMaybe<IdOperationFilterInput>;
  readonly lastDateBilled?: InputMaybe<DateTimeOperationFilterInput>;
  readonly lastMatRefBilled?: InputMaybe<IdOperationFilterInput>;
  readonly lastTimeRefBilled?: InputMaybe<IdOperationFilterInput>;
  readonly manuscriptPages?: InputMaybe<StringOperationFilterInput>;
  readonly materialDiscountPercent?: InputMaybe<DecimalOperationFilterInput>;
  readonly materialSaleGlAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly nameCont?: InputMaybe<StringOperationFilterInput>;
  readonly numberOfColors?: InputMaybe<StringOperationFilterInput>;
  readonly numberOfCopies?: InputMaybe<StringOperationFilterInput>;
  readonly ohCode1?: InputMaybe<StringOperationFilterInput>;
  readonly ohCode2?: InputMaybe<StringOperationFilterInput>;
  readonly ohCode3?: InputMaybe<StringOperationFilterInput>;
  readonly ohCode4?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<JobFilterInput>>;
  readonly quoteDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly quoteNumber?: InputMaybe<StringOperationFilterInput>;
  readonly retainerPercent?: InputMaybe<StringOperationFilterInput>;
  readonly salespersonId?: InputMaybe<StringOperationFilterInput>;
  readonly scaleWage?: InputMaybe<DecimalOperationFilterInput>;
  readonly shipResidentialCommercial?: InputMaybe<NullableOfResidentialCommercialOperationFilterInput>;
  readonly spare1?: InputMaybe<StringOperationFilterInput>;
  readonly streetAddress?: InputMaybe<StringOperationFilterInput>;
  readonly subContractSaleGlAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly subcontractDisc?: InputMaybe<StringOperationFilterInput>;
  readonly taxCode?: InputMaybe<StringOperationFilterInput>;
  readonly trimSize?: InputMaybe<StringOperationFilterInput>;
  readonly type?: InputMaybe<StringOperationFilterInput>;
  readonly zipCode?: InputMaybe<ZipCodeFilterInput>;
  readonly zipCodeId?: InputMaybe<StringOperationFilterInput>;
  readonly zipExt?: InputMaybe<StringOperationFilterInput>;
};

export enum JobFixedOvertime {
  No = 'NO',
  Yes = 'YES'
}

export type JobSaveResult = {
  readonly __typename: 'JobSaveResult';
  readonly data?: Maybe<Job>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type JobSortInput = {
  readonly advertiser?: InputMaybe<SortEnumType>;
  readonly amountChangeOrders?: InputMaybe<SortEnumType>;
  readonly amountToBill1?: InputMaybe<SortEnumType>;
  readonly amountToBill2?: InputMaybe<SortEnumType>;
  readonly amountToBill3?: InputMaybe<SortEnumType>;
  readonly amountToBill4?: InputMaybe<SortEnumType>;
  readonly awardDate?: InputMaybe<SortEnumType>;
  readonly billCodeDetailCombineSubJobclass?: InputMaybe<SortEnumType>;
  readonly billDescription1?: InputMaybe<SortEnumType>;
  readonly billDescription2?: InputMaybe<SortEnumType>;
  readonly billDescription3?: InputMaybe<SortEnumType>;
  readonly billDescription4?: InputMaybe<SortEnumType>;
  readonly bookPages?: InputMaybe<SortEnumType>;
  readonly building?: InputMaybe<SortEnumType>;
  readonly callAnsweredBy?: InputMaybe<SortEnumType>;
  readonly changeOrders?: InputMaybe<SortEnumType>;
  readonly commissionDollars?: InputMaybe<SortEnumType>;
  readonly commissionPercent?: InputMaybe<SortEnumType>;
  readonly contactPerson?: InputMaybe<SortEnumType>;
  readonly contractedPrice?: InputMaybe<SortEnumType>;
  readonly costFromScheduling?: InputMaybe<SortEnumType>;
  readonly customer?: InputMaybe<CustomerSortInput>;
  readonly customerId?: InputMaybe<SortEnumType>;
  readonly customerPo?: InputMaybe<SortEnumType>;
  readonly customerTerms?: InputMaybe<SortEnumType>;
  readonly customerTypeId?: InputMaybe<SortEnumType>;
  readonly dateCompleted1?: InputMaybe<SortEnumType>;
  readonly dateCompleted2?: InputMaybe<SortEnumType>;
  readonly dateCompleted3?: InputMaybe<SortEnumType>;
  readonly dateCompleted4?: InputMaybe<SortEnumType>;
  readonly dateStarted?: InputMaybe<SortEnumType>;
  readonly departmentId?: InputMaybe<SortEnumType>;
  readonly description0?: InputMaybe<SortEnumType>;
  readonly description1?: InputMaybe<SortEnumType>;
  readonly description2?: InputMaybe<SortEnumType>;
  readonly description3?: InputMaybe<SortEnumType>;
  readonly description4?: InputMaybe<SortEnumType>;
  readonly description5?: InputMaybe<SortEnumType>;
  readonly description6?: InputMaybe<SortEnumType>;
  readonly description7?: InputMaybe<SortEnumType>;
  readonly description8?: InputMaybe<SortEnumType>;
  readonly description9?: InputMaybe<SortEnumType>;
  readonly endDate?: InputMaybe<SortEnumType>;
  readonly estimatedHours?: InputMaybe<SortEnumType>;
  readonly fixedOty?: InputMaybe<SortEnumType>;
  readonly fixedOverhead?: InputMaybe<SortEnumType>;
  readonly foreman?: InputMaybe<SortEnumType>;
  readonly groupId?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly inspectionDate?: InputMaybe<SortEnumType>;
  readonly inspectionDescription?: InputMaybe<SortEnumType>;
  readonly jobClassId?: InputMaybe<SortEnumType>;
  readonly jobDates1?: InputMaybe<SortEnumType>;
  readonly jobDates2?: InputMaybe<SortEnumType>;
  readonly jobDates3?: InputMaybe<SortEnumType>;
  readonly jobDates4?: InputMaybe<SortEnumType>;
  readonly jobDates5?: InputMaybe<SortEnumType>;
  readonly jobDates6?: InputMaybe<SortEnumType>;
  readonly jobDates7?: InputMaybe<SortEnumType>;
  readonly jobDates8?: InputMaybe<SortEnumType>;
  readonly jobDescription1?: InputMaybe<SortEnumType>;
  readonly jobDescription2?: InputMaybe<SortEnumType>;
  readonly jobDescription3?: InputMaybe<SortEnumType>;
  readonly jobDescription4?: InputMaybe<SortEnumType>;
  readonly jobDescription5?: InputMaybe<SortEnumType>;
  readonly jobPhone?: InputMaybe<SortEnumType>;
  readonly laborBillCode?: InputMaybe<SortEnumType>;
  readonly laborBillingRate1?: InputMaybe<SortEnumType>;
  readonly laborBillingRate2?: InputMaybe<SortEnumType>;
  readonly laborBillingRate3?: InputMaybe<SortEnumType>;
  readonly laborSaleGlAccountId?: InputMaybe<SortEnumType>;
  readonly lastBillRefBilled?: InputMaybe<SortEnumType>;
  readonly lastDateBilled?: InputMaybe<SortEnumType>;
  readonly lastMatRefBilled?: InputMaybe<SortEnumType>;
  readonly lastTimeRefBilled?: InputMaybe<SortEnumType>;
  readonly manuscriptPages?: InputMaybe<SortEnumType>;
  readonly materialDiscountPercent?: InputMaybe<SortEnumType>;
  readonly materialSaleGlAccountId?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly nameCont?: InputMaybe<SortEnumType>;
  readonly numberOfColors?: InputMaybe<SortEnumType>;
  readonly numberOfCopies?: InputMaybe<SortEnumType>;
  readonly ohCode1?: InputMaybe<SortEnumType>;
  readonly ohCode2?: InputMaybe<SortEnumType>;
  readonly ohCode3?: InputMaybe<SortEnumType>;
  readonly ohCode4?: InputMaybe<SortEnumType>;
  readonly quoteDate?: InputMaybe<SortEnumType>;
  readonly quoteNumber?: InputMaybe<SortEnumType>;
  readonly retainerPercent?: InputMaybe<SortEnumType>;
  readonly salespersonId?: InputMaybe<SortEnumType>;
  readonly scaleWage?: InputMaybe<SortEnumType>;
  readonly shipResidentialCommercial?: InputMaybe<SortEnumType>;
  readonly spare1?: InputMaybe<SortEnumType>;
  readonly streetAddress?: InputMaybe<SortEnumType>;
  readonly subContractSaleGlAccountId?: InputMaybe<SortEnumType>;
  readonly subcontractDisc?: InputMaybe<SortEnumType>;
  readonly taxCode?: InputMaybe<SortEnumType>;
  readonly trimSize?: InputMaybe<SortEnumType>;
  readonly type?: InputMaybe<SortEnumType>;
  readonly zipCode?: InputMaybe<ZipCodeSortInput>;
  readonly zipCodeId?: InputMaybe<SortEnumType>;
  readonly zipExt?: InputMaybe<SortEnumType>;
};

export type JobUpdateInput = {
  readonly advertiser?: InputMaybe<Scalars['String']['input']>;
  readonly amountChangeOrders?: InputMaybe<Scalars['Decimal']['input']>;
  readonly amountToBill1?: InputMaybe<Scalars['Decimal']['input']>;
  readonly amountToBill2?: InputMaybe<Scalars['Decimal']['input']>;
  readonly amountToBill3?: InputMaybe<Scalars['Decimal']['input']>;
  readonly amountToBill4?: InputMaybe<Scalars['Decimal']['input']>;
  readonly awardDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly billCodeDetailCombineSubJobclass?: InputMaybe<Scalars['String']['input']>;
  readonly billDescription1?: InputMaybe<Scalars['String']['input']>;
  readonly billDescription2?: InputMaybe<Scalars['String']['input']>;
  readonly billDescription3?: InputMaybe<Scalars['String']['input']>;
  readonly billDescription4?: InputMaybe<Scalars['String']['input']>;
  readonly bookPages?: InputMaybe<Scalars['String']['input']>;
  readonly building?: InputMaybe<Scalars['String']['input']>;
  readonly callAnsweredBy?: InputMaybe<Scalars['String']['input']>;
  readonly changeOrders?: InputMaybe<Scalars['Int']['input']>;
  readonly commissionDollars?: InputMaybe<Scalars['String']['input']>;
  readonly commissionPercent?: InputMaybe<Scalars['String']['input']>;
  readonly contactPerson?: InputMaybe<Scalars['String']['input']>;
  readonly contractedPrice?: InputMaybe<Scalars['Decimal']['input']>;
  readonly costFromScheduling?: InputMaybe<Scalars['Decimal']['input']>;
  readonly customerId?: InputMaybe<Scalars['String']['input']>;
  readonly customerPo?: InputMaybe<Scalars['String']['input']>;
  readonly customerTerms?: InputMaybe<Scalars['String']['input']>;
  readonly customerTypeId?: InputMaybe<Scalars['String']['input']>;
  readonly dateCompleted1?: InputMaybe<Scalars['DateTime']['input']>;
  readonly dateCompleted2?: InputMaybe<Scalars['DateTime']['input']>;
  readonly dateCompleted3?: InputMaybe<Scalars['DateTime']['input']>;
  readonly dateCompleted4?: InputMaybe<Scalars['DateTime']['input']>;
  readonly dateStarted?: InputMaybe<Scalars['DateTime']['input']>;
  readonly departmentId?: InputMaybe<Scalars['String']['input']>;
  readonly description0?: InputMaybe<Scalars['String']['input']>;
  readonly description1?: InputMaybe<Scalars['String']['input']>;
  readonly description2?: InputMaybe<Scalars['String']['input']>;
  readonly description3?: InputMaybe<Scalars['String']['input']>;
  readonly description4?: InputMaybe<Scalars['String']['input']>;
  readonly description5?: InputMaybe<Scalars['String']['input']>;
  readonly description6?: InputMaybe<Scalars['String']['input']>;
  readonly description7?: InputMaybe<Scalars['String']['input']>;
  readonly description8?: InputMaybe<Scalars['String']['input']>;
  readonly description9?: InputMaybe<Scalars['String']['input']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly estimatedHours?: InputMaybe<Scalars['Decimal']['input']>;
  readonly fixedOty?: InputMaybe<JobFixedOvertime>;
  readonly fixedOverhead?: InputMaybe<Scalars['Decimal']['input']>;
  readonly foreman?: InputMaybe<Scalars['String']['input']>;
  readonly groupId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly inspectionDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly inspectionDescription?: InputMaybe<Scalars['String']['input']>;
  readonly jobClassId?: InputMaybe<Scalars['String']['input']>;
  readonly jobDates1?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates2?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates3?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates4?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates5?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates6?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates7?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDates8?: InputMaybe<Scalars['DateTime']['input']>;
  readonly jobDescription1?: InputMaybe<Scalars['String']['input']>;
  readonly jobDescription2?: InputMaybe<Scalars['String']['input']>;
  readonly jobDescription3?: InputMaybe<Scalars['String']['input']>;
  readonly jobDescription4?: InputMaybe<Scalars['String']['input']>;
  readonly jobDescription5?: InputMaybe<Scalars['String']['input']>;
  readonly jobPhone?: InputMaybe<Scalars['String']['input']>;
  readonly laborBillCode?: InputMaybe<Scalars['String']['input']>;
  readonly laborBillingRate1?: InputMaybe<Scalars['Decimal']['input']>;
  readonly laborBillingRate2?: InputMaybe<Scalars['Decimal']['input']>;
  readonly laborBillingRate3?: InputMaybe<Scalars['Decimal']['input']>;
  readonly laborSaleGlAccountId?: InputMaybe<Scalars['ID']['input']>;
  readonly lastBillRefBilled?: InputMaybe<Scalars['ID']['input']>;
  readonly lastDateBilled?: InputMaybe<Scalars['DateTime']['input']>;
  readonly lastMatRefBilled?: InputMaybe<Scalars['ID']['input']>;
  readonly lastTimeRefBilled?: InputMaybe<Scalars['ID']['input']>;
  readonly manuscriptPages?: InputMaybe<Scalars['String']['input']>;
  readonly materialDiscountPercent?: InputMaybe<Scalars['Decimal']['input']>;
  readonly materialSaleGlAccountId?: InputMaybe<Scalars['ID']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly nameCont?: InputMaybe<Scalars['String']['input']>;
  readonly numberOfColors?: InputMaybe<Scalars['String']['input']>;
  readonly numberOfCopies?: InputMaybe<Scalars['String']['input']>;
  readonly ohCode1?: InputMaybe<Scalars['String']['input']>;
  readonly ohCode2?: InputMaybe<Scalars['String']['input']>;
  readonly ohCode3?: InputMaybe<Scalars['String']['input']>;
  readonly ohCode4?: InputMaybe<Scalars['String']['input']>;
  readonly quoteDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly quoteNumber?: InputMaybe<Scalars['String']['input']>;
  readonly retainerPercent?: InputMaybe<Scalars['String']['input']>;
  readonly salespersonId?: InputMaybe<Scalars['String']['input']>;
  readonly scaleWage?: InputMaybe<Scalars['Decimal']['input']>;
  readonly shipResidentialCommercial?: InputMaybe<ResidentialCommercial>;
  readonly spare1?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly subContractSaleGlAccountId?: InputMaybe<Scalars['ID']['input']>;
  readonly subcontractDisc?: InputMaybe<Scalars['String']['input']>;
  readonly taxCode?: InputMaybe<Scalars['String']['input']>;
  readonly trimSize?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly zipCodeId?: InputMaybe<Scalars['String']['input']>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type JobsConnection = {
  readonly __typename: 'JobsConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<JobsEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Job>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type JobsEdge = {
  readonly __typename: 'JobsEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Job;
};

export type LabeledStatisticValue = {
  readonly __typename: 'LabeledStatisticValue';
  readonly id: Scalars['String']['output'];
  readonly label: Scalars['String']['output'];
  readonly value: Scalars['Long']['output'];
};

export type LedgerBankTransaction = {
  readonly __typename: 'LedgerBankTransaction';
  readonly amount: Scalars['Decimal']['output'];
  readonly bankStatement?: Maybe<BankStatement>;
  readonly bankStatementId?: Maybe<Scalars['ID']['output']>;
  readonly date: Scalars['DateTime']['output'];
  readonly employeeId?: Maybe<Scalars['String']['output']>;
  readonly glAccountId: Scalars['ID']['output'];
  readonly id: Scalars['Decimal']['output'];
  readonly isInternal: Scalars['Boolean']['output'];
  readonly ledger: Scalars['Int']['output'];
  readonly ledgerType?: Maybe<Scalars['String']['output']>;
  readonly paycheckAmount: Scalars['Decimal']['output'];
  readonly referenceNumber?: Maybe<Scalars['String']['output']>;
  readonly vendorId?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type LedgerEntriesConnection = {
  readonly __typename: 'LedgerEntriesConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<LedgerEntriesEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<LedgerEntrySummary>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LedgerEntriesEdge = {
  readonly __typename: 'LedgerEntriesEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: LedgerEntrySummary;
};

export type LedgerEntry = LedgerEntryBase & {
  readonly __typename: 'LedgerEntry';
  readonly checkNumber?: Maybe<Scalars['String']['output']>;
  /** ID of the contact on the transaction. */
  readonly contactId?: Maybe<Scalars['String']['output']>;
  readonly creditDetail?: Maybe<LedgerEntryDetail>;
  readonly creditTotal: Scalars['Decimal']['output'];
  readonly customer?: Maybe<Customer>;
  readonly customerId?: Maybe<Scalars['String']['output']>;
  readonly date: Scalars['Date']['output'];
  readonly debitDetail?: Maybe<LedgerEntryDetail>;
  readonly debitTotal: Scalars['Decimal']['output'];
  readonly details?: Maybe<ReadonlyArray<LedgerEntryDetail>>;
  readonly employee?: Maybe<Employee>;
  readonly employeeId?: Maybe<Scalars['String']['output']>;
  readonly externalId?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly memo?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Name>;
  readonly nameId?: Maybe<Scalars['String']['output']>;
  readonly payPeriodEnd?: Maybe<Scalars['Date']['output']>;
  readonly sequenceId?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly transactionType: Scalars['String']['output'];
  readonly updatedBy?: Maybe<Scalars['String']['output']>;
  readonly validRange: DateRange;
  readonly vendor?: Maybe<Vendor>;
  readonly vendorId?: Maybe<Scalars['String']['output']>;
};

export type LedgerEntryAddInput = {
  readonly checkNumber?: InputMaybe<Scalars['String']['input']>;
  readonly creditDetail?: InputMaybe<LedgerEntryDetailInput>;
  readonly customerId?: InputMaybe<Scalars['String']['input']>;
  readonly date: Scalars['Date']['input'];
  readonly debitDetail?: InputMaybe<LedgerEntryDetailInput>;
  readonly details?: InputMaybe<ReadonlyArray<LedgerEntryDetailInput>>;
  readonly employeeId?: InputMaybe<Scalars['String']['input']>;
  readonly externalId?: InputMaybe<Scalars['String']['input']>;
  readonly memo?: InputMaybe<Scalars['String']['input']>;
  readonly nameId?: InputMaybe<Scalars['String']['input']>;
  readonly payPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  readonly sequenceId?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly transactionType: Scalars['String']['input'];
  readonly vendorId?: InputMaybe<Scalars['String']['input']>;
};

export type LedgerEntryBase = {
  readonly checkNumber?: Maybe<Scalars['String']['output']>;
  readonly customerId?: Maybe<Scalars['String']['output']>;
  readonly date: Scalars['Date']['output'];
  readonly employeeId?: Maybe<Scalars['String']['output']>;
  readonly externalId?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly memo?: Maybe<Scalars['String']['output']>;
  readonly nameId?: Maybe<Scalars['String']['output']>;
  readonly payPeriodEnd?: Maybe<Scalars['Date']['output']>;
  readonly sequenceId?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly transactionType: Scalars['String']['output'];
  readonly updatedBy?: Maybe<Scalars['String']['output']>;
  readonly validRange: DateRange;
  readonly vendorId?: Maybe<Scalars['String']['output']>;
};

export type LedgerEntryDetail = {
  readonly __typename: 'LedgerEntryDetail';
  readonly achInfo?: Maybe<Scalars['String']['output']>;
  readonly bankStatementId?: Maybe<Scalars['ID']['output']>;
  readonly billId?: Maybe<Scalars['ID']['output']>;
  readonly comment?: Maybe<Scalars['String']['output']>;
  readonly creditAmount: Scalars['Decimal']['output'];
  readonly debitAmount: Scalars['Decimal']['output'];
  readonly departmentId?: Maybe<Scalars['ID']['output']>;
  readonly glAccount?: Maybe<GlAccount>;
  readonly glAccountId?: Maybe<Scalars['ID']['output']>;
  /** Balance of the selected GL account as of this transaction. */
  readonly glBalance: Scalars['Decimal']['output'];
  readonly id: Scalars['ID']['output'];
  readonly isInternal: Scalars['Boolean']['output'];
  readonly jobId?: Maybe<Scalars['String']['output']>;
  readonly ledgerEntry: LedgerEntrySummary;
  readonly ledgerEntryId: Scalars['ID']['output'];
  readonly lineNum: Scalars['Int']['output'];
  readonly locationId?: Maybe<Scalars['String']['output']>;
  readonly payrollCategoryId?: Maybe<Scalars['String']['output']>;
  readonly price: Scalars['Decimal']['output'];
  readonly quantity: Scalars['Decimal']['output'];
  readonly serialNumberId?: Maybe<Scalars['String']['output']>;
  readonly tags?: Maybe<Scalars['String']['output']>;
  readonly unit?: Maybe<Scalars['String']['output']>;
  readonly updatedBy?: Maybe<Scalars['String']['output']>;
  readonly validRange: DateRange;
};

export type LedgerEntryDetailFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<LedgerEntryDetailFilterInput>>;
  readonly comment?: InputMaybe<StringOperationFilterInput>;
  readonly creditAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly debitAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly glAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly ledgerEntry?: InputMaybe<LedgerEntryFilterInput>;
  readonly ledgerEntryId?: InputMaybe<IdOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<LedgerEntryDetailFilterInput>>;
};

export type LedgerEntryDetailInput = {
  readonly bankStatementId?: InputMaybe<Scalars['ID']['input']>;
  readonly billId?: InputMaybe<Scalars['ID']['input']>;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly creditAmount?: InputMaybe<Scalars['Decimal']['input']>;
  readonly debitAmount?: InputMaybe<Scalars['Decimal']['input']>;
  readonly departmentId?: InputMaybe<Scalars['ID']['input']>;
  readonly glAccountId?: InputMaybe<Scalars['ID']['input']>;
  /** Leaving null will create a new row. Specifying an existing ID will update that row. */
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  readonly isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  readonly jobId?: InputMaybe<Scalars['String']['input']>;
  readonly lineNum?: InputMaybe<Scalars['Int']['input']>;
  readonly locationId?: InputMaybe<Scalars['String']['input']>;
  readonly payrollCategoryId?: InputMaybe<Scalars['String']['input']>;
  readonly price?: InputMaybe<Scalars['Decimal']['input']>;
  readonly quantity?: InputMaybe<Scalars['Decimal']['input']>;
  readonly serialNumberId?: InputMaybe<Scalars['String']['input']>;
  readonly tags?: InputMaybe<Scalars['String']['input']>;
  readonly unit?: InputMaybe<Scalars['String']['input']>;
};

export type LedgerEntryDetailSortInput = {
  readonly id?: InputMaybe<SortEnumType>;
  readonly ledgerEntry?: InputMaybe<LedgerEntrySortInput>;
};

/** A connection to a list of items. */
export type LedgerEntryDetailsConnection = {
  readonly __typename: 'LedgerEntryDetailsConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<LedgerEntryDetailsEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<LedgerEntryDetail>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LedgerEntryDetailsEdge = {
  readonly __typename: 'LedgerEntryDetailsEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: LedgerEntryDetail;
};

export type LedgerEntryFilterInput = {
  readonly addressName?: InputMaybe<StringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<LedgerEntryFilterInput>>;
  readonly attention?: InputMaybe<StringOperationFilterInput>;
  readonly checkNumber?: InputMaybe<StringOperationFilterInput>;
  readonly customer?: InputMaybe<CustomerFilterInput>;
  readonly customerId?: InputMaybe<StringOperationFilterInput>;
  readonly date?: InputMaybe<DateOperationFilterInput>;
  readonly details?: InputMaybe<ListFilterInputTypeOfLedgerEntryDetailFilterInput>;
  readonly employee?: InputMaybe<EmployeeFilterInput>;
  readonly employeeId?: InputMaybe<StringOperationFilterInput>;
  readonly externalId?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly memo?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<NameFilterInput>;
  readonly nameId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<LedgerEntryFilterInput>>;
  readonly payPeriodEnd?: InputMaybe<DateOperationFilterInput>;
  readonly sequenceId?: InputMaybe<StringOperationFilterInput>;
  readonly status?: InputMaybe<StringOperationFilterInput>;
  readonly streetAddress?: InputMaybe<StringOperationFilterInput>;
  readonly transactionType?: InputMaybe<StringOperationFilterInput>;
  readonly updatedBy?: InputMaybe<StringOperationFilterInput>;
  readonly validRange?: InputMaybe<NpgsqlRangeOfDateTimeFilterInput>;
  readonly vendor?: InputMaybe<VendorFilterInput>;
  readonly vendorId?: InputMaybe<StringOperationFilterInput>;
  readonly zipCode?: InputMaybe<ZipCodeFilterInput>;
  readonly zipCodeId?: InputMaybe<StringOperationFilterInput>;
  readonly zipExt?: InputMaybe<StringOperationFilterInput>;
};

export type LedgerEntrySortInput = {
  readonly addressName?: InputMaybe<SortEnumType>;
  readonly attention?: InputMaybe<SortEnumType>;
  readonly checkNumber?: InputMaybe<SortEnumType>;
  readonly customer?: InputMaybe<CustomerSortInput>;
  readonly customerId?: InputMaybe<SortEnumType>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly employee?: InputMaybe<EmployeeSortInput>;
  readonly employeeId?: InputMaybe<SortEnumType>;
  readonly externalId?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly memo?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<NameSortInput>;
  readonly nameId?: InputMaybe<SortEnumType>;
  readonly payPeriodEnd?: InputMaybe<SortEnumType>;
  readonly sequenceId?: InputMaybe<SortEnumType>;
  readonly status?: InputMaybe<SortEnumType>;
  readonly streetAddress?: InputMaybe<SortEnumType>;
  readonly transactionType?: InputMaybe<SortEnumType>;
  readonly updatedBy?: InputMaybe<SortEnumType>;
  readonly validRange?: InputMaybe<SortEnumType>;
  readonly vendor?: InputMaybe<VendorSortInput>;
  readonly vendorId?: InputMaybe<SortEnumType>;
  readonly zipCode?: InputMaybe<ZipCodeSortInput>;
  readonly zipCodeId?: InputMaybe<SortEnumType>;
  readonly zipExt?: InputMaybe<SortEnumType>;
};

export type LedgerEntrySummary = LedgerEntryBase & {
  readonly __typename: 'LedgerEntrySummary';
  readonly checkNumber?: Maybe<Scalars['String']['output']>;
  /** ID of the contact on the transaction. */
  readonly contactId?: Maybe<Scalars['String']['output']>;
  /** This loads the first debit detail with a GL Account and CreditAmount. This may not be the same as is loaded on a non-summary ledger entry */
  readonly creditDetail?: Maybe<LedgerEntryDetail>;
  readonly creditTotal: Scalars['Decimal']['output'];
  readonly customer?: Maybe<Customer>;
  readonly customerId?: Maybe<Scalars['String']['output']>;
  readonly date: Scalars['Date']['output'];
  /** This loads the first debit detail with a GL Account and DebitAmount. This may not be the same as is loaded on a non-summary ledger entry */
  readonly debitDetail?: Maybe<LedgerEntryDetail>;
  readonly debitTotal: Scalars['Decimal']['output'];
  readonly employee?: Maybe<Employee>;
  readonly employeeId?: Maybe<Scalars['String']['output']>;
  readonly externalId?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly memo?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Name>;
  readonly nameId?: Maybe<Scalars['String']['output']>;
  readonly payPeriodEnd?: Maybe<Scalars['Date']['output']>;
  readonly sequenceId?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly transactionType: Scalars['String']['output'];
  readonly updatedBy?: Maybe<Scalars['String']['output']>;
  readonly validRange: DateRange;
  readonly vendor?: Maybe<Vendor>;
  readonly vendorId?: Maybe<Scalars['String']['output']>;
};

export type LedgerEntrySummaryFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<LedgerEntrySummaryFilterInput>>;
  readonly checkNumber?: InputMaybe<StringOperationFilterInput>;
  readonly customerId?: InputMaybe<StringOperationFilterInput>;
  readonly date?: InputMaybe<DateOperationFilterInput>;
  readonly employeeId?: InputMaybe<StringOperationFilterInput>;
  readonly externalId?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly memo?: InputMaybe<StringOperationFilterInput>;
  readonly nameId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<LedgerEntrySummaryFilterInput>>;
  readonly payPeriodEnd?: InputMaybe<DateOperationFilterInput>;
  readonly sequenceId?: InputMaybe<StringOperationFilterInput>;
  readonly status?: InputMaybe<StringOperationFilterInput>;
  readonly transactionType?: InputMaybe<StringOperationFilterInput>;
  readonly updatedBy?: InputMaybe<StringOperationFilterInput>;
  readonly validRange?: InputMaybe<NpgsqlRangeOfDateTimeFilterInput>;
  readonly vendorId?: InputMaybe<StringOperationFilterInput>;
};

export type LedgerEntrySummarySortInput = {
  readonly checkNumber?: InputMaybe<SortEnumType>;
  readonly customerId?: InputMaybe<SortEnumType>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly employeeId?: InputMaybe<SortEnumType>;
  readonly externalId?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly memo?: InputMaybe<SortEnumType>;
  readonly nameId?: InputMaybe<SortEnumType>;
  readonly payPeriodEnd?: InputMaybe<SortEnumType>;
  readonly sequenceId?: InputMaybe<SortEnumType>;
  readonly status?: InputMaybe<SortEnumType>;
  readonly transactionType?: InputMaybe<SortEnumType>;
  readonly updatedBy?: InputMaybe<SortEnumType>;
  readonly validRange?: InputMaybe<SortEnumType>;
  readonly vendorId?: InputMaybe<SortEnumType>;
};

export type LedgerEntryUpdateInput = {
  readonly checkNumber?: InputMaybe<Scalars['String']['input']>;
  readonly creditDetail?: InputMaybe<LedgerEntryDetailInput>;
  readonly customerId?: InputMaybe<Scalars['String']['input']>;
  readonly date: Scalars['Date']['input'];
  readonly debitDetail?: InputMaybe<LedgerEntryDetailInput>;
  readonly details?: InputMaybe<ReadonlyArray<LedgerEntryDetailInput>>;
  readonly employeeId?: InputMaybe<Scalars['String']['input']>;
  readonly externalId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly memo?: InputMaybe<Scalars['String']['input']>;
  readonly nameId?: InputMaybe<Scalars['String']['input']>;
  readonly payPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  readonly sequenceId?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly transactionType: Scalars['String']['input'];
  readonly vendorId?: InputMaybe<Scalars['String']['input']>;
};

export type LedgerEntryValidateInput = {
  readonly checkNumber?: InputMaybe<Scalars['String']['input']>;
  readonly creditDetail?: InputMaybe<LedgerEntryDetailInput>;
  readonly customerId?: InputMaybe<Scalars['String']['input']>;
  readonly date?: InputMaybe<Scalars['Date']['input']>;
  readonly debitDetail?: InputMaybe<LedgerEntryDetailInput>;
  readonly details?: InputMaybe<ReadonlyArray<LedgerEntryDetailInput>>;
  readonly employeeId?: InputMaybe<Scalars['String']['input']>;
  readonly externalId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  readonly memo?: InputMaybe<Scalars['String']['input']>;
  readonly nameId?: InputMaybe<Scalars['String']['input']>;
  readonly payPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  readonly sequenceId?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly transactionType?: InputMaybe<Scalars['String']['input']>;
  readonly vendorId?: InputMaybe<Scalars['String']['input']>;
};

/** Ledger transaction for a bank account. */
export type LedgerTransaction = {
  readonly __typename: 'LedgerTransaction';
  readonly bankGlAccountId?: Maybe<Scalars['ID']['output']>;
  readonly bankStatementId?: Maybe<Scalars['ID']['output']>;
  readonly contactId?: Maybe<Scalars['String']['output']>;
  readonly credit?: Maybe<Scalars['Decimal']['output']>;
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly debit?: Maybe<Scalars['Decimal']['output']>;
  readonly id: Scalars['Decimal']['output'];
  readonly isInternal?: Maybe<Scalars['Boolean']['output']>;
  readonly ledger?: Maybe<Scalars['Int']['output']>;
  readonly ledgerType?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly referenceNumber?: Maybe<Scalars['String']['output']>;
  readonly returned?: Maybe<Scalars['DateTime']['output']>;
};

export type LedgerTransactionFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<LedgerTransactionFilterInput>>;
  readonly bankGlAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly bankStatementId?: InputMaybe<IdOperationFilterInput>;
  readonly contactId?: InputMaybe<StringOperationFilterInput>;
  readonly credit?: InputMaybe<DecimalOperationFilterInput>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly debit?: InputMaybe<DecimalOperationFilterInput>;
  readonly id?: InputMaybe<DecimalOperationFilterInput>;
  readonly isInternal?: InputMaybe<BooleanOperationFilterInput>;
  readonly ledger?: InputMaybe<IntOperationFilterInput>;
  readonly ledgerType?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<LedgerTransactionFilterInput>>;
  readonly referenceNumber?: InputMaybe<StringOperationFilterInput>;
  readonly returned?: InputMaybe<DateTimeOperationFilterInput>;
};

export type LedgerTransactionSortInput = {
  readonly bankGlAccountId?: InputMaybe<SortEnumType>;
  readonly bankStatementId?: InputMaybe<SortEnumType>;
  readonly contactId?: InputMaybe<SortEnumType>;
  readonly credit?: InputMaybe<SortEnumType>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly debit?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly isInternal?: InputMaybe<SortEnumType>;
  readonly ledger?: InputMaybe<SortEnumType>;
  readonly ledgerType?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly referenceNumber?: InputMaybe<SortEnumType>;
  readonly returned?: InputMaybe<SortEnumType>;
};

export type LegacyServer = {
  readonly __typename: 'LegacyServer';
  readonly added: Scalars['DateTime']['output'];
  readonly application?: Maybe<Scalars['String']['output']>;
  readonly companies: ReadonlyArray<Company>;
  readonly directory: Scalars['String']['output'];
  readonly dll?: Maybe<Scalars['String']['output']>;
  readonly fmt?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly number: Scalars['Int']['output'];
  readonly par?: Maybe<Scalars['String']['output']>;
  readonly port: Scalars['Int']['output'];
  readonly recentResourceUsage: RecentResourceUsage;
  readonly serverFileErrors: ServerFileErrors;
  readonly service: ServerService;
  readonly shutdownTimeout: Scalars['Int']['output'];
  readonly startup: LegacyServerStartup;
  readonly updated: Scalars['DateTime']['output'];
  readonly url?: Maybe<Scalars['String']['output']>;
  readonly webServerUri?: Maybe<Scalars['String']['output']>;
};


export type LegacyServerCompaniesArgs = {
  order?: InputMaybe<ReadonlyArray<CompanySortInput>>;
  where?: InputMaybe<CompanyFilterInput>;
};

export type LegacyServerAddInput = {
  readonly directory: Scalars['String']['input'];
  readonly dll: Scalars['String']['input'];
  readonly dllSource?: InputMaybe<Scalars['String']['input']>;
  readonly exe: Scalars['String']['input'];
  readonly fmt: Scalars['String']['input'];
  readonly fmtSource?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly number: Scalars['Int']['input'];
  readonly par: Scalars['String']['input'];
  readonly parSource?: InputMaybe<Scalars['String']['input']>;
  readonly port: Scalars['Int']['input'];
  readonly shutdownTimeout: Scalars['Int']['input'];
  readonly startup: LegacyServerStartup;
  readonly url: Scalars['String']['input'];
  readonly webServerUri: Scalars['String']['input'];
};

export type LegacyServerAuthorizeResult = {
  readonly __typename: 'LegacyServerAuthorizeResult';
  readonly error?: Maybe<Scalars['String']['output']>;
  readonly success: Scalars['Boolean']['output'];
};

export type LegacyServerDeleteInput = {
  /** If not specified, will default to false */
  readonly deleteFiles?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id: Scalars['String']['input'];
};

export type LegacyServerDirectoryInfo = {
  readonly __typename: 'LegacyServerDirectoryInfo';
  readonly directory: Scalars['String']['output'];
  readonly dllFiles: ReadonlyArray<Scalars['String']['output']>;
  readonly exeFiles: ReadonlyArray<Scalars['String']['output']>;
  readonly exists: Scalars['Boolean']['output'];
  readonly fmtFiles: ReadonlyArray<Scalars['String']['output']>;
  readonly isEmpty: Scalars['Boolean']['output'];
  readonly isServer: Scalars['Boolean']['output'];
  /** The next available number for this server. */
  readonly number: Scalars['Int']['output'];
  readonly parFiles: ReadonlyArray<Scalars['String']['output']>;
};

export type LegacyServerFile = {
  readonly __typename: 'LegacyServerFile';
  readonly availableFile?: Maybe<FileDetails>;
  readonly currentFile?: Maybe<FileDetails>;
  readonly isUpdateAvailable: Scalars['Boolean']['output'];
};

export type LegacyServerFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<LegacyServerFilterInput>>;
  readonly application?: InputMaybe<StringOperationFilterInput>;
  readonly directory?: InputMaybe<StringOperationFilterInput>;
  readonly dll?: InputMaybe<StringOperationFilterInput>;
  readonly fmt?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly number?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<LegacyServerFilterInput>>;
  readonly par?: InputMaybe<StringOperationFilterInput>;
  readonly port?: InputMaybe<IntOperationFilterInput>;
  readonly shutdownTimeout?: InputMaybe<IntOperationFilterInput>;
  readonly startup?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
  readonly url?: InputMaybe<StringOperationFilterInput>;
};

export type LegacyServerLogsResult = {
  readonly __typename: 'LegacyServerLogsResult';
  readonly logs?: Maybe<Scalars['String']['output']>;
  readonly serverName: Scalars['String']['output'];
};

export type LegacyServerSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly application?: InputMaybe<SortEnumType>;
  readonly directory?: InputMaybe<SortEnumType>;
  readonly dll?: InputMaybe<SortEnumType>;
  readonly fmt?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly number?: InputMaybe<SortEnumType>;
  readonly par?: InputMaybe<SortEnumType>;
  readonly port?: InputMaybe<SortEnumType>;
  readonly shutdownTimeout?: InputMaybe<SortEnumType>;
  readonly startup?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
  readonly url?: InputMaybe<SortEnumType>;
};

export enum LegacyServerStartup {
  Automatic = 'AUTOMATIC',
  Disabled = 'DISABLED',
  Manual = 'MANUAL'
}

export type LegacyServerStatusEvent = {
  readonly __typename: 'LegacyServerStatusEvent';
  readonly isRunning: Scalars['Boolean']['output'];
  readonly serverId: Scalars['String']['output'];
};

export type LegacyServerUpdateInput = {
  readonly directory: Scalars['String']['input'];
  readonly dll: Scalars['String']['input'];
  readonly dllSource?: InputMaybe<Scalars['String']['input']>;
  readonly exe: Scalars['String']['input'];
  readonly fmt: Scalars['String']['input'];
  readonly fmtSource?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly number: Scalars['Int']['input'];
  readonly par: Scalars['String']['input'];
  readonly parSource?: InputMaybe<Scalars['String']['input']>;
  readonly port: Scalars['Int']['input'];
  readonly shutdownTimeout: Scalars['Int']['input'];
  readonly startup: LegacyServerStartup;
  readonly url: Scalars['String']['input'];
  readonly webServerUri: Scalars['String']['input'];
};

export type LegacyServerValidateNumberResults = {
  readonly __typename: 'LegacyServerValidateNumberResults';
  readonly isValid: Scalars['Boolean']['output'];
  readonly nextNumber: Scalars['Int']['output'];
};

export type LegacyServerValidatePortResults = {
  readonly __typename: 'LegacyServerValidatePortResults';
  readonly isUnique: Scalars['Boolean']['output'];
  readonly nextPort: Scalars['Int']['output'];
};

export type LegacyServerValidateUrlResults = {
  readonly __typename: 'LegacyServerValidateUrlResults';
  readonly isUnique: Scalars['Boolean']['output'];
  readonly isValid: Scalars['Boolean']['output'];
  readonly nextPort: Scalars['Int']['output'];
};

export type LicenseCompany = {
  readonly __typename: 'LicenseCompany';
  readonly allocatedUsers: Scalars['Int']['output'];
  /** The ABC Company that this license company is assigned to. */
  readonly company?: Maybe<Company>;
  readonly features: ReadonlyArray<LicenseCompanyFeature>;
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
};

export type LicenseCompanyFeature = {
  readonly __typename: 'LicenseCompanyFeature';
  readonly expiration?: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
};

export type LicenseKey = {
  readonly __typename: 'LicenseKey';
  readonly added: Scalars['DateTime']['output'];
  readonly backupForMachineId?: Maybe<Scalars['String']['output']>;
  readonly backupForName?: Maybe<Scalars['String']['output']>;
  readonly backupServerIds: ReadonlyArray<Scalars['UUID']['output']>;
  readonly companies: ReadonlyArray<LicenseCompany>;
  readonly expiration: Scalars['DateTime']['output'];
  readonly galaxyId: Scalars['UUID']['output'];
  readonly isBackup: Scalars['Boolean']['output'];
  readonly isExpired: Scalars['Boolean']['output'];
  /** If the license key is coming from the source server or has been downloaded directly */
  readonly isLicenseFromSourceServer: Scalars['Boolean']['output'];
  readonly machineId: Scalars['String']['output'];
  readonly owner: LicenseOwner;
  readonly packages: ReadonlyArray<LicensePackage>;
  readonly serverId: Scalars['UUID']['output'];
  readonly version: Scalars['String']['output'];
};

export type LicenseKeyCompanyMapInput = {
  readonly companyId: Scalars['String']['input'];
  readonly galaxyId?: InputMaybe<Scalars['String']['input']>;
};

export type LicenseOwner = {
  readonly __typename: 'LicenseOwner';
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
};

export type LicensePackage = {
  readonly __typename: 'LicensePackage';
  readonly features: ReadonlyArray<FeatureFlag>;
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
};

export type ListByteOperationFilterInput = {
  readonly all?: InputMaybe<ByteOperationFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<ByteOperationFilterInput>;
  readonly some?: InputMaybe<ByteOperationFilterInput>;
};

export type ListFilterInputTypeOfCoordinateFilterInput = {
  readonly all?: InputMaybe<CoordinateFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<CoordinateFilterInput>;
  readonly some?: InputMaybe<CoordinateFilterInput>;
};

export type ListFilterInputTypeOfFormDataFilterInput = {
  readonly all?: InputMaybe<FormDataFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<FormDataFilterInput>;
  readonly some?: InputMaybe<FormDataFilterInput>;
};

export type ListFilterInputTypeOfFormDataGroupFilterInput = {
  readonly all?: InputMaybe<FormDataGroupFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<FormDataGroupFilterInput>;
  readonly some?: InputMaybe<FormDataGroupFilterInput>;
};

export type ListFilterInputTypeOfInvoiceDateFilterInput = {
  readonly all?: InputMaybe<InvoiceDateFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<InvoiceDateFilterInput>;
  readonly some?: InputMaybe<InvoiceDateFilterInput>;
};

export type ListFilterInputTypeOfInvoiceDetailFilterInput = {
  readonly all?: InputMaybe<InvoiceDetailFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<InvoiceDetailFilterInput>;
  readonly some?: InputMaybe<InvoiceDetailFilterInput>;
};

export type ListFilterInputTypeOfInvoiceShipmentFilterInput = {
  readonly all?: InputMaybe<InvoiceShipmentFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<InvoiceShipmentFilterInput>;
  readonly some?: InputMaybe<InvoiceShipmentFilterInput>;
};

export type ListFilterInputTypeOfItemLogImageFilterInput = {
  readonly all?: InputMaybe<ItemLogImageFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<ItemLogImageFilterInput>;
  readonly some?: InputMaybe<ItemLogImageFilterInput>;
};

export type ListFilterInputTypeOfLedgerEntryDetailFilterInput = {
  readonly all?: InputMaybe<LedgerEntryDetailFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<LedgerEntryDetailFilterInput>;
  readonly some?: InputMaybe<LedgerEntryDetailFilterInput>;
};

export type ListFilterInputTypeOfLoadLogFilterInput = {
  readonly all?: InputMaybe<LoadLogFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<LoadLogFilterInput>;
  readonly some?: InputMaybe<LoadLogFilterInput>;
};

export type ListFilterInputTypeOfLocationFilterInput = {
  readonly all?: InputMaybe<LocationFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<LocationFilterInput>;
  readonly some?: InputMaybe<LocationFilterInput>;
};

export type ListFilterInputTypeOfPurchaseOrderDetailViewFilterInput = {
  readonly all?: InputMaybe<PurchaseOrderDetailFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<PurchaseOrderDetailFilterInput>;
  readonly some?: InputMaybe<PurchaseOrderDetailFilterInput>;
};

export type ListFilterInputTypeOfRentalContractSignatureFilterInput = {
  readonly all?: InputMaybe<RentalContractSignatureFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<RentalContractSignatureFilterInput>;
  readonly some?: InputMaybe<RentalContractSignatureFilterInput>;
};

export type ListFilterInputTypeOfRentalDateFilterInput = {
  readonly all?: InputMaybe<RentalDateFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<RentalDateFilterInput>;
  readonly some?: InputMaybe<RentalDateFilterInput>;
};

export type ListFilterInputTypeOfRentalDetailFilterInput = {
  readonly all?: InputMaybe<RentalDetailFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<RentalDetailFilterInput>;
  readonly some?: InputMaybe<RentalDetailFilterInput>;
};

export type ListFilterInputTypeOfRentalItemLogFilterInput = {
  readonly all?: InputMaybe<RentalItemLogFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<RentalItemLogFilterInput>;
  readonly some?: InputMaybe<RentalItemLogFilterInput>;
};

export type ListFilterInputTypeOfRentalShipmentFilterInput = {
  readonly all?: InputMaybe<RentalShipmentFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<RentalShipmentFilterInput>;
  readonly some?: InputMaybe<RentalShipmentFilterInput>;
};

export type ListFilterInputTypeOfRentalSurchargeFilterInput = {
  readonly all?: InputMaybe<RentalSurchargeFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<RentalSurchargeFilterInput>;
  readonly some?: InputMaybe<RentalSurchargeFilterInput>;
};

export type ListFilterInputTypeOfReportCategoryFilterInput = {
  readonly all?: InputMaybe<ReportCategoryFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<ReportCategoryFilterInput>;
  readonly some?: InputMaybe<ReportCategoryFilterInput>;
};

export type ListFilterInputTypeOfSalespersonCommissionFilterInput = {
  readonly all?: InputMaybe<SalespersonCommissionFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<SalespersonCommissionFilterInput>;
  readonly some?: InputMaybe<SalespersonCommissionFilterInput>;
};

export type ListFilterInputTypeOfShipmentFilterInput = {
  readonly all?: InputMaybe<ShipmentFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<ShipmentFilterInput>;
  readonly some?: InputMaybe<ShipmentFilterInput>;
};

export type ListFilterInputTypeOfShipmentLogFilterInput = {
  readonly all?: InputMaybe<ShipmentLogFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<ShipmentLogFilterInput>;
  readonly some?: InputMaybe<ShipmentLogFilterInput>;
};

export type ListFilterInputTypeOfUserFilterInput = {
  readonly all?: InputMaybe<UserFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<UserFilterInput>;
  readonly some?: InputMaybe<UserFilterInput>;
};

export type ListFilterInputTypeOfUserRoleFilterInput = {
  readonly all?: InputMaybe<UserRoleFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<UserRoleFilterInput>;
  readonly some?: InputMaybe<UserRoleFilterInput>;
};

export type ListFilterInputTypeOfVerificationFilterInput = {
  readonly all?: InputMaybe<VerificationFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<VerificationFilterInput>;
  readonly some?: InputMaybe<VerificationFilterInput>;
};

export type ListFilterInputTypeOfWebStoreItemFilterInput = {
  readonly all?: InputMaybe<WebStoreItemFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<WebStoreItemFilterInput>;
  readonly some?: InputMaybe<WebStoreItemFilterInput>;
};

export type ListFilterInputTypeOfWorkOrderDateFilterInput = {
  readonly all?: InputMaybe<WorkOrderDateFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<WorkOrderDateFilterInput>;
  readonly some?: InputMaybe<WorkOrderDateFilterInput>;
};

export type ListFilterInputTypeOfWorkOrderDetailViewFilterInput = {
  readonly all?: InputMaybe<WorkOrderDetailFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<WorkOrderDetailFilterInput>;
  readonly some?: InputMaybe<WorkOrderDetailFilterInput>;
};

export type ListFilterInputTypeOfWorkOrderShipmentFilterInput = {
  readonly all?: InputMaybe<WorkOrderShipmentFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<WorkOrderShipmentFilterInput>;
  readonly some?: InputMaybe<WorkOrderShipmentFilterInput>;
};

export type ListStringOperationFilterInput = {
  readonly all?: InputMaybe<StringOperationFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<StringOperationFilterInput>;
  readonly some?: InputMaybe<StringOperationFilterInput>;
};

export type Load = {
  readonly __typename: 'Load';
  readonly added: Scalars['DateTime']['output'];
  readonly driver?: Maybe<Driver>;
  readonly driverId?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly logs?: Maybe<ReadonlyArray<LoadLog>>;
  readonly number: Scalars['Int']['output'];
  readonly shipments?: Maybe<ReadonlyArray<Shipment>>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly truck?: Maybe<Vehicle>;
  readonly truckId?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type LoadFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<LoadFilterInput>>;
  readonly driver?: InputMaybe<DriverFilterInput>;
  readonly driverId?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly logs?: InputMaybe<ListFilterInputTypeOfLoadLogFilterInput>;
  readonly number?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<LoadFilterInput>>;
  readonly shipments?: InputMaybe<ListFilterInputTypeOfShipmentFilterInput>;
  readonly status?: InputMaybe<StringOperationFilterInput>;
  readonly truck?: InputMaybe<VehicleFilterInput>;
  readonly truckId?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type LoadLog = {
  readonly __typename: 'LoadLog';
  readonly added: Scalars['DateTime']['output'];
  readonly date: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type LoadLogFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<LoadLogFilterInput>>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<LoadLogFilterInput>>;
  readonly status?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type LoadSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly driver?: InputMaybe<DriverSortInput>;
  readonly driverId?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly number?: InputMaybe<SortEnumType>;
  readonly status?: InputMaybe<SortEnumType>;
  readonly truck?: InputMaybe<VehicleSortInput>;
  readonly truckId?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

export type LoadsStatuses = {
  readonly __typename: 'LoadsStatuses';
  readonly delivered: Scalars['Int']['output'];
  readonly loaded: Scalars['Int']['output'];
  readonly truckAssigned: Scalars['Int']['output'];
  readonly unassigned: Scalars['Int']['output'];
};

export type LoadsStatusesFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<LoadsStatusesFilterInput>>;
  readonly delivered?: InputMaybe<IntOperationFilterInput>;
  readonly loaded?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<LoadsStatusesFilterInput>>;
  readonly truckAssigned?: InputMaybe<IntOperationFilterInput>;
  readonly unassigned?: InputMaybe<IntOperationFilterInput>;
};

export type LoadsStatusesSortInput = {
  readonly delivered?: InputMaybe<SortEnumType>;
  readonly loaded?: InputMaybe<SortEnumType>;
  readonly truckAssigned?: InputMaybe<SortEnumType>;
  readonly unassigned?: InputMaybe<SortEnumType>;
};

export type Location = {
  readonly __typename: 'Location';
  readonly added: Scalars['DateTime']['output'];
  readonly completeAddress?: Maybe<Scalars['String']['output']>;
  readonly coordinates?: Maybe<GeoJsonPointType>;
  readonly customer?: Maybe<Customer>;
  readonly customerId?: Maybe<Scalars['String']['output']>;
  readonly employee?: Maybe<Employee>;
  readonly employeeId?: Maybe<Scalars['String']['output']>;
  readonly houseNumber?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name?: Maybe<Name>;
  readonly nameId?: Maybe<Scalars['String']['output']>;
  readonly postalCode?: Maybe<PostalCode>;
  readonly postalCodeId?: Maybe<Scalars['Int']['output']>;
  readonly postalCodeIdResolved?: Maybe<Scalars['String']['output']>;
  readonly postalExt?: Maybe<Scalars['String']['output']>;
  readonly street?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly vendor?: Maybe<Vendor>;
  readonly vendorId?: Maybe<Scalars['String']['output']>;
  readonly verifications?: Maybe<ReadonlyArray<Maybe<Verification>>>;
};

export type LocationFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<LocationFilterInput>>;
  readonly completeAddress?: InputMaybe<StringOperationFilterInput>;
  readonly coordinates?: InputMaybe<PointFilterInput>;
  readonly customer?: InputMaybe<CustomerFilterInput>;
  readonly customerId?: InputMaybe<StringOperationFilterInput>;
  readonly employee?: InputMaybe<EmployeeFilterInput>;
  readonly employeeId?: InputMaybe<StringOperationFilterInput>;
  readonly houseNumber?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<NameFilterInput>;
  readonly nameId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<LocationFilterInput>>;
  readonly postalCode?: InputMaybe<PostalCodeFilterInput>;
  readonly postalCodeId?: InputMaybe<IntOperationFilterInput>;
  readonly postalCodeIdResolved?: InputMaybe<StringOperationFilterInput>;
  readonly postalExt?: InputMaybe<StringOperationFilterInput>;
  readonly street?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
  readonly vendor?: InputMaybe<VendorFilterInput>;
  readonly vendorId?: InputMaybe<StringOperationFilterInput>;
  readonly verifications?: InputMaybe<ListFilterInputTypeOfVerificationFilterInput>;
};

export type LogInInformation = {
  readonly __typename: 'LogInInformation';
  readonly isLoginDomain: Scalars['Boolean']['output'];
};

export type LogInResult = {
  readonly __typename: 'LogInResult';
  readonly canAccessCompany: Scalars['Boolean']['output'];
  readonly message: Scalars['String']['output'];
  readonly requiresMfa: Scalars['Boolean']['output'];
  readonly success: Scalars['Boolean']['output'];
};

export type LogInVerifyAuthAppCodeInput = {
  readonly code: Scalars['String']['input'];
  readonly rememberClient: Scalars['Boolean']['input'];
  readonly rememberMe: Scalars['Boolean']['input'];
};

export type LoginResetPasswordInput = {
  readonly code: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly password: Scalars['String']['input'];
};

export type MachineInfo = {
  readonly __typename: 'MachineInfo';
  readonly machineId: Scalars['String']['output'];
  readonly version: Scalars['String']['output'];
};

export type Migration = {
  readonly __typename: 'Migration';
  readonly added: Scalars['DateTime']['output'];
  readonly companyId: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly status: MigrationStatus;
};

export type MigrationFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<MigrationFilterInput>>;
  readonly companyId?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<MigrationFilterInput>>;
  readonly status?: InputMaybe<MigrationStatusOperationFilterInput>;
};

export type MigrationSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly companyId?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly status?: InputMaybe<SortEnumType>;
};

export enum MigrationStatus {
  Applied = 'APPLIED',
  Applying = 'APPLYING',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED'
}

export type MigrationStatusOperationFilterInput = {
  readonly eq?: InputMaybe<MigrationStatus>;
  readonly in?: InputMaybe<ReadonlyArray<MigrationStatus>>;
  readonly neq?: InputMaybe<MigrationStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<MigrationStatus>>;
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly addIntegrationEnvironment: IntegrationEnvironment;
  readonly bankStatementAdd: BankStatementWithBalances;
  readonly bankStatementDelete: Scalars['Int']['output'];
  readonly bankStatementReset: Scalars['Boolean']['output'];
  readonly bankStatementSetTransactionInternal: LedgerTransaction;
  readonly bankStatementSetTransactionReturned?: Maybe<LedgerTransaction>;
  readonly bankStatementUpdate: BankStatementWithBalances;
  readonly boardAdd: ActionResultOfBoard;
  readonly boardsAdd: ReadonlyArray<ActionResultOfBoard>;
  readonly companyAdd: CompanyAddStatus;
  readonly companyAddWithBackup: CompanyRestoreStatus;
  readonly companyBackupDelete: Result;
  readonly companyBackupRestore: Result;
  readonly companyBackupRun: Result;
  readonly companyBackupTaxFormsRestore: Scalars['Boolean']['output'];
  readonly companyBackupTaxFormsUpload: BackupFileInfo;
  readonly companyBackupUpload: CompanyBackup;
  readonly companyConnectionStringVerify: CompanyConnectionStringVerificationResult;
  readonly companyConnectionUpdate: Company;
  readonly companyDatabaseRename: Company;
  readonly companyDelete: CompanyDeleteStatus;
  readonly companyDuplicate: CompanyDuplicateStatus;
  readonly companyIconUpload: Company;
  readonly companyImportDatabaseCreate: CompanyImportCreateStatus;
  readonly companyImportImport: CompanyImportDataStatus;
  readonly companyImportMigrate: CompanyImportMigrateStatus;
  readonly companyLogoUpload: Company;
  readonly companyMigrationApply: Migration;
  readonly companyStylesheetPreferenceUpdate: CompanyStylesheetPreference;
  readonly companyUpdate: Company;
  readonly customerAdd: CustomerSaveResult;
  readonly customerDelete: Scalars['Boolean']['output'];
  readonly customerGeocode: Customer;
  readonly customerUpdate: CustomerSaveResult;
  readonly customersBulkGeocode: Scalars['Boolean']['output'];
  readonly dashboardAdd: Dashboard;
  readonly dashboardDelete: Scalars['Boolean']['output'];
  readonly dashboardSetUserDefault?: Maybe<Dashboard>;
  readonly dashboardUpdate?: Maybe<Dashboard>;
  readonly deviceAdd: ActionResultOfDevice;
  readonly deviceDelete: Scalars['Boolean']['output'];
  readonly deviceUpdate: ActionResultOfDevice;
  readonly dispatchingAssignInvoiceToLoad: Result;
  readonly dispatchingAssignRentalToLoad: Result;
  readonly dispatchingAssignShipmentToLoad: Shipment;
  readonly dispatchingAssignWorkOrderToLoad: Result;
  readonly dispatchingDriverAdd: Driver;
  readonly dispatchingDriverDelete: Result;
  readonly dispatchingDriverUpdate: Driver;
  readonly dispatchingLoadAdd: Load;
  readonly dispatchingLoadAssignDriver: ReadonlyArray<Load>;
  readonly dispatchingLoadAssignTruck: ReadonlyArray<Load>;
  readonly dispatchingLoadDelete: Result;
  readonly dispatchingLoadShipmentDateUpdate: Load;
  readonly dispatchingLoadStatusUpdate: Load;
  readonly dispatchingPinColorAdd: DispatcherPinColor;
  readonly dispatchingPinColorDelete: Result;
  readonly dispatchingPinColorOrderUpdate: Result;
  readonly dispatchingPinColorUpdate: DispatcherPinColor;
  readonly dispatchingPinConfigurationAdd: DispatcherPinConfiguration;
  readonly dispatchingPinConfigurationDelete: Result;
  readonly dispatchingPinConfigurationPriorityUpdate: ReadonlyArray<DispatcherPinConfiguration>;
  readonly dispatchingPinConfigurationUpdate: DispatcherPinConfiguration;
  readonly dispatchingPinIconAdd: DispatcherPinIcon;
  readonly dispatchingPinIconDelete: Result;
  readonly dispatchingPinIconOrderUpdate: Result;
  readonly dispatchingPinIconUpdate: DispatcherPinIcon;
  readonly dispatchingRemoveInvoiceFromLoad: ReadonlyArray<Invoice>;
  readonly dispatchingRemoveRentalFromLoad: ReadonlyArray<Rental>;
  readonly dispatchingRemoveShipmentFromLoad: Result;
  readonly dispatchingRemoveWorkOrderFromLoad: ReadonlyArray<WorkOrder>;
  readonly dispatchingShipmentDateUpdate: Shipment;
  readonly dispatchingTruckAdd: Vehicle;
  readonly dispatchingTruckDelete: Result;
  readonly dispatchingTruckUpdate: Vehicle;
  readonly documentGenerationGeneratorConfigUpdate: DocumentGenerator;
  readonly documentGenerationGeneratorToggle?: Maybe<DocumentGenerator>;
  readonly employeeAdd: ActionResultOfEmployee;
  readonly employeeDelete: Scalars['Boolean']['output'];
  readonly employeeGeocode: Employee;
  readonly employeeUpdate: ActionResultOfEmployee;
  readonly employeesBulkGeocode: Scalars['Boolean']['output'];
  readonly featureToggleIntegration?: Maybe<Feature>;
  readonly formTemplateAdd?: Maybe<FormTemplate>;
  readonly formTemplateDuplicate?: Maybe<FormTemplate>;
  readonly formTemplateRemove?: Maybe<Result>;
  readonly formTemplateUpdate?: Maybe<FormTemplate>;
  readonly galaxyConnect: Scalars['Boolean']['output'];
  readonly galaxyDisconnect: Scalars['Boolean']['output'];
  readonly glAccountUpdateAccountId: GlAccount;
  readonly integrationUpdate?: Maybe<Integration>;
  readonly invoiceUpdate: Invoice;
  readonly itemAdd: ItemSaveResult;
  readonly itemDelete: DeleteResult;
  readonly itemScanAdd: ItemScanSaveResult;
  /** Adds an ItemScan record for each lot number/quantity combination. */
  readonly itemScanLotAdd: ReadonlyArray<ItemScanSaveResult>;
  /** Adds an ItemScan record for each serial number. */
  readonly itemScanSerialAdd: ReadonlyArray<ItemScanSaveResult>;
  readonly itemScanUpdate: ItemScanSaveResult;
  readonly itemUpdatePartial: ItemSaveResult;
  readonly itemValidate: ValidateResult;
  readonly jobAdd: JobSaveResult;
  readonly jobDelete: Scalars['Boolean']['output'];
  readonly jobUpdate: JobSaveResult;
  readonly ledgerEntryAdd: ActionResultOfLedgerEntry;
  readonly ledgerEntryDelete: DeleteResult;
  readonly ledgerEntryUpdate: ActionResultOfLedgerEntry;
  readonly ledgerEntryValidate: ValidateResult;
  readonly legacyServerAdd: LegacyServer;
  readonly legacyServerApplyUpdates?: Maybe<LegacyServer>;
  readonly legacyServerAuthorize?: Maybe<LegacyServerAuthorizeResult>;
  readonly legacyServerDelete: Scalars['Boolean']['output'];
  readonly legacyServerFileUpload: LegacyServerDirectoryInfo;
  readonly legacyServerStart: Scalars['Boolean']['output'];
  readonly legacyServerStop: Scalars['Boolean']['output'];
  readonly legacyServerUpdate: LegacyServer;
  readonly licenseKeyCompanyAssignmentUpdate?: Maybe<LicenseKey>;
  readonly licenseKeyRefresh: Scalars['Boolean']['output'];
  readonly licenseKeysAdd: Scalars['Boolean']['output'];
  readonly logIn: LogInResult;
  readonly logInForgetDeviceMfaRemembered: Scalars['Boolean']['output'];
  /** Verify authenticator app code or recovery code and complete login. */
  readonly logInVerifyAuthAppCode: LogInResult;
  readonly loginForgotPassword: Scalars['Boolean']['output'];
  /** If the password is successfully changed, we'll attempt to sign the user in. */
  readonly loginResetPassword: Scalars['Boolean']['output'];
  readonly logout?: Maybe<Scalars['Boolean']['output']>;
  readonly nameAdd: EmployeeSaveResult;
  readonly nameDelete: Scalars['Boolean']['output'];
  readonly nameGeocode: Name;
  readonly nameUpdate: EmployeeSaveResult;
  readonly namesBulkGeocode: Scalars['Boolean']['output'];
  readonly openIdApplicationAdd: OpenIdApplicationAddResult;
  readonly openIdApplicationDelete: Result;
  readonly openIdApplicationTokenRevoke: OpenIdApplication;
  readonly openIdApplicationUpdate: OpenIdApplication;
  /** Migrates a single company to the current ABC version. */
  readonly previousVersionImportCompany: PreviousVersionCompanyImportResult;
  /** Migrates a previous version legacy server to the current ABC version. */
  readonly previousVersionImportLegacyServer: PreviousVersionCompanyImportResult;
  readonly printChannelAdd?: Maybe<ActionResultOfPrintChannel>;
  readonly printChannelDelete: Scalars['Boolean']['output'];
  readonly printChannelUpdate?: Maybe<ActionResultOfPrintChannel>;
  readonly processPublicToken?: Maybe<GlAccount>;
  readonly register: RegisterResult;
  readonly removeIntegrationEnvironment?: Maybe<Integration>;
  readonly renameIntegrationEnvironment: IntegrationEnvironment;
  readonly reportCategoryAdd: Category;
  readonly reportCategoryDelete: Result;
  readonly reportCategoryUpdate?: Maybe<Category>;
  readonly reportDelete: Result;
  readonly reportImport: Company;
  readonly reportStylesheetDelete: Result;
  readonly reportStylesheetImport: Result;
  readonly reportStylesheetUpload: Stylesheet;
  readonly reportUpdate: Report;
  readonly reportUpload: Report;
  readonly reportsMigrateAuth: Result;
  readonly salesOrderAdd: SalesOrder;
  readonly salesOrderDelete: Scalars['Boolean']['output'];
  readonly salesOrderUpdate: SalesOrder;
  /** Report the success/failure of a virtual terminal transaction. */
  readonly salesVtermIframeCompleted: Scalars['Boolean']['output'];
  /** Generate a one-time use url for the virtual terminal iframe. */
  readonly salesVtermIframeUrl: SalesVtermIframeUrlResult;
  readonly salespersonAdd: ActionResultOfSalesperson;
  readonly salespersonDelete: Scalars['Boolean']['output'];
  readonly salespersonUpdate: ActionResultOfSalesperson;
  readonly salespersonValidate: ValidateResult;
  /** Pushes any queued jobs in the search index into the job scheduler. */
  readonly searchIndexCheckQueue: Scalars['Boolean']['output'];
  readonly searchIndexClear: Scalars['Boolean']['output'];
  readonly searchIndexFieldsUpdate: ReadonlyArray<SearchIndexField>;
  readonly smsSend?: Maybe<SmsMessage>;
  readonly switchIntegrationEnvironment: IntegrationDetails;
  readonly systemBackupDelete?: Maybe<Result>;
  readonly systemBackupRestore?: Maybe<Result>;
  readonly systemBackupRun?: Maybe<Result>;
  readonly systemBackupUpload?: Maybe<SystemBackup>;
  readonly systemConfigCodeGenerationSave: CodeGenerationConfig;
  /** Creates vendor records for invalid vendor ids in the legacy ledger_*_transaction tables. Returns the quantity of ledger entry records that were updated. */
  readonly toolCleanLedgerVendors: Scalars['Int']['output'];
  /** Cleans up the user_authorizations table. Returns list of affected usernames. */
  readonly toolCleanUpUserAuthorizations: ReadonlyArray<Scalars['String']['output']>;
  readonly updateNLogConfig?: Maybe<Result>;
  readonly userDelete: Result;
  readonly userEmailConfirm: Result;
  readonly userGroupAdd: UserGroup;
  readonly userGroupRemove: Result;
  readonly userGroupUpdate: UserGroup;
  readonly userLockoutReset: Result;
  readonly userPasswordReset: Result;
  readonly userPoliciesUpdateCompany: ReadonlyArray<CompanyAuthorizationPolicy>;
  readonly userPreferenceThemeUpdate: ThemePreference;
  readonly userProfileEmailConfirm?: Maybe<User>;
  readonly userProfileEmailConfirmationSend: Scalars['Boolean']['output'];
  readonly userProfileMfaConfigureApp: AuthenticatorAppSharedKeyInfo;
  readonly userProfileMfaDisable: User;
  readonly userProfileMfaResetApp: User;
  readonly userProfileMfaResetRecoveryCodes: ReadonlyArray<Scalars['String']['output']>;
  /** Verifies that the security token is valid when configuring the app. */
  readonly userProfileMfaVerifyAppToken: UserProfileVerifyMfaAppTokenResult;
  readonly userProfilePasswordUpdate: ActionResultOfBoolean;
  readonly userProfileUpdate: ActionResultOfUser;
  readonly userSetSystemAdmin: UserSystemAdmin;
  readonly userUpdate: User;
  /** This will delete the validator configuration from the database. Note that some validators are enabled by default, executing this mutation will put them back into a default state. */
  readonly validationValidatorRemove: Scalars['Boolean']['output'];
  readonly validationValidatorToggle?: Maybe<ValidatorInformation>;
  readonly validationValidatorUpdate: ValidatorInformation;
  readonly vendorAdd: VendorSaveResult;
  readonly vendorDelete: Scalars['Boolean']['output'];
  readonly vendorGeocode: Vendor;
  readonly vendorUpdate: VendorSaveResult;
  readonly vendorsBulkGeocode: Scalars['Boolean']['output'];
  readonly webStoreAdd?: Maybe<WebStore>;
  readonly webStoreDelete: Scalars['Boolean']['output'];
  readonly webStoreUpdate?: Maybe<WebStore>;
  readonly workOrderUpdate: WorkOrder;
};


export type MutationAddIntegrationEnvironmentArgs = {
  environment: Scalars['String']['input'];
  featureId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
};


export type MutationBankStatementAddArgs = {
  input: BankStatementInput;
};


export type MutationBankStatementDeleteArgs = {
  bankStatementId: Scalars['ID']['input'];
};


export type MutationBankStatementResetArgs = {
  bankStatementId: Scalars['ID']['input'];
  mode?: BankStatementResetMode;
};


export type MutationBankStatementSetTransactionInternalArgs = {
  input: BankStatementSetTransactionInternalInput;
};


export type MutationBankStatementSetTransactionReturnedArgs = {
  input: BankStatementSetTransactionReturnedInput;
};


export type MutationBankStatementUpdateArgs = {
  input: BankStatementUpdateInput;
};


export type MutationBoardAddArgs = {
  input: BoardAddInput;
};


export type MutationBoardsAddArgs = {
  input: ReadonlyArray<BoardAddInput>;
};


export type MutationCompanyAddArgs = {
  connection: PostgresConnectionInfoInput;
  legacyServerId: Scalars['String']['input'];
  names: CompanyNamesInput;
  number: Scalars['Int']['input'];
  publicUrl: Scalars['String']['input'];
  seedData: ReadonlyArray<CompanySeedDataOptionInput>;
};


export type MutationCompanyAddWithBackupArgs = {
  backupName: Scalars['String']['input'];
  companyBackupId?: InputMaybe<Scalars['String']['input']>;
  connection: PostgresConnectionInfoInput;
  legacyServerId: Scalars['String']['input'];
  names: CompanyNamesInput;
  number: Scalars['Int']['input'];
  publicUrl: Scalars['String']['input'];
};


export type MutationCompanyBackupDeleteArgs = {
  backupName: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
};


export type MutationCompanyBackupRestoreArgs = {
  backupName: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
};


export type MutationCompanyBackupRunArgs = {
  companyId: Scalars['String']['input'];
};


export type MutationCompanyBackupTaxFormsRestoreArgs = {
  backupName: Scalars['String']['input'];
  legacyServerId: Scalars['String']['input'];
};


export type MutationCompanyBackupTaxFormsUploadArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationCompanyBackupUploadArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
};


export type MutationCompanyConnectionStringVerifyArgs = {
  database: Scalars['String']['input'];
  password: Scalars['String']['input'];
  port: Scalars['Int']['input'];
  server: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationCompanyConnectionUpdateArgs = {
  companyId: Scalars['String']['input'];
  database: Scalars['String']['input'];
  password: Scalars['String']['input'];
  port: Scalars['Int']['input'];
  server: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationCompanyDatabaseRenameArgs = {
  companyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCompanyDeleteArgs = {
  deleteDatabase: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};


export type MutationCompanyDuplicateArgs = {
  connection: PostgresConnectionInfoInput;
  legacyServerId: Scalars['String']['input'];
  name: CompanyNamesInput;
  number: Scalars['Int']['input'];
  publicUrl: Scalars['String']['input'];
  sourceCompanyId: Scalars['String']['input'];
};


export type MutationCompanyIconUploadArgs = {
  companyId: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
};


export type MutationCompanyImportDatabaseCreateArgs = {
  folderName: Scalars['String']['input'];
  legacyServerId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCompanyImportImportArgs = {
  folderName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCompanyImportMigrateArgs = {
  folderName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCompanyLogoUploadArgs = {
  companyId: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
};


export type MutationCompanyMigrationApplyArgs = {
  companyId: Scalars['String']['input'];
  migration: Scalars['String']['input'];
};


export type MutationCompanyStylesheetPreferenceUpdateArgs = {
  stylesheetId: Scalars['String']['input'];
};


export type MutationCompanyUpdateArgs = {
  input: CompanyUpdateInput;
};


export type MutationCustomerAddArgs = {
  input: CustomerAddInput;
  quoteRecordId?: Scalars['String']['input'];
};


export type MutationCustomerDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationCustomerGeocodeArgs = {
  customerId: Scalars['String']['input'];
};


export type MutationCustomerUpdateArgs = {
  input: CustomerUpdateInput;
};


export type MutationDashboardAddArgs = {
  input: DashboardAddInput;
};


export type MutationDashboardDeleteArgs = {
  dashboardId: Scalars['String']['input'];
};


export type MutationDashboardSetUserDefaultArgs = {
  groupId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationDashboardUpdateArgs = {
  input: DashboardUpdateInput;
};


export type MutationDeviceAddArgs = {
  input: DeviceAddInput;
};


export type MutationDeviceDeleteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeviceUpdateArgs = {
  input: DeviceUpdateInput;
};


export type MutationDispatchingAssignInvoiceToLoadArgs = {
  invoiceId: Scalars['ID']['input'];
  itemId?: InputMaybe<Scalars['String']['input']>;
  loadId?: InputMaybe<Scalars['String']['input']>;
  shipmentDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationDispatchingAssignRentalToLoadArgs = {
  itemId?: InputMaybe<Scalars['String']['input']>;
  loadId?: InputMaybe<Scalars['String']['input']>;
  rentalId: Scalars['String']['input'];
  shipmentDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationDispatchingAssignShipmentToLoadArgs = {
  loadId: Scalars['String']['input'];
  shipmentDate?: InputMaybe<Scalars['DateTime']['input']>;
  shipmentId: Scalars['String']['input'];
};


export type MutationDispatchingAssignWorkOrderToLoadArgs = {
  itemId?: InputMaybe<Scalars['String']['input']>;
  loadId?: InputMaybe<Scalars['String']['input']>;
  shipmentDate?: InputMaybe<Scalars['DateTime']['input']>;
  workOrderId: Scalars['ID']['input'];
};


export type MutationDispatchingDriverDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationDispatchingDriverUpdateArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationDispatchingLoadAssignDriverArgs = {
  driverId: Scalars['String']['input'];
  loadId: Scalars['String']['input'];
};


export type MutationDispatchingLoadAssignTruckArgs = {
  loadId: Scalars['String']['input'];
  truckId: Scalars['String']['input'];
};


export type MutationDispatchingLoadDeleteArgs = {
  loadId: Scalars['String']['input'];
};


export type MutationDispatchingLoadShipmentDateUpdateArgs = {
  date: Scalars['DateTime']['input'];
  loadId: Scalars['String']['input'];
};


export type MutationDispatchingLoadStatusUpdateArgs = {
  loadId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};


export type MutationDispatchingPinColorAddArgs = {
  companyWide: Scalars['Boolean']['input'];
};


export type MutationDispatchingPinColorDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationDispatchingPinColorOrderUpdateArgs = {
  id: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};


export type MutationDispatchingPinColorUpdateArgs = {
  color: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationDispatchingPinConfigurationAddArgs = {
  companyWide: Scalars['Boolean']['input'];
};


export type MutationDispatchingPinConfigurationDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationDispatchingPinConfigurationPriorityUpdateArgs = {
  id: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
};


export type MutationDispatchingPinConfigurationUpdateArgs = {
  colorId?: InputMaybe<Scalars['String']['input']>;
  expression: Scalars['String']['input'];
  iconId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  opacity: Scalars['Decimal']['input'];
  priority: Scalars['Int']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDispatchingPinIconAddArgs = {
  companyWide: Scalars['Boolean']['input'];
};


export type MutationDispatchingPinIconDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationDispatchingPinIconOrderUpdateArgs = {
  id: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};


export type MutationDispatchingPinIconUpdateArgs = {
  icon: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationDispatchingRemoveInvoiceFromLoadArgs = {
  invoiceId: Scalars['ID']['input'];
  shipmentId: Scalars['String']['input'];
};


export type MutationDispatchingRemoveRentalFromLoadArgs = {
  rentalId: Scalars['String']['input'];
  shipmentId: Scalars['String']['input'];
};


export type MutationDispatchingRemoveShipmentFromLoadArgs = {
  shipmentId: Scalars['String']['input'];
};


export type MutationDispatchingRemoveWorkOrderFromLoadArgs = {
  shipmentId: Scalars['String']['input'];
  workOrderId: Scalars['ID']['input'];
};


export type MutationDispatchingShipmentDateUpdateArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['TimeSpan']['input']>;
  shipmentId: Scalars['String']['input'];
};


export type MutationDispatchingTruckDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationDispatchingTruckUpdateArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDocumentGenerationGeneratorConfigUpdateArgs = {
  input: DocumentGeneratorConfigInput;
};


export type MutationDocumentGenerationGeneratorToggleArgs = {
  enabled: Scalars['Boolean']['input'];
  entityId: Scalars['String']['input'];
  generatorId: Scalars['String']['input'];
};


export type MutationEmployeeAddArgs = {
  input: EmployeeAddInput;
};


export type MutationEmployeeDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationEmployeeGeocodeArgs = {
  employeeId: Scalars['String']['input'];
};


export type MutationEmployeeUpdateArgs = {
  input: EmployeeUpdateInput;
};


export type MutationFeatureToggleIntegrationArgs = {
  enabled: Scalars['Boolean']['input'];
  featureId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
};


export type MutationFormTemplateAddArgs = {
  input?: InputMaybe<FormTemplateInput>;
};


export type MutationFormTemplateDuplicateArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationFormTemplateRemoveArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationFormTemplateUpdateArgs = {
  input?: InputMaybe<FormTemplateInput>;
};


export type MutationGlAccountUpdateAccountIdArgs = {
  accountId: Scalars['ID']['input'];
  remoteAccountId: Scalars['String']['input'];
};


export type MutationIntegrationUpdateArgs = {
  config: Scalars['String']['input'];
  environment: Scalars['String']['input'];
  featureId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
};


export type MutationInvoiceUpdateArgs = {
  input: InvoiceUpdateInput;
};


export type MutationItemAddArgs = {
  input: ItemAddInput;
};


export type MutationItemDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationItemScanAddArgs = {
  input: ItemScanAddInput;
};


export type MutationItemScanLotAddArgs = {
  input: ItemScanLotAddInput;
};


export type MutationItemScanSerialAddArgs = {
  input: ItemScanSerialAddInput;
};


export type MutationItemScanUpdateArgs = {
  input: ItemScanUpdateInput;
};


export type MutationItemUpdatePartialArgs = {
  input: ItemUpdatePartialInput;
};


export type MutationItemValidateArgs = {
  input: ItemValidateInput;
};


export type MutationJobAddArgs = {
  input: JobAddInput;
};


export type MutationJobDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationJobUpdateArgs = {
  input: JobUpdateInput;
};


export type MutationLedgerEntryAddArgs = {
  input: LedgerEntryAddInput;
};


export type MutationLedgerEntryDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLedgerEntryUpdateArgs = {
  input: LedgerEntryUpdateInput;
};


export type MutationLedgerEntryValidateArgs = {
  input: LedgerEntryValidateInput;
};


export type MutationLegacyServerAddArgs = {
  input: LegacyServerAddInput;
};


export type MutationLegacyServerApplyUpdatesArgs = {
  serverId: Scalars['String']['input'];
};


export type MutationLegacyServerAuthorizeArgs = {
  serverId: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};


export type MutationLegacyServerDeleteArgs = {
  input: LegacyServerDeleteInput;
};


export type MutationLegacyServerFileUploadArgs = {
  directory: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
};


export type MutationLegacyServerStartArgs = {
  serverId: Scalars['String']['input'];
};


export type MutationLegacyServerStopArgs = {
  serverId: Scalars['String']['input'];
};


export type MutationLegacyServerUpdateArgs = {
  input: LegacyServerUpdateInput;
};


export type MutationLicenseKeyCompanyAssignmentUpdateArgs = {
  companies: ReadonlyArray<LicenseKeyCompanyMapInput>;
};


export type MutationLicenseKeysAddArgs = {
  licenseKeys: ReadonlyArray<Scalars['String']['input']>;
};


export type MutationLogInArgs = {
  password: Scalars['String']['input'];
  rememberMe?: Scalars['Boolean']['input'];
  username: Scalars['String']['input'];
};


export type MutationLogInVerifyAuthAppCodeArgs = {
  input: LogInVerifyAuthAppCodeInput;
};


export type MutationLoginForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginResetPasswordArgs = {
  input: LoginResetPasswordInput;
};


export type MutationNameAddArgs = {
  input: NameAddInput;
};


export type MutationNameDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationNameGeocodeArgs = {
  nameId: Scalars['String']['input'];
};


export type MutationNameUpdateArgs = {
  input: NameUpdateInput;
};


export type MutationOpenIdApplicationAddArgs = {
  clientId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  grants: ReadonlyArray<OpenIdApplicationGrantInput>;
  scopes: ReadonlyArray<Scalars['String']['input']>;
};


export type MutationOpenIdApplicationDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationOpenIdApplicationTokenRevokeArgs = {
  appId: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
};


export type MutationOpenIdApplicationUpdateArgs = {
  clientId: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  grants: ReadonlyArray<OpenIdApplicationGrantInput>;
  id: Scalars['String']['input'];
  scopes: ReadonlyArray<Scalars['String']['input']>;
};


export type MutationPreviousVersionImportCompanyArgs = {
  companyId: Scalars['String']['input'];
};


export type MutationPreviousVersionImportLegacyServerArgs = {
  serverId: Scalars['String']['input'];
};


export type MutationPrintChannelAddArgs = {
  input?: InputMaybe<PrintChannelAddInput>;
};


export type MutationPrintChannelDeleteArgs = {
  id: Scalars['Int']['input'];
};


export type MutationPrintChannelUpdateArgs = {
  input?: InputMaybe<PrintChannelUpdateInput>;
};


export type MutationProcessPublicTokenArgs = {
  accountId: Scalars['ID']['input'];
  publicToken: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  email: Scalars['String']['input'];
  login?: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRemoveIntegrationEnvironmentArgs = {
  environment: Scalars['String']['input'];
  featureId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
};


export type MutationRenameIntegrationEnvironmentArgs = {
  environment: Scalars['String']['input'];
  featureId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationReportCategoryAddArgs = {
  isCustom: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  parentPolicyId: Scalars['String']['input'];
};


export type MutationReportCategoryDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationReportCategoryUpdateArgs = {
  id: Scalars['String']['input'];
  isCustom: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  parentPolicyId: Scalars['String']['input'];
};


export type MutationReportDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationReportStylesheetDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationReportStylesheetUploadArgs = {
  file: Scalars['Upload']['input'];
  isCustom: Scalars['Boolean']['input'];
};


export type MutationReportUpdateArgs = {
  id: Scalars['String']['input'];
  isCustom: Scalars['Boolean']['input'];
  report: ReportInput;
};


export type MutationReportUploadArgs = {
  file: Scalars['Upload']['input'];
  isCustom: Scalars['Boolean']['input'];
};


export type MutationSalesOrderAddArgs = {
  input: SalesOrderAddInput;
};


export type MutationSalesOrderDeleteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationSalesOrderUpdateArgs = {
  input: SalesOrderUpdateInput;
};


export type MutationSalesVtermIframeCompletedArgs = {
  input: SalesVtermIframeCompletedInput;
};


export type MutationSalesVtermIframeUrlArgs = {
  input: SalesVtermIframeUrlInput;
};


export type MutationSalespersonAddArgs = {
  input: SalespersonAddInput;
};


export type MutationSalespersonDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationSalespersonUpdateArgs = {
  input: SalespersonUpdateInput;
};


export type MutationSalespersonValidateArgs = {
  input: SalespersonValidateInput;
};


export type MutationSearchIndexCheckQueueArgs = {
  companyId: Scalars['String']['input'];
};


export type MutationSearchIndexClearArgs = {
  companyId: Scalars['String']['input'];
};


export type MutationSearchIndexFieldsUpdateArgs = {
  input: SearchIndexFieldsUpdateInput;
};


export type MutationSmsSendArgs = {
  fromName?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  toName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSwitchIntegrationEnvironmentArgs = {
  environment: Scalars['String']['input'];
  featureId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
};


export type MutationSystemBackupDeleteArgs = {
  backupName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSystemBackupRestoreArgs = {
  backupName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSystemBackupUploadArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationSystemConfigCodeGenerationSaveArgs = {
  input: CodeGenerationConfigInput;
};


export type MutationUpdateNLogConfigArgs = {
  config?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserDeleteArgs = {
  userId: Scalars['String']['input'];
};


export type MutationUserEmailConfirmArgs = {
  email: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUserGroupAddArgs = {
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};


export type MutationUserGroupRemoveArgs = {
  id: Scalars['String']['input'];
};


export type MutationUserGroupUpdateArgs = {
  id: Scalars['String']['input'];
  userGroup: UserGroupInput;
};


export type MutationUserLockoutResetArgs = {
  userId: Scalars['String']['input'];
};


export type MutationUserPasswordResetArgs = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUserPoliciesUpdateCompanyArgs = {
  isSysAdmin: Scalars['Boolean']['input'];
  policies: ReadonlyArray<CompanyAuthorizationPoliciesInput>;
  userId: Scalars['String']['input'];
};


export type MutationUserPreferenceThemeUpdateArgs = {
  input: ThemePreferenceInput;
};


export type MutationUserProfileEmailConfirmArgs = {
  code: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUserProfileMfaVerifyAppTokenArgs = {
  code: Scalars['String']['input'];
};


export type MutationUserProfilePasswordUpdateArgs = {
  input: UserProfilePasswordUpdateInput;
};


export type MutationUserProfileUpdateArgs = {
  input: UserProfileUpdateInput;
};


export type MutationUserSetSystemAdminArgs = {
  systemAdmin: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};


export type MutationValidationValidatorRemoveArgs = {
  id: Scalars['String']['input'];
};


export type MutationValidationValidatorToggleArgs = {
  enabled: Scalars['Boolean']['input'];
  entityId: Scalars['String']['input'];
  validatorId: Scalars['String']['input'];
};


export type MutationValidationValidatorUpdateArgs = {
  input: ValidatorConfigInput;
};


export type MutationVendorAddArgs = {
  input: VendorAddInput;
};


export type MutationVendorDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationVendorGeocodeArgs = {
  vendorId: Scalars['String']['input'];
};


export type MutationVendorUpdateArgs = {
  input: VendorUpdateInput;
};


export type MutationWebStoreAddArgs = {
  input: WebStoreAddInput;
};


export type MutationWebStoreDeleteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationWebStoreUpdateArgs = {
  input: WebStoreUpdateInput;
};


export type MutationWorkOrderUpdateArgs = {
  input: WorkOrderUpdateInput;
};

export type Name = {
  readonly __typename: 'Name';
  readonly attention?: Maybe<Scalars['String']['output']>;
  readonly billCode?: Maybe<Scalars['String']['output']>;
  readonly billTo0CustomerId?: Maybe<Scalars['String']['output']>;
  readonly billTo1CustomerId?: Maybe<Scalars['String']['output']>;
  readonly birthDate?: Maybe<Scalars['DateTime']['output']>;
  readonly comment?: Maybe<Scalars['String']['output']>;
  readonly contactPerson?: Maybe<Scalars['String']['output']>;
  readonly deathDate?: Maybe<Scalars['DateTime']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly expireDate?: Maybe<Scalars['DateTime']['output']>;
  readonly fatherNameId?: Maybe<Scalars['String']['output']>;
  readonly fax?: Maybe<Scalars['String']['output']>;
  readonly fedId?: Maybe<Scalars['String']['output']>;
  readonly firstInquiryDate?: Maybe<Scalars['DateTime']['output']>;
  readonly fullName: Scalars['String']['output'];
  readonly gpsLatitude: Scalars['Decimal']['output'];
  readonly gpsLongitude: Scalars['Decimal']['output'];
  readonly greetingName?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly lastInquiryDate?: Maybe<Scalars['DateTime']['output']>;
  readonly locations: ReadonlyArray<Location>;
  readonly messageImprintNumber?: Maybe<Scalars['String']['output']>;
  readonly motherNameId?: Maybe<Scalars['String']['output']>;
  readonly multiCodeY?: Maybe<Scalars['String']['output']>;
  readonly multiCodeZ?: Maybe<Scalars['String']['output']>;
  readonly namePosted?: Maybe<NamePosted>;
  readonly oldId?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly printCode?: Maybe<Scalars['String']['output']>;
  readonly reviewDate?: Maybe<Scalars['DateTime']['output']>;
  readonly salesTaxExpire?: Maybe<Scalars['DateTime']['output']>;
  readonly secondaryAddress?: Maybe<Scalars['String']['output']>;
  readonly sex?: Maybe<Scalars['String']['output']>;
  readonly shipResidentialCommercial?: Maybe<ResidentialCommercial>;
  readonly spare0?: Maybe<Scalars['String']['output']>;
  readonly spare1?: Maybe<Scalars['String']['output']>;
  readonly spare2?: Maybe<Scalars['String']['output']>;
  readonly spare3?: Maybe<Scalars['String']['output']>;
  readonly spare4?: Maybe<Scalars['String']['output']>;
  readonly spare5?: Maybe<Scalars['String']['output']>;
  readonly spare6?: Maybe<Scalars['String']['output']>;
  readonly spare7?: Maybe<Scalars['String']['output']>;
  readonly spare8?: Maybe<Scalars['String']['output']>;
  readonly spare9?: Maybe<Scalars['String']['output']>;
  readonly startDate?: Maybe<Scalars['DateTime']['output']>;
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly subscriptionCode?: Maybe<Scalars['String']['output']>;
  readonly taxNumber?: Maybe<Scalars['String']['output']>;
  readonly taxStatus?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly zipCode?: Maybe<ZipCode>;
  readonly zipCodeId?: Maybe<Scalars['String']['output']>;
  readonly zipExt?: Maybe<Scalars['String']['output']>;
};

export type NameAddInput = {
  readonly attention?: InputMaybe<Scalars['String']['input']>;
  readonly billCode?: InputMaybe<Scalars['String']['input']>;
  readonly billTo0CustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly billTo1CustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly contactPerson?: InputMaybe<Scalars['String']['input']>;
  readonly deathDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly fatherNameId?: InputMaybe<Scalars['String']['input']>;
  readonly fax?: InputMaybe<Scalars['String']['input']>;
  readonly fedId?: InputMaybe<Scalars['String']['input']>;
  readonly fullName: Scalars['String']['input'];
  readonly greetingName?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly messageImprintNumber?: InputMaybe<Scalars['String']['input']>;
  readonly motherNameId?: InputMaybe<Scalars['String']['input']>;
  readonly multiCodeY?: InputMaybe<Scalars['String']['input']>;
  readonly multiCodeZ?: InputMaybe<Scalars['String']['input']>;
  readonly oldId?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly salesTaxExpire?: InputMaybe<Scalars['DateTime']['input']>;
  readonly secondaryAddress?: InputMaybe<Scalars['String']['input']>;
  readonly sex?: InputMaybe<Scalars['String']['input']>;
  readonly shipResidentialCommercial?: InputMaybe<ResidentialCommercial>;
  readonly spare0?: InputMaybe<Scalars['String']['input']>;
  readonly spare1?: InputMaybe<Scalars['String']['input']>;
  readonly spare2?: InputMaybe<Scalars['String']['input']>;
  readonly spare3?: InputMaybe<Scalars['String']['input']>;
  readonly spare4?: InputMaybe<Scalars['String']['input']>;
  readonly spare5?: InputMaybe<Scalars['String']['input']>;
  readonly spare6?: InputMaybe<Scalars['String']['input']>;
  readonly spare7?: InputMaybe<Scalars['String']['input']>;
  readonly spare8?: InputMaybe<Scalars['String']['input']>;
  readonly spare9?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly subscriptionCode?: InputMaybe<Scalars['String']['input']>;
  readonly taxNumber?: InputMaybe<Scalars['String']['input']>;
  readonly taxStatus?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  /** When populated with a new zip code, it will be created. Existing zip codes will not be modified. */
  readonly zipCode?: InputMaybe<ZipCodeInput>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
};

export type NameFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<NameFilterInput>>;
  readonly attention?: InputMaybe<StringOperationFilterInput>;
  readonly billCode?: InputMaybe<StringOperationFilterInput>;
  readonly billTo0CustomerId?: InputMaybe<StringOperationFilterInput>;
  readonly billTo1CustomerId?: InputMaybe<StringOperationFilterInput>;
  readonly birthDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly comment?: InputMaybe<StringOperationFilterInput>;
  readonly contactPerson?: InputMaybe<StringOperationFilterInput>;
  readonly deathDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly email?: InputMaybe<StringOperationFilterInput>;
  readonly expireDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly fatherNameId?: InputMaybe<StringOperationFilterInput>;
  readonly fax?: InputMaybe<StringOperationFilterInput>;
  readonly fedId?: InputMaybe<StringOperationFilterInput>;
  readonly firstInquiryDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly fullName?: InputMaybe<StringOperationFilterInput>;
  readonly gpsLatitude?: InputMaybe<DecimalOperationFilterInput>;
  readonly gpsLongitude?: InputMaybe<DecimalOperationFilterInput>;
  readonly greetingName?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly lastInquiryDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly locations?: InputMaybe<ListFilterInputTypeOfLocationFilterInput>;
  readonly messageImprintNumber?: InputMaybe<StringOperationFilterInput>;
  readonly motherNameId?: InputMaybe<StringOperationFilterInput>;
  readonly multiCodeY?: InputMaybe<StringOperationFilterInput>;
  readonly multiCodeZ?: InputMaybe<StringOperationFilterInput>;
  readonly oldId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<NameFilterInput>>;
  readonly phone?: InputMaybe<StringOperationFilterInput>;
  readonly printCode?: InputMaybe<StringOperationFilterInput>;
  readonly reviewDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly salesTaxExpire?: InputMaybe<DateTimeOperationFilterInput>;
  readonly secondaryAddress?: InputMaybe<StringOperationFilterInput>;
  readonly sex?: InputMaybe<StringOperationFilterInput>;
  readonly shipResidentialCommercial?: InputMaybe<NullableOfResidentialCommercialOperationFilterInput>;
  readonly spare0?: InputMaybe<StringOperationFilterInput>;
  readonly spare1?: InputMaybe<StringOperationFilterInput>;
  readonly spare2?: InputMaybe<StringOperationFilterInput>;
  readonly spare3?: InputMaybe<StringOperationFilterInput>;
  readonly spare4?: InputMaybe<StringOperationFilterInput>;
  readonly spare5?: InputMaybe<StringOperationFilterInput>;
  readonly spare6?: InputMaybe<StringOperationFilterInput>;
  readonly spare7?: InputMaybe<StringOperationFilterInput>;
  readonly spare8?: InputMaybe<StringOperationFilterInput>;
  readonly spare9?: InputMaybe<StringOperationFilterInput>;
  readonly startDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly streetAddress?: InputMaybe<StringOperationFilterInput>;
  readonly subscriptionCode?: InputMaybe<StringOperationFilterInput>;
  readonly taxNumber?: InputMaybe<StringOperationFilterInput>;
  readonly taxStatus?: InputMaybe<StringOperationFilterInput>;
  readonly title?: InputMaybe<StringOperationFilterInput>;
  readonly zipCode?: InputMaybe<ZipCodeFilterInput>;
  readonly zipCodeId?: InputMaybe<StringOperationFilterInput>;
  readonly zipExt?: InputMaybe<StringOperationFilterInput>;
};

export type NamePosted = {
  readonly __typename: 'NamePosted';
  readonly childrenPtr?: Maybe<Scalars['String']['output']>;
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly filePtr1099?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly invoicePtr?: Maybe<Scalars['String']['output']>;
  readonly noteFilePtr?: Maybe<Scalars['String']['output']>;
  readonly paymentPtr?: Maybe<Scalars['String']['output']>;
  readonly recieverPtr?: Maybe<Scalars['String']['output']>;
  readonly spouseNameId?: Maybe<Scalars['String']['output']>;
  readonly telephoneLogPtr?: Maybe<Scalars['String']['output']>;
  readonly weddingFilePtr?: Maybe<Scalars['String']['output']>;
};

export type NameSaveResult = {
  readonly __typename: 'NameSaveResult';
  readonly data?: Maybe<Name>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

/** A connection to a list of items. */
export type NameSearchConnection = {
  readonly __typename: 'NameSearchConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<NameSearchEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Name>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NameSearchEdge = {
  readonly __typename: 'NameSearchEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Name;
};

export type NameSortInput = {
  readonly attention?: InputMaybe<SortEnumType>;
  readonly billCode?: InputMaybe<SortEnumType>;
  readonly billTo0CustomerId?: InputMaybe<SortEnumType>;
  readonly billTo1CustomerId?: InputMaybe<SortEnumType>;
  readonly birthDate?: InputMaybe<SortEnumType>;
  readonly comment?: InputMaybe<SortEnumType>;
  readonly contactPerson?: InputMaybe<SortEnumType>;
  readonly deathDate?: InputMaybe<SortEnumType>;
  readonly email?: InputMaybe<SortEnumType>;
  readonly expireDate?: InputMaybe<SortEnumType>;
  readonly fatherNameId?: InputMaybe<SortEnumType>;
  readonly fax?: InputMaybe<SortEnumType>;
  readonly fedId?: InputMaybe<SortEnumType>;
  readonly firstInquiryDate?: InputMaybe<SortEnumType>;
  readonly fullName?: InputMaybe<SortEnumType>;
  readonly gpsLatitude?: InputMaybe<SortEnumType>;
  readonly gpsLongitude?: InputMaybe<SortEnumType>;
  readonly greetingName?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly lastInquiryDate?: InputMaybe<SortEnumType>;
  readonly messageImprintNumber?: InputMaybe<SortEnumType>;
  readonly motherNameId?: InputMaybe<SortEnumType>;
  readonly multiCodeY?: InputMaybe<SortEnumType>;
  readonly multiCodeZ?: InputMaybe<SortEnumType>;
  readonly oldId?: InputMaybe<SortEnumType>;
  readonly phone?: InputMaybe<SortEnumType>;
  readonly printCode?: InputMaybe<SortEnumType>;
  readonly reviewDate?: InputMaybe<SortEnumType>;
  readonly salesTaxExpire?: InputMaybe<SortEnumType>;
  readonly secondaryAddress?: InputMaybe<SortEnumType>;
  readonly sex?: InputMaybe<SortEnumType>;
  readonly shipResidentialCommercial?: InputMaybe<SortEnumType>;
  readonly spare0?: InputMaybe<SortEnumType>;
  readonly spare1?: InputMaybe<SortEnumType>;
  readonly spare2?: InputMaybe<SortEnumType>;
  readonly spare3?: InputMaybe<SortEnumType>;
  readonly spare4?: InputMaybe<SortEnumType>;
  readonly spare5?: InputMaybe<SortEnumType>;
  readonly spare6?: InputMaybe<SortEnumType>;
  readonly spare7?: InputMaybe<SortEnumType>;
  readonly spare8?: InputMaybe<SortEnumType>;
  readonly spare9?: InputMaybe<SortEnumType>;
  readonly startDate?: InputMaybe<SortEnumType>;
  readonly streetAddress?: InputMaybe<SortEnumType>;
  readonly subscriptionCode?: InputMaybe<SortEnumType>;
  readonly taxNumber?: InputMaybe<SortEnumType>;
  readonly taxStatus?: InputMaybe<SortEnumType>;
  readonly title?: InputMaybe<SortEnumType>;
  readonly zipCode?: InputMaybe<ZipCodeSortInput>;
  readonly zipCodeId?: InputMaybe<SortEnumType>;
  readonly zipExt?: InputMaybe<SortEnumType>;
};

export type NameUpdateInput = {
  readonly attention?: InputMaybe<Scalars['String']['input']>;
  readonly billCode?: InputMaybe<Scalars['String']['input']>;
  readonly billTo0CustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly billTo1CustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly contactPerson?: InputMaybe<Scalars['String']['input']>;
  readonly deathDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly fatherNameId?: InputMaybe<Scalars['String']['input']>;
  readonly fax?: InputMaybe<Scalars['String']['input']>;
  readonly fedId?: InputMaybe<Scalars['String']['input']>;
  readonly fullName: Scalars['String']['input'];
  readonly greetingName?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly messageImprintNumber?: InputMaybe<Scalars['String']['input']>;
  readonly motherNameId?: InputMaybe<Scalars['String']['input']>;
  readonly multiCodeY?: InputMaybe<Scalars['String']['input']>;
  readonly multiCodeZ?: InputMaybe<Scalars['String']['input']>;
  readonly oldId?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly salesTaxExpire?: InputMaybe<Scalars['DateTime']['input']>;
  readonly secondaryAddress?: InputMaybe<Scalars['String']['input']>;
  readonly sex?: InputMaybe<Scalars['String']['input']>;
  readonly shipResidentialCommercial?: InputMaybe<ResidentialCommercial>;
  readonly spare0?: InputMaybe<Scalars['String']['input']>;
  readonly spare1?: InputMaybe<Scalars['String']['input']>;
  readonly spare2?: InputMaybe<Scalars['String']['input']>;
  readonly spare3?: InputMaybe<Scalars['String']['input']>;
  readonly spare4?: InputMaybe<Scalars['String']['input']>;
  readonly spare5?: InputMaybe<Scalars['String']['input']>;
  readonly spare6?: InputMaybe<Scalars['String']['input']>;
  readonly spare7?: InputMaybe<Scalars['String']['input']>;
  readonly spare8?: InputMaybe<Scalars['String']['input']>;
  readonly spare9?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly subscriptionCode?: InputMaybe<Scalars['String']['input']>;
  readonly taxNumber?: InputMaybe<Scalars['String']['input']>;
  readonly taxStatus?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  /** When populated with a new zip code, it will be created. Existing zip codes will not be modified. */
  readonly zipCode?: InputMaybe<ZipCodeInput>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
};

export type NameValidateInput = {
  readonly attention?: InputMaybe<Scalars['String']['input']>;
  readonly billCode?: InputMaybe<Scalars['String']['input']>;
  readonly billTo0CustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly billTo1CustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly contactPerson?: InputMaybe<Scalars['String']['input']>;
  readonly deathDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly expireDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly fatherNameId?: InputMaybe<Scalars['String']['input']>;
  readonly fax?: InputMaybe<Scalars['String']['input']>;
  readonly fedId?: InputMaybe<Scalars['String']['input']>;
  readonly firstInquiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly fullName?: InputMaybe<Scalars['String']['input']>;
  readonly gpsLatitude?: InputMaybe<Scalars['Decimal']['input']>;
  readonly gpsLongitude?: InputMaybe<Scalars['Decimal']['input']>;
  readonly greetingName?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly lastInquiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly messageImprintNumber?: InputMaybe<Scalars['String']['input']>;
  readonly motherNameId?: InputMaybe<Scalars['String']['input']>;
  readonly multiCodeY?: InputMaybe<Scalars['String']['input']>;
  readonly multiCodeZ?: InputMaybe<Scalars['String']['input']>;
  readonly oldId?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly reviewDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly salesTaxExpire?: InputMaybe<Scalars['DateTime']['input']>;
  readonly secondaryAddress?: InputMaybe<Scalars['String']['input']>;
  readonly sex?: InputMaybe<Scalars['String']['input']>;
  readonly shipResidentialCommercial?: InputMaybe<ResidentialCommercial>;
  readonly spare0?: InputMaybe<Scalars['String']['input']>;
  readonly spare1?: InputMaybe<Scalars['String']['input']>;
  readonly spare2?: InputMaybe<Scalars['String']['input']>;
  readonly spare3?: InputMaybe<Scalars['String']['input']>;
  readonly spare4?: InputMaybe<Scalars['String']['input']>;
  readonly spare5?: InputMaybe<Scalars['String']['input']>;
  readonly spare6?: InputMaybe<Scalars['String']['input']>;
  readonly spare7?: InputMaybe<Scalars['String']['input']>;
  readonly spare8?: InputMaybe<Scalars['String']['input']>;
  readonly spare9?: InputMaybe<Scalars['String']['input']>;
  readonly startDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly subscriptionCode?: InputMaybe<Scalars['String']['input']>;
  readonly taxNumber?: InputMaybe<Scalars['String']['input']>;
  readonly taxStatus?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly zipCode?: InputMaybe<ZipCodeValidateInput>;
  readonly zipCodeId?: InputMaybe<Scalars['String']['input']>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type NamesConnection = {
  readonly __typename: 'NamesConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<NamesEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Name>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NamesEdge = {
  readonly __typename: 'NamesEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Name;
};

/** A connection to a list of items. */
export type NamesListConnection = {
  readonly __typename: 'NamesListConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<NamesListEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Name>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type NamesListEdge = {
  readonly __typename: 'NamesListEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Name;
};

export type NpgsqlRangeOfDateTimeFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<NpgsqlRangeOfDateTimeFilterInput>>;
  readonly end?: InputMaybe<DateTimeOperationFilterInput>;
  readonly noEnd?: InputMaybe<BooleanOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<NpgsqlRangeOfDateTimeFilterInput>>;
  readonly start?: InputMaybe<DateTimeOperationFilterInput>;
};

export type NtsGeometryServicesFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<NtsGeometryServicesFilterInput>>;
  readonly coordinateEqualityComparer?: InputMaybe<CoordinateEqualityComparerFilterInput>;
  readonly defaultCoordinateSequenceFactory?: InputMaybe<CoordinateSequenceFactoryFilterInput>;
  readonly defaultPrecisionModel?: InputMaybe<PrecisionModelFilterInput>;
  readonly defaultSRID?: InputMaybe<IntOperationFilterInput>;
  readonly geometryOverlay?: InputMaybe<GeometryOverlayFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<NtsGeometryServicesFilterInput>>;
};

export type NullableOfJobFixedOvertimeOperationFilterInput = {
  readonly eq?: InputMaybe<JobFixedOvertime>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<JobFixedOvertime>>>;
  readonly neq?: InputMaybe<JobFixedOvertime>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<JobFixedOvertime>>>;
};

export type NullableOfRentalMaintenancePriorityOperationFilterInput = {
  readonly eq?: InputMaybe<RentalMaintenancePriority>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<RentalMaintenancePriority>>>;
  readonly neq?: InputMaybe<RentalMaintenancePriority>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<RentalMaintenancePriority>>>;
};

export type NullableOfRentalServiceStatusOperationFilterInput = {
  readonly eq?: InputMaybe<RentalServiceStatus>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<RentalServiceStatus>>>;
  readonly neq?: InputMaybe<RentalServiceStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<RentalServiceStatus>>>;
};

export type NullableOfRentalTypeOperationFilterInput = {
  readonly eq?: InputMaybe<RentalType>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<RentalType>>>;
  readonly neq?: InputMaybe<RentalType>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<RentalType>>>;
};

export type NullableOfResidentialCommercialOperationFilterInput = {
  readonly eq?: InputMaybe<ResidentialCommercial>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<ResidentialCommercial>>>;
  readonly neq?: InputMaybe<ResidentialCommercial>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<ResidentialCommercial>>>;
};

export type NullableOfSerializationModesOperationFilterInput = {
  readonly eq?: InputMaybe<ItemSerializationModes>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<ItemSerializationModes>>>;
  readonly neq?: InputMaybe<ItemSerializationModes>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<ItemSerializationModes>>>;
};

export type NullableOfTaxableStatusOperationFilterInput = {
  readonly eq?: InputMaybe<TaxableStatus>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<TaxableStatus>>>;
  readonly neq?: InputMaybe<TaxableStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<TaxableStatus>>>;
};

export type NullableOfVendorDeletePoOperationFilterInput = {
  readonly eq?: InputMaybe<VendorDeletePo>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<VendorDeletePo>>>;
  readonly neq?: InputMaybe<VendorDeletePo>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<VendorDeletePo>>>;
};

export enum OgcGeometryType {
  CircularString = 'CIRCULAR_STRING',
  CompoundCurve = 'COMPOUND_CURVE',
  Curve = 'CURVE',
  CurvePolygon = 'CURVE_POLYGON',
  GeometryCollection = 'GEOMETRY_COLLECTION',
  LineString = 'LINE_STRING',
  MultiCurve = 'MULTI_CURVE',
  MultiLineString = 'MULTI_LINE_STRING',
  MultiPoint = 'MULTI_POINT',
  MultiPolygon = 'MULTI_POLYGON',
  MultiSurface = 'MULTI_SURFACE',
  Point = 'POINT',
  Polygon = 'POLYGON',
  PolyhedralSurface = 'POLYHEDRAL_SURFACE',
  Surface = 'SURFACE',
  Tin = 'TIN'
}

export type OgcGeometryTypeOperationFilterInput = {
  readonly eq?: InputMaybe<OgcGeometryType>;
  readonly in?: InputMaybe<ReadonlyArray<OgcGeometryType>>;
  readonly neq?: InputMaybe<OgcGeometryType>;
  readonly nin?: InputMaybe<ReadonlyArray<OgcGeometryType>>;
};

export type OpenIdApplication = {
  readonly __typename: 'OpenIdApplication';
  readonly clientId?: Maybe<Scalars['String']['output']>;
  readonly consentType?: Maybe<Scalars['String']['output']>;
  readonly displayName?: Maybe<Scalars['String']['output']>;
  readonly displayNames?: Maybe<Scalars['String']['output']>;
  readonly grants: ReadonlyArray<OpenIdApplicationGrant>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly postLogoutRedirectUris?: Maybe<Scalars['String']['output']>;
  readonly redirectUris?: Maybe<Scalars['String']['output']>;
  readonly requirements?: Maybe<Scalars['String']['output']>;
  readonly scopes: ReadonlyArray<OpenIdScope>;
  readonly tokens: ReadonlyArray<OpenIdToken>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

export type OpenIdApplicationAddResult = {
  readonly __typename: 'OpenIdApplicationAddResult';
  readonly clientId: Scalars['String']['output'];
  readonly clientSecret: Scalars['String']['output'];
  readonly companyId: Scalars['String']['output'];
  readonly displayName: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly permissions: ReadonlyArray<Scalars['String']['output']>;
};

export type OpenIdApplicationGrant = {
  readonly __typename: 'OpenIdApplicationGrant';
  readonly id: Scalars['String']['output'];
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type OpenIdApplicationGrantInput = {
  readonly id: Scalars['String']['input'];
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

export type OpenIdAuthorization = {
  readonly __typename: 'OpenIdAuthorization';
  readonly application?: Maybe<OpenIdApplication>;
  readonly creationDate?: Maybe<Scalars['DateTime']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly properties?: Maybe<Scalars['String']['output']>;
  readonly scopes?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly subject?: Maybe<Scalars['String']['output']>;
  readonly tokens: ReadonlyArray<OpenIdToken>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

export type OpenIdScope = {
  readonly __typename: 'OpenIdScope';
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly requiredGrants: ReadonlyArray<Scalars['String']['output']>;
};

export type OpenIdToken = {
  readonly __typename: 'OpenIdToken';
  readonly application?: Maybe<OpenIdApplication>;
  readonly authorization?: Maybe<OpenIdAuthorization>;
  readonly creationDate?: Maybe<Scalars['DateTime']['output']>;
  readonly expirationDate?: Maybe<Scalars['DateTime']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly subject?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

export enum Ordinates {
  AllMeasureOrdinates = 'ALL_MEASURE_ORDINATES',
  AllOrdinates = 'ALL_ORDINATES',
  AllSpatialOrdinates = 'ALL_SPATIAL_ORDINATES',
  M = 'M',
  Measure2 = 'MEASURE2',
  Measure3 = 'MEASURE3',
  Measure4 = 'MEASURE4',
  Measure5 = 'MEASURE5',
  Measure6 = 'MEASURE6',
  Measure7 = 'MEASURE7',
  Measure8 = 'MEASURE8',
  Measure9 = 'MEASURE9',
  Measure10 = 'MEASURE10',
  Measure11 = 'MEASURE11',
  Measure12 = 'MEASURE12',
  Measure13 = 'MEASURE13',
  Measure14 = 'MEASURE14',
  Measure15 = 'MEASURE15',
  Measure16 = 'MEASURE16',
  None = 'NONE',
  Spatial1 = 'SPATIAL1',
  Spatial2 = 'SPATIAL2',
  Spatial4 = 'SPATIAL4',
  Spatial5 = 'SPATIAL5',
  Spatial6 = 'SPATIAL6',
  Spatial7 = 'SPATIAL7',
  Spatial8 = 'SPATIAL8',
  Spatial9 = 'SPATIAL9',
  Spatial10 = 'SPATIAL10',
  Spatial11 = 'SPATIAL11',
  Spatial12 = 'SPATIAL12',
  Spatial13 = 'SPATIAL13',
  Spatial14 = 'SPATIAL14',
  Spatial15 = 'SPATIAL15',
  Spatial16 = 'SPATIAL16',
  Xy = 'XY',
  Xym = 'XYM',
  Xyz = 'XYZ',
  Xyzm = 'XYZM',
  Z = 'Z'
}

export type OrdinatesOperationFilterInput = {
  readonly eq?: InputMaybe<Ordinates>;
  readonly in?: InputMaybe<ReadonlyArray<Ordinates>>;
  readonly neq?: InputMaybe<Ordinates>;
  readonly nin?: InputMaybe<ReadonlyArray<Ordinates>>;
};

export type OutstandingTransactionReportItem = {
  readonly __typename: 'OutstandingTransactionReportItem';
  readonly adjustedBankBalance: Scalars['Decimal']['output'];
  readonly bankGlAccountId: Scalars['ID']['output'];
  readonly computerBalance: Scalars['Decimal']['output'];
  readonly contactId?: Maybe<Scalars['String']['output']>;
  readonly contactName?: Maybe<Scalars['String']['output']>;
  readonly credit?: Maybe<Scalars['Decimal']['output']>;
  readonly credits: Scalars['Decimal']['output'];
  readonly creditsInternal?: Maybe<Scalars['Decimal']['output']>;
  readonly creditsOutstanding?: Maybe<Scalars['Decimal']['output']>;
  readonly creditsReturned?: Maybe<Scalars['Decimal']['output']>;
  readonly creditsReturnedFuture?: Maybe<Scalars['Decimal']['output']>;
  readonly date: Scalars['DateTime']['output'];
  readonly debit?: Maybe<Scalars['Decimal']['output']>;
  readonly debits: Scalars['Decimal']['output'];
  readonly debitsInternal?: Maybe<Scalars['Decimal']['output']>;
  readonly debitsOutstanding?: Maybe<Scalars['Decimal']['output']>;
  readonly debitsReturned?: Maybe<Scalars['Decimal']['output']>;
  readonly debitsReturnedFuture?: Maybe<Scalars['Decimal']['output']>;
  readonly difference: Scalars['Decimal']['output'];
  readonly endBalance: Scalars['Decimal']['output'];
  readonly id: Scalars['Decimal']['output'];
  readonly isInternal: Scalars['Boolean']['output'];
  readonly isOutstanding: Scalars['Boolean']['output'];
  readonly isReturnedFuture: Scalars['Boolean']['output'];
  readonly ledger: Scalars['Int']['output'];
  readonly ledgerType?: Maybe<Scalars['String']['output']>;
  readonly referenceNumber?: Maybe<Scalars['String']['output']>;
  readonly startBalance: Scalars['Decimal']['output'];
  readonly statementDate: Scalars['DateTime']['output'];
  readonly statementId: Scalars['ID']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  readonly __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  readonly hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

export type ParentalPolicies = {
  readonly __typename: 'ParentalPolicies';
  readonly availableOn: ReadonlyArray<Scalars['String']['output']>;
  readonly childPolicies?: Maybe<ReadonlyArray<Policy>>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly parentId?: Maybe<Scalars['String']['output']>;
  readonly reportCategories: ReadonlyArray<PolicyReportCategory>;
  readonly type: PolicyType;
};

export type Payment = {
  readonly __typename: 'Payment';
  readonly amount: Scalars['Decimal']['output'];
  readonly amountNotApplied: Scalars['Decimal']['output'];
  readonly amountTendered: Scalars['Decimal']['output'];
  readonly customer: Customer;
  readonly customerId?: Maybe<Scalars['String']['output']>;
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly details: ReadonlyArray<DepositDetail>;
  readonly glAccountId: Scalars['ID']['output'];
  readonly glAmount: Scalars['Decimal']['output'];
  readonly id: Scalars['ID']['output'];
  readonly isBatchCreditCards: Scalars['Boolean']['output'];
  readonly isDepositTotal: Scalars['Boolean']['output'];
  readonly ledgerId: Scalars['ID']['output'];
  readonly location?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly operator?: Maybe<Scalars['String']['output']>;
  readonly payTypeId?: Maybe<Scalars['String']['output']>;
  readonly remark?: Maybe<Scalars['String']['output']>;
  readonly tenderedDetails?: Maybe<Scalars['String']['output']>;
  readonly totalDiscount: Scalars['Decimal']['output'];
  readonly transactionId?: Maybe<Scalars['String']['output']>;
  readonly zipCode?: Maybe<ZipCode>;
};

export type PaymentFilterInput = {
  readonly amount?: InputMaybe<DecimalOperationFilterInput>;
  readonly amountNotApplied?: InputMaybe<DecimalOperationFilterInput>;
  readonly amountTendered?: InputMaybe<DecimalOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<PaymentFilterInput>>;
  readonly customer?: InputMaybe<CustomerFilterInput>;
  readonly customerId?: InputMaybe<StringOperationFilterInput>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly glAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly glAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly isBatchCreditCards?: InputMaybe<BooleanOperationFilterInput>;
  readonly isDepositTotal?: InputMaybe<BooleanOperationFilterInput>;
  readonly ledgerId?: InputMaybe<IdOperationFilterInput>;
  readonly location?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly operator?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PaymentFilterInput>>;
  readonly payTypeId?: InputMaybe<StringOperationFilterInput>;
  readonly remark?: InputMaybe<StringOperationFilterInput>;
  readonly tenderedDetails?: InputMaybe<StringOperationFilterInput>;
  readonly totalDiscount?: InputMaybe<DecimalOperationFilterInput>;
  readonly transactionId?: InputMaybe<StringOperationFilterInput>;
};

export type PaymentSortInput = {
  readonly amount?: InputMaybe<SortEnumType>;
  readonly amountNotApplied?: InputMaybe<SortEnumType>;
  readonly amountTendered?: InputMaybe<SortEnumType>;
  readonly customer?: InputMaybe<CustomerSortInput>;
  readonly customerId?: InputMaybe<SortEnumType>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly glAccountId?: InputMaybe<SortEnumType>;
  readonly glAmount?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly isBatchCreditCards?: InputMaybe<SortEnumType>;
  readonly isDepositTotal?: InputMaybe<SortEnumType>;
  readonly ledgerId?: InputMaybe<SortEnumType>;
  readonly location?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly operator?: InputMaybe<SortEnumType>;
  readonly payTypeId?: InputMaybe<SortEnumType>;
  readonly remark?: InputMaybe<SortEnumType>;
  readonly tenderedDetails?: InputMaybe<SortEnumType>;
  readonly totalDiscount?: InputMaybe<SortEnumType>;
  readonly transactionId?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type PaymentsConnection = {
  readonly __typename: 'PaymentsConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<PaymentsEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Payment>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentsEdge = {
  readonly __typename: 'PaymentsEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Payment;
};

export type PayrollCategory = {
  readonly __typename: 'PayrollCategory';
  readonly calculationRoutineNumber?: Maybe<Scalars['String']['output']>;
  readonly checkStubDescription?: Maybe<Scalars['String']['output']>;
  readonly dependentAmount: Scalars['Decimal']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly estimatedCostPerUnit?: Maybe<Scalars['String']['output']>;
  readonly glAccountId: Scalars['ID']['output'];
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly laborBurdenPerHour: Scalars['Decimal']['output'];
  readonly laborBurdenPercentOfWage: Scalars['Decimal']['output'];
  readonly laborType?: Maybe<Scalars['String']['output']>;
  readonly postLocationForAmount?: Maybe<Scalars['String']['output']>;
  readonly postLocationForDeductHoursUnitWage?: Maybe<Scalars['String']['output']>;
  readonly postLocationForHoursUnitWage?: Maybe<Scalars['String']['output']>;
  readonly postLocationforDateAndAmount?: Maybe<Scalars['String']['output']>;
  readonly remark?: Maybe<Scalars['String']['output']>;
  readonly stateTaxCredit: Scalars['Decimal']['output'];
};

export type PlaidAccount = {
  readonly __typename: 'PlaidAccount';
  readonly accountId: Scalars['String']['output'];
  readonly balances: AccountBalance;
  readonly mask?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly officialName?: Maybe<Scalars['String']['output']>;
  readonly subtype?: Maybe<PlaidAccountSubtype>;
  readonly type: AccountType;
  readonly verificationStatus: AccountVerificationStatusEnum;
};

export enum PlaidAccountSubtype {
  Auto = 'AUTO',
  Brokerage = 'BROKERAGE',
  Business = 'BUSINESS',
  CashIsa = 'CASH_ISA',
  CashManagement = 'CASH_MANAGEMENT',
  Cd = 'CD',
  Checking = 'CHECKING',
  Commercial = 'COMMERCIAL',
  Construction = 'CONSTRUCTION',
  Consumer = 'CONSUMER',
  CreditCard = 'CREDIT_CARD',
  Ebt = 'EBT',
  EducationSavingsAccount = 'EDUCATION_SAVINGS_ACCOUNT',
  FixedAnnuity = 'FIXED_ANNUITY',
  Gic = 'GIC',
  HealthReimbursementArrangement = 'HEALTH_REIMBURSEMENT_ARRANGEMENT',
  HomeEquity = 'HOME_EQUITY',
  Hsa = 'HSA',
  Ira = 'IRA',
  Isa = 'ISA',
  Keogh = 'KEOGH',
  Lif = 'LIF',
  LifeInsurance = 'LIFE_INSURANCE',
  LineOfCredit = 'LINE_OF_CREDIT',
  Lira = 'LIRA',
  Loan = 'LOAN',
  Lrif = 'LRIF',
  Lrsp = 'LRSP',
  MoneyMarket = 'MONEY_MARKET',
  Mortgage = 'MORTGAGE',
  MutualFund = 'MUTUAL_FUND',
  NonTaxableBrokerageAccount = 'NON_TAXABLE_BROKERAGE_ACCOUNT',
  Other = 'OTHER',
  OtherAnnuity = 'OTHER_ANNUITY',
  OtherInsurance = 'OTHER_INSURANCE',
  Overdraft = 'OVERDRAFT',
  Paypal = 'PAYPAL',
  Pension = 'PENSION',
  Prepaid = 'PREPAID',
  Prif = 'PRIF',
  ProfitSharingPlan = 'PROFIT_SHARING_PLAN',
  Qshr = 'QSHR',
  Rdsp = 'RDSP',
  Resp = 'RESP',
  Retirement = 'RETIREMENT',
  Rlif = 'RLIF',
  Roth = 'ROTH',
  Roth401K = 'ROTH401K',
  Rrif = 'RRIF',
  Rrsp = 'RRSP',
  Sarsep = 'SARSEP',
  Savings = 'SAVINGS',
  SepIra = 'SEP_IRA',
  SimpleIra = 'SIMPLE_IRA',
  Sipp = 'SIPP',
  StockPlan = 'STOCK_PLAN',
  Student = 'STUDENT',
  Tfsa = 'TFSA',
  Trust = 'TRUST',
  Ugma = 'UGMA',
  Undefined = 'UNDEFINED',
  Utma = 'UTMA',
  VariableAnnuity = 'VARIABLE_ANNUITY',
  /** Renamed with an 'x' in front to resolve JS naming issues. */
  X529 = 'X529',
  '401A' = '_401A',
  '401K' = '_401K',
  '403B' = '_403B',
  '457B' = '_457B'
}

export type PlaidConnection = {
  readonly __typename: 'PlaidConnection';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type PlaidTransaction = {
  readonly __typename: 'PlaidTransaction';
  readonly amount: Scalars['Decimal']['output'];
  readonly checkNumber?: Maybe<Scalars['String']['output']>;
  readonly date: Scalars['Date']['output'];
  readonly id: Scalars['String']['output'];
  readonly merchantName?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
};

export type PointFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<PointFilterInput>>;
  readonly area?: InputMaybe<FloatOperationFilterInput>;
  readonly boundary?: InputMaybe<GeometryFilterInput>;
  readonly boundaryDimension?: InputMaybe<DimensionOperationFilterInput>;
  readonly centroid?: InputMaybe<PointFilterInput>;
  readonly coordinate?: InputMaybe<CoordinateFilterInput>;
  readonly coordinateSequence?: InputMaybe<CoordinateSequenceFilterInput>;
  readonly coordinates?: InputMaybe<ListFilterInputTypeOfCoordinateFilterInput>;
  readonly dimension?: InputMaybe<DimensionOperationFilterInput>;
  readonly envelope?: InputMaybe<GeometryFilterInput>;
  readonly envelopeInternal?: InputMaybe<EnvelopeFilterInput>;
  readonly factory?: InputMaybe<GeometryFactoryFilterInput>;
  readonly geometryType?: InputMaybe<StringOperationFilterInput>;
  readonly interiorPoint?: InputMaybe<PointFilterInput>;
  readonly isEmpty?: InputMaybe<BooleanOperationFilterInput>;
  readonly isRectangle?: InputMaybe<BooleanOperationFilterInput>;
  readonly isSimple?: InputMaybe<BooleanOperationFilterInput>;
  readonly isValid?: InputMaybe<BooleanOperationFilterInput>;
  readonly length?: InputMaybe<FloatOperationFilterInput>;
  readonly m?: InputMaybe<FloatOperationFilterInput>;
  readonly numGeometries?: InputMaybe<IntOperationFilterInput>;
  readonly numPoints?: InputMaybe<IntOperationFilterInput>;
  readonly ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PointFilterInput>>;
  readonly pointOnSurface?: InputMaybe<PointFilterInput>;
  readonly precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  readonly srid?: InputMaybe<IntOperationFilterInput>;
  readonly x?: InputMaybe<FloatOperationFilterInput>;
  readonly y?: InputMaybe<FloatOperationFilterInput>;
  readonly z?: InputMaybe<FloatOperationFilterInput>;
};

export type PointOfInterest = {
  readonly __typename: 'PointOfInterest';
  readonly addresses: ReadonlyArray<PointOfInterestAddress>;
  readonly latitude: Scalars['Float']['output'];
  readonly longitude: Scalars['Float']['output'];
  readonly pinColor?: Maybe<Scalars['String']['output']>;
  readonly pinIcon?: Maybe<Scalars['String']['output']>;
  readonly pinOpacity?: Maybe<Scalars['Decimal']['output']>;
};

export type PointOfInterestAddress = {
  readonly __typename: 'PointOfInterestAddress';
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly transactionShipments: ReadonlyArray<TransactionShipment>;
  readonly zipCode?: Maybe<ZipCode>;
};

export type Policy = {
  readonly __typename: 'Policy';
  readonly childPolicies?: Maybe<ReadonlyArray<Policy>>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly reportCategories: ReadonlyArray<PolicyReportCategory>;
};

export type PolicyReport = {
  readonly __typename: 'PolicyReport';
  readonly name: Scalars['String']['output'];
  readonly policy: Scalars['String']['output'];
};

export type PolicyReportCategory = {
  readonly __typename: 'PolicyReportCategory';
  readonly name: Scalars['String']['output'];
  readonly policy: Scalars['String']['output'];
  readonly reports: ReadonlyArray<PolicyReport>;
};

export enum PolicyType {
  Policy = 'POLICY',
  Report = 'REPORT'
}

export type PopularEquipment = {
  readonly __typename: 'PopularEquipment';
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly value: Scalars['Int']['output'];
};

export type PopularEquipmentFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<PopularEquipmentFilterInput>>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PopularEquipmentFilterInput>>;
  readonly value?: InputMaybe<IntOperationFilterInput>;
};

export type PopularEquipmentSortInput = {
  readonly name?: InputMaybe<SortEnumType>;
  readonly value?: InputMaybe<SortEnumType>;
};

export type PostalCode = {
  readonly __typename: 'PostalCode';
  readonly added: Scalars['DateTime']['output'];
  readonly area?: Maybe<Scalars['String']['output']>;
  readonly city: Scalars['String']['output'];
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['Int']['output'];
  readonly state?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly verifications?: Maybe<ReadonlyArray<Maybe<Verification>>>;
};

export type PostalCodeFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<PostalCodeFilterInput>>;
  readonly area?: InputMaybe<StringOperationFilterInput>;
  readonly city?: InputMaybe<StringOperationFilterInput>;
  readonly code?: InputMaybe<StringOperationFilterInput>;
  readonly country?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PostalCodeFilterInput>>;
  readonly state?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
  readonly verifications?: InputMaybe<ListFilterInputTypeOfVerificationFilterInput>;
};

export type PostgresConnectionInfo = {
  readonly __typename: 'PostgresConnectionInfo';
  readonly database: Scalars['String']['output'];
  readonly password: Scalars['String']['output'];
  readonly port: Scalars['Int']['output'];
  readonly server: Scalars['String']['output'];
  readonly toPostgresUriString: Scalars['String']['output'];
  readonly toUriString: Scalars['String']['output'];
  readonly userId: Scalars['String']['output'];
};

export type PostgresConnectionInfoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<PostgresConnectionInfoFilterInput>>;
  readonly database?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PostgresConnectionInfoFilterInput>>;
  readonly password?: InputMaybe<StringOperationFilterInput>;
  readonly port?: InputMaybe<IntOperationFilterInput>;
  readonly server?: InputMaybe<StringOperationFilterInput>;
  readonly userId?: InputMaybe<StringOperationFilterInput>;
};

export type PostgresConnectionInfoInput = {
  readonly database: Scalars['String']['input'];
  readonly password: Scalars['String']['input'];
  readonly port: Scalars['Int']['input'];
  readonly server: Scalars['String']['input'];
  readonly userId: Scalars['String']['input'];
};

export type PostgresConnectionInfoSortInput = {
  readonly database?: InputMaybe<SortEnumType>;
  readonly password?: InputMaybe<SortEnumType>;
  readonly port?: InputMaybe<SortEnumType>;
  readonly server?: InputMaybe<SortEnumType>;
  readonly userId?: InputMaybe<SortEnumType>;
};

export type PrecisionModelFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<PrecisionModelFilterInput>>;
  readonly gridSize?: InputMaybe<FloatOperationFilterInput>;
  readonly isFloating?: InputMaybe<BooleanOperationFilterInput>;
  readonly maximumSignificantDigits?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PrecisionModelFilterInput>>;
  readonly precisionModelType?: InputMaybe<PrecisionModelsOperationFilterInput>;
  readonly scale?: InputMaybe<FloatOperationFilterInput>;
};

export enum PrecisionModels {
  Fixed = 'FIXED',
  Floating = 'FLOATING',
  FloatingSingle = 'FLOATING_SINGLE'
}

export type PrecisionModelsOperationFilterInput = {
  readonly eq?: InputMaybe<PrecisionModels>;
  readonly in?: InputMaybe<ReadonlyArray<PrecisionModels>>;
  readonly neq?: InputMaybe<PrecisionModels>;
  readonly nin?: InputMaybe<ReadonlyArray<PrecisionModels>>;
};

export type PreviousVersionCompany = {
  readonly __typename: 'PreviousVersionCompany';
  readonly added: Scalars['DateTime']['output'];
  readonly clientAppUrl?: Maybe<Scalars['String']['output']>;
  readonly connectionString?: Maybe<Scalars['String']['output']>;
  readonly databaseConnection: PostgresConnectionInfo;
  readonly fmtVersion?: Maybe<Scalars['String']['output']>;
  readonly galaxyCompanyId?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly legacyServerNumber: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly number: Scalars['Int']['output'];
  readonly publicUrl?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type PreviousVersionCompanyImportResult = {
  readonly __typename: 'PreviousVersionCompanyImportResult';
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly success: Scalars['Boolean']['output'];
};

export type PreviousVersionLegacyServer = {
  readonly __typename: 'PreviousVersionLegacyServer';
  readonly added: Scalars['DateTime']['output'];
  readonly application?: Maybe<Scalars['String']['output']>;
  readonly companies: ReadonlyArray<PreviousVersionCompany>;
  readonly directory?: Maybe<Scalars['String']['output']>;
  readonly dll?: Maybe<Scalars['String']['output']>;
  readonly fmt?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly isRunning: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly number: Scalars['Int']['output'];
  readonly par?: Maybe<Scalars['String']['output']>;
  readonly port: Scalars['Int']['output'];
  readonly shutdownTimeout: Scalars['Int']['output'];
  readonly startup?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type PrintChannel = {
  readonly __typename: 'PrintChannel';
  readonly id: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
};

export type PrintChannelAddInput = {
  readonly name: Scalars['String']['input'];
};

export type PrintChannelFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<PrintChannelFilterInput>>;
  readonly id?: InputMaybe<IntOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PrintChannelFilterInput>>;
};

export type PrintChannelSortInput = {
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
};

export type PrintChannelUpdateInput = {
  readonly id: Scalars['Int']['input'];
  readonly name: Scalars['String']['input'];
};

export type PurchaseOrder = {
  readonly __typename: 'PurchaseOrder';
  readonly addressCode?: Maybe<Scalars['String']['output']>;
  readonly arInvoiceId: Scalars['ID']['output'];
  readonly autoPay?: Maybe<Scalars['String']['output']>;
  readonly controlDate?: Maybe<Scalars['DateTime']['output']>;
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly details: ReadonlyArray<PurchaseOrderDetail>;
  readonly discountAmount: Scalars['Decimal']['output'];
  readonly discountDate?: Maybe<Scalars['DateTime']['output']>;
  readonly fobPoint?: Maybe<Scalars['String']['output']>;
  readonly forms?: Maybe<ReadonlyArray<FormDataGroup>>;
  readonly fulfilled: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly jobId?: Maybe<Scalars['String']['output']>;
  readonly lockFlag: Scalars['ID']['output'];
  readonly netDueDate?: Maybe<Scalars['DateTime']['output']>;
  readonly orderDate?: Maybe<Scalars['DateTime']['output']>;
  readonly orderShipDate?: Maybe<Scalars['DateTime']['output']>;
  readonly orderType?: Maybe<Scalars['String']['output']>;
  readonly poNumber?: Maybe<Scalars['String']['output']>;
  readonly pricePo?: Maybe<Scalars['String']['output']>;
  readonly purchaserAccountNumber?: Maybe<Scalars['String']['output']>;
  readonly receiveList: ReadonlyArray<PurchaseOrderReceiveItem>;
  readonly requestor?: Maybe<Scalars['String']['output']>;
  readonly salesperson?: Maybe<Scalars['String']['output']>;
  readonly shipToCode?: Maybe<Scalars['String']['output']>;
  readonly shipVia?: Maybe<Scalars['String']['output']>;
  readonly specialInstructions1?: Maybe<Scalars['String']['output']>;
  readonly specialInstructions2?: Maybe<Scalars['String']['output']>;
  readonly specialInstructions3?: Maybe<Scalars['String']['output']>;
  readonly specialInstructions4?: Maybe<Scalars['String']['output']>;
  readonly subcontract?: Maybe<Scalars['String']['output']>;
  readonly taxable?: Maybe<Scalars['String']['output']>;
  readonly terms?: Maybe<Scalars['String']['output']>;
  readonly timeMaterialContract?: Maybe<Scalars['String']['output']>;
  readonly timesBackordered: Scalars['Int']['output'];
  readonly totalAmount: Scalars['Decimal']['output'];
  readonly totalWeight: Scalars['Decimal']['output'];
  readonly vendor?: Maybe<Vendor>;
  readonly vendorAttention?: Maybe<Scalars['String']['output']>;
  readonly vendorCityStateZip?: Maybe<Scalars['String']['output']>;
  readonly vendorId?: Maybe<Scalars['String']['output']>;
  readonly vendorInvoiceNumber?: Maybe<Scalars['String']['output']>;
  readonly vendorName?: Maybe<Scalars['String']['output']>;
  readonly vendorPhone?: Maybe<Scalars['String']['output']>;
  readonly vendorStreet?: Maybe<Scalars['String']['output']>;
};

export type PurchaseOrderDetail = {
  readonly __typename: 'PurchaseOrderDetail';
  readonly amount: Scalars['Decimal']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly freightAmount: Scalars['Decimal']['output'];
  readonly fulfilled: Scalars['Boolean']['output'];
  readonly glAccountId: Scalars['ID']['output'];
  readonly id: Scalars['ID']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly jobClassId?: Maybe<Scalars['String']['output']>;
  readonly lineNum: Scalars['Int']['output'];
  readonly lineShipDate?: Maybe<Scalars['DateTime']['output']>;
  readonly locationDetail?: Maybe<Scalars['String']['output']>;
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly price: Scalars['Decimal']['output'];
  readonly priceUpdateId?: Maybe<Scalars['String']['output']>;
  readonly quantity: Scalars['Decimal']['output'];
  readonly quantityOfLabels: Scalars['Int']['output'];
  readonly quantityPerCase: Scalars['Decimal']['output'];
  readonly quantityReceived: Scalars['Decimal']['output'];
  readonly receiveFlag?: Maybe<Scalars['String']['output']>;
  readonly serialNumbers?: Maybe<Scalars['String']['output']>;
  readonly unit?: Maybe<Scalars['String']['output']>;
  readonly vendorItemId?: Maybe<Scalars['String']['output']>;
  readonly weightEach: Scalars['Decimal']['output'];
};

export type PurchaseOrderDetailFilterInput = {
  readonly amount?: InputMaybe<DecimalOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<PurchaseOrderDetailFilterInput>>;
  readonly description?: InputMaybe<StringOperationFilterInput>;
  readonly freightAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly fulfilled?: InputMaybe<BooleanOperationFilterInput>;
  readonly glAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly jobClassId?: InputMaybe<StringOperationFilterInput>;
  readonly lineNum?: InputMaybe<IntOperationFilterInput>;
  readonly lineShipDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly locationDetail?: InputMaybe<StringOperationFilterInput>;
  readonly message?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PurchaseOrderDetailFilterInput>>;
  readonly price?: InputMaybe<DecimalOperationFilterInput>;
  readonly priceUpdateId?: InputMaybe<StringOperationFilterInput>;
  readonly purchaseOrder?: InputMaybe<PurchaseOrderFilterInput>;
  readonly quantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly quantityOfLabels?: InputMaybe<IntOperationFilterInput>;
  readonly quantityPerCase?: InputMaybe<DecimalOperationFilterInput>;
  readonly quantityReceived?: InputMaybe<DecimalOperationFilterInput>;
  readonly receiveFlag?: InputMaybe<StringOperationFilterInput>;
  readonly serialNumbers?: InputMaybe<StringOperationFilterInput>;
  readonly unit?: InputMaybe<StringOperationFilterInput>;
  readonly vendorItemId?: InputMaybe<StringOperationFilterInput>;
  readonly weightEach?: InputMaybe<DecimalOperationFilterInput>;
};

export type PurchaseOrderDetailSortInput = {
  readonly amount?: InputMaybe<SortEnumType>;
  readonly description?: InputMaybe<SortEnumType>;
  readonly freightAmount?: InputMaybe<SortEnumType>;
  readonly fulfilled?: InputMaybe<SortEnumType>;
  readonly glAccountId?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly itemId?: InputMaybe<SortEnumType>;
  readonly jobClassId?: InputMaybe<SortEnumType>;
  readonly lineNum?: InputMaybe<SortEnumType>;
  readonly lineShipDate?: InputMaybe<SortEnumType>;
  readonly locationDetail?: InputMaybe<SortEnumType>;
  readonly message?: InputMaybe<SortEnumType>;
  readonly price?: InputMaybe<SortEnumType>;
  readonly priceUpdateId?: InputMaybe<SortEnumType>;
  readonly purchaseOrder?: InputMaybe<PurchaseOrderSortInput>;
  readonly quantity?: InputMaybe<SortEnumType>;
  readonly quantityOfLabels?: InputMaybe<SortEnumType>;
  readonly quantityPerCase?: InputMaybe<SortEnumType>;
  readonly quantityReceived?: InputMaybe<SortEnumType>;
  readonly receiveFlag?: InputMaybe<SortEnumType>;
  readonly serialNumbers?: InputMaybe<SortEnumType>;
  readonly unit?: InputMaybe<SortEnumType>;
  readonly vendorItemId?: InputMaybe<SortEnumType>;
  readonly weightEach?: InputMaybe<SortEnumType>;
};

export type PurchaseOrderFilterInput = {
  readonly addressCode?: InputMaybe<StringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<PurchaseOrderFilterInput>>;
  readonly arInvoiceId?: InputMaybe<IdOperationFilterInput>;
  readonly autoPay?: InputMaybe<StringOperationFilterInput>;
  readonly controlDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly details?: InputMaybe<ListFilterInputTypeOfPurchaseOrderDetailViewFilterInput>;
  readonly discountAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly discountDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly fobPoint?: InputMaybe<StringOperationFilterInput>;
  readonly forms?: InputMaybe<ListFilterInputTypeOfFormDataGroupFilterInput>;
  readonly fulfilled?: InputMaybe<BooleanOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly jobId?: InputMaybe<StringOperationFilterInput>;
  readonly lockFlag?: InputMaybe<IdOperationFilterInput>;
  readonly netDueDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PurchaseOrderFilterInput>>;
  readonly orderDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly orderShipDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly orderType?: InputMaybe<StringOperationFilterInput>;
  readonly poNumber?: InputMaybe<StringOperationFilterInput>;
  readonly pricePo?: InputMaybe<StringOperationFilterInput>;
  readonly purchaserAccountNumber?: InputMaybe<StringOperationFilterInput>;
  readonly requestor?: InputMaybe<StringOperationFilterInput>;
  readonly salesperson?: InputMaybe<StringOperationFilterInput>;
  readonly shipToCode?: InputMaybe<StringOperationFilterInput>;
  readonly shipVia?: InputMaybe<StringOperationFilterInput>;
  readonly specialInstructions1?: InputMaybe<StringOperationFilterInput>;
  readonly specialInstructions2?: InputMaybe<StringOperationFilterInput>;
  readonly specialInstructions3?: InputMaybe<StringOperationFilterInput>;
  readonly specialInstructions4?: InputMaybe<StringOperationFilterInput>;
  readonly subcontract?: InputMaybe<StringOperationFilterInput>;
  readonly taxable?: InputMaybe<StringOperationFilterInput>;
  readonly terms?: InputMaybe<StringOperationFilterInput>;
  readonly timeMaterialContract?: InputMaybe<StringOperationFilterInput>;
  readonly timesBackordered?: InputMaybe<IntOperationFilterInput>;
  readonly totalAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly totalWeight?: InputMaybe<DecimalOperationFilterInput>;
  readonly vendorAttention?: InputMaybe<StringOperationFilterInput>;
  readonly vendorCityStateZip?: InputMaybe<StringOperationFilterInput>;
  readonly vendorId?: InputMaybe<StringOperationFilterInput>;
  readonly vendorInvoiceNumber?: InputMaybe<StringOperationFilterInput>;
  readonly vendorName?: InputMaybe<StringOperationFilterInput>;
  readonly vendorPhone?: InputMaybe<StringOperationFilterInput>;
  readonly vendorStreet?: InputMaybe<StringOperationFilterInput>;
};

export type PurchaseOrderReceiveItem = {
  readonly __typename: 'PurchaseOrderReceiveItem';
  readonly fulfilled: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId: Scalars['String']['output'];
  readonly orderedQuantity: Scalars['Decimal']['output'];
  readonly pickedQuantity: Scalars['Decimal']['output'];
};

export type PurchaseOrderSortInput = {
  readonly addressCode?: InputMaybe<SortEnumType>;
  readonly arInvoiceId?: InputMaybe<SortEnumType>;
  readonly autoPay?: InputMaybe<SortEnumType>;
  readonly controlDate?: InputMaybe<SortEnumType>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly discountAmount?: InputMaybe<SortEnumType>;
  readonly discountDate?: InputMaybe<SortEnumType>;
  readonly fobPoint?: InputMaybe<SortEnumType>;
  readonly fulfilled?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly jobId?: InputMaybe<SortEnumType>;
  readonly lockFlag?: InputMaybe<SortEnumType>;
  readonly netDueDate?: InputMaybe<SortEnumType>;
  readonly orderDate?: InputMaybe<SortEnumType>;
  readonly orderShipDate?: InputMaybe<SortEnumType>;
  readonly orderType?: InputMaybe<SortEnumType>;
  readonly poNumber?: InputMaybe<SortEnumType>;
  readonly pricePo?: InputMaybe<SortEnumType>;
  readonly purchaserAccountNumber?: InputMaybe<SortEnumType>;
  readonly requestor?: InputMaybe<SortEnumType>;
  readonly salesperson?: InputMaybe<SortEnumType>;
  readonly shipToCode?: InputMaybe<SortEnumType>;
  readonly shipVia?: InputMaybe<SortEnumType>;
  readonly specialInstructions1?: InputMaybe<SortEnumType>;
  readonly specialInstructions2?: InputMaybe<SortEnumType>;
  readonly specialInstructions3?: InputMaybe<SortEnumType>;
  readonly specialInstructions4?: InputMaybe<SortEnumType>;
  readonly subcontract?: InputMaybe<SortEnumType>;
  readonly taxable?: InputMaybe<SortEnumType>;
  readonly terms?: InputMaybe<SortEnumType>;
  readonly timeMaterialContract?: InputMaybe<SortEnumType>;
  readonly timesBackordered?: InputMaybe<SortEnumType>;
  readonly totalAmount?: InputMaybe<SortEnumType>;
  readonly totalWeight?: InputMaybe<SortEnumType>;
  readonly vendorAttention?: InputMaybe<SortEnumType>;
  readonly vendorCityStateZip?: InputMaybe<SortEnumType>;
  readonly vendorId?: InputMaybe<SortEnumType>;
  readonly vendorInvoiceNumber?: InputMaybe<SortEnumType>;
  readonly vendorName?: InputMaybe<SortEnumType>;
  readonly vendorPhone?: InputMaybe<SortEnumType>;
  readonly vendorStreet?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type PurchaseOrdersConnection = {
  readonly __typename: 'PurchaseOrdersConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<PurchaseOrdersEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<PurchaseOrder>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PurchaseOrdersEdge = {
  readonly __typename: 'PurchaseOrdersEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: PurchaseOrder;
};

export type QuantityAndAmount = {
  readonly __typename: 'QuantityAndAmount';
  readonly amount: Scalars['Decimal']['output'];
  readonly description: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly quantity: Scalars['Decimal']['output'];
};

export type Query = {
  readonly __typename: 'Query';
  readonly applicationConnectionInfo: PostgresConnectionInfo;
  readonly bankAccounts: ReadonlyArray<GlAccount>;
  readonly bankStatementMostRecentBankAccount?: Maybe<Scalars['ID']['output']>;
  readonly bankStatementReconciliationReport?: Maybe<ReconciliationReport>;
  readonly bankStatementReconciliationReportURLs: BankStatementReportUrLs;
  readonly bankStatementTransactions: ReadonlyArray<LedgerTransaction>;
  readonly bankStatements?: Maybe<BankStatementsConnection>;
  readonly bankStatementsReportUrls: BankStatementReportUrLs;
  /** List of possible barcode types. */
  readonly barcodeEntityTypes: ReadonlyArray<Scalars['String']['output']>;
  /** Resolve a barcode result into a list of things it could possibly match. */
  readonly barcodeResolve: BarcodeResult;
  readonly boards?: Maybe<BoardsConnection>;
  readonly canAddBoards: Scalars['Boolean']['output'];
  readonly canEditDashboards: Scalars['Boolean']['output'];
  readonly canViewBankStatementReconciliation: Scalars['Boolean']['output'];
  readonly canViewBills: Scalars['Boolean']['output'];
  readonly canViewBoards: Scalars['Boolean']['output'];
  readonly canViewCustomers: Scalars['Boolean']['output'];
  readonly canViewDispatcher: Scalars['Boolean']['output'];
  readonly canViewEmployees: Scalars['Boolean']['output'];
  readonly canViewInvoices: Scalars['Boolean']['output'];
  readonly canViewItemScans: Scalars['Boolean']['output'];
  readonly canViewItems: Scalars['Boolean']['output'];
  readonly canViewJobCosting: Scalars['Boolean']['output'];
  readonly canViewLedgerEntries: Scalars['Boolean']['output'];
  readonly canViewNames: Scalars['Boolean']['output'];
  readonly canViewPaymentCosting: Scalars['Boolean']['output'];
  readonly canViewPayments: Scalars['Boolean']['output'];
  readonly canViewPurchaseOrders: Scalars['Boolean']['output'];
  readonly canViewRentals: Scalars['Boolean']['output'];
  readonly canViewSalespeople: Scalars['Boolean']['output'];
  readonly canViewVendors: Scalars['Boolean']['output'];
  readonly canViewWorkOrders: Scalars['Boolean']['output'];
  readonly canViewZipCodes: Scalars['Boolean']['output'];
  readonly cashProjectionCustomerInvoices?: Maybe<ReadonlyArray<Maybe<CashProjectionCustomer>>>;
  readonly cashProjectionCustomerInvoicesSummary?: Maybe<ReadonlyArray<Maybe<CashProjectionCustomerInvoicePeriod>>>;
  readonly companies: ReadonlyArray<Company>;
  readonly companiesNotLinked: ReadonlyArray<Company>;
  readonly company?: Maybe<Company>;
  readonly companyBackupName?: Maybe<Scalars['String']['output']>;
  readonly companyBackups: ReadonlyArray<CompanyBackup>;
  readonly companyByName?: Maybe<Company>;
  readonly companyImportOptions: ReadonlyArray<CompanyImportStatus>;
  readonly companyInfo?: Maybe<CompanyInfo>;
  readonly companyNextNumber: CompanyNextNumber;
  readonly companyNumberValid: Scalars['Boolean']['output'];
  readonly companySeedData: ReadonlyArray<SeedDataOption>;
  readonly companyStylesheetPreference: CompanyStylesheetPreference;
  readonly customer?: Maybe<Customer>;
  readonly customerCount: Scalars['Int']['output'];
  readonly customerInvoiceStatistics: ReadonlyArray<CustomerSalesStatistic>;
  readonly customerOrdersByMonth: ReadonlyArray<StatisticValue>;
  readonly customerRegionCounts?: Maybe<ReadonlyArray<StatisticValue>>;
  readonly customerRepeatNewCounts?: Maybe<ReadonlyArray<StatisticValue>>;
  readonly customerSalesByMonth: ReadonlyArray<StatisticValue>;
  readonly customerSearch?: Maybe<CustomerSearchConnection>;
  readonly customerTypeCounts: ReadonlyArray<LabeledStatisticValue>;
  readonly customerValidate: ReadonlyArray<ValidationNotice>;
  readonly customers?: Maybe<CustomersConnection>;
  readonly customersTop: ReadonlyArray<CustomerTop>;
  /** id will take precedence over group id */
  readonly dashboard?: Maybe<Dashboard>;
  readonly dashboardGroups: ReadonlyArray<Scalars['String']['output']>;
  readonly databaseInfo: DatabaseInfoResult;
  readonly device?: Maybe<Device>;
  readonly devices?: Maybe<DevicesConnection>;
  readonly dispatchingCoordinatesForLoad: ReadonlyArray<Coords>;
  readonly dispatchingDailyLoads: ReadonlyArray<DailyLoad>;
  readonly dispatchingDrivers: ReadonlyArray<Driver>;
  readonly dispatchingHistoricLoads: ReadonlyArray<HistoricLoads>;
  readonly dispatchingLoads: ReadonlyArray<Load>;
  readonly dispatchingLoadsStatuses: LoadsStatuses;
  readonly dispatchingPinColors: ReadonlyArray<DispatcherPinColor>;
  readonly dispatchingPinConfigurations: ReadonlyArray<DispatcherPinConfiguration>;
  readonly dispatchingPinIcons: ReadonlyArray<DispatcherPinIcon>;
  readonly dispatchingPointsOfInterest: ReadonlyArray<PointOfInterest>;
  readonly dispatchingPopularEquipment: ReadonlyArray<PopularEquipment>;
  readonly dispatchingSearch: ReadonlyArray<SearchResult>;
  readonly dispatchingSelectLoads: ReadonlyArray<Load>;
  readonly dispatchingVehicles: ReadonlyArray<Vehicle>;
  readonly documentGenerationEntities: ReadonlyArray<DocumentGenerationEntity>;
  readonly documentGenerationEntity?: Maybe<DocumentGenerationEntity>;
  readonly documentGenerationHasGenerator: Scalars['Boolean']['output'];
  readonly documentGenerationListDocuments: ReadonlyArray<DocumentGenerationDocumentInfo>;
  readonly documentGenerator?: Maybe<DocumentGenerator>;
  readonly employee?: Maybe<Employee>;
  readonly employeeCount: Scalars['Int']['output'];
  readonly employeeSearch?: Maybe<EmployeeSearchConnection>;
  readonly employeesList?: Maybe<EmployeesListConnection>;
  /** List of enabled features for the active company. */
  readonly featureFlags: ReadonlyArray<FeatureFlag>;
  readonly features: ReadonlyArray<Feature>;
  readonly formTemplate: FormTemplate;
  readonly formTemplateValidateName: Result;
  readonly formTemplates: ReadonlyArray<FormTemplate>;
  readonly glAccounts: ReadonlyArray<GlAccount>;
  readonly groupPolicies: ReadonlyArray<CompanyAuthorizationPolicy>;
  /** Retrieves billing information from a Here Platforms account. */
  readonly hereMapsUsage: HereMapsUsage;
  readonly integration?: Maybe<Integration>;
  readonly integrationDetails: IntegrationDetails;
  readonly integrationEnvironments: ReadonlyArray<IntegrationEnvironment>;
  readonly integrationFields: ReadonlyArray<IntegrationConfigField>;
  readonly invoice: Invoice;
  readonly invoiceBillCodes: ReadonlyArray<Scalars['String']['output']>;
  readonly invoiceSearch?: Maybe<InvoiceSearchConnection>;
  readonly invoiceShipVia: ReadonlyArray<Scalars['String']['output']>;
  readonly invoices?: Maybe<InvoicesConnection>;
  readonly invoicesDailyStatistics?: Maybe<DailySalesStatistics>;
  readonly invoicesTotalByPeriod?: Maybe<ReadonlyArray<InvoiceTotalsByPeriod>>;
  readonly isCompanyAdmin: Scalars['Boolean']['output'];
  readonly isDeviceMfaRemembered: Scalars['Boolean']['output'];
  readonly isLoginDomain: Scalars['Boolean']['output'];
  readonly isSystemAdmin: Scalars['Boolean']['output'];
  readonly item?: Maybe<Item>;
  readonly itemScan?: Maybe<ItemScan>;
  /** Groups item scans by transaction and user. */
  readonly itemScanHistoryByTransaction?: Maybe<ItemScanHistoryByTransactionConnection>;
  readonly itemScans?: Maybe<ItemScansConnection>;
  readonly items?: Maybe<ItemsConnection>;
  readonly itemsTop: ReadonlyArray<ItemTop>;
  readonly job: Job;
  readonly jobs?: Maybe<JobsConnection>;
  readonly ledgerEntries?: Maybe<LedgerEntriesConnection>;
  readonly ledgerEntry?: Maybe<LedgerEntry>;
  readonly ledgerEntryDetails?: Maybe<LedgerEntryDetailsConnection>;
  readonly legacyServer?: Maybe<LegacyServer>;
  readonly legacyServerClientPortValidate: LegacyServerValidatePortResults;
  readonly legacyServerLogs?: Maybe<LegacyServerLogsResult>;
  readonly legacyServerNewDirectoryInfo: LegacyServerDirectoryInfo;
  readonly legacyServerNumberValidate?: Maybe<LegacyServerValidateNumberResults>;
  readonly legacyServerUrlValidate?: Maybe<LegacyServerValidateUrlResults>;
  readonly legacyServers: ReadonlyArray<LegacyServer>;
  readonly licenseKey?: Maybe<LicenseKey>;
  readonly licenseKeyActive?: Maybe<LicenseKey>;
  readonly licenseKeyQty: Scalars['Int']['output'];
  readonly licenseKeys: ReadonlyArray<LicenseKey>;
  readonly licenseMachineInfo: MachineInfo;
  readonly linkToken: Scalars['String']['output'];
  readonly logInInformation: LogInInformation;
  readonly mapApiKey?: Maybe<Scalars['String']['output']>;
  readonly nLogConfig?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Name>;
  readonly nameCount: Scalars['Int']['output'];
  readonly nameSearch?: Maybe<NameSearchConnection>;
  readonly nameValidate: ReadonlyArray<ValidationNotice>;
  readonly names?: Maybe<NamesConnection>;
  readonly namesList?: Maybe<NamesListConnection>;
  readonly openIdApplication?: Maybe<OpenIdApplication>;
  readonly openIdApplicationScopes: ReadonlyArray<OpenIdScope>;
  readonly openIdApplications: ReadonlyArray<OpenIdApplication>;
  readonly payment: Payment;
  readonly payments?: Maybe<PaymentsConnection>;
  readonly plaidBankAccounts: ReadonlyArray<PlaidAccount>;
  readonly plaidConnections: ReadonlyArray<PlaidConnection>;
  readonly plaidTransactions: ReadonlyArray<PlaidTransaction>;
  readonly policies: ReadonlyArray<ParentalPolicies>;
  /** Checks if a previous version of ABC is found in the database. */
  readonly previousVersionExists: Scalars['Boolean']['output'];
  readonly previousVersionLegacyServers: ReadonlyArray<PreviousVersionLegacyServer>;
  readonly previousVersionUnlinkedCompanies: ReadonlyArray<PreviousVersionCompany>;
  readonly printChannel?: Maybe<PrintChannel>;
  readonly printChannels: ReadonlyArray<PrintChannel>;
  readonly purchaseOrder?: Maybe<PurchaseOrder>;
  readonly purchaseOrders?: Maybe<PurchaseOrdersConnection>;
  readonly rentals?: Maybe<ReadonlyArray<Maybe<Rental>>>;
  readonly report?: Maybe<Report>;
  readonly reportCategories: ReadonlyArray<Category>;
  readonly reportCategoriesOrdered: ReadonlyArray<Category>;
  readonly reportStylesheets: ReadonlyArray<Stylesheet>;
  readonly reports?: Maybe<ReportsConnection>;
  readonly resolveReportParameters: ReadonlyArray<ReportParameter>;
  readonly salesOrder?: Maybe<SalesOrder>;
  readonly salesOrders?: Maybe<SalesOrdersConnection>;
  readonly salespeople?: Maybe<SalespeopleConnection>;
  readonly salesperson?: Maybe<Salesperson>;
  readonly salespersonOrdersByMonth: ReadonlyArray<StatisticValue>;
  readonly salespersonSalesByMonth: ReadonlyArray<StatisticValue>;
  readonly searchIndexEntities: ReadonlyArray<SearchIndexEntity>;
  readonly searchIndexFields: ReadonlyArray<SearchIndexField>;
  readonly searchIndexStats: ReadonlyArray<SearchIndexStatsCompany>;
  readonly serialNumbers?: Maybe<SerialNumbersConnection>;
  readonly serverVersion: ServerVersion;
  readonly smsMessages: ReadonlyArray<SmsMessage>;
  readonly smsRecipientPhoneNumbers: ReadonlyArray<Scalars['String']['output']>;
  readonly systemBackups: ReadonlyArray<SystemBackup>;
  readonly systemConfig: SystemConfig;
  readonly systemConfigGenerateCode: ReadonlyArray<Scalars['String']['output']>;
  readonly systemSetup: ReadonlyArray<SystemSetup>;
  readonly user?: Maybe<User>;
  readonly userByUsername?: Maybe<User>;
  readonly userGroup: UserGroup;
  readonly userGroups: ReadonlyArray<UserGroup>;
  readonly userPolicies: ReadonlyArray<CompanyAuthorizationPolicy>;
  readonly userPreferenceTheme: ThemePreference;
  readonly userSystemAdmin: UserSystemAdmin;
  readonly userWhoAmI?: Maybe<User>;
  readonly users?: Maybe<UsersConnection>;
  readonly usersWithCompanyAccess: ReadonlyArray<User>;
  readonly validationEntities: ReadonlyArray<EntityInformation>;
  readonly validationEntity: EntityInformation;
  readonly validationValidator?: Maybe<ValidatorInformation>;
  readonly vehicleJob: Job;
  readonly vehicleJobs?: Maybe<VehicleJobsConnection>;
  readonly vendor?: Maybe<Vendor>;
  readonly vendorCount: Scalars['Int']['output'];
  readonly vendorSearch?: Maybe<VendorSearchConnection>;
  readonly vendorValidate: ReadonlyArray<ValidationNotice>;
  readonly vendors?: Maybe<VendorsConnection>;
  readonly vendorsList?: Maybe<VendorsListConnection>;
  readonly vendorsTop: ReadonlyArray<VendorTop>;
  readonly webStore?: Maybe<WebStore>;
  readonly webStores: ReadonlyArray<WebStore>;
  readonly workOrder: WorkOrder;
  readonly workOrderBillCodes: ReadonlyArray<Scalars['String']['output']>;
  readonly workOrderSearch?: Maybe<WorkOrderSearchConnection>;
  readonly workOrderShipVia: ReadonlyArray<Scalars['String']['output']>;
  readonly workOrders?: Maybe<WorkOrdersConnection>;
  readonly workOrdersOpenByBillCode: ReadonlyArray<StatisticValue>;
  readonly workOrdersOpenByCustomerType: ReadonlyArray<StatisticValue>;
  readonly zipCodeCount?: Maybe<Scalars['Int']['output']>;
  readonly zipCodeList: ReadonlyArray<ZipCode>;
  readonly zipCodeSearch?: Maybe<ZipCodeSearchConnection>;
  readonly zipCodes?: Maybe<ZipCodesConnection>;
};


export type QueryBankAccountsArgs = {
  order?: InputMaybe<ReadonlyArray<GlAccountViewSortInput>>;
  where?: InputMaybe<GlAccountViewFilterInput>;
};


export type QueryBankStatementReconciliationReportArgs = {
  statementId: Scalars['ID']['input'];
};


export type QueryBankStatementReconciliationReportUrLsArgs = {
  bankStatementId: Scalars['ID']['input'];
  glAccountId: Scalars['ID']['input'];
};


export type QueryBankStatementTransactionsArgs = {
  order?: InputMaybe<ReadonlyArray<LedgerTransactionSortInput>>;
  statementId?: InputMaybe<Scalars['ID']['input']>;
  where?: InputMaybe<LedgerTransactionFilterInput>;
};


export type QueryBankStatementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<BankStatementWithBalancesSortInput>>;
  where?: InputMaybe<BankStatementWithBalancesFilterInput>;
};


export type QueryBankStatementsReportUrlsArgs = {
  bankStatementId: Scalars['ID']['input'];
  glAccountId: Scalars['ID']['input'];
};


export type QueryBarcodeResolveArgs = {
  barcode: Scalars['String']['input'];
  possibleTypes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};


export type QueryBoardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<BoardSortInput>>;
  where?: InputMaybe<BoardFilterInput>;
};


export type QueryCashProjectionCustomerInvoicesArgs = {
  daysPerPeriod: Scalars['Int']['input'];
};


export type QueryCashProjectionCustomerInvoicesSummaryArgs = {
  daysPerPeriod: Scalars['Int']['input'];
};


export type QueryCompaniesArgs = {
  where?: InputMaybe<CompanyFilterInput>;
};


export type QueryCompaniesNotLinkedArgs = {
  order?: InputMaybe<ReadonlyArray<CompanySortInput>>;
  where?: InputMaybe<CompanyFilterInput>;
};


export type QueryCompanyArgs = {
  id: Scalars['String']['input'];
};


export type QueryCompanyBackupNameArgs = {
  backupName: Scalars['String']['input'];
  companyId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompanyBackupsArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompanyByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryCompanyNextNumberArgs = {
  legacyServerId: Scalars['String']['input'];
};


export type QueryCompanyNumberValidArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  legacyServerNumber: Scalars['Int']['input'];
  number: Scalars['Int']['input'];
};


export type QueryCustomerArgs = {
  id: Scalars['String']['input'];
};


export type QueryCustomerInvoiceStatisticsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  type: SalesType;
};


export type QueryCustomerOrdersByMonthArgs = {
  customerId: Scalars['String']['input'];
};


export type QueryCustomerRegionCountsArgs = {
  includeBlank?: Scalars['Boolean']['input'];
  qtyResults?: Scalars['Int']['input'];
  region?: InputMaybe<CustomerRegionOptions>;
};


export type QueryCustomerRepeatNewCountsArgs = {
  mode?: CustomerRepeatNewCountMode;
  period?: CustomerRepeatNewCountPeriod;
};


export type QueryCustomerSalesByMonthArgs = {
  customerId: Scalars['String']['input'];
};


export type QueryCustomerSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<CustomerSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCustomerTypeCountsArgs = {
  includeBlank?: Scalars['Boolean']['input'];
};


export type QueryCustomerValidateArgs = {
  input: CustomerValidateInput;
  minLevel?: ErrorLevel;
};


export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<CustomerSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<CustomerFilterInput>;
};


export type QueryCustomersTopArgs = {
  period?: CustomersTopPeriod;
  qty?: Scalars['Int']['input'];
  sort?: CustomersTopSort;
};


export type QueryDashboardArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDashboardGroupsArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDeviceArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<DeviceSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<DeviceFilterInput>;
};


export type QueryDispatchingCoordinatesForLoadArgs = {
  loadId: Scalars['String']['input'];
};


export type QueryDispatchingDailyLoadsArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  order?: InputMaybe<ReadonlyArray<DailyLoadSortInput>>;
  where?: InputMaybe<DailyLoadFilterInput>;
};


export type QueryDispatchingHistoricLoadsArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  order?: InputMaybe<ReadonlyArray<HistoricLoadsSortInput>>;
  where?: InputMaybe<HistoricLoadsFilterInput>;
};


export type QueryDispatchingLoadsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type QueryDispatchingLoadsStatusesArgs = {
  endDate: Scalars['DateTime']['input'];
  order?: InputMaybe<ReadonlyArray<LoadsStatusesSortInput>>;
  startDate: Scalars['DateTime']['input'];
  where?: InputMaybe<LoadsStatusesFilterInput>;
};


export type QueryDispatchingPinConfigurationsArgs = {
  order?: InputMaybe<ReadonlyArray<DispatcherPinConfigurationSortInput>>;
};


export type QueryDispatchingPointsOfInterestArgs = {
  endDate: Scalars['DateTime']['input'];
  includeInvoices?: Scalars['Boolean']['input'];
  includeRentals?: Scalars['Boolean']['input'];
  includeWorkOrders?: Scalars['Boolean']['input'];
  mode: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type QueryDispatchingPopularEquipmentArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  order?: InputMaybe<ReadonlyArray<PopularEquipmentSortInput>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  where?: InputMaybe<PopularEquipmentFilterInput>;
};


export type QueryDispatchingSearchArgs = {
  allTime?: Scalars['Boolean']['input'];
  endDate: Scalars['DateTime']['input'];
  includeInvoices?: Scalars['Boolean']['input'];
  includeRentals?: Scalars['Boolean']['input'];
  includeWorkOrders?: Scalars['Boolean']['input'];
  search: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type QueryDispatchingSelectLoadsArgs = {
  endDate: Scalars['DateTime']['input'];
  order?: InputMaybe<ReadonlyArray<LoadSortInput>>;
  startDate: Scalars['DateTime']['input'];
  where?: InputMaybe<LoadFilterInput>;
};


export type QueryDispatchingVehiclesArgs = {
  order?: InputMaybe<ReadonlyArray<VehicleSortInput>>;
};


export type QueryDocumentGenerationEntityArgs = {
  entityId: Scalars['String']['input'];
};


export type QueryDocumentGenerationHasGeneratorArgs = {
  entityId: Scalars['String']['input'];
};


export type QueryDocumentGenerationListDocumentsArgs = {
  entityId: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
};


export type QueryDocumentGeneratorArgs = {
  entityId: Scalars['String']['input'];
  generatorId: Scalars['String']['input'];
};


export type QueryEmployeeArgs = {
  id: Scalars['String']['input'];
};


export type QueryEmployeeSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<EmployeeSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEmployeesListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<EmployeeSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<EmployeeFilterInput>;
};


export type QueryFormTemplateArgs = {
  id: Scalars['String']['input'];
};


export type QueryFormTemplateValidateNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryFormTemplatesArgs = {
  validTables?: InputMaybe<ReadonlyArray<FormTemplateValidTables>>;
};


export type QueryGlAccountsArgs = {
  order?: InputMaybe<ReadonlyArray<GlAccountViewSortInput>>;
  where?: InputMaybe<GlAccountViewFilterInput>;
};


export type QueryGroupPoliciesArgs = {
  groupId: Scalars['String']['input'];
};


export type QueryHereMapsUsageArgs = {
  companyId: Scalars['String']['input'];
};


export type QueryIntegrationArgs = {
  featureId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
};


export type QueryIntegrationDetailsArgs = {
  featureId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
};


export type QueryIntegrationEnvironmentsArgs = {
  featureId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
};


export type QueryIntegrationFieldsArgs = {
  environment: Scalars['String']['input'];
  featureId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoiceSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<InvoiceSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<InvoiceFilterInput>;
};


export type QueryInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<InvoiceSortInput>>;
  where?: InputMaybe<InvoiceFilterInput>;
};


export type QueryInvoicesDailyStatisticsArgs = {
  date: Scalars['DateTime']['input'];
};


export type QueryInvoicesTotalByPeriodArgs = {
  period?: InvoiceTotalsPeriod;
  qty?: Scalars['Int']['input'];
};


export type QueryItemArgs = {
  id: Scalars['String']['input'];
};


export type QueryItemScanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryItemScanHistoryByTransactionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<ItemScanTransactionSortInput>>;
  where?: InputMaybe<ItemScanTransactionFilterInput>;
};


export type QueryItemScansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<ItemScanSortInput>>;
  where?: InputMaybe<ItemScanFilterInput>;
};


export type QueryItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<ItemSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<ItemFilterInput>;
};


export type QueryItemsTopArgs = {
  period?: ItemsTopPeriod;
  qty?: Scalars['Int']['input'];
  sort?: ItemsTopSort;
};


export type QueryJobArgs = {
  id: Scalars['String']['input'];
};


export type QueryJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<JobSortInput>>;
  where?: InputMaybe<JobFilterInput>;
};


export type QueryLedgerEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<LedgerEntrySummarySortInput>>;
  transactionType?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<LedgerEntrySummaryFilterInput>;
};


export type QueryLedgerEntryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLedgerEntryDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<LedgerEntryDetailSortInput>>;
  where?: InputMaybe<LedgerEntryDetailFilterInput>;
};


export type QueryLegacyServerArgs = {
  id: Scalars['String']['input'];
};


export type QueryLegacyServerClientPortValidateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  port?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLegacyServerLogsArgs = {
  serverId: Scalars['String']['input'];
};


export type QueryLegacyServerNewDirectoryInfoArgs = {
  directory: Scalars['String']['input'];
  serverId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLegacyServerNumberValidateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLegacyServerUrlValidateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLegacyServersArgs = {
  order?: InputMaybe<ReadonlyArray<LegacyServerSortInput>>;
  where?: InputMaybe<LegacyServerFilterInput>;
};


export type QueryLicenseKeyArgs = {
  galaxyId: Scalars['UUID']['input'];
};


export type QueryNameArgs = {
  id: Scalars['String']['input'];
};


export type QueryNameSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<NameSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNameValidateArgs = {
  input: NameValidateInput;
  minLevel?: ErrorLevel;
};


export type QueryNamesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<NameSortInput>>;
  where?: InputMaybe<NameFilterInput>;
};


export type QueryNamesListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<NameSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<NameFilterInput>;
};


export type QueryOpenIdApplicationArgs = {
  id: Scalars['String']['input'];
};


export type QueryOpenIdApplicationsArgs = {
  companyId: Scalars['String']['input'];
};


export type QueryPaymentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<PaymentSortInput>>;
  where?: InputMaybe<PaymentFilterInput>;
};


export type QueryPlaidBankAccountsArgs = {
  accessToken: Scalars['String']['input'];
};


export type QueryPlaidTransactionsArgs = {
  accountId: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryPoliciesArgs = {
  includeCompanyPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  policyType?: InputMaybe<PolicyType>;
};


export type QueryPrintChannelArgs = {
  id: Scalars['Int']['input'];
};


export type QueryPrintChannelsArgs = {
  order?: InputMaybe<ReadonlyArray<PrintChannelSortInput>>;
  where?: InputMaybe<PrintChannelFilterInput>;
};


export type QueryPurchaseOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<PurchaseOrderSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<PurchaseOrderFilterInput>;
};


export type QueryRentalsArgs = {
  order?: InputMaybe<ReadonlyArray<RentalSortInput>>;
  where?: InputMaybe<RentalFilterInput>;
};


export type QueryReportArgs = {
  id: Scalars['String']['input'];
  isCustom: Scalars['Boolean']['input'];
};


export type QueryReportCategoriesArgs = {
  order?: InputMaybe<ReadonlyArray<CategorySortInput>>;
  where?: InputMaybe<CategoryFilterInput>;
};


export type QueryReportCategoriesOrderedArgs = {
  order?: InputMaybe<ReadonlyArray<CategorySortInput>>;
  where?: InputMaybe<CategoryFilterInput>;
};


export type QueryReportStylesheetsArgs = {
  order?: InputMaybe<ReadonlyArray<StylesheetSortInput>>;
  where?: InputMaybe<StylesheetFilterInput>;
};


export type QueryReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<ReportSortInput>>;
  where?: InputMaybe<ReportFilterInput>;
};


export type QueryResolveReportParametersArgs = {
  queryString: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
};


export type QuerySalesOrderArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerySalesOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeDraft?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<SalesOrderSortInput>>;
  where?: InputMaybe<SalesOrderFilterInput>;
};


export type QuerySalespeopleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<SalespersonSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<SalespersonFilterInput>;
};


export type QuerySalespersonArgs = {
  id: Scalars['String']['input'];
};


export type QuerySalespersonOrdersByMonthArgs = {
  salespersonId: Scalars['String']['input'];
};


export type QuerySalespersonSalesByMonthArgs = {
  salespersonId: Scalars['String']['input'];
};


export type QuerySearchIndexFieldsArgs = {
  companyId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};


export type QuerySerialNumbersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeEmptyItems?: Scalars['Boolean']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<SerialNumberSortInput>>;
  where?: InputMaybe<SerialNumberFilterInput>;
};


export type QuerySmsMessagesArgs = {
  order?: InputMaybe<ReadonlyArray<SmsMessageSortInput>>;
  toNumber: Scalars['String']['input'];
  where?: InputMaybe<SmsMessageFilterInput>;
};


export type QuerySystemConfigGenerateCodeArgs = {
  config: CodeGenerationInput;
  names: ReadonlyArray<Scalars['String']['input']>;
};


export type QuerySystemSetupArgs = {
  order?: InputMaybe<ReadonlyArray<SystemSetupSortInput>>;
  where?: InputMaybe<SystemSetupFilterInput>;
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserByUsernameArgs = {
  username: Scalars['String']['input'];
};


export type QueryUserGroupArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserGroupsArgs = {
  order?: InputMaybe<ReadonlyArray<UserGroupSortInput>>;
  where?: InputMaybe<UserGroupFilterInput>;
};


export type QueryUserPoliciesArgs = {
  userId: Scalars['String']['input'];
};


export type QueryUserSystemAdminArgs = {
  userId: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};


export type QueryUsersWithCompanyAccessArgs = {
  companyId: Scalars['String']['input'];
  order?: InputMaybe<ReadonlyArray<UserSortInput>>;
};


export type QueryValidationEntityArgs = {
  entityId: Scalars['String']['input'];
};


export type QueryValidationValidatorArgs = {
  entityId: Scalars['String']['input'];
  validatorId: Scalars['String']['input'];
};


export type QueryVehicleJobArgs = {
  id: Scalars['String']['input'];
};


export type QueryVehicleJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<JobSortInput>>;
  where?: InputMaybe<JobFilterInput>;
};


export type QueryVendorArgs = {
  id: Scalars['String']['input'];
};


export type QueryVendorSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<VendorSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVendorValidateArgs = {
  input: VendorValidateInput;
  minLevel?: ErrorLevel;
};


export type QueryVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<VendorSortInput>>;
  where?: InputMaybe<VendorFilterInput>;
};


export type QueryVendorsListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<VendorSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<VendorFilterInput>;
};


export type QueryVendorsTopArgs = {
  period?: VendorsTopPeriod;
  qty?: Scalars['Int']['input'];
  sort?: VendorsTopSort;
};


export type QueryWebStoreArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryWebStoresArgs = {
  order?: InputMaybe<ReadonlyArray<WebStoreSortInput>>;
};


export type QueryWorkOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkOrderSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<WorkOrderSortInput>>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<WorkOrderFilterInput>;
};


export type QueryWorkOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<WorkOrderSortInput>>;
  where?: InputMaybe<WorkOrderFilterInput>;
};


export type QueryWorkOrdersOpenByBillCodeArgs = {
  includeProposals?: Scalars['Boolean']['input'];
  qtyResults?: Scalars['Int']['input'];
};


export type QueryWorkOrdersOpenByCustomerTypeArgs = {
  includeProposals?: Scalars['Boolean']['input'];
  qtyResults?: Scalars['Int']['input'];
};


export type QueryZipCodeSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<ZipCodeSortInput>>;
  searchBy: Scalars['String']['input'];
};


export type QueryZipCodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReadonlyArray<ZipCodeSortInput>>;
  where?: InputMaybe<ZipCodeFilterInput>;
};

export type RecentResourceUsage = {
  readonly __typename: 'RecentResourceUsage';
  readonly cpuPercent: ReadonlyArray<ResourceUsage>;
  readonly isRunning: Scalars['Boolean']['output'];
  readonly memory: ReadonlyArray<ResourceUsage>;
  readonly workingSet: ReadonlyArray<ResourceUsage>;
};

export type ReconciliationReport = {
  readonly __typename: 'ReconciliationReport';
  readonly adjustedBankBalance: Scalars['Decimal']['output'];
  readonly bankGlAccountId: Scalars['ID']['output'];
  readonly computerBalance: Scalars['Decimal']['output'];
  readonly credits: Scalars['Decimal']['output'];
  readonly creditsInternal?: Maybe<Scalars['Decimal']['output']>;
  readonly creditsOutstanding?: Maybe<Scalars['Decimal']['output']>;
  readonly creditsReturned?: Maybe<Scalars['Decimal']['output']>;
  readonly creditsReturnedFuture?: Maybe<Scalars['Decimal']['output']>;
  readonly debits: Scalars['Decimal']['output'];
  readonly debitsInternal?: Maybe<Scalars['Decimal']['output']>;
  readonly debitsOutstanding?: Maybe<Scalars['Decimal']['output']>;
  readonly debitsReturned?: Maybe<Scalars['Decimal']['output']>;
  readonly debitsReturnedFuture?: Maybe<Scalars['Decimal']['output']>;
  readonly difference: Scalars['Decimal']['output'];
  readonly endBalance: Scalars['Decimal']['output'];
  readonly startBalance: Scalars['Decimal']['output'];
  readonly statementDate: Scalars['DateTime']['output'];
  readonly statementId: Scalars['ID']['output'];
  readonly transactions: ReadonlyArray<OutstandingTransactionReportItem>;
};

export type RegisterResult = {
  readonly __typename: 'RegisterResult';
  readonly canAccessCompany: Scalars['Boolean']['output'];
  readonly errors: ReadonlyArray<Scalars['String']['output']>;
  readonly jwt: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly success: Scalars['Boolean']['output'];
};

export type Rental = {
  readonly __typename: 'Rental';
  readonly added: Scalars['DateTime']['output'];
  readonly contract?: Maybe<RentalContract>;
  readonly contractId?: Maybe<Scalars['String']['output']>;
  readonly contractSignatures?: Maybe<ReadonlyArray<Maybe<RentalContractSignature>>>;
  readonly customer: Customer;
  readonly customerId?: Maybe<Scalars['String']['output']>;
  readonly dateRentalDetail?: Maybe<RentalDetail>;
  readonly dateRentalDetailId?: Maybe<Scalars['String']['output']>;
  readonly dates: ReadonlyArray<RentalDate>;
  readonly days?: Maybe<Scalars['Int']['output']>;
  readonly deliverBy?: Maybe<Scalars['DateTime']['output']>;
  readonly deliveryDate: Scalars['DateTime']['output'];
  readonly deliveryNotes?: Maybe<Scalars['String']['output']>;
  readonly details: ReadonlyArray<RentalDetail>;
  readonly hoursAllowed?: Maybe<Scalars['Int']['output']>;
  readonly id: Scalars['String']['output'];
  readonly isCustomerPickup: Scalars['Boolean']['output'];
  readonly isSigned: Scalars['Boolean']['output'];
  readonly logs?: Maybe<ReadonlyArray<Maybe<RentalItemLog>>>;
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly number: Scalars['ID']['output'];
  readonly pickupDate: Scalars['DateTime']['output'];
  readonly pickupNotes?: Maybe<Scalars['String']['output']>;
  readonly shipments?: Maybe<ReadonlyArray<Maybe<RentalShipment>>>;
  readonly shippingCustomer?: Maybe<Name>;
  readonly shippingCustomerId?: Maybe<Scalars['String']['output']>;
  readonly status: RentalStatus;
  readonly surcharges?: Maybe<ReadonlyArray<Maybe<RentalSurcharge>>>;
  readonly surchargesTotal: Scalars['Decimal']['output'];
  readonly taxCode?: Maybe<Scalars['String']['output']>;
  readonly total: Scalars['Decimal']['output'];
  readonly updated: Scalars['DateTime']['output'];
};

export type RentalContract = {
  readonly __typename: 'RentalContract';
  readonly added: Scalars['DateTime']['output'];
  readonly billCode?: Maybe<Scalars['String']['output']>;
  readonly htmlLayout?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type RentalContractFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<RentalContractFilterInput>>;
  readonly billCode?: InputMaybe<StringOperationFilterInput>;
  readonly htmlLayout?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<RentalContractFilterInput>>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type RentalContractSignature = {
  readonly __typename: 'RentalContractSignature';
  readonly added: Scalars['DateTime']['output'];
  readonly htmlLayout?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly rentalId?: Maybe<Scalars['String']['output']>;
  readonly signature?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type RentalContractSignatureFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<RentalContractSignatureFilterInput>>;
  readonly htmlLayout?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<RentalContractSignatureFilterInput>>;
  readonly rentalId?: InputMaybe<StringOperationFilterInput>;
  readonly signature?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type RentalContractSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly billCode?: InputMaybe<SortEnumType>;
  readonly htmlLayout?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

export type RentalDate = BaseDate & {
  readonly __typename: 'RentalDate';
  readonly added: Scalars['DateTime']['output'];
  readonly endDate: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly rentalDetail?: Maybe<RentalDetail>;
  readonly rentalDetailId?: Maybe<Scalars['String']['output']>;
  readonly startDate: Scalars['DateTime']['output'];
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly weight?: Maybe<Scalars['Decimal']['output']>;
};

export type RentalDateFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<RentalDateFilterInput>>;
  readonly endDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<RentalDateFilterInput>>;
  readonly rentalDetail?: InputMaybe<RentalDetailFilterInput>;
  readonly rentalDetailId?: InputMaybe<StringOperationFilterInput>;
  readonly startDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly type?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
  readonly weight?: InputMaybe<DecimalOperationFilterInput>;
};

export type RentalDetail = {
  readonly __typename: 'RentalDetail';
  readonly added: Scalars['DateTime']['output'];
  readonly extendedPrice: Scalars['Decimal']['output'];
  readonly id: Scalars['String']['output'];
  readonly item?: Maybe<Item>;
  readonly itemGroup?: Maybe<Scalars['String']['output']>;
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly listPrice: Scalars['Decimal']['output'];
  readonly overageCharge?: Maybe<Scalars['Decimal']['output']>;
  readonly price: Scalars['Decimal']['output'];
  readonly quantity: Scalars['Decimal']['output'];
  readonly rental?: Maybe<Rental>;
  readonly rentalId?: Maybe<Scalars['String']['output']>;
  readonly taxMode?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type RentalDetailFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<RentalDetailFilterInput>>;
  readonly extendedPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly item?: InputMaybe<ItemFilterInput>;
  readonly itemGroup?: InputMaybe<StringOperationFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly listPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<RentalDetailFilterInput>>;
  readonly overageCharge?: InputMaybe<DecimalOperationFilterInput>;
  readonly price?: InputMaybe<DecimalOperationFilterInput>;
  readonly quantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly rental?: InputMaybe<RentalFilterInput>;
  readonly rentalId?: InputMaybe<StringOperationFilterInput>;
  readonly taxMode?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type RentalDetailSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly extendedPrice?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly item?: InputMaybe<ItemSortInput>;
  readonly itemGroup?: InputMaybe<SortEnumType>;
  readonly itemId?: InputMaybe<SortEnumType>;
  readonly listPrice?: InputMaybe<SortEnumType>;
  readonly overageCharge?: InputMaybe<SortEnumType>;
  readonly price?: InputMaybe<SortEnumType>;
  readonly quantity?: InputMaybe<SortEnumType>;
  readonly rental?: InputMaybe<RentalSortInput>;
  readonly rentalId?: InputMaybe<SortEnumType>;
  readonly taxMode?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

export type RentalFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<RentalFilterInput>>;
  readonly contract?: InputMaybe<RentalContractFilterInput>;
  readonly contractId?: InputMaybe<StringOperationFilterInput>;
  readonly contractSignatures?: InputMaybe<ListFilterInputTypeOfRentalContractSignatureFilterInput>;
  readonly customer?: InputMaybe<CustomerFilterInput>;
  readonly customerId?: InputMaybe<StringOperationFilterInput>;
  readonly dateRentalDetail?: InputMaybe<RentalDetailFilterInput>;
  readonly dateRentalDetailId?: InputMaybe<StringOperationFilterInput>;
  readonly dates?: InputMaybe<ListFilterInputTypeOfRentalDateFilterInput>;
  readonly days?: InputMaybe<IntOperationFilterInput>;
  readonly deliverBy?: InputMaybe<DateTimeOperationFilterInput>;
  readonly deliveryDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly deliveryNotes?: InputMaybe<StringOperationFilterInput>;
  readonly details?: InputMaybe<ListFilterInputTypeOfRentalDetailFilterInput>;
  readonly hoursAllowed?: InputMaybe<IntOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly isCustomerPickup?: InputMaybe<BooleanOperationFilterInput>;
  readonly isSigned?: InputMaybe<BooleanOperationFilterInput>;
  readonly logs?: InputMaybe<ListFilterInputTypeOfRentalItemLogFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly number?: InputMaybe<IdOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<RentalFilterInput>>;
  readonly pickupDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly pickupNotes?: InputMaybe<StringOperationFilterInput>;
  readonly shipments?: InputMaybe<ListFilterInputTypeOfRentalShipmentFilterInput>;
  readonly shippingCustomer?: InputMaybe<NameFilterInput>;
  readonly shippingCustomerId?: InputMaybe<StringOperationFilterInput>;
  readonly status?: InputMaybe<RentalStatusOperationFilterInput>;
  readonly surcharges?: InputMaybe<ListFilterInputTypeOfRentalSurchargeFilterInput>;
  readonly surchargesTotal?: InputMaybe<DecimalOperationFilterInput>;
  readonly taxCode?: InputMaybe<StringOperationFilterInput>;
  readonly total?: InputMaybe<DecimalOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type RentalItemLog = {
  readonly __typename: 'RentalItemLog';
  readonly added: Scalars['DateTime']['output'];
  readonly condition?: Maybe<Scalars['String']['output']>;
  readonly hours: Scalars['Decimal']['output'];
  readonly id: Scalars['String']['output'];
  readonly images?: Maybe<ReadonlyArray<Maybe<ItemLogImage>>>;
  readonly isClean: Scalars['Boolean']['output'];
  readonly isTankFull: Scalars['Boolean']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly logType?: Maybe<RentalType>;
  readonly maintenanceNotes?: Maybe<Scalars['String']['output']>;
  readonly maintenancePriority?: Maybe<RentalMaintenancePriority>;
  readonly miles: Scalars['Decimal']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly rental?: Maybe<Rental>;
  readonly rentalId?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<RentalServiceStatus>;
  readonly updated: Scalars['DateTime']['output'];
};

export type RentalItemLogFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<RentalItemLogFilterInput>>;
  readonly condition?: InputMaybe<StringOperationFilterInput>;
  readonly hours?: InputMaybe<DecimalOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly images?: InputMaybe<ListFilterInputTypeOfItemLogImageFilterInput>;
  readonly isClean?: InputMaybe<BooleanOperationFilterInput>;
  readonly isTankFull?: InputMaybe<BooleanOperationFilterInput>;
  readonly item?: InputMaybe<ItemFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly logType?: InputMaybe<NullableOfRentalTypeOperationFilterInput>;
  readonly maintenanceNotes?: InputMaybe<StringOperationFilterInput>;
  readonly maintenancePriority?: InputMaybe<NullableOfRentalMaintenancePriorityOperationFilterInput>;
  readonly miles?: InputMaybe<DecimalOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<RentalItemLogFilterInput>>;
  readonly rental?: InputMaybe<RentalFilterInput>;
  readonly rentalId?: InputMaybe<StringOperationFilterInput>;
  readonly status?: InputMaybe<NullableOfRentalServiceStatusOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export enum RentalMaintenancePriority {
  BeforeNextRental = 'BEFORE_NEXT_RENTAL',
  OnNextMaintenance = 'ON_NEXT_MAINTENANCE'
}

export enum RentalServiceStatus {
  NeedsServiced = 'NEEDS_SERVICED',
  OutOfService = 'OUT_OF_SERVICE',
  ReadyToGo = 'READY_TO_GO',
  Rented = 'RENTED'
}

export type RentalShipment = {
  readonly __typename: 'RentalShipment';
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly rental: Rental;
  readonly rentalDetail?: Maybe<RentalDetail>;
  readonly rentalDetailId?: Maybe<Scalars['String']['output']>;
  readonly rentalId: Scalars['String']['output'];
  readonly shipment?: Maybe<Shipment>;
  readonly shipmentId: Scalars['String']['output'];
  readonly updated: Scalars['DateTime']['output'];
};

export type RentalShipmentFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<RentalShipmentFilterInput>>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<RentalShipmentFilterInput>>;
  readonly rental?: InputMaybe<RentalFilterInput>;
  readonly rentalDetail?: InputMaybe<RentalDetailFilterInput>;
  readonly rentalDetailId?: InputMaybe<StringOperationFilterInput>;
  readonly rentalId?: InputMaybe<StringOperationFilterInput>;
  readonly shipment?: InputMaybe<ShipmentFilterInput>;
  readonly shipmentId?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type RentalSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly contract?: InputMaybe<RentalContractSortInput>;
  readonly contractId?: InputMaybe<SortEnumType>;
  readonly customer?: InputMaybe<CustomerSortInput>;
  readonly customerId?: InputMaybe<SortEnumType>;
  readonly dateRentalDetail?: InputMaybe<RentalDetailSortInput>;
  readonly dateRentalDetailId?: InputMaybe<SortEnumType>;
  readonly days?: InputMaybe<SortEnumType>;
  readonly deliverBy?: InputMaybe<SortEnumType>;
  readonly deliveryDate?: InputMaybe<SortEnumType>;
  readonly deliveryNotes?: InputMaybe<SortEnumType>;
  readonly hoursAllowed?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly isCustomerPickup?: InputMaybe<SortEnumType>;
  readonly isSigned?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly number?: InputMaybe<SortEnumType>;
  readonly pickupDate?: InputMaybe<SortEnumType>;
  readonly pickupNotes?: InputMaybe<SortEnumType>;
  readonly shippingCustomer?: InputMaybe<NameSortInput>;
  readonly shippingCustomerId?: InputMaybe<SortEnumType>;
  readonly status?: InputMaybe<SortEnumType>;
  readonly surchargesTotal?: InputMaybe<SortEnumType>;
  readonly taxCode?: InputMaybe<SortEnumType>;
  readonly total?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

export enum RentalStatus {
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Delivered = 'DELIVERED',
  NotSet = 'NOT_SET',
  ReadyForPickup = 'READY_FOR_PICKUP',
  Returned = 'RETURNED'
}

export type RentalStatusOperationFilterInput = {
  readonly eq?: InputMaybe<RentalStatus>;
  readonly in?: InputMaybe<ReadonlyArray<RentalStatus>>;
  readonly neq?: InputMaybe<RentalStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<RentalStatus>>;
};

export type RentalSurcharge = {
  readonly __typename: 'RentalSurcharge';
  readonly added: Scalars['DateTime']['output'];
  readonly extendedPrice: Scalars['Decimal']['output'];
  readonly id: Scalars['String']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly listPrice: Scalars['Decimal']['output'];
  readonly price: Scalars['Decimal']['output'];
  readonly quantity: Scalars['Decimal']['output'];
  readonly rental?: Maybe<Rental>;
  readonly rentalId?: Maybe<Scalars['String']['output']>;
  readonly taxMode?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type RentalSurchargeFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<RentalSurchargeFilterInput>>;
  readonly extendedPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly item?: InputMaybe<ItemFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly listPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<RentalSurchargeFilterInput>>;
  readonly price?: InputMaybe<DecimalOperationFilterInput>;
  readonly quantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly rental?: InputMaybe<RentalFilterInput>;
  readonly rentalId?: InputMaybe<StringOperationFilterInput>;
  readonly taxMode?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export enum RentalType {
  Delivery = 'DELIVERY',
  NotSet = 'NOT_SET',
  Pickup = 'PICKUP'
}

export type Report = {
  readonly __typename: 'Report';
  readonly added: Scalars['DateTime']['output'];
  readonly categoryLinks: ReadonlyArray<ReportCategory>;
  readonly cramId?: Maybe<Scalars['String']['output']>;
  readonly defaultParameters?: Maybe<Scalars['String']['output']>;
  readonly definition?: Maybe<ReadonlyArray<Scalars['Byte']['output']>>;
  readonly id: Scalars['String']['output'];
  readonly isCustom: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly printChannel?: Maybe<PrintChannel>;
  readonly printChannelId: Scalars['Int']['output'];
  readonly updated: Scalars['DateTime']['output'];
};

export type ReportCategory = {
  readonly __typename: 'ReportCategory';
  readonly category?: Maybe<Category>;
  readonly categoryId?: Maybe<Scalars['String']['output']>;
  readonly isCustom: Scalars['Boolean']['output'];
  readonly report?: Maybe<Report>;
  readonly reportId?: Maybe<Scalars['String']['output']>;
  readonly reportIsCustom: Scalars['Boolean']['output'];
};

export type ReportCategoryFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ReportCategoryFilterInput>>;
  readonly category?: InputMaybe<CategoryFilterInput>;
  readonly categoryId?: InputMaybe<StringOperationFilterInput>;
  readonly isCustom?: InputMaybe<BooleanOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ReportCategoryFilterInput>>;
  readonly report?: InputMaybe<ReportFilterInput>;
  readonly reportId?: InputMaybe<StringOperationFilterInput>;
  readonly reportIsCustom?: InputMaybe<BooleanOperationFilterInput>;
};

export type ReportFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<ReportFilterInput>>;
  readonly categoryLinks?: InputMaybe<ListFilterInputTypeOfReportCategoryFilterInput>;
  readonly cramId?: InputMaybe<StringOperationFilterInput>;
  readonly defaultParameters?: InputMaybe<StringOperationFilterInput>;
  readonly definition?: InputMaybe<ListByteOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly isCustom?: InputMaybe<BooleanOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ReportFilterInput>>;
  readonly printChannel?: InputMaybe<PrintChannelFilterInput>;
  readonly printChannelId?: InputMaybe<IntOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ReportInput = {
  readonly categoryLinks?: InputMaybe<ReadonlyArray<InputMaybe<CategoryLinkInput>>>;
  readonly cramId?: InputMaybe<Scalars['String']['input']>;
  readonly defaultParameters?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly isCustom: Scalars['Boolean']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly printChannelId: Scalars['Int']['input'];
};

export type ReportParameter = {
  readonly __typename: 'ReportParameter';
  readonly parameter: Scalars['String']['output'];
  readonly placeholder: Scalars['String']['output'];
};

export type ReportSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly cramId?: InputMaybe<SortEnumType>;
  readonly defaultParameters?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly isCustom?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly printChannel?: InputMaybe<PrintChannelSortInput>;
  readonly printChannelId?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ReportsConnection = {
  readonly __typename: 'ReportsConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<ReportsEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Report>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReportsEdge = {
  readonly __typename: 'ReportsEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Report;
};

export enum ResidentialCommercial {
  Commercial = 'COMMERCIAL',
  Residential = 'RESIDENTIAL'
}

export type ResourceUsage = {
  readonly __typename: 'ResourceUsage';
  readonly date: Scalars['DateTime']['output'];
  readonly usage: Scalars['Long']['output'];
};

export type Result = {
  readonly __typename: 'Result';
  readonly success: Scalars['Boolean']['output'];
};

export type SmsMessage = {
  readonly __typename: 'SMSMessage';
  readonly accountId: Scalars['String']['output'];
  readonly body: Scalars['String']['output'];
  readonly companyId: Scalars['String']['output'];
  readonly date: Scalars['DateTime']['output'];
  readonly from: Scalars['String']['output'];
  readonly fromCity?: Maybe<Scalars['String']['output']>;
  readonly fromCountry?: Maybe<Scalars['String']['output']>;
  readonly fromName?: Maybe<Scalars['String']['output']>;
  readonly fromState?: Maybe<Scalars['String']['output']>;
  readonly fromZip?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly smsId: Scalars['String']['output'];
  readonly to: Scalars['String']['output'];
  readonly toCity?: Maybe<Scalars['String']['output']>;
  readonly toCountry?: Maybe<Scalars['String']['output']>;
  readonly toName?: Maybe<Scalars['String']['output']>;
  readonly toState?: Maybe<Scalars['String']['output']>;
  readonly toZip?: Maybe<Scalars['String']['output']>;
};

export type SmsMessageFilterInput = {
  readonly accountId?: InputMaybe<StringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<SmsMessageFilterInput>>;
  readonly body?: InputMaybe<StringOperationFilterInput>;
  readonly companyId?: InputMaybe<StringOperationFilterInput>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly from?: InputMaybe<StringOperationFilterInput>;
  readonly fromCity?: InputMaybe<StringOperationFilterInput>;
  readonly fromCountry?: InputMaybe<StringOperationFilterInput>;
  readonly fromName?: InputMaybe<StringOperationFilterInput>;
  readonly fromState?: InputMaybe<StringOperationFilterInput>;
  readonly fromZip?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SmsMessageFilterInput>>;
  readonly smsId?: InputMaybe<StringOperationFilterInput>;
  readonly to?: InputMaybe<StringOperationFilterInput>;
  readonly toCity?: InputMaybe<StringOperationFilterInput>;
  readonly toCountry?: InputMaybe<StringOperationFilterInput>;
  readonly toName?: InputMaybe<StringOperationFilterInput>;
  readonly toState?: InputMaybe<StringOperationFilterInput>;
  readonly toZip?: InputMaybe<StringOperationFilterInput>;
};

export type SmsMessageSortInput = {
  readonly accountId?: InputMaybe<SortEnumType>;
  readonly body?: InputMaybe<SortEnumType>;
  readonly companyId?: InputMaybe<SortEnumType>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly from?: InputMaybe<SortEnumType>;
  readonly fromCity?: InputMaybe<SortEnumType>;
  readonly fromCountry?: InputMaybe<SortEnumType>;
  readonly fromName?: InputMaybe<SortEnumType>;
  readonly fromState?: InputMaybe<SortEnumType>;
  readonly fromZip?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly smsId?: InputMaybe<SortEnumType>;
  readonly to?: InputMaybe<SortEnumType>;
  readonly toCity?: InputMaybe<SortEnumType>;
  readonly toCountry?: InputMaybe<SortEnumType>;
  readonly toName?: InputMaybe<SortEnumType>;
  readonly toState?: InputMaybe<SortEnumType>;
  readonly toZip?: InputMaybe<SortEnumType>;
};

export type SalesOrder = {
  readonly __typename: 'SalesOrder';
  readonly added: Scalars['DateTime']['output'];
  readonly billToAddress1?: Maybe<Scalars['String']['output']>;
  readonly billToAddress2?: Maybe<Scalars['String']['output']>;
  readonly billToCity?: Maybe<Scalars['String']['output']>;
  readonly billToCompany?: Maybe<Scalars['String']['output']>;
  readonly billToCountry?: Maybe<Scalars['String']['output']>;
  readonly billToCustomer?: Maybe<Customer>;
  readonly billToCustomerId?: Maybe<Scalars['String']['output']>;
  readonly billToFirstName?: Maybe<Scalars['String']['output']>;
  readonly billToFullName?: Maybe<Scalars['String']['output']>;
  readonly billToLastName?: Maybe<Scalars['String']['output']>;
  readonly billToPostalCode?: Maybe<Scalars['String']['output']>;
  readonly billToSetAsDefault?: Maybe<Scalars['Boolean']['output']>;
  readonly billToState?: Maybe<Scalars['String']['output']>;
  readonly customerPoNumber?: Maybe<Scalars['String']['output']>;
  readonly date: Scalars['DateTime']['output'];
  readonly deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly invoice?: Maybe<Invoice>;
  readonly isDraft: Scalars['Boolean']['output'];
  readonly lineItems: ReadonlyArray<SalesOrderLineItem>;
  readonly num: Scalars['ID']['output'];
  readonly orderTotal: Scalars['Decimal']['output'];
  readonly remark?: Maybe<Scalars['String']['output']>;
  readonly shipToAddress1?: Maybe<Scalars['String']['output']>;
  readonly shipToAddress2?: Maybe<Scalars['String']['output']>;
  readonly shipToCity?: Maybe<Scalars['String']['output']>;
  readonly shipToCompany?: Maybe<Scalars['String']['output']>;
  readonly shipToCountry?: Maybe<Scalars['String']['output']>;
  readonly shipToFirstName?: Maybe<Scalars['String']['output']>;
  readonly shipToFullName?: Maybe<Scalars['String']['output']>;
  readonly shipToLastName?: Maybe<Scalars['String']['output']>;
  readonly shipToName?: Maybe<Name>;
  readonly shipToNameId?: Maybe<Scalars['String']['output']>;
  readonly shipToPostalCode?: Maybe<Scalars['String']['output']>;
  readonly shipToSetAsDefault?: Maybe<Scalars['Boolean']['output']>;
  readonly shipToState?: Maybe<Scalars['String']['output']>;
  readonly shipVia?: Maybe<Scalars['String']['output']>;
  readonly taxStatus?: Maybe<Scalars['String']['output']>;
  readonly terms?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly webStore?: Maybe<WebStore>;
  readonly webStoreId?: Maybe<Scalars['UUID']['output']>;
  readonly webStoreOrderNumber?: Maybe<Scalars['String']['output']>;
  readonly workOrder?: Maybe<WorkOrder>;
};

export type SalesOrderAddInput = {
  readonly billToAddress1?: InputMaybe<Scalars['String']['input']>;
  readonly billToAddress2?: InputMaybe<Scalars['String']['input']>;
  readonly billToCity?: InputMaybe<Scalars['String']['input']>;
  readonly billToCompany?: InputMaybe<Scalars['String']['input']>;
  readonly billToCountry?: InputMaybe<Scalars['String']['input']>;
  readonly billToCustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly billToFirstName?: InputMaybe<Scalars['String']['input']>;
  readonly billToFullName?: InputMaybe<Scalars['String']['input']>;
  readonly billToLastName?: InputMaybe<Scalars['String']['input']>;
  readonly billToPostalCode?: InputMaybe<Scalars['String']['input']>;
  readonly billToSetAsDefault?: InputMaybe<Scalars['Boolean']['input']>;
  readonly billToState?: InputMaybe<Scalars['String']['input']>;
  readonly customerPoNumber?: InputMaybe<Scalars['String']['input']>;
  readonly date?: InputMaybe<Scalars['DateTime']['input']>;
  readonly deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  readonly lineItems?: InputMaybe<ReadonlyArray<SalesOrderLineItemAddInput>>;
  readonly remark?: InputMaybe<Scalars['String']['input']>;
  readonly shipToAddress1?: InputMaybe<Scalars['String']['input']>;
  readonly shipToAddress2?: InputMaybe<Scalars['String']['input']>;
  readonly shipToCity?: InputMaybe<Scalars['String']['input']>;
  readonly shipToCompany?: InputMaybe<Scalars['String']['input']>;
  readonly shipToCountry?: InputMaybe<Scalars['String']['input']>;
  readonly shipToFirstName?: InputMaybe<Scalars['String']['input']>;
  readonly shipToFullName?: InputMaybe<Scalars['String']['input']>;
  readonly shipToLastName?: InputMaybe<Scalars['String']['input']>;
  readonly shipToNameId?: InputMaybe<Scalars['String']['input']>;
  readonly shipToPostalCode?: InputMaybe<Scalars['String']['input']>;
  readonly shipToSetAsDefault?: InputMaybe<Scalars['Boolean']['input']>;
  readonly shipToState?: InputMaybe<Scalars['String']['input']>;
  readonly shipVia?: InputMaybe<Scalars['String']['input']>;
  readonly taxStatus?: InputMaybe<Scalars['String']['input']>;
  readonly terms?: InputMaybe<Scalars['String']['input']>;
  readonly webStoreId?: InputMaybe<Scalars['UUID']['input']>;
  readonly webStoreOrderNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SalesOrderFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<SalesOrderFilterInput>>;
  readonly billToAddress1?: InputMaybe<StringOperationFilterInput>;
  readonly billToAddress2?: InputMaybe<StringOperationFilterInput>;
  readonly billToCity?: InputMaybe<StringOperationFilterInput>;
  readonly billToCompany?: InputMaybe<StringOperationFilterInput>;
  readonly billToCountry?: InputMaybe<StringOperationFilterInput>;
  readonly billToCustomer?: InputMaybe<CustomerFilterInput>;
  readonly billToCustomerId?: InputMaybe<StringOperationFilterInput>;
  readonly billToFirstName?: InputMaybe<StringOperationFilterInput>;
  readonly billToFullName?: InputMaybe<StringOperationFilterInput>;
  readonly billToLastName?: InputMaybe<StringOperationFilterInput>;
  readonly billToPostalCode?: InputMaybe<StringOperationFilterInput>;
  readonly billToSetAsDefault?: InputMaybe<BooleanOperationFilterInput>;
  readonly billToState?: InputMaybe<StringOperationFilterInput>;
  readonly customerPoNumber?: InputMaybe<StringOperationFilterInput>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly deliveryDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly isDraft?: InputMaybe<BooleanOperationFilterInput>;
  readonly lineItems?: InputMaybe<SalesOrderLineItemFilterInput>;
  readonly num?: InputMaybe<IdOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SalesOrderFilterInput>>;
  readonly orderTotal?: InputMaybe<DecimalOperationFilterInput>;
  readonly remark?: InputMaybe<StringOperationFilterInput>;
  readonly shipToAddress1?: InputMaybe<StringOperationFilterInput>;
  readonly shipToAddress2?: InputMaybe<StringOperationFilterInput>;
  readonly shipToCity?: InputMaybe<StringOperationFilterInput>;
  readonly shipToCompany?: InputMaybe<StringOperationFilterInput>;
  readonly shipToCountry?: InputMaybe<StringOperationFilterInput>;
  readonly shipToFirstName?: InputMaybe<StringOperationFilterInput>;
  readonly shipToFullName?: InputMaybe<StringOperationFilterInput>;
  readonly shipToLastName?: InputMaybe<StringOperationFilterInput>;
  readonly shipToName?: InputMaybe<NameFilterInput>;
  readonly shipToNameId?: InputMaybe<StringOperationFilterInput>;
  readonly shipToPostalCode?: InputMaybe<StringOperationFilterInput>;
  readonly shipToSetAsDefault?: InputMaybe<BooleanOperationFilterInput>;
  readonly shipToState?: InputMaybe<StringOperationFilterInput>;
  readonly shipVia?: InputMaybe<StringOperationFilterInput>;
  readonly taxStatus?: InputMaybe<StringOperationFilterInput>;
  readonly terms?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
  readonly webStore?: InputMaybe<WebStoreFilterInput>;
  readonly webStoreId?: InputMaybe<UuidOperationFilterInput>;
  readonly webStoreOrderNumber?: InputMaybe<StringOperationFilterInput>;
};

export type SalesOrderLineItem = {
  readonly __typename: 'SalesOrderLineItem';
  readonly added: Scalars['DateTime']['output'];
  readonly amount?: Maybe<Scalars['Decimal']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly lineNum: Scalars['Int']['output'];
  readonly ordered?: Maybe<Scalars['Decimal']['output']>;
  readonly price?: Maybe<Scalars['Decimal']['output']>;
  readonly salesOrder?: Maybe<SalesOrder>;
  readonly shipped?: Maybe<Scalars['Decimal']['output']>;
  readonly unit?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type SalesOrderLineItemAddInput = {
  readonly amount: Scalars['Decimal']['input'];
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly itemId: Scalars['String']['input'];
  /** The quantity that have been ordered */
  readonly ordered: Scalars['Decimal']['input'];
  readonly price: Scalars['Decimal']['input'];
  readonly shipped?: InputMaybe<Scalars['Decimal']['input']>;
  readonly unit?: InputMaybe<Scalars['String']['input']>;
};

export type SalesOrderLineItemFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly amount?: InputMaybe<DecimalOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<SalesOrderLineItemFilterInput>>;
  readonly description?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly item?: InputMaybe<ItemFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly lineNum?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SalesOrderLineItemFilterInput>>;
  readonly ordered?: InputMaybe<DecimalOperationFilterInput>;
  readonly price?: InputMaybe<DecimalOperationFilterInput>;
  readonly salesOrder?: InputMaybe<SalesOrderFilterInput>;
  readonly shipped?: InputMaybe<DecimalOperationFilterInput>;
  readonly unit?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type SalesOrderLineItemUpdateInput = {
  readonly amount: Scalars['Decimal']['input'];
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['UUID']['input']>;
  readonly itemId: Scalars['String']['input'];
  /** The quantity that have been ordered */
  readonly ordered: Scalars['Decimal']['input'];
  readonly price: Scalars['Decimal']['input'];
  readonly shipped?: InputMaybe<Scalars['Decimal']['input']>;
  readonly unit?: InputMaybe<Scalars['String']['input']>;
};

export type SalesOrderSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly billToAddress1?: InputMaybe<SortEnumType>;
  readonly billToAddress2?: InputMaybe<SortEnumType>;
  readonly billToCity?: InputMaybe<SortEnumType>;
  readonly billToCompany?: InputMaybe<SortEnumType>;
  readonly billToCountry?: InputMaybe<SortEnumType>;
  readonly billToCustomer?: InputMaybe<CustomerSortInput>;
  readonly billToCustomerId?: InputMaybe<SortEnumType>;
  readonly billToFirstName?: InputMaybe<SortEnumType>;
  readonly billToFullName?: InputMaybe<SortEnumType>;
  readonly billToLastName?: InputMaybe<SortEnumType>;
  readonly billToPostalCode?: InputMaybe<SortEnumType>;
  readonly billToSetAsDefault?: InputMaybe<SortEnumType>;
  readonly billToState?: InputMaybe<SortEnumType>;
  readonly customerPoNumber?: InputMaybe<SortEnumType>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly deliveryDate?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly isDraft?: InputMaybe<SortEnumType>;
  readonly num?: InputMaybe<SortEnumType>;
  readonly orderTotal?: InputMaybe<SortEnumType>;
  readonly remark?: InputMaybe<SortEnumType>;
  readonly shipToAddress1?: InputMaybe<SortEnumType>;
  readonly shipToAddress2?: InputMaybe<SortEnumType>;
  readonly shipToCity?: InputMaybe<SortEnumType>;
  readonly shipToCompany?: InputMaybe<SortEnumType>;
  readonly shipToCountry?: InputMaybe<SortEnumType>;
  readonly shipToFirstName?: InputMaybe<SortEnumType>;
  readonly shipToFullName?: InputMaybe<SortEnumType>;
  readonly shipToLastName?: InputMaybe<SortEnumType>;
  readonly shipToName?: InputMaybe<NameSortInput>;
  readonly shipToNameId?: InputMaybe<SortEnumType>;
  readonly shipToPostalCode?: InputMaybe<SortEnumType>;
  readonly shipToSetAsDefault?: InputMaybe<SortEnumType>;
  readonly shipToState?: InputMaybe<SortEnumType>;
  readonly shipVia?: InputMaybe<SortEnumType>;
  readonly taxStatus?: InputMaybe<SortEnumType>;
  readonly terms?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
  readonly webStore?: InputMaybe<WebStoreSortInput>;
  readonly webStoreId?: InputMaybe<SortEnumType>;
  readonly webStoreOrderNumber?: InputMaybe<SortEnumType>;
};

export type SalesOrderUpdateInput = {
  readonly billToAddress1?: InputMaybe<Scalars['String']['input']>;
  readonly billToAddress2?: InputMaybe<Scalars['String']['input']>;
  readonly billToCity?: InputMaybe<Scalars['String']['input']>;
  readonly billToCompany?: InputMaybe<Scalars['String']['input']>;
  readonly billToCountry?: InputMaybe<Scalars['String']['input']>;
  readonly billToCustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly billToFirstName?: InputMaybe<Scalars['String']['input']>;
  readonly billToFullName?: InputMaybe<Scalars['String']['input']>;
  readonly billToLastName?: InputMaybe<Scalars['String']['input']>;
  readonly billToPostalCode?: InputMaybe<Scalars['String']['input']>;
  readonly billToSetAsDefault?: InputMaybe<Scalars['Boolean']['input']>;
  readonly billToState?: InputMaybe<Scalars['String']['input']>;
  readonly customerPoNumber?: InputMaybe<Scalars['String']['input']>;
  readonly date?: InputMaybe<Scalars['DateTime']['input']>;
  readonly deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly id: Scalars['UUID']['input'];
  readonly isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  readonly lineItems?: InputMaybe<ReadonlyArray<SalesOrderLineItemUpdateInput>>;
  readonly remark?: InputMaybe<Scalars['String']['input']>;
  readonly shipToAddress1?: InputMaybe<Scalars['String']['input']>;
  readonly shipToAddress2?: InputMaybe<Scalars['String']['input']>;
  readonly shipToCity?: InputMaybe<Scalars['String']['input']>;
  readonly shipToCompany?: InputMaybe<Scalars['String']['input']>;
  readonly shipToCountry?: InputMaybe<Scalars['String']['input']>;
  readonly shipToFirstName?: InputMaybe<Scalars['String']['input']>;
  readonly shipToFullName?: InputMaybe<Scalars['String']['input']>;
  readonly shipToLastName?: InputMaybe<Scalars['String']['input']>;
  readonly shipToNameId?: InputMaybe<Scalars['String']['input']>;
  readonly shipToPostalCode?: InputMaybe<Scalars['String']['input']>;
  readonly shipToSetAsDefault?: InputMaybe<Scalars['Boolean']['input']>;
  readonly shipToState?: InputMaybe<Scalars['String']['input']>;
  readonly shipVia?: InputMaybe<Scalars['String']['input']>;
  readonly taxStatus?: InputMaybe<Scalars['String']['input']>;
  readonly terms?: InputMaybe<Scalars['String']['input']>;
  readonly webStoreId?: InputMaybe<Scalars['UUID']['input']>;
  readonly webStoreOrderNumber?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type SalesOrdersConnection = {
  readonly __typename: 'SalesOrdersConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<SalesOrdersEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<SalesOrder>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesOrdersEdge = {
  readonly __typename: 'SalesOrdersEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: SalesOrder;
};

export type SalesTransaction = {
  readonly customer?: Maybe<Customer>;
  readonly customerId?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly salesperson?: Maybe<Salesperson>;
  readonly salespersonId?: Maybe<Scalars['String']['output']>;
  readonly shipToName?: Maybe<Name>;
  readonly shipToNameId?: Maybe<Scalars['String']['output']>;
};

export type SalesTransactionShipment = {
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly salesTransaction: SalesTransaction;
  readonly shipment?: Maybe<Shipment>;
  readonly shipmentId?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export enum SalesType {
  Invoice = 'INVOICE',
  WorkOrder = 'WORK_ORDER'
}

export type SalesVtermIframeCompletedInput = {
  readonly authorizationCode?: InputMaybe<Scalars['String']['input']>;
  readonly authorizationResult?: InputMaybe<Scalars['String']['input']>;
  readonly maskedAccountNumber?: InputMaybe<Scalars['String']['input']>;
  /** Visa, MasterCard, etc */
  readonly paymentTypeId?: InputMaybe<Scalars['String']['input']>;
  readonly success: Scalars['Boolean']['input'];
  readonly ticketNumber?: InputMaybe<Scalars['String']['input']>;
  readonly token?: InputMaybe<Scalars['String']['input']>;
  /** Amount from the payment processor */
  readonly transactionAmount?: InputMaybe<Scalars['Decimal']['input']>;
  /** ID of the credit card transaction we're processing. */
  readonly transactionId: Scalars['ID']['input'];
  /** Transaction ID of the successful transaction. */
  readonly transactionIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type SalesVtermIframeUrlInput = {
  readonly amount: Scalars['Decimal']['input'];
  readonly transactionId: Scalars['ID']['input'];
};

export type SalesVtermIframeUrlResult = {
  readonly __typename: 'SalesVtermIframeUrlResult';
  /** Error when something goes wrong */
  readonly error?: Maybe<Scalars['String']['output']>;
  /** Will only be null when no processor is enabled */
  readonly processor?: Maybe<Scalars['String']['output']>;
  /** URL to use in the iframe src */
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type SalespeopleConnection = {
  readonly __typename: 'SalespeopleConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<SalespeopleEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Salesperson>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalespeopleEdge = {
  readonly __typename: 'SalespeopleEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Salesperson;
};

export type Salesperson = {
  readonly __typename: 'Salesperson';
  readonly commissions: ReadonlyArray<SalespersonCommission>;
  readonly currentJob: Scalars['ID']['output'];
  readonly custom?: Maybe<Scalars['String']['output']>;
  readonly defaultCommissionCode?: Maybe<Scalars['String']['output']>;
  readonly extension?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly primaryJob: Scalars['ID']['output'];
  readonly userNumber?: Maybe<Scalars['String']['output']>;
};

export type SalespersonAddInput = {
  /** If null, no changes will be made. If not null, commissions will be updated to match specified values. */
  readonly commissions?: InputMaybe<ReadonlyArray<SalespersonCommissionInput>>;
  readonly extension?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly phone?: InputMaybe<Scalars['String']['input']>;
};

export type SalespersonCommission = {
  readonly __typename: 'SalespersonCommission';
  readonly category: Scalars['String']['output'];
  readonly rate: Scalars['String']['output'];
  readonly salesperson: Salesperson;
  readonly salespersonId: Scalars['String']['output'];
};

export type SalespersonCommissionFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<SalespersonCommissionFilterInput>>;
  readonly category?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SalespersonCommissionFilterInput>>;
  readonly rate?: InputMaybe<StringOperationFilterInput>;
  readonly salesperson?: InputMaybe<SalespersonFilterInput>;
  readonly salespersonId?: InputMaybe<StringOperationFilterInput>;
};

export type SalespersonCommissionInput = {
  readonly category?: InputMaybe<Scalars['String']['input']>;
  readonly rate?: InputMaybe<Scalars['String']['input']>;
};

export type SalespersonFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<SalespersonFilterInput>>;
  readonly commissionCodeA?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeB?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeC?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeD?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeE?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeF?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeG?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeH?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeI?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeJ?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeK?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeL?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeM?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeN?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeO?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeP?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeQ?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeR?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeS?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeT?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeU?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeV?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeW?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeX?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeY?: InputMaybe<StringOperationFilterInput>;
  readonly commissionCodeZ?: InputMaybe<StringOperationFilterInput>;
  readonly commissions?: InputMaybe<ListFilterInputTypeOfSalespersonCommissionFilterInput>;
  readonly currentJob?: InputMaybe<IdOperationFilterInput>;
  readonly custom?: InputMaybe<StringOperationFilterInput>;
  readonly defaultCommissionCode?: InputMaybe<StringOperationFilterInput>;
  readonly extension?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SalespersonFilterInput>>;
  readonly phone?: InputMaybe<StringOperationFilterInput>;
  readonly primaryJob?: InputMaybe<IdOperationFilterInput>;
  readonly userNumber?: InputMaybe<StringOperationFilterInput>;
};

export type SalespersonSortInput = {
  readonly commissionCodeA?: InputMaybe<SortEnumType>;
  readonly commissionCodeB?: InputMaybe<SortEnumType>;
  readonly commissionCodeC?: InputMaybe<SortEnumType>;
  readonly commissionCodeD?: InputMaybe<SortEnumType>;
  readonly commissionCodeE?: InputMaybe<SortEnumType>;
  readonly commissionCodeF?: InputMaybe<SortEnumType>;
  readonly commissionCodeG?: InputMaybe<SortEnumType>;
  readonly commissionCodeH?: InputMaybe<SortEnumType>;
  readonly commissionCodeI?: InputMaybe<SortEnumType>;
  readonly commissionCodeJ?: InputMaybe<SortEnumType>;
  readonly commissionCodeK?: InputMaybe<SortEnumType>;
  readonly commissionCodeL?: InputMaybe<SortEnumType>;
  readonly commissionCodeM?: InputMaybe<SortEnumType>;
  readonly commissionCodeN?: InputMaybe<SortEnumType>;
  readonly commissionCodeO?: InputMaybe<SortEnumType>;
  readonly commissionCodeP?: InputMaybe<SortEnumType>;
  readonly commissionCodeQ?: InputMaybe<SortEnumType>;
  readonly commissionCodeR?: InputMaybe<SortEnumType>;
  readonly commissionCodeS?: InputMaybe<SortEnumType>;
  readonly commissionCodeT?: InputMaybe<SortEnumType>;
  readonly commissionCodeU?: InputMaybe<SortEnumType>;
  readonly commissionCodeV?: InputMaybe<SortEnumType>;
  readonly commissionCodeW?: InputMaybe<SortEnumType>;
  readonly commissionCodeX?: InputMaybe<SortEnumType>;
  readonly commissionCodeY?: InputMaybe<SortEnumType>;
  readonly commissionCodeZ?: InputMaybe<SortEnumType>;
  readonly currentJob?: InputMaybe<SortEnumType>;
  readonly custom?: InputMaybe<SortEnumType>;
  readonly defaultCommissionCode?: InputMaybe<SortEnumType>;
  readonly extension?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly phone?: InputMaybe<SortEnumType>;
  readonly primaryJob?: InputMaybe<SortEnumType>;
  readonly userNumber?: InputMaybe<SortEnumType>;
};

export type SalespersonUpdateInput = {
  /** If null, no changes will be made. If not null, commissions will be updated to match specified values. */
  readonly commissions?: InputMaybe<ReadonlyArray<SalespersonCommissionInput>>;
  readonly extension?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly phone?: InputMaybe<Scalars['String']['input']>;
};

export type SalespersonValidateInput = {
  readonly commissions?: InputMaybe<ReadonlyArray<SalespersonCommissionInput>>;
  readonly extension?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
};

export type SearchIndexEntity = {
  readonly __typename: 'SearchIndexEntity';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type SearchIndexField = {
  readonly __typename: 'SearchIndexField';
  readonly defaultEnabled: Scalars['Boolean']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Enabling or disabling a field with children has no effect on the children. */
  readonly enabled: Scalars['Boolean']['output'];
  /** The unique ID of the field including it's parent(s) */
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly parentId?: Maybe<Scalars['String']['output']>;
};

export type SearchIndexFieldsUpdateInput = {
  readonly companyId: Scalars['String']['input'];
  readonly entityId: Scalars['String']['input'];
  readonly fields: ReadonlyArray<Scalars['String']['input']>;
};

export type SearchIndexQueueStat = {
  readonly __typename: 'SearchIndexQueueStat';
  readonly entityName: Scalars['String']['output'];
  readonly queueLength: Scalars['Int']['output'];
};

export type SearchIndexStatsCompany = {
  readonly __typename: 'SearchIndexStatsCompany';
  readonly companyId: Scalars['String']['output'];
  readonly companyName: Scalars['String']['output'];
  readonly details: ReadonlyArray<SearchIndexQueueStat>;
};

export type SearchResult = {
  readonly __typename: 'SearchResult';
  readonly invoice?: Maybe<Invoice>;
  readonly rental?: Maybe<Rental>;
  readonly workOrder?: Maybe<WorkOrder>;
};

export type SeedDataOption = {
  readonly __typename: 'SeedDataOption';
  readonly items?: Maybe<ReadonlyArray<SeedDataOption>>;
  readonly key: Scalars['String']['output'];
  readonly migration?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly table?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['Boolean']['output']>;
};

export type SerialNumber = {
  readonly __typename: 'SerialNumber';
  readonly costAmount: Scalars['Decimal']['output'];
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly invoices: ReadonlyArray<Invoice>;
  readonly item?: Maybe<Item>;
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly sellPrice: Scalars['Decimal']['output'];
  readonly serialNum?: Maybe<Scalars['String']['output']>;
  readonly serialNumberPosted?: Maybe<SerialNumberPosted>;
  readonly workOrders: ReadonlyArray<WorkOrder>;
};

export type SerialNumberFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<SerialNumberFilterInput>>;
  readonly costAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly field2?: InputMaybe<StringOperationFilterInput>;
  readonly field3?: InputMaybe<StringOperationFilterInput>;
  readonly field4?: InputMaybe<StringOperationFilterInput>;
  readonly field7?: InputMaybe<StringOperationFilterInput>;
  readonly field8?: InputMaybe<StringOperationFilterInput>;
  readonly field9?: InputMaybe<StringOperationFilterInput>;
  readonly field10?: InputMaybe<StringOperationFilterInput>;
  readonly field11?: InputMaybe<StringOperationFilterInput>;
  readonly field12?: InputMaybe<StringOperationFilterInput>;
  readonly field13?: InputMaybe<StringOperationFilterInput>;
  readonly field14?: InputMaybe<StringOperationFilterInput>;
  readonly field15?: InputMaybe<StringOperationFilterInput>;
  readonly field16?: InputMaybe<StringOperationFilterInput>;
  readonly field17?: InputMaybe<StringOperationFilterInput>;
  readonly field18?: InputMaybe<StringOperationFilterInput>;
  readonly field19?: InputMaybe<StringOperationFilterInput>;
  readonly field20?: InputMaybe<StringOperationFilterInput>;
  readonly field21?: InputMaybe<StringOperationFilterInput>;
  readonly field22?: InputMaybe<StringOperationFilterInput>;
  readonly field23?: InputMaybe<StringOperationFilterInput>;
  readonly field24?: InputMaybe<StringOperationFilterInput>;
  readonly field25?: InputMaybe<StringOperationFilterInput>;
  readonly field26?: InputMaybe<StringOperationFilterInput>;
  readonly field27?: InputMaybe<StringOperationFilterInput>;
  readonly field28?: InputMaybe<StringOperationFilterInput>;
  readonly field29?: InputMaybe<StringOperationFilterInput>;
  readonly field30?: InputMaybe<StringOperationFilterInput>;
  readonly field31?: InputMaybe<StringOperationFilterInput>;
  readonly field32?: InputMaybe<StringOperationFilterInput>;
  readonly field33?: InputMaybe<StringOperationFilterInput>;
  readonly field34?: InputMaybe<StringOperationFilterInput>;
  readonly field35?: InputMaybe<StringOperationFilterInput>;
  readonly field36?: InputMaybe<StringOperationFilterInput>;
  readonly field37?: InputMaybe<StringOperationFilterInput>;
  readonly field38?: InputMaybe<StringOperationFilterInput>;
  readonly field39?: InputMaybe<StringOperationFilterInput>;
  readonly field40?: InputMaybe<StringOperationFilterInput>;
  readonly field41?: InputMaybe<StringOperationFilterInput>;
  readonly field42?: InputMaybe<StringOperationFilterInput>;
  readonly field43?: InputMaybe<StringOperationFilterInput>;
  readonly field44?: InputMaybe<StringOperationFilterInput>;
  readonly field45?: InputMaybe<StringOperationFilterInput>;
  readonly field46?: InputMaybe<StringOperationFilterInput>;
  readonly field47?: InputMaybe<StringOperationFilterInput>;
  readonly field48?: InputMaybe<StringOperationFilterInput>;
  readonly field49?: InputMaybe<StringOperationFilterInput>;
  readonly field50?: InputMaybe<StringOperationFilterInput>;
  readonly field51?: InputMaybe<StringOperationFilterInput>;
  readonly field52?: InputMaybe<StringOperationFilterInput>;
  readonly field53?: InputMaybe<StringOperationFilterInput>;
  readonly field54?: InputMaybe<StringOperationFilterInput>;
  readonly field55?: InputMaybe<StringOperationFilterInput>;
  readonly field56?: InputMaybe<StringOperationFilterInput>;
  readonly field57?: InputMaybe<StringOperationFilterInput>;
  readonly field58?: InputMaybe<StringOperationFilterInput>;
  readonly field59?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly location?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SerialNumberFilterInput>>;
  readonly sellPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly serialNum?: InputMaybe<StringOperationFilterInput>;
  readonly serialNumberPosted?: InputMaybe<SerialNumberPostedFilterInput>;
};

export type SerialNumberPosted = {
  readonly __typename: 'SerialNumberPosted';
  readonly apPayAmount: Scalars['Decimal']['output'];
  readonly arPayAmount: Scalars['Decimal']['output'];
  readonly billPtr?: Maybe<Scalars['String']['output']>;
  readonly buyAmount: Scalars['Decimal']['output'];
  readonly custom23: Scalars['Decimal']['output'];
  readonly customerOrder: Scalars['Decimal']['output'];
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly freightAdjustment: Scalars['Decimal']['output'];
  readonly glAccountId: Scalars['ID']['output'];
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly invoicePtr?: Maybe<Scalars['String']['output']>;
  readonly materialPtr?: Maybe<Scalars['String']['output']>;
  readonly orderPtr?: Maybe<Scalars['String']['output']>;
  readonly poPtr?: Maybe<Scalars['String']['output']>;
  readonly priorBuy: Scalars['Decimal']['output'];
  readonly priorSell: Scalars['Decimal']['output'];
  readonly quantityOnHand: Scalars['Decimal']['output'];
  readonly rebateQuantity: Scalars['Decimal']['output'];
  readonly saleDate?: Maybe<Scalars['DateTime']['output']>;
  readonly sellAmount: Scalars['Decimal']['output'];
  readonly sellQuantity: Scalars['Decimal']['output'];
  readonly serialNumber?: Maybe<SerialNumber>;
  readonly serviceAmount: Scalars['Decimal']['output'];
  readonly serviceInvoicePtr?: Maybe<Scalars['String']['output']>;
  readonly setupAmount: Scalars['Decimal']['output'];
  readonly vendorOrder: Scalars['Decimal']['output'];
};

export type SerialNumberPostedFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<SerialNumberPostedFilterInput>>;
  readonly apPayAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly arPayAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly billPtr?: InputMaybe<StringOperationFilterInput>;
  readonly buyAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly custom23?: InputMaybe<DecimalOperationFilterInput>;
  readonly customerOrder?: InputMaybe<DecimalOperationFilterInput>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly freightAdjustment?: InputMaybe<DecimalOperationFilterInput>;
  readonly glAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly invoicePtr?: InputMaybe<StringOperationFilterInput>;
  readonly materialPtr?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SerialNumberPostedFilterInput>>;
  readonly orderPtr?: InputMaybe<StringOperationFilterInput>;
  readonly poPtr?: InputMaybe<StringOperationFilterInput>;
  readonly priorBuy?: InputMaybe<DecimalOperationFilterInput>;
  readonly priorSell?: InputMaybe<DecimalOperationFilterInput>;
  readonly quantityOnHand?: InputMaybe<DecimalOperationFilterInput>;
  readonly rebateQuantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly saleDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly sellAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly sellQuantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly serialNumber?: InputMaybe<SerialNumberFilterInput>;
  readonly serviceAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly serviceInvoicePtr?: InputMaybe<StringOperationFilterInput>;
  readonly setupAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly vendorOrder?: InputMaybe<DecimalOperationFilterInput>;
};

export type SerialNumberPostedSortInput = {
  readonly apPayAmount?: InputMaybe<SortEnumType>;
  readonly arPayAmount?: InputMaybe<SortEnumType>;
  readonly billPtr?: InputMaybe<SortEnumType>;
  readonly buyAmount?: InputMaybe<SortEnumType>;
  readonly custom23?: InputMaybe<SortEnumType>;
  readonly customerOrder?: InputMaybe<SortEnumType>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly freightAdjustment?: InputMaybe<SortEnumType>;
  readonly glAccountId?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly invoicePtr?: InputMaybe<SortEnumType>;
  readonly materialPtr?: InputMaybe<SortEnumType>;
  readonly orderPtr?: InputMaybe<SortEnumType>;
  readonly poPtr?: InputMaybe<SortEnumType>;
  readonly priorBuy?: InputMaybe<SortEnumType>;
  readonly priorSell?: InputMaybe<SortEnumType>;
  readonly quantityOnHand?: InputMaybe<SortEnumType>;
  readonly rebateQuantity?: InputMaybe<SortEnumType>;
  readonly saleDate?: InputMaybe<SortEnumType>;
  readonly sellAmount?: InputMaybe<SortEnumType>;
  readonly sellQuantity?: InputMaybe<SortEnumType>;
  readonly serialNumber?: InputMaybe<SerialNumberSortInput>;
  readonly serviceAmount?: InputMaybe<SortEnumType>;
  readonly serviceInvoicePtr?: InputMaybe<SortEnumType>;
  readonly setupAmount?: InputMaybe<SortEnumType>;
  readonly vendorOrder?: InputMaybe<SortEnumType>;
};

export type SerialNumberSortInput = {
  readonly costAmount?: InputMaybe<SortEnumType>;
  readonly field2?: InputMaybe<SortEnumType>;
  readonly field3?: InputMaybe<SortEnumType>;
  readonly field4?: InputMaybe<SortEnumType>;
  readonly field7?: InputMaybe<SortEnumType>;
  readonly field8?: InputMaybe<SortEnumType>;
  readonly field9?: InputMaybe<SortEnumType>;
  readonly field10?: InputMaybe<SortEnumType>;
  readonly field11?: InputMaybe<SortEnumType>;
  readonly field12?: InputMaybe<SortEnumType>;
  readonly field13?: InputMaybe<SortEnumType>;
  readonly field14?: InputMaybe<SortEnumType>;
  readonly field15?: InputMaybe<SortEnumType>;
  readonly field16?: InputMaybe<SortEnumType>;
  readonly field17?: InputMaybe<SortEnumType>;
  readonly field18?: InputMaybe<SortEnumType>;
  readonly field19?: InputMaybe<SortEnumType>;
  readonly field20?: InputMaybe<SortEnumType>;
  readonly field21?: InputMaybe<SortEnumType>;
  readonly field22?: InputMaybe<SortEnumType>;
  readonly field23?: InputMaybe<SortEnumType>;
  readonly field24?: InputMaybe<SortEnumType>;
  readonly field25?: InputMaybe<SortEnumType>;
  readonly field26?: InputMaybe<SortEnumType>;
  readonly field27?: InputMaybe<SortEnumType>;
  readonly field28?: InputMaybe<SortEnumType>;
  readonly field29?: InputMaybe<SortEnumType>;
  readonly field30?: InputMaybe<SortEnumType>;
  readonly field31?: InputMaybe<SortEnumType>;
  readonly field32?: InputMaybe<SortEnumType>;
  readonly field33?: InputMaybe<SortEnumType>;
  readonly field34?: InputMaybe<SortEnumType>;
  readonly field35?: InputMaybe<SortEnumType>;
  readonly field36?: InputMaybe<SortEnumType>;
  readonly field37?: InputMaybe<SortEnumType>;
  readonly field38?: InputMaybe<SortEnumType>;
  readonly field39?: InputMaybe<SortEnumType>;
  readonly field40?: InputMaybe<SortEnumType>;
  readonly field41?: InputMaybe<SortEnumType>;
  readonly field42?: InputMaybe<SortEnumType>;
  readonly field43?: InputMaybe<SortEnumType>;
  readonly field44?: InputMaybe<SortEnumType>;
  readonly field45?: InputMaybe<SortEnumType>;
  readonly field46?: InputMaybe<SortEnumType>;
  readonly field47?: InputMaybe<SortEnumType>;
  readonly field48?: InputMaybe<SortEnumType>;
  readonly field49?: InputMaybe<SortEnumType>;
  readonly field50?: InputMaybe<SortEnumType>;
  readonly field51?: InputMaybe<SortEnumType>;
  readonly field52?: InputMaybe<SortEnumType>;
  readonly field53?: InputMaybe<SortEnumType>;
  readonly field54?: InputMaybe<SortEnumType>;
  readonly field55?: InputMaybe<SortEnumType>;
  readonly field56?: InputMaybe<SortEnumType>;
  readonly field57?: InputMaybe<SortEnumType>;
  readonly field58?: InputMaybe<SortEnumType>;
  readonly field59?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly itemId?: InputMaybe<SortEnumType>;
  readonly location?: InputMaybe<SortEnumType>;
  readonly sellPrice?: InputMaybe<SortEnumType>;
  readonly serialNum?: InputMaybe<SortEnumType>;
  readonly serialNumberPosted?: InputMaybe<SerialNumberPostedSortInput>;
};

/** A connection to a list of items. */
export type SerialNumbersConnection = {
  readonly __typename: 'SerialNumbersConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<SerialNumbersEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<SerialNumber>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SerialNumbersEdge = {
  readonly __typename: 'SerialNumbersEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: SerialNumber;
};

export type ServerFileErrors = {
  readonly __typename: 'ServerFileErrors';
  readonly availableUpdates: ReadonlyArray<LegacyServerFile>;
  readonly missingFiles: ReadonlyArray<Scalars['String']['output']>;
};

export type ServerService = {
  readonly __typename: 'ServerService';
  readonly application?: Maybe<Scalars['String']['output']>;
  readonly authorizationCode?: Maybe<Scalars['String']['output']>;
  readonly cpuPercent: Scalars['Int']['output'];
  readonly cpuPercentHistory?: Maybe<ReadonlyArray<Maybe<ResourceUsage>>>;
  readonly directory?: Maybe<Scalars['String']['output']>;
  readonly dllFile?: Maybe<Scalars['String']['output']>;
  readonly fmtFile?: Maybe<Scalars['String']['output']>;
  readonly httpPort?: Maybe<Scalars['Int']['output']>;
  readonly httpUri?: Maybe<Scalars['URL']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly isRunning: Scalars['Boolean']['output'];
  readonly legacyClientPort: Scalars['Int']['output'];
  readonly memory: Scalars['Long']['output'];
  readonly memoryHistory?: Maybe<ReadonlyArray<Maybe<ResourceUsage>>>;
  readonly output?: Maybe<Scalars['String']['output']>;
  readonly parFile?: Maybe<Scalars['String']['output']>;
  readonly peakWorkingSet: Scalars['Long']['output'];
  readonly requiresAuthorization: Scalars['Boolean']['output'];
  readonly serverNumber?: Maybe<Scalars['Int']['output']>;
  readonly shutdownTimeout: Scalars['Int']['output'];
  readonly startup: LegacyServerStartup;
  readonly webServerUri?: Maybe<Scalars['String']['output']>;
  readonly workingSet: Scalars['Long']['output'];
  readonly workingSetHistory?: Maybe<ReadonlyArray<Maybe<ResourceUsage>>>;
};

export type ServerVersion = {
  readonly __typename: 'ServerVersion';
  readonly build: Scalars['Int']['output'];
  readonly major: Scalars['Int']['output'];
  readonly minor: Scalars['Int']['output'];
  readonly revision: Scalars['Int']['output'];
};

export type Shipment = {
  readonly __typename: 'Shipment';
  readonly added: Scalars['DateTime']['output'];
  readonly date: Scalars['DateTime']['output'];
  readonly deliveryAddress?: Maybe<Address>;
  readonly deliveryAddressId?: Maybe<Scalars['String']['output']>;
  readonly duration: Scalars['TimeSpan']['output'];
  readonly id: Scalars['String']['output'];
  readonly load: Load;
  readonly loadId?: Maybe<Scalars['String']['output']>;
  readonly logs?: Maybe<ReadonlyArray<ShipmentLog>>;
  readonly number: Scalars['Int']['output'];
  readonly pickupAddress?: Maybe<Address>;
  readonly pickupAddressId?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tracking?: Maybe<Scalars['String']['output']>;
  /** All the invoices, work orders and rentals on the shipment */
  readonly transactionShipments: ReadonlyArray<TransactionShipment>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};


export type ShipmentTransactionShipmentsArgs = {
  includeInvoices?: Scalars['Boolean']['input'];
  includeRentals?: Scalars['Boolean']['input'];
  includeWorkOrders?: Scalars['Boolean']['input'];
};

export type ShipmentFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<ShipmentFilterInput>>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly deliveryAddress?: InputMaybe<AddressFilterInput>;
  readonly deliveryAddressId?: InputMaybe<StringOperationFilterInput>;
  readonly duration?: InputMaybe<TimeSpanOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly load?: InputMaybe<LoadFilterInput>;
  readonly loadId?: InputMaybe<StringOperationFilterInput>;
  readonly logs?: InputMaybe<ListFilterInputTypeOfShipmentLogFilterInput>;
  readonly number?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ShipmentFilterInput>>;
  readonly pickupAddress?: InputMaybe<AddressFilterInput>;
  readonly pickupAddressId?: InputMaybe<StringOperationFilterInput>;
  readonly status?: InputMaybe<StringOperationFilterInput>;
  readonly tracking?: InputMaybe<StringOperationFilterInput>;
  readonly type?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ShipmentLog = {
  readonly __typename: 'ShipmentLog';
  readonly added: Scalars['DateTime']['output'];
  readonly date: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type ShipmentLogFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<ShipmentLogFilterInput>>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ShipmentLogFilterInput>>;
  readonly status?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StatisticDatedValue = {
  readonly __typename: 'StatisticDatedValue';
  readonly date: Scalars['DateTime']['output'];
  readonly value: Scalars['Long']['output'];
};

export type StatisticValue = {
  readonly __typename: 'StatisticValue';
  readonly id: Scalars['String']['output'];
  readonly value: Scalars['Long']['output'];
};

export type StringOperationFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly contains?: InputMaybe<Scalars['String']['input']>;
  readonly endsWith?: InputMaybe<Scalars['String']['input']>;
  readonly eq?: InputMaybe<Scalars['String']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly ncontains?: InputMaybe<Scalars['String']['input']>;
  readonly nendsWith?: InputMaybe<Scalars['String']['input']>;
  readonly neq?: InputMaybe<Scalars['String']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly nstartsWith?: InputMaybe<Scalars['String']['input']>;
  readonly or?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Stylesheet = {
  readonly __typename: 'Stylesheet';
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly isCustom: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly styles?: Maybe<ReadonlyArray<Scalars['Byte']['output']>>;
  readonly updated: Scalars['DateTime']['output'];
};

export type StylesheetFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<StylesheetFilterInput>>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly isCustom?: InputMaybe<BooleanOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<StylesheetFilterInput>>;
  readonly styles?: InputMaybe<ListByteOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type StylesheetSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly isCustom?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

export type Subscription = {
  readonly __typename: 'Subscription';
  readonly bankStatementTransactions: BankStatementTransaction;
  readonly companyAddStatuses: ReadonlyArray<CompanyAddStatus>;
  readonly companyBackupLogs: ReadonlyArray<CompanyBackupLog>;
  readonly companyBackups: ReadonlyArray<CompanyBackup>;
  readonly companyDuplicateStatus: CompanyDuplicateStatus;
  readonly companyImportCreateStatus: CompanyImportCreateStatus;
  readonly companyImportDataStatuses: CompanyImportDataStatus;
  readonly companyImportMigrateStatuses: CompanyImportMigrateStatus;
  readonly companyMigrationStatus: CompanyMigrationStatus;
  readonly companyRestoreStatus: CompanyRestoreStatus;
  readonly galaxyConnectionStatus: GalaxyConnectionEvent;
  readonly galaxyKeysReceived: GalaxyKeysReceivedEvent;
  readonly geocodingStatusUpdate: GeocodingStatusUpdate;
  readonly invoices: InvoiceUpdatedEvent;
  readonly legacyServersStatus: LegacyServerStatusEvent;
  readonly purchaseOrder?: Maybe<PurchaseOrder>;
  readonly searchIndexQueueLength: ReadonlyArray<SearchIndexQueueStat>;
  readonly sms: SmsMessage;
  readonly systemBackupLogs: ReadonlyArray<SystemBackupLog>;
  readonly systemBackups: ReadonlyArray<SystemBackup>;
  readonly workOrder: WorkOrder;
  readonly workOrders: WorkOrderUpdatedEvent;
};


export type SubscriptionCompanyMigrationStatusArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionPurchaseOrderArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionSearchIndexQueueLengthArgs = {
  companyId: Scalars['String']['input'];
};


export type SubscriptionWorkOrderArgs = {
  id: Scalars['ID']['input'];
};

export type SystemBackup = {
  readonly __typename: 'SystemBackup';
  readonly created: Scalars['DateTime']['output'];
  readonly name: Scalars['String']['output'];
  readonly size: Scalars['Long']['output'];
  readonly status: Scalars['String']['output'];
};

export type SystemBackupLog = {
  readonly __typename: 'SystemBackupLog';
  readonly log?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
};

export type SystemConfig = {
  readonly __typename: 'SystemConfig';
  readonly codeGeneration: CodeGenerationConfigEntities;
};

export enum SystemConfigCodeGenerationEntities {
  Customer = 'CUSTOMER',
  Employee = 'EMPLOYEE',
  Name = 'NAME',
  Salesperson = 'SALESPERSON',
  Vendor = 'VENDOR'
}

export type SystemSetup = {
  readonly __typename: 'SystemSetup';
  readonly allocatedTransactions: Scalars['ID']['output'];
  readonly allowNegativeDiscounts?: Maybe<Scalars['String']['output']>;
  readonly apBookIncrease?: Maybe<Scalars['Decimal']['output']>;
  readonly apCheckPrinter?: Maybe<Scalars['String']['output']>;
  readonly apCloseout?: Maybe<Scalars['String']['output']>;
  readonly apDiscountGLAccountId: Scalars['ID']['output'];
  readonly apLedgerGLAccountId: Scalars['ID']['output'];
  readonly apLockDate?: Maybe<Scalars['DateTime']['output']>;
  readonly apglAccountId: Scalars['ID']['output'];
  readonly apglAdder: Scalars['Int']['output'];
  readonly arDiscountGLAccountId: Scalars['ID']['output'];
  readonly arInvoiceMessage?: Maybe<Scalars['String']['output']>;
  readonly arInvoicePrinter?: Maybe<Scalars['String']['output']>;
  readonly arInvoiceReportNumber: Scalars['ID']['output'];
  readonly arLockDate?: Maybe<Scalars['DateTime']['output']>;
  readonly arglAccountId: Scalars['ID']['output'];
  readonly autoAddItemsToInvoice?: Maybe<Scalars['String']['output']>;
  readonly autoBackOrders?: Maybe<Scalars['String']['output']>;
  readonly autoBackupUserNumber: Scalars['Int']['output'];
  readonly autoPostDeposits?: Maybe<Scalars['String']['output']>;
  readonly backupDevice?: Maybe<Scalars['String']['output']>;
  readonly bank1GLAccountId: Scalars['ID']['output'];
  readonly bank1Name?: Maybe<Scalars['String']['output']>;
  readonly bank2GLAccountId: Scalars['ID']['output'];
  readonly bank2Name?: Maybe<Scalars['String']['output']>;
  readonly bank3GLAccountId: Scalars['ID']['output'];
  readonly bank3Name?: Maybe<Scalars['String']['output']>;
  readonly bank4GLAccountId: Scalars['ID']['output'];
  readonly bank4Name?: Maybe<Scalars['String']['output']>;
  readonly bank5GLAccountId: Scalars['ID']['output'];
  readonly bank5Name?: Maybe<Scalars['String']['output']>;
  readonly baseModemNumber?: Maybe<Scalars['String']['output']>;
  readonly billingJobClassId?: Maybe<Scalars['String']['output']>;
  readonly businessHours?: Maybe<Scalars['String']['output']>;
  readonly checkFormat?: Maybe<Scalars['String']['output']>;
  readonly combineOrSplitCheckPrintJobs?: Maybe<Scalars['String']['output']>;
  readonly commissionSaleEndGLAccountId: Scalars['ID']['output'];
  readonly commissionSaleStartGLAccountId: Scalars['ID']['output'];
  readonly companyNumber?: Maybe<Scalars['String']['output']>;
  readonly compoundInt?: Maybe<Scalars['String']['output']>;
  readonly createQuotePriceFile?: Maybe<Scalars['String']['output']>;
  readonly creditCardTransitGLAccountId: Scalars['ID']['output'];
  readonly currentPeriodNumber: Scalars['Int']['output'];
  readonly custom?: Maybe<Scalars['String']['output']>;
  readonly customerCompanyIdFormat?: Maybe<Scalars['String']['output']>;
  readonly customerPersonIdFormat?: Maybe<Scalars['String']['output']>;
  readonly customerPrepaymentGLAccountId: Scalars['ID']['output'];
  readonly customerStatementReportNumber: Scalars['ID']['output'];
  readonly customerTypeId?: Maybe<Scalars['String']['output']>;
  readonly departmentMultiplier: Scalars['ID']['output'];
  readonly departmentStartNumber: Scalars['Int']['output'];
  readonly depositTicketBankAccountId?: Maybe<Scalars['String']['output']>;
  readonly ein?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly employeeIdFormat?: Maybe<Scalars['String']['output']>;
  readonly endDate?: Maybe<Scalars['DateTime']['output']>;
  readonly envelopePrinter?: Maybe<Scalars['String']['output']>;
  readonly fax?: Maybe<Scalars['String']['output']>;
  readonly faxPort?: Maybe<Scalars['String']['output']>;
  readonly faxPrinterName?: Maybe<Scalars['String']['output']>;
  readonly fcDays: Scalars['Int']['output'];
  readonly fcDaysFree: Scalars['Int']['output'];
  readonly fcMinimumCharge?: Maybe<Scalars['Decimal']['output']>;
  readonly fcRate?: Maybe<Scalars['Decimal']['output']>;
  readonly fcRemark?: Maybe<Scalars['String']['output']>;
  readonly fcglAccountId: Scalars['ID']['output'];
  readonly field137: Scalars['ID']['output'];
  readonly field138: Scalars['ID']['output'];
  readonly field175?: Maybe<Scalars['String']['output']>;
  readonly field183?: Maybe<Scalars['DateTime']['output']>;
  readonly field184?: Maybe<Scalars['DateTime']['output']>;
  readonly field185?: Maybe<Scalars['DateTime']['output']>;
  readonly field186?: Maybe<Scalars['DateTime']['output']>;
  readonly field187?: Maybe<Scalars['DateTime']['output']>;
  readonly field188?: Maybe<Scalars['DateTime']['output']>;
  readonly field189?: Maybe<Scalars['DateTime']['output']>;
  readonly field190: Scalars['ID']['output'];
  readonly field191: Scalars['ID']['output'];
  readonly field192: Scalars['ID']['output'];
  readonly field193: Scalars['ID']['output'];
  readonly field194: Scalars['ID']['output'];
  readonly field195: Scalars['ID']['output'];
  readonly field196: Scalars['ID']['output'];
  readonly field197: Scalars['ID']['output'];
  readonly field198: Scalars['ID']['output'];
  readonly field199: Scalars['ID']['output'];
  readonly field200?: Maybe<Scalars['Decimal']['output']>;
  readonly field201?: Maybe<Scalars['Decimal']['output']>;
  readonly field202?: Maybe<Scalars['Decimal']['output']>;
  readonly field203?: Maybe<Scalars['Decimal']['output']>;
  readonly floorPlanningGLAccountId: Scalars['ID']['output'];
  readonly giftCardItemId?: Maybe<Scalars['String']['output']>;
  readonly glCheckPrinter?: Maybe<Scalars['String']['output']>;
  readonly glLockDate?: Maybe<Scalars['DateTime']['output']>;
  readonly glStatementFootnote?: Maybe<Scalars['String']['output']>;
  readonly icon?: Maybe<ReadonlyArray<Scalars['Byte']['output']>>;
  readonly iconFileName?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly initials?: Maybe<Scalars['String']['output']>;
  readonly inventoryEndGLAccountId: Scalars['ID']['output'];
  readonly inventoryMarkup?: Maybe<Scalars['Decimal']['output']>;
  readonly inventoryReversal: Scalars['ID']['output'];
  readonly inventoryStartGLAccountId: Scalars['ID']['output'];
  readonly jobAPMaterialMarkup?: Maybe<Scalars['Decimal']['output']>;
  readonly jobAPSubcontractMarkup?: Maybe<Scalars['Decimal']['output']>;
  readonly jobLaborSaleGLAccountId: Scalars['ID']['output'];
  readonly jobMaterialSaleGLAccountId: Scalars['ID']['output'];
  readonly jobSubContractSaleGLAccountId: Scalars['ID']['output'];
  readonly labelPrinter?: Maybe<Scalars['String']['output']>;
  readonly lastFCDate?: Maybe<Scalars['DateTime']['output']>;
  readonly listPriceIncludeFreight?: Maybe<Scalars['String']['output']>;
  readonly logo?: Maybe<ReadonlyArray<Scalars['Byte']['output']>>;
  readonly logoFileName?: Maybe<Scalars['String']['output']>;
  readonly logoMacroNumber?: Maybe<Scalars['String']['output']>;
  readonly lowerStatementLimit?: Maybe<Scalars['Decimal']['output']>;
  readonly maximumProposalId: Scalars['ID']['output'];
  readonly multiLocationId?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly nameCompanyIdFormat?: Maybe<Scalars['String']['output']>;
  readonly nameCont?: Maybe<Scalars['String']['output']>;
  readonly namePersonIdFormat?: Maybe<Scalars['String']['output']>;
  readonly numberOfARInvoices: Scalars['Int']['output'];
  readonly omitCompanyHeadingARInvoice?: Maybe<Scalars['String']['output']>;
  readonly omitPOHeading?: Maybe<Scalars['String']['output']>;
  readonly orderPrinter?: Maybe<Scalars['String']['output']>;
  readonly otBase?: Maybe<Scalars['Decimal']['output']>;
  readonly otFactor?: Maybe<Scalars['Decimal']['output']>;
  readonly otPerDays: Scalars['Int']['output'];
  readonly otPerEndDay: Scalars['Int']['output'];
  readonly paycheckSequenceNumber: Scalars['Int']['output'];
  readonly payrollCashGLAccountId: Scalars['ID']['output'];
  readonly periodEndDate00?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate01?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate02?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate03?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate04?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate05?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate06?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate07?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate08?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate09?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate10?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate11?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate12?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate13?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate14?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate15?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate16?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate17?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate18?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate19?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate20?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate21?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate22?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate23?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate24?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate25?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate26?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate27?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate28?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate29?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate30?: Maybe<Scalars['DateTime']['output']>;
  readonly periodEndDate31?: Maybe<Scalars['DateTime']['output']>;
  readonly periodsPerYear: Scalars['Int']['output'];
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly posInvoiceReportNumber: Scalars['ID']['output'];
  readonly pricePOs?: Maybe<Scalars['String']['output']>;
  readonly priceUpdateId?: Maybe<Scalars['String']['output']>;
  readonly priceUpdateMultiplier?: Maybe<Scalars['Decimal']['output']>;
  readonly printCustomerMessageNumber: Scalars['ID']['output'];
  readonly printer?: Maybe<Scalars['String']['output']>;
  readonly purchaseEndGLAccountId: Scalars['ID']['output'];
  readonly purchaseGLAccountId: Scalars['ID']['output'];
  readonly purchaseStartGLAccountId: Scalars['ID']['output'];
  readonly reportStylesheet?: Maybe<Stylesheet>;
  readonly reportStylesheetId?: Maybe<Scalars['String']['output']>;
  readonly retainedEarningsGLAccountId: Scalars['ID']['output'];
  readonly saleGLAccountId: Scalars['ID']['output'];
  readonly saleStartGLAccountId: Scalars['ID']['output'];
  readonly shortName?: Maybe<Scalars['String']['output']>;
  readonly showStockIconsOnWorkOrder?: Maybe<Scalars['String']['output']>;
  readonly showWorkOrderListOnInvoice?: Maybe<Scalars['String']['output']>;
  readonly showWorkOrderListOnWorkOrder?: Maybe<Scalars['String']['output']>;
  readonly signatureFileName?: Maybe<Scalars['String']['output']>;
  readonly specialAltBScreens?: Maybe<Scalars['String']['output']>;
  readonly specialDepositGLAccountId: Scalars['ID']['output'];
  readonly stockAdjustLockDate?: Maybe<Scalars['DateTime']['output']>;
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly subcontractAdder: Scalars['Int']['output'];
  readonly subcontractGLAccountId: Scalars['ID']['output'];
  readonly supportPhone?: Maybe<Scalars['String']['output']>;
  readonly supportPhone2?: Maybe<Scalars['String']['output']>;
  readonly supportPhone3?: Maybe<Scalars['String']['output']>;
  readonly suppressStockErrors?: Maybe<Scalars['String']['output']>;
  readonly suspenseGLAccountId: Scalars['ID']['output'];
  readonly systemDate?: Maybe<Scalars['DateTime']['output']>;
  readonly tax?: Maybe<Scalars['String']['output']>;
  readonly taxOnOrder?: Maybe<Scalars['String']['output']>;
  readonly transactions: Scalars['ID']['output'];
  readonly transactionsAvailable: Scalars['ID']['output'];
  readonly upperStatementLimit?: Maybe<Scalars['Decimal']['output']>;
  readonly vendorCompanyIdFormat?: Maybe<Scalars['String']['output']>;
  readonly vendorPersonIdFormat?: Maybe<Scalars['String']['output']>;
  readonly vendorPrepaymentGLAccountId: Scalars['ID']['output'];
  readonly vendorShippingTicketGLAccountId: Scalars['ID']['output'];
  readonly website?: Maybe<Scalars['String']['output']>;
  readonly yearBeginningFieldNumber: Scalars['Int']['output'];
  readonly yearEndMonth: Scalars['Int']['output'];
  readonly zipCode?: Maybe<ZipCode>;
  readonly zipCodeId?: Maybe<Scalars['String']['output']>;
  readonly zipExt?: Maybe<Scalars['String']['output']>;
};

export type SystemSetupFilterInput = {
  readonly allocatedTransactions?: InputMaybe<IdOperationFilterInput>;
  readonly allowNegativeDiscounts?: InputMaybe<StringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<SystemSetupFilterInput>>;
  readonly apBookIncrease?: InputMaybe<DecimalOperationFilterInput>;
  readonly apCheckPrinter?: InputMaybe<StringOperationFilterInput>;
  readonly apCloseout?: InputMaybe<StringOperationFilterInput>;
  readonly apDiscountGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly apLedgerGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly apLockDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly apglAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly apglAdder?: InputMaybe<IntOperationFilterInput>;
  readonly arDiscountGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly arInvoiceMessage?: InputMaybe<StringOperationFilterInput>;
  readonly arInvoicePrinter?: InputMaybe<StringOperationFilterInput>;
  readonly arInvoiceReportNumber?: InputMaybe<IdOperationFilterInput>;
  readonly arLockDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly arglAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly autoAddItemsToInvoice?: InputMaybe<StringOperationFilterInput>;
  readonly autoBackOrders?: InputMaybe<StringOperationFilterInput>;
  readonly autoBackupUserNumber?: InputMaybe<IntOperationFilterInput>;
  readonly autoPostDeposits?: InputMaybe<StringOperationFilterInput>;
  readonly backupDevice?: InputMaybe<StringOperationFilterInput>;
  readonly bank1GLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly bank1Name?: InputMaybe<StringOperationFilterInput>;
  readonly bank2GLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly bank2Name?: InputMaybe<StringOperationFilterInput>;
  readonly bank3GLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly bank3Name?: InputMaybe<StringOperationFilterInput>;
  readonly bank4GLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly bank4Name?: InputMaybe<StringOperationFilterInput>;
  readonly bank5GLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly bank5Name?: InputMaybe<StringOperationFilterInput>;
  readonly baseModemNumber?: InputMaybe<StringOperationFilterInput>;
  readonly billingJobClassId?: InputMaybe<StringOperationFilterInput>;
  readonly businessHours?: InputMaybe<StringOperationFilterInput>;
  readonly checkFormat?: InputMaybe<StringOperationFilterInput>;
  readonly combineOrSplitCheckPrintJobs?: InputMaybe<StringOperationFilterInput>;
  readonly commissionSaleEndGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly commissionSaleStartGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly companyNumber?: InputMaybe<StringOperationFilterInput>;
  readonly compoundInt?: InputMaybe<StringOperationFilterInput>;
  readonly createQuotePriceFile?: InputMaybe<StringOperationFilterInput>;
  readonly creditCardTransitGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly currentPeriodNumber?: InputMaybe<IntOperationFilterInput>;
  readonly custom?: InputMaybe<StringOperationFilterInput>;
  readonly customerCompanyIdFormat?: InputMaybe<StringOperationFilterInput>;
  readonly customerPersonIdFormat?: InputMaybe<StringOperationFilterInput>;
  readonly customerPrepaymentGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly customerStatementReportNumber?: InputMaybe<IdOperationFilterInput>;
  readonly customerTypeId?: InputMaybe<StringOperationFilterInput>;
  readonly departmentMultiplier?: InputMaybe<IdOperationFilterInput>;
  readonly departmentStartNumber?: InputMaybe<IntOperationFilterInput>;
  readonly depositTicketBankAccountId?: InputMaybe<StringOperationFilterInput>;
  readonly ein?: InputMaybe<StringOperationFilterInput>;
  readonly email?: InputMaybe<StringOperationFilterInput>;
  readonly employeeIdFormat?: InputMaybe<StringOperationFilterInput>;
  readonly endDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly envelopePrinter?: InputMaybe<StringOperationFilterInput>;
  readonly fax?: InputMaybe<StringOperationFilterInput>;
  readonly faxPort?: InputMaybe<StringOperationFilterInput>;
  readonly faxPrinterName?: InputMaybe<StringOperationFilterInput>;
  readonly fcDays?: InputMaybe<IntOperationFilterInput>;
  readonly fcDaysFree?: InputMaybe<IntOperationFilterInput>;
  readonly fcMinimumCharge?: InputMaybe<DecimalOperationFilterInput>;
  readonly fcRate?: InputMaybe<DecimalOperationFilterInput>;
  readonly fcRemark?: InputMaybe<StringOperationFilterInput>;
  readonly fcglAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly field137?: InputMaybe<IdOperationFilterInput>;
  readonly field138?: InputMaybe<IdOperationFilterInput>;
  readonly field175?: InputMaybe<StringOperationFilterInput>;
  readonly field183?: InputMaybe<DateTimeOperationFilterInput>;
  readonly field184?: InputMaybe<DateTimeOperationFilterInput>;
  readonly field185?: InputMaybe<DateTimeOperationFilterInput>;
  readonly field186?: InputMaybe<DateTimeOperationFilterInput>;
  readonly field187?: InputMaybe<DateTimeOperationFilterInput>;
  readonly field188?: InputMaybe<DateTimeOperationFilterInput>;
  readonly field189?: InputMaybe<DateTimeOperationFilterInput>;
  readonly field190?: InputMaybe<IdOperationFilterInput>;
  readonly field191?: InputMaybe<IdOperationFilterInput>;
  readonly field192?: InputMaybe<IdOperationFilterInput>;
  readonly field193?: InputMaybe<IdOperationFilterInput>;
  readonly field194?: InputMaybe<IdOperationFilterInput>;
  readonly field195?: InputMaybe<IdOperationFilterInput>;
  readonly field196?: InputMaybe<IdOperationFilterInput>;
  readonly field197?: InputMaybe<IdOperationFilterInput>;
  readonly field198?: InputMaybe<IdOperationFilterInput>;
  readonly field199?: InputMaybe<IdOperationFilterInput>;
  readonly field200?: InputMaybe<DecimalOperationFilterInput>;
  readonly field201?: InputMaybe<DecimalOperationFilterInput>;
  readonly field202?: InputMaybe<DecimalOperationFilterInput>;
  readonly field203?: InputMaybe<DecimalOperationFilterInput>;
  readonly floorPlanningGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly giftCardItemId?: InputMaybe<StringOperationFilterInput>;
  readonly glCheckPrinter?: InputMaybe<StringOperationFilterInput>;
  readonly glLockDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly glStatementFootnote?: InputMaybe<StringOperationFilterInput>;
  readonly icon?: InputMaybe<ListByteOperationFilterInput>;
  readonly iconFileName?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly initials?: InputMaybe<StringOperationFilterInput>;
  readonly inventoryEndGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly inventoryMarkup?: InputMaybe<DecimalOperationFilterInput>;
  readonly inventoryReversal?: InputMaybe<IdOperationFilterInput>;
  readonly inventoryStartGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly jobAPMaterialMarkup?: InputMaybe<DecimalOperationFilterInput>;
  readonly jobAPSubcontractMarkup?: InputMaybe<DecimalOperationFilterInput>;
  readonly jobLaborSaleGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly jobMaterialSaleGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly jobSubContractSaleGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly labelPrinter?: InputMaybe<StringOperationFilterInput>;
  readonly lastFCDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly listPriceIncludeFreight?: InputMaybe<StringOperationFilterInput>;
  readonly logo?: InputMaybe<ListByteOperationFilterInput>;
  readonly logoFileName?: InputMaybe<StringOperationFilterInput>;
  readonly logoMacroNumber?: InputMaybe<StringOperationFilterInput>;
  readonly lowerStatementLimit?: InputMaybe<DecimalOperationFilterInput>;
  readonly maximumProposalId?: InputMaybe<IdOperationFilterInput>;
  readonly multiLocationId?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly nameCompanyIdFormat?: InputMaybe<StringOperationFilterInput>;
  readonly nameCont?: InputMaybe<StringOperationFilterInput>;
  readonly namePersonIdFormat?: InputMaybe<StringOperationFilterInput>;
  readonly numberOfARInvoices?: InputMaybe<IntOperationFilterInput>;
  readonly omitCompanyHeadingARInvoice?: InputMaybe<StringOperationFilterInput>;
  readonly omitPOHeading?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SystemSetupFilterInput>>;
  readonly orderPrinter?: InputMaybe<StringOperationFilterInput>;
  readonly otBase?: InputMaybe<DecimalOperationFilterInput>;
  readonly otFactor?: InputMaybe<DecimalOperationFilterInput>;
  readonly otPerDays?: InputMaybe<IntOperationFilterInput>;
  readonly otPerEndDay?: InputMaybe<IntOperationFilterInput>;
  readonly paycheckSequenceNumber?: InputMaybe<IntOperationFilterInput>;
  readonly payrollCashGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly periodEndDate00?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate01?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate02?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate03?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate04?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate05?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate06?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate07?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate08?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate09?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate10?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate11?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate12?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate13?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate14?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate15?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate16?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate17?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate18?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate19?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate20?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate21?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate22?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate23?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate24?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate25?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate26?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate27?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate28?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate29?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate30?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodEndDate31?: InputMaybe<DateTimeOperationFilterInput>;
  readonly periodsPerYear?: InputMaybe<IntOperationFilterInput>;
  readonly phone?: InputMaybe<StringOperationFilterInput>;
  readonly posInvoiceReportNumber?: InputMaybe<IdOperationFilterInput>;
  readonly pricePOs?: InputMaybe<StringOperationFilterInput>;
  readonly priceUpdateId?: InputMaybe<StringOperationFilterInput>;
  readonly priceUpdateMultiplier?: InputMaybe<DecimalOperationFilterInput>;
  readonly printCustomerMessageNumber?: InputMaybe<IdOperationFilterInput>;
  readonly printer?: InputMaybe<StringOperationFilterInput>;
  readonly purchaseEndGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly purchaseGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly purchaseStartGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly reportStylesheet?: InputMaybe<StylesheetFilterInput>;
  readonly reportStylesheetId?: InputMaybe<StringOperationFilterInput>;
  readonly retainedEarningsGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly saleGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly saleStartGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly shortName?: InputMaybe<StringOperationFilterInput>;
  readonly showStockIconsOnWorkOrder?: InputMaybe<StringOperationFilterInput>;
  readonly showWorkOrderListOnInvoice?: InputMaybe<StringOperationFilterInput>;
  readonly showWorkOrderListOnWorkOrder?: InputMaybe<StringOperationFilterInput>;
  readonly signatureFileName?: InputMaybe<StringOperationFilterInput>;
  readonly specialAltBScreens?: InputMaybe<StringOperationFilterInput>;
  readonly specialDepositGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly stockAdjustLockDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly streetAddress?: InputMaybe<StringOperationFilterInput>;
  readonly subcontractAdder?: InputMaybe<IntOperationFilterInput>;
  readonly subcontractGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly supportPhone?: InputMaybe<StringOperationFilterInput>;
  readonly supportPhone2?: InputMaybe<StringOperationFilterInput>;
  readonly supportPhone3?: InputMaybe<StringOperationFilterInput>;
  readonly suppressStockErrors?: InputMaybe<StringOperationFilterInput>;
  readonly suspenseGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly systemDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly tax?: InputMaybe<StringOperationFilterInput>;
  readonly taxOnOrder?: InputMaybe<StringOperationFilterInput>;
  readonly transactions?: InputMaybe<IdOperationFilterInput>;
  readonly transactionsAvailable?: InputMaybe<IdOperationFilterInput>;
  readonly upperStatementLimit?: InputMaybe<DecimalOperationFilterInput>;
  readonly vendorCompanyIdFormat?: InputMaybe<StringOperationFilterInput>;
  readonly vendorPersonIdFormat?: InputMaybe<StringOperationFilterInput>;
  readonly vendorPrepaymentGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly vendorShippingTicketGLAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly website?: InputMaybe<StringOperationFilterInput>;
  readonly yearBeginningFieldNumber?: InputMaybe<IntOperationFilterInput>;
  readonly yearEndMonth?: InputMaybe<IntOperationFilterInput>;
  readonly zipCode?: InputMaybe<ZipCodeFilterInput>;
  readonly zipCodeId?: InputMaybe<StringOperationFilterInput>;
  readonly zipExt?: InputMaybe<StringOperationFilterInput>;
};

export type SystemSetupSortInput = {
  readonly allocatedTransactions?: InputMaybe<SortEnumType>;
  readonly allowNegativeDiscounts?: InputMaybe<SortEnumType>;
  readonly apBookIncrease?: InputMaybe<SortEnumType>;
  readonly apCheckPrinter?: InputMaybe<SortEnumType>;
  readonly apCloseout?: InputMaybe<SortEnumType>;
  readonly apDiscountGLAccountId?: InputMaybe<SortEnumType>;
  readonly apLedgerGLAccountId?: InputMaybe<SortEnumType>;
  readonly apLockDate?: InputMaybe<SortEnumType>;
  readonly apglAccountId?: InputMaybe<SortEnumType>;
  readonly apglAdder?: InputMaybe<SortEnumType>;
  readonly arDiscountGLAccountId?: InputMaybe<SortEnumType>;
  readonly arInvoiceMessage?: InputMaybe<SortEnumType>;
  readonly arInvoicePrinter?: InputMaybe<SortEnumType>;
  readonly arInvoiceReportNumber?: InputMaybe<SortEnumType>;
  readonly arLockDate?: InputMaybe<SortEnumType>;
  readonly arglAccountId?: InputMaybe<SortEnumType>;
  readonly autoAddItemsToInvoice?: InputMaybe<SortEnumType>;
  readonly autoBackOrders?: InputMaybe<SortEnumType>;
  readonly autoBackupUserNumber?: InputMaybe<SortEnumType>;
  readonly autoPostDeposits?: InputMaybe<SortEnumType>;
  readonly backupDevice?: InputMaybe<SortEnumType>;
  readonly bank1GLAccountId?: InputMaybe<SortEnumType>;
  readonly bank1Name?: InputMaybe<SortEnumType>;
  readonly bank2GLAccountId?: InputMaybe<SortEnumType>;
  readonly bank2Name?: InputMaybe<SortEnumType>;
  readonly bank3GLAccountId?: InputMaybe<SortEnumType>;
  readonly bank3Name?: InputMaybe<SortEnumType>;
  readonly bank4GLAccountId?: InputMaybe<SortEnumType>;
  readonly bank4Name?: InputMaybe<SortEnumType>;
  readonly bank5GLAccountId?: InputMaybe<SortEnumType>;
  readonly bank5Name?: InputMaybe<SortEnumType>;
  readonly baseModemNumber?: InputMaybe<SortEnumType>;
  readonly billingJobClassId?: InputMaybe<SortEnumType>;
  readonly businessHours?: InputMaybe<SortEnumType>;
  readonly checkFormat?: InputMaybe<SortEnumType>;
  readonly combineOrSplitCheckPrintJobs?: InputMaybe<SortEnumType>;
  readonly commissionSaleEndGLAccountId?: InputMaybe<SortEnumType>;
  readonly commissionSaleStartGLAccountId?: InputMaybe<SortEnumType>;
  readonly companyNumber?: InputMaybe<SortEnumType>;
  readonly compoundInt?: InputMaybe<SortEnumType>;
  readonly createQuotePriceFile?: InputMaybe<SortEnumType>;
  readonly creditCardTransitGLAccountId?: InputMaybe<SortEnumType>;
  readonly currentPeriodNumber?: InputMaybe<SortEnumType>;
  readonly custom?: InputMaybe<SortEnumType>;
  readonly customerCompanyIdFormat?: InputMaybe<SortEnumType>;
  readonly customerPersonIdFormat?: InputMaybe<SortEnumType>;
  readonly customerPrepaymentGLAccountId?: InputMaybe<SortEnumType>;
  readonly customerStatementReportNumber?: InputMaybe<SortEnumType>;
  readonly customerTypeId?: InputMaybe<SortEnumType>;
  readonly departmentMultiplier?: InputMaybe<SortEnumType>;
  readonly departmentStartNumber?: InputMaybe<SortEnumType>;
  readonly depositTicketBankAccountId?: InputMaybe<SortEnumType>;
  readonly ein?: InputMaybe<SortEnumType>;
  readonly email?: InputMaybe<SortEnumType>;
  readonly employeeIdFormat?: InputMaybe<SortEnumType>;
  readonly endDate?: InputMaybe<SortEnumType>;
  readonly envelopePrinter?: InputMaybe<SortEnumType>;
  readonly fax?: InputMaybe<SortEnumType>;
  readonly faxPort?: InputMaybe<SortEnumType>;
  readonly faxPrinterName?: InputMaybe<SortEnumType>;
  readonly fcDays?: InputMaybe<SortEnumType>;
  readonly fcDaysFree?: InputMaybe<SortEnumType>;
  readonly fcMinimumCharge?: InputMaybe<SortEnumType>;
  readonly fcRate?: InputMaybe<SortEnumType>;
  readonly fcRemark?: InputMaybe<SortEnumType>;
  readonly fcglAccountId?: InputMaybe<SortEnumType>;
  readonly field137?: InputMaybe<SortEnumType>;
  readonly field138?: InputMaybe<SortEnumType>;
  readonly field175?: InputMaybe<SortEnumType>;
  readonly field183?: InputMaybe<SortEnumType>;
  readonly field184?: InputMaybe<SortEnumType>;
  readonly field185?: InputMaybe<SortEnumType>;
  readonly field186?: InputMaybe<SortEnumType>;
  readonly field187?: InputMaybe<SortEnumType>;
  readonly field188?: InputMaybe<SortEnumType>;
  readonly field189?: InputMaybe<SortEnumType>;
  readonly field190?: InputMaybe<SortEnumType>;
  readonly field191?: InputMaybe<SortEnumType>;
  readonly field192?: InputMaybe<SortEnumType>;
  readonly field193?: InputMaybe<SortEnumType>;
  readonly field194?: InputMaybe<SortEnumType>;
  readonly field195?: InputMaybe<SortEnumType>;
  readonly field196?: InputMaybe<SortEnumType>;
  readonly field197?: InputMaybe<SortEnumType>;
  readonly field198?: InputMaybe<SortEnumType>;
  readonly field199?: InputMaybe<SortEnumType>;
  readonly field200?: InputMaybe<SortEnumType>;
  readonly field201?: InputMaybe<SortEnumType>;
  readonly field202?: InputMaybe<SortEnumType>;
  readonly field203?: InputMaybe<SortEnumType>;
  readonly floorPlanningGLAccountId?: InputMaybe<SortEnumType>;
  readonly giftCardItemId?: InputMaybe<SortEnumType>;
  readonly glCheckPrinter?: InputMaybe<SortEnumType>;
  readonly glLockDate?: InputMaybe<SortEnumType>;
  readonly glStatementFootnote?: InputMaybe<SortEnumType>;
  readonly iconFileName?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly initials?: InputMaybe<SortEnumType>;
  readonly inventoryEndGLAccountId?: InputMaybe<SortEnumType>;
  readonly inventoryMarkup?: InputMaybe<SortEnumType>;
  readonly inventoryReversal?: InputMaybe<SortEnumType>;
  readonly inventoryStartGLAccountId?: InputMaybe<SortEnumType>;
  readonly jobAPMaterialMarkup?: InputMaybe<SortEnumType>;
  readonly jobAPSubcontractMarkup?: InputMaybe<SortEnumType>;
  readonly jobLaborSaleGLAccountId?: InputMaybe<SortEnumType>;
  readonly jobMaterialSaleGLAccountId?: InputMaybe<SortEnumType>;
  readonly jobSubContractSaleGLAccountId?: InputMaybe<SortEnumType>;
  readonly labelPrinter?: InputMaybe<SortEnumType>;
  readonly lastFCDate?: InputMaybe<SortEnumType>;
  readonly listPriceIncludeFreight?: InputMaybe<SortEnumType>;
  readonly logoFileName?: InputMaybe<SortEnumType>;
  readonly logoMacroNumber?: InputMaybe<SortEnumType>;
  readonly lowerStatementLimit?: InputMaybe<SortEnumType>;
  readonly maximumProposalId?: InputMaybe<SortEnumType>;
  readonly multiLocationId?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly nameCompanyIdFormat?: InputMaybe<SortEnumType>;
  readonly nameCont?: InputMaybe<SortEnumType>;
  readonly namePersonIdFormat?: InputMaybe<SortEnumType>;
  readonly numberOfARInvoices?: InputMaybe<SortEnumType>;
  readonly omitCompanyHeadingARInvoice?: InputMaybe<SortEnumType>;
  readonly omitPOHeading?: InputMaybe<SortEnumType>;
  readonly orderPrinter?: InputMaybe<SortEnumType>;
  readonly otBase?: InputMaybe<SortEnumType>;
  readonly otFactor?: InputMaybe<SortEnumType>;
  readonly otPerDays?: InputMaybe<SortEnumType>;
  readonly otPerEndDay?: InputMaybe<SortEnumType>;
  readonly paycheckSequenceNumber?: InputMaybe<SortEnumType>;
  readonly payrollCashGLAccountId?: InputMaybe<SortEnumType>;
  readonly periodEndDate00?: InputMaybe<SortEnumType>;
  readonly periodEndDate01?: InputMaybe<SortEnumType>;
  readonly periodEndDate02?: InputMaybe<SortEnumType>;
  readonly periodEndDate03?: InputMaybe<SortEnumType>;
  readonly periodEndDate04?: InputMaybe<SortEnumType>;
  readonly periodEndDate05?: InputMaybe<SortEnumType>;
  readonly periodEndDate06?: InputMaybe<SortEnumType>;
  readonly periodEndDate07?: InputMaybe<SortEnumType>;
  readonly periodEndDate08?: InputMaybe<SortEnumType>;
  readonly periodEndDate09?: InputMaybe<SortEnumType>;
  readonly periodEndDate10?: InputMaybe<SortEnumType>;
  readonly periodEndDate11?: InputMaybe<SortEnumType>;
  readonly periodEndDate12?: InputMaybe<SortEnumType>;
  readonly periodEndDate13?: InputMaybe<SortEnumType>;
  readonly periodEndDate14?: InputMaybe<SortEnumType>;
  readonly periodEndDate15?: InputMaybe<SortEnumType>;
  readonly periodEndDate16?: InputMaybe<SortEnumType>;
  readonly periodEndDate17?: InputMaybe<SortEnumType>;
  readonly periodEndDate18?: InputMaybe<SortEnumType>;
  readonly periodEndDate19?: InputMaybe<SortEnumType>;
  readonly periodEndDate20?: InputMaybe<SortEnumType>;
  readonly periodEndDate21?: InputMaybe<SortEnumType>;
  readonly periodEndDate22?: InputMaybe<SortEnumType>;
  readonly periodEndDate23?: InputMaybe<SortEnumType>;
  readonly periodEndDate24?: InputMaybe<SortEnumType>;
  readonly periodEndDate25?: InputMaybe<SortEnumType>;
  readonly periodEndDate26?: InputMaybe<SortEnumType>;
  readonly periodEndDate27?: InputMaybe<SortEnumType>;
  readonly periodEndDate28?: InputMaybe<SortEnumType>;
  readonly periodEndDate29?: InputMaybe<SortEnumType>;
  readonly periodEndDate30?: InputMaybe<SortEnumType>;
  readonly periodEndDate31?: InputMaybe<SortEnumType>;
  readonly periodsPerYear?: InputMaybe<SortEnumType>;
  readonly phone?: InputMaybe<SortEnumType>;
  readonly posInvoiceReportNumber?: InputMaybe<SortEnumType>;
  readonly pricePOs?: InputMaybe<SortEnumType>;
  readonly priceUpdateId?: InputMaybe<SortEnumType>;
  readonly priceUpdateMultiplier?: InputMaybe<SortEnumType>;
  readonly printCustomerMessageNumber?: InputMaybe<SortEnumType>;
  readonly printer?: InputMaybe<SortEnumType>;
  readonly purchaseEndGLAccountId?: InputMaybe<SortEnumType>;
  readonly purchaseGLAccountId?: InputMaybe<SortEnumType>;
  readonly purchaseStartGLAccountId?: InputMaybe<SortEnumType>;
  readonly reportStylesheet?: InputMaybe<StylesheetSortInput>;
  readonly reportStylesheetId?: InputMaybe<SortEnumType>;
  readonly retainedEarningsGLAccountId?: InputMaybe<SortEnumType>;
  readonly saleGLAccountId?: InputMaybe<SortEnumType>;
  readonly saleStartGLAccountId?: InputMaybe<SortEnumType>;
  readonly shortName?: InputMaybe<SortEnumType>;
  readonly showStockIconsOnWorkOrder?: InputMaybe<SortEnumType>;
  readonly showWorkOrderListOnInvoice?: InputMaybe<SortEnumType>;
  readonly showWorkOrderListOnWorkOrder?: InputMaybe<SortEnumType>;
  readonly signatureFileName?: InputMaybe<SortEnumType>;
  readonly specialAltBScreens?: InputMaybe<SortEnumType>;
  readonly specialDepositGLAccountId?: InputMaybe<SortEnumType>;
  readonly stockAdjustLockDate?: InputMaybe<SortEnumType>;
  readonly streetAddress?: InputMaybe<SortEnumType>;
  readonly subcontractAdder?: InputMaybe<SortEnumType>;
  readonly subcontractGLAccountId?: InputMaybe<SortEnumType>;
  readonly supportPhone?: InputMaybe<SortEnumType>;
  readonly supportPhone2?: InputMaybe<SortEnumType>;
  readonly supportPhone3?: InputMaybe<SortEnumType>;
  readonly suppressStockErrors?: InputMaybe<SortEnumType>;
  readonly suspenseGLAccountId?: InputMaybe<SortEnumType>;
  readonly systemDate?: InputMaybe<SortEnumType>;
  readonly tax?: InputMaybe<SortEnumType>;
  readonly taxOnOrder?: InputMaybe<SortEnumType>;
  readonly transactions?: InputMaybe<SortEnumType>;
  readonly transactionsAvailable?: InputMaybe<SortEnumType>;
  readonly upperStatementLimit?: InputMaybe<SortEnumType>;
  readonly vendorCompanyIdFormat?: InputMaybe<SortEnumType>;
  readonly vendorPersonIdFormat?: InputMaybe<SortEnumType>;
  readonly vendorPrepaymentGLAccountId?: InputMaybe<SortEnumType>;
  readonly vendorShippingTicketGLAccountId?: InputMaybe<SortEnumType>;
  readonly website?: InputMaybe<SortEnumType>;
  readonly yearBeginningFieldNumber?: InputMaybe<SortEnumType>;
  readonly yearEndMonth?: InputMaybe<SortEnumType>;
  readonly zipCode?: InputMaybe<ZipCodeSortInput>;
  readonly zipCodeId?: InputMaybe<SortEnumType>;
  readonly zipExt?: InputMaybe<SortEnumType>;
};

export enum TaxableStatus {
  Exempt = 'EXEMPT',
  Manual = 'MANUAL',
  SalesTaxItem = 'SALES_TAX_ITEM',
  SalesTaxOnPurchases = 'SALES_TAX_ON_PURCHASES',
  SometimesTaxable = 'SOMETIMES_TAXABLE',
  Taxable = 'TAXABLE',
  UseTaxItem = 'USE_TAX_ITEM'
}

export enum ThemeMode {
  Dark = 'DARK',
  Light = 'LIGHT'
}

export type ThemePreference = {
  readonly __typename: 'ThemePreference';
  readonly mode: ThemeMode;
  readonly theme: Scalars['String']['output'];
};

export type ThemePreferenceInput = {
  readonly mode?: ThemeMode;
  readonly theme?: Scalars['String']['input'];
};

export type TimeSpanOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['TimeSpan']['input']>;
  readonly gt?: InputMaybe<Scalars['TimeSpan']['input']>;
  readonly gte?: InputMaybe<Scalars['TimeSpan']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['TimeSpan']['input']>>>;
  readonly lt?: InputMaybe<Scalars['TimeSpan']['input']>;
  readonly lte?: InputMaybe<Scalars['TimeSpan']['input']>;
  readonly neq?: InputMaybe<Scalars['TimeSpan']['input']>;
  readonly ngt?: InputMaybe<Scalars['TimeSpan']['input']>;
  readonly ngte?: InputMaybe<Scalars['TimeSpan']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['TimeSpan']['input']>>>;
  readonly nlt?: InputMaybe<Scalars['TimeSpan']['input']>;
  readonly nlte?: InputMaybe<Scalars['TimeSpan']['input']>;
};

export type Transaction = Invoice | PurchaseOrder | WorkOrder;

export type TransactionShipment = InvoiceShipment | RentalShipment | WorkOrderShipment;

export type UrlInfo = {
  readonly __typename: 'UrlInfo';
  readonly fullUri: Scalars['String']['output'];
  readonly host: Scalars['String']['output'];
  readonly port: Scalars['Int']['output'];
  readonly schema: Scalars['String']['output'];
};

export type User = {
  readonly __typename: 'User';
  readonly accessFailedCount: Scalars['Int']['output'];
  readonly defaultCompany?: Maybe<Company>;
  readonly defaultCompanyId?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly emailConfirmed: Scalars['Boolean']['output'];
  readonly emailConfirming: Scalars['Boolean']['output'];
  readonly hasAuthenticatorMfa: Scalars['Boolean']['output'];
  readonly id: Scalars['String']['output'];
  readonly lockedOut: Scalars['Boolean']['output'];
  readonly lockoutEnabled: Scalars['Boolean']['output'];
  readonly lockoutEnd?: Maybe<Scalars['DateTime']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly normalizedEmail?: Maybe<Scalars['String']['output']>;
  readonly normalizedUserName?: Maybe<Scalars['String']['output']>;
  readonly ownedByLegacyServer: Scalars['Int']['output'];
  readonly passwordHash?: Maybe<Scalars['String']['output']>;
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  readonly phoneNumberConfirmed: Scalars['Boolean']['output'];
  readonly theme: ThemePreference;
  readonly twoFactorEnabled: Scalars['Boolean']['output'];
  readonly userName?: Maybe<Scalars['String']['output']>;
  readonly userRoles?: Maybe<ReadonlyArray<Maybe<UserRole>>>;
};

export type UserFilterInput = {
  readonly accessFailedCount?: InputMaybe<IntOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<UserFilterInput>>;
  readonly concurrencyStamp?: InputMaybe<StringOperationFilterInput>;
  readonly defaultCompany?: InputMaybe<CompanyFilterInput>;
  readonly defaultCompanyId?: InputMaybe<StringOperationFilterInput>;
  readonly email?: InputMaybe<StringOperationFilterInput>;
  readonly emailConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly lockoutEnabled?: InputMaybe<BooleanOperationFilterInput>;
  readonly lockoutEnd?: InputMaybe<DateTimeOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly normalizedEmail?: InputMaybe<StringOperationFilterInput>;
  readonly normalizedUserName?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<UserFilterInput>>;
  readonly ownedByLegacyServer?: InputMaybe<IntOperationFilterInput>;
  readonly passwordHash?: InputMaybe<StringOperationFilterInput>;
  readonly phoneNumber?: InputMaybe<StringOperationFilterInput>;
  readonly phoneNumberConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  readonly securityStamp?: InputMaybe<StringOperationFilterInput>;
  readonly twoFactorEnabled?: InputMaybe<BooleanOperationFilterInput>;
  readonly userName?: InputMaybe<StringOperationFilterInput>;
  readonly userRoles?: InputMaybe<ListFilterInputTypeOfUserRoleFilterInput>;
};

export type UserGroup = {
  readonly __typename: 'UserGroup';
  readonly added: Scalars['DateTime']['output'];
  readonly authorizations?: Maybe<ReadonlyArray<CompanyAuthorizationPolicy>>;
  readonly dashboards: ReadonlyArray<Dashboard>;
  readonly defaultDashboardIds: ReadonlyArray<UserGroupDefaultDashboard>;
  readonly id: Scalars['String']['output'];
  readonly members?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly policies?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly updated: Scalars['DateTime']['output'];
};

export type UserGroupDefaultDashboard = {
  readonly __typename: 'UserGroupDefaultDashboard';
  readonly dashboardId: Scalars['String']['output'];
  readonly groupId: Scalars['String']['output'];
};

export type UserGroupDefaultDashboardInput = {
  readonly dashboardId: Scalars['String']['input'];
  readonly groupId: Scalars['String']['input'];
};

export type UserGroupFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<UserGroupFilterInput>>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly members?: InputMaybe<ListStringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<UserGroupFilterInput>>;
  readonly policies?: InputMaybe<ListStringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type UserGroupInput = {
  readonly authorizations: ReadonlyArray<CompanyAuthorizationPolicyInput>;
  readonly defaultDashboards?: InputMaybe<ReadonlyArray<UserGroupDefaultDashboardInput>>;
  readonly members: ReadonlyArray<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly notes: Scalars['String']['input'];
};

export type UserGroupSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

export type UserProfilePasswordUpdateInput = {
  readonly current: Scalars['String']['input'];
  readonly new: Scalars['String']['input'];
  readonly repeat: Scalars['String']['input'];
};

export type UserProfileUpdateInput = {
  readonly defaultCompanyId?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Changing the Username will require the user to log out and back in. */
  readonly username: Scalars['String']['input'];
};

export type UserProfileVerifyMfaAppTokenResult = {
  readonly __typename: 'UserProfileVerifyMfaAppTokenResult';
  readonly recoveryCodes?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly success: Scalars['Boolean']['output'];
  readonly user: User;
};

export type UserRole = {
  readonly __typename: 'UserRole';
  readonly role?: Maybe<IdentityRole>;
  readonly roleId: Scalars['String']['output'];
  readonly user?: Maybe<User>;
  readonly userId: Scalars['String']['output'];
};

export type UserRoleFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<UserRoleFilterInput>>;
  readonly or?: InputMaybe<ReadonlyArray<UserRoleFilterInput>>;
  readonly role?: InputMaybe<IdentityRoleFilterInput>;
  readonly roleId?: InputMaybe<StringOperationFilterInput>;
  readonly user?: InputMaybe<UserFilterInput>;
  readonly userId?: InputMaybe<StringOperationFilterInput>;
};

export type UserSortInput = {
  readonly accessFailedCount?: InputMaybe<SortEnumType>;
  readonly concurrencyStamp?: InputMaybe<SortEnumType>;
  readonly defaultCompany?: InputMaybe<CompanySortInput>;
  readonly defaultCompanyId?: InputMaybe<SortEnumType>;
  readonly email?: InputMaybe<SortEnumType>;
  readonly emailConfirmed?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly lockoutEnabled?: InputMaybe<SortEnumType>;
  readonly lockoutEnd?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly normalizedEmail?: InputMaybe<SortEnumType>;
  readonly normalizedUserName?: InputMaybe<SortEnumType>;
  readonly ownedByLegacyServer?: InputMaybe<SortEnumType>;
  readonly passwordHash?: InputMaybe<SortEnumType>;
  readonly phoneNumber?: InputMaybe<SortEnumType>;
  readonly phoneNumberConfirmed?: InputMaybe<SortEnumType>;
  readonly securityStamp?: InputMaybe<SortEnumType>;
  readonly twoFactorEnabled?: InputMaybe<SortEnumType>;
  readonly userName?: InputMaybe<SortEnumType>;
};

export type UserSystemAdmin = {
  readonly __typename: 'UserSystemAdmin';
  readonly id: Scalars['String']['output'];
  readonly systemAdmin: Scalars['Boolean']['output'];
};

export type UserUpdateInput = {
  readonly defaultCompanyId?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Changing the Username will require the user to log out and back in. */
  readonly username: Scalars['String']['input'];
};

/** A connection to a list of items. */
export type UsersConnection = {
  readonly __typename: 'UsersConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<UsersEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<User>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UsersEdge = {
  readonly __typename: 'UsersEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: User;
};

export type UuidOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['UUID']['input']>;
  readonly gt?: InputMaybe<Scalars['UUID']['input']>;
  readonly gte?: InputMaybe<Scalars['UUID']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']['input']>>>;
  readonly lt?: InputMaybe<Scalars['UUID']['input']>;
  readonly lte?: InputMaybe<Scalars['UUID']['input']>;
  readonly neq?: InputMaybe<Scalars['UUID']['input']>;
  readonly ngt?: InputMaybe<Scalars['UUID']['input']>;
  readonly ngte?: InputMaybe<Scalars['UUID']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']['input']>>>;
  readonly nlt?: InputMaybe<Scalars['UUID']['input']>;
  readonly nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type ValidateResult = {
  readonly __typename: 'ValidateResult';
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

export type ValidationConfigField = {
  readonly __typename: 'ValidationConfigField';
  readonly id: Scalars['String']['output'];
  readonly label: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type ValidationNotice = {
  readonly __typename: 'ValidationNotice';
  readonly dismissAllowed: Scalars['Boolean']['output'];
  readonly fieldId: Scalars['String']['output'];
  /** Array of the field ID path to the error. */
  readonly fieldPath: ReadonlyArray<Scalars['String']['output']>;
  readonly level: ErrorLevel;
  readonly message: Scalars['String']['output'];
  readonly suggestions?: Maybe<ReadonlyArray<ValidationSuggestion>>;
};

export type ValidationSuggestion = {
  readonly __typename: 'ValidationSuggestion';
  readonly allowed: Scalars['Boolean']['output'];
  readonly fieldValues: ReadonlyArray<ValidationSuggestionField>;
  readonly message: Scalars['String']['output'];
};

export type ValidationSuggestionField = {
  readonly __typename: 'ValidationSuggestionField';
  readonly fieldId: Scalars['String']['output'];
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type ValidatorConfigInput = {
  readonly configuration?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly disabled: Scalars['Boolean']['input'];
  /** This field is required when creating a new validator. */
  readonly entity?: InputMaybe<Scalars['String']['input']>;
  /** Required for user defined validators. */
  readonly field?: InputMaybe<Scalars['String']['input']>;
  /** If the id isn't provided, a new validator will be created. */
  readonly id?: InputMaybe<Scalars['String']['input']>;
  /** This field is required when creating a new validator. */
  readonly validatorId?: InputMaybe<Scalars['String']['input']>;
};

export type ValidatorInformation = {
  readonly __typename: 'ValidatorInformation';
  /** If the validator can be disabled or not */
  readonly canDisable: Scalars['Boolean']['output'];
  readonly configFields?: Maybe<ReadonlyArray<ValidationConfigField>>;
  readonly description: Scalars['String']['output'];
  readonly disabled: Scalars['Boolean']['output'];
  readonly explanation?: Maybe<Scalars['String']['output']>;
  readonly field?: Maybe<FieldInformation>;
  readonly id: Scalars['String']['output'];
  readonly isFieldConfigurable: Scalars['Boolean']['output'];
  readonly isUserDefined: Scalars['Boolean']['output'];
  readonly isUserDefinedType: Scalars['Boolean']['output'];
  readonly supportedStates: ReadonlyArray<EntityState>;
};

export type Vehicle = {
  readonly __typename: 'Vehicle';
  readonly added: Scalars['DateTime']['output'];
  readonly color?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly make?: Maybe<Scalars['String']['output']>;
  readonly model?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly vin?: Maybe<Scalars['String']['output']>;
  readonly year: Scalars['Int']['output'];
};

export type VehicleFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<VehicleFilterInput>>;
  readonly color?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly make?: InputMaybe<StringOperationFilterInput>;
  readonly model?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<VehicleFilterInput>>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
  readonly vin?: InputMaybe<StringOperationFilterInput>;
  readonly year?: InputMaybe<IntOperationFilterInput>;
};

/** A connection to a list of items. */
export type VehicleJobsConnection = {
  readonly __typename: 'VehicleJobsConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<VehicleJobsEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Job>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VehicleJobsEdge = {
  readonly __typename: 'VehicleJobsEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Job;
};

export type VehicleSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly color?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly make?: InputMaybe<SortEnumType>;
  readonly model?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
  readonly vin?: InputMaybe<SortEnumType>;
  readonly year?: InputMaybe<SortEnumType>;
};

export type Vendor = {
  readonly __typename: 'Vendor';
  readonly attention?: Maybe<Scalars['String']['output']>;
  readonly autoPay?: Maybe<Scalars['String']['output']>;
  readonly county?: Maybe<Scalars['String']['output']>;
  readonly creditLimit: Scalars['Decimal']['output'];
  readonly deletePo?: Maybe<VendorDeletePo>;
  readonly discountDays?: Maybe<Scalars['String']['output']>;
  readonly discountPercent?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly fax?: Maybe<Scalars['String']['output']>;
  readonly fedId?: Maybe<Scalars['String']['output']>;
  readonly ficaId?: Maybe<Scalars['String']['output']>;
  readonly first?: Maybe<Scalars['String']['output']>;
  readonly fob?: Maybe<Scalars['String']['output']>;
  readonly glAccounts?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly incorporated?: Maybe<Scalars['String']['output']>;
  readonly last?: Maybe<Scalars['String']['output']>;
  readonly listForPrepayment?: Maybe<Scalars['String']['output']>;
  readonly locations?: Maybe<ReadonlyArray<Maybe<Location>>>;
  readonly middle?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly newNetDays?: Maybe<Scalars['String']['output']>;
  readonly oldId?: Maybe<Scalars['String']['output']>;
  readonly orderFromVendor?: Maybe<Vendor>;
  readonly orderFromVendorId?: Maybe<Scalars['String']['output']>;
  readonly origNetDays: Scalars['Int']['output'];
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly poAddresses?: Maybe<Scalars['String']['output']>;
  readonly poSpecialInstructions1?: Maybe<Scalars['String']['output']>;
  readonly poSpecialInstructions2?: Maybe<Scalars['String']['output']>;
  readonly pricePo?: Maybe<Scalars['String']['output']>;
  readonly printCode?: Maybe<Scalars['String']['output']>;
  readonly purchaserAccount?: Maybe<Scalars['String']['output']>;
  readonly quickBooksNameKey?: Maybe<Scalars['String']['output']>;
  readonly remark?: Maybe<Scalars['String']['output']>;
  readonly remitToName?: Maybe<Name>;
  readonly remitToNameId?: Maybe<Scalars['String']['output']>;
  readonly salesperson?: Maybe<Scalars['String']['output']>;
  readonly salespersonFax?: Maybe<Scalars['String']['output']>;
  readonly salespersonPhone?: Maybe<Scalars['String']['output']>;
  readonly salutation?: Maybe<Scalars['String']['output']>;
  readonly secondaryAddress?: Maybe<Scalars['String']['output']>;
  readonly shipResidentialCommercial?: Maybe<ResidentialCommercial>;
  readonly spare1?: Maybe<Scalars['String']['output']>;
  readonly spare2?: Maybe<Scalars['String']['output']>;
  readonly spare3?: Maybe<Scalars['String']['output']>;
  readonly spare4?: Maybe<Scalars['String']['output']>;
  readonly spare5?: Maybe<Scalars['String']['output']>;
  readonly spare6?: Maybe<Scalars['String']['output']>;
  readonly spare7?: Maybe<Scalars['String']['output']>;
  readonly spare9?: Maybe<Scalars['String']['output']>;
  readonly spare10?: Maybe<Scalars['String']['output']>;
  readonly spare11?: Maybe<Scalars['String']['output']>;
  readonly spare12?: Maybe<Scalars['String']['output']>;
  readonly spare13?: Maybe<Scalars['String']['output']>;
  readonly spare14?: Maybe<Scalars['String']['output']>;
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly subcontract?: Maybe<Scalars['String']['output']>;
  readonly taxable?: Maybe<Scalars['String']['output']>;
  readonly terms?: Maybe<Scalars['String']['output']>;
  readonly township?: Maybe<Scalars['String']['output']>;
  readonly vendorPosted?: Maybe<VendorPosted>;
  readonly zipCode?: Maybe<ZipCode>;
  readonly zipCodeId?: Maybe<Scalars['String']['output']>;
  readonly zipExt?: Maybe<Scalars['String']['output']>;
};

export type VendorAddInput = {
  readonly attention?: InputMaybe<Scalars['String']['input']>;
  readonly autoPay?: InputMaybe<Scalars['String']['input']>;
  readonly county?: InputMaybe<Scalars['String']['input']>;
  readonly creditLimit?: InputMaybe<Scalars['Decimal']['input']>;
  readonly deletePo?: InputMaybe<VendorDeletePo>;
  readonly discountDays?: InputMaybe<Scalars['String']['input']>;
  readonly discountPercent?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly fax?: InputMaybe<Scalars['String']['input']>;
  readonly fedId?: InputMaybe<Scalars['String']['input']>;
  readonly ficaId?: InputMaybe<Scalars['String']['input']>;
  readonly first?: InputMaybe<Scalars['String']['input']>;
  readonly fob?: InputMaybe<Scalars['String']['input']>;
  readonly glAccounts?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly incorporated?: InputMaybe<Scalars['String']['input']>;
  readonly last?: InputMaybe<Scalars['String']['input']>;
  readonly listForPrepayment?: InputMaybe<Scalars['String']['input']>;
  readonly middle?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly newNetDays?: InputMaybe<Scalars['String']['input']>;
  readonly oldId?: InputMaybe<Scalars['String']['input']>;
  readonly orderFromVendorId?: InputMaybe<Scalars['String']['input']>;
  readonly origNetDays?: InputMaybe<Scalars['Int']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly poAddresses?: InputMaybe<Scalars['String']['input']>;
  readonly poSpecialInstructions1?: InputMaybe<Scalars['String']['input']>;
  readonly poSpecialInstructions2?: InputMaybe<Scalars['String']['input']>;
  readonly pricePo?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly purchaserAccount?: InputMaybe<Scalars['String']['input']>;
  readonly quickBooksNameKey?: InputMaybe<Scalars['String']['input']>;
  readonly remark?: InputMaybe<Scalars['String']['input']>;
  readonly remitToNameId?: InputMaybe<Scalars['String']['input']>;
  readonly salesperson?: InputMaybe<Scalars['String']['input']>;
  readonly salespersonFax?: InputMaybe<Scalars['String']['input']>;
  readonly salespersonPhone?: InputMaybe<Scalars['String']['input']>;
  readonly salutation?: InputMaybe<Scalars['String']['input']>;
  readonly secondaryAddress?: InputMaybe<Scalars['String']['input']>;
  readonly shipResidentialCommercial?: InputMaybe<ResidentialCommercial>;
  readonly spare1?: InputMaybe<Scalars['String']['input']>;
  readonly spare2?: InputMaybe<Scalars['String']['input']>;
  readonly spare3?: InputMaybe<Scalars['String']['input']>;
  readonly spare4?: InputMaybe<Scalars['String']['input']>;
  readonly spare5?: InputMaybe<Scalars['String']['input']>;
  readonly spare6?: InputMaybe<Scalars['String']['input']>;
  readonly spare7?: InputMaybe<Scalars['String']['input']>;
  readonly spare9?: InputMaybe<Scalars['String']['input']>;
  readonly spare10?: InputMaybe<Scalars['String']['input']>;
  readonly spare11?: InputMaybe<Scalars['String']['input']>;
  readonly spare12?: InputMaybe<Scalars['String']['input']>;
  readonly spare13?: InputMaybe<Scalars['String']['input']>;
  readonly spare14?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly subcontract?: InputMaybe<Scalars['String']['input']>;
  readonly taxable?: InputMaybe<Scalars['String']['input']>;
  readonly terms?: InputMaybe<Scalars['String']['input']>;
  readonly township?: InputMaybe<Scalars['String']['input']>;
  /** When populated with a new zip code, it will be created. Existing zip codes will not be modified. */
  readonly zipCode?: InputMaybe<ZipCodeInput>;
  readonly zipCodeId?: InputMaybe<Scalars['String']['input']>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
};

export enum VendorDeletePo {
  No = 'NO',
  OnComplete = 'ON_COMPLETE',
  Yes = 'YES'
}

export type VendorFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<VendorFilterInput>>;
  readonly attention?: InputMaybe<StringOperationFilterInput>;
  readonly autoPay?: InputMaybe<StringOperationFilterInput>;
  readonly county?: InputMaybe<StringOperationFilterInput>;
  readonly creditLimit?: InputMaybe<DecimalOperationFilterInput>;
  readonly deletePo?: InputMaybe<NullableOfVendorDeletePoOperationFilterInput>;
  readonly discountDays?: InputMaybe<StringOperationFilterInput>;
  readonly discountPercent?: InputMaybe<StringOperationFilterInput>;
  readonly email?: InputMaybe<StringOperationFilterInput>;
  readonly fax?: InputMaybe<StringOperationFilterInput>;
  readonly fedId?: InputMaybe<StringOperationFilterInput>;
  readonly ficaId?: InputMaybe<StringOperationFilterInput>;
  readonly first?: InputMaybe<StringOperationFilterInput>;
  readonly fob?: InputMaybe<StringOperationFilterInput>;
  readonly glAccounts?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly incorporated?: InputMaybe<StringOperationFilterInput>;
  readonly last?: InputMaybe<StringOperationFilterInput>;
  readonly listForPrepayment?: InputMaybe<StringOperationFilterInput>;
  readonly locations?: InputMaybe<ListFilterInputTypeOfLocationFilterInput>;
  readonly middle?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly newNetDays?: InputMaybe<StringOperationFilterInput>;
  readonly oldId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<VendorFilterInput>>;
  readonly orderFromVendorId?: InputMaybe<StringOperationFilterInput>;
  readonly origNetDays?: InputMaybe<IntOperationFilterInput>;
  readonly phone?: InputMaybe<StringOperationFilterInput>;
  readonly poAddresses?: InputMaybe<StringOperationFilterInput>;
  readonly poSpecialInstructions1?: InputMaybe<StringOperationFilterInput>;
  readonly poSpecialInstructions2?: InputMaybe<StringOperationFilterInput>;
  readonly pricePo?: InputMaybe<StringOperationFilterInput>;
  readonly printCode?: InputMaybe<StringOperationFilterInput>;
  readonly purchaserAccount?: InputMaybe<StringOperationFilterInput>;
  readonly quickBooksNameKey?: InputMaybe<StringOperationFilterInput>;
  readonly remark?: InputMaybe<StringOperationFilterInput>;
  readonly remitToNameId?: InputMaybe<StringOperationFilterInput>;
  readonly salesperson?: InputMaybe<StringOperationFilterInput>;
  readonly salespersonFax?: InputMaybe<StringOperationFilterInput>;
  readonly salespersonPhone?: InputMaybe<StringOperationFilterInput>;
  readonly salutation?: InputMaybe<StringOperationFilterInput>;
  readonly secondaryAddress?: InputMaybe<StringOperationFilterInput>;
  readonly shipResidentialCommercial?: InputMaybe<NullableOfResidentialCommercialOperationFilterInput>;
  readonly spare1?: InputMaybe<StringOperationFilterInput>;
  readonly spare2?: InputMaybe<StringOperationFilterInput>;
  readonly spare3?: InputMaybe<StringOperationFilterInput>;
  readonly spare4?: InputMaybe<StringOperationFilterInput>;
  readonly spare5?: InputMaybe<StringOperationFilterInput>;
  readonly spare6?: InputMaybe<StringOperationFilterInput>;
  readonly spare7?: InputMaybe<StringOperationFilterInput>;
  readonly spare9?: InputMaybe<StringOperationFilterInput>;
  readonly spare10?: InputMaybe<StringOperationFilterInput>;
  readonly spare11?: InputMaybe<StringOperationFilterInput>;
  readonly spare12?: InputMaybe<StringOperationFilterInput>;
  readonly spare13?: InputMaybe<StringOperationFilterInput>;
  readonly spare14?: InputMaybe<StringOperationFilterInput>;
  readonly streetAddress?: InputMaybe<StringOperationFilterInput>;
  readonly subcontract?: InputMaybe<StringOperationFilterInput>;
  readonly taxable?: InputMaybe<StringOperationFilterInput>;
  readonly terms?: InputMaybe<StringOperationFilterInput>;
  readonly township?: InputMaybe<StringOperationFilterInput>;
  readonly zipCode?: InputMaybe<ZipCodeFilterInput>;
  readonly zipCodeId?: InputMaybe<StringOperationFilterInput>;
  readonly zipExt?: InputMaybe<StringOperationFilterInput>;
};

export type VendorPosted = {
  readonly __typename: 'VendorPosted';
  readonly adjustmentEntryPtrs?: Maybe<Scalars['String']['output']>;
  readonly beginningofYearBalance: Scalars['Decimal']['output'];
  readonly checkBook1Ptrs?: Maybe<Scalars['String']['output']>;
  readonly checkBook2Ptrs?: Maybe<Scalars['String']['output']>;
  readonly checkBook3Ptrs?: Maybe<Scalars['String']['output']>;
  readonly checkBook4Ptrs?: Maybe<Scalars['String']['output']>;
  readonly checkBook5Ptrs?: Maybe<Scalars['String']['output']>;
  readonly computerEntriesPtrs?: Maybe<Scalars['String']['output']>;
  readonly currentAmountOwed: Scalars['Decimal']['output'];
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly field16: Scalars['Decimal']['output'];
  readonly field17: Scalars['Decimal']['output'];
  readonly field18: Scalars['Decimal']['output'];
  readonly field19: Scalars['Decimal']['output'];
  readonly field20: Scalars['Decimal']['output'];
  readonly field21: Scalars['Decimal']['output'];
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly mtDonPurchaseorder: Scalars['Decimal']['output'];
  readonly mtdBought: Scalars['Decimal']['output'];
  readonly mtdDiscountsTaken: Scalars['Decimal']['output'];
  readonly mtdPaid: Scalars['Decimal']['output'];
  readonly mtdShippingTicketsNotInvoiced: Scalars['Decimal']['output'];
  readonly openInvoicePtrs?: Maybe<Scalars['String']['output']>;
  readonly paidInvoicePtrs?: Maybe<Scalars['String']['output']>;
  readonly poPtrs?: Maybe<Scalars['String']['output']>;
  readonly priorMonthBought: Scalars['Decimal']['output'];
  readonly priorMonthDiscountsTaken: Scalars['Decimal']['output'];
  readonly priorMonthPaid: Scalars['Decimal']['output'];
  readonly priorMonthShippingTicketsNotInvoiced: Scalars['Decimal']['output'];
  readonly priorMonthonPurchasedorder: Scalars['Decimal']['output'];
  readonly priorYtdDiscountsTaken: Scalars['Decimal']['output'];
  readonly priorYtdPaid: Scalars['Decimal']['output'];
  readonly priorYtdShippingTicketsNotInvoiced: Scalars['Decimal']['output'];
  readonly shippingTicketPtrs?: Maybe<Scalars['String']['output']>;
  readonly specialUsePtr0?: Maybe<Scalars['String']['output']>;
  readonly specialUsePtr1?: Maybe<Scalars['String']['output']>;
  readonly specialUsePtr2?: Maybe<Scalars['String']['output']>;
  readonly telephoneLogPtr?: Maybe<Scalars['String']['output']>;
  readonly ytDonPurchaseorder: Scalars['Decimal']['output'];
  readonly ytdBought: Scalars['Decimal']['output'];
};

export type VendorSaveResult = {
  readonly __typename: 'VendorSaveResult';
  readonly data?: Maybe<Vendor>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly validation: ReadonlyArray<ValidationNotice>;
};

/** A connection to a list of items. */
export type VendorSearchConnection = {
  readonly __typename: 'VendorSearchConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<VendorSearchEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Vendor>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VendorSearchEdge = {
  readonly __typename: 'VendorSearchEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Vendor;
};

export type VendorSortInput = {
  readonly attention?: InputMaybe<SortEnumType>;
  readonly autoPay?: InputMaybe<SortEnumType>;
  readonly county?: InputMaybe<SortEnumType>;
  readonly creditLimit?: InputMaybe<SortEnumType>;
  readonly deletePo?: InputMaybe<SortEnumType>;
  readonly discountDays?: InputMaybe<SortEnumType>;
  readonly discountPercent?: InputMaybe<SortEnumType>;
  readonly email?: InputMaybe<SortEnumType>;
  readonly fax?: InputMaybe<SortEnumType>;
  readonly fedId?: InputMaybe<SortEnumType>;
  readonly ficaId?: InputMaybe<SortEnumType>;
  readonly first?: InputMaybe<SortEnumType>;
  readonly fob?: InputMaybe<SortEnumType>;
  readonly glAccounts?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly incorporated?: InputMaybe<SortEnumType>;
  readonly last?: InputMaybe<SortEnumType>;
  readonly listForPrepayment?: InputMaybe<SortEnumType>;
  readonly middle?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly newNetDays?: InputMaybe<SortEnumType>;
  readonly oldId?: InputMaybe<SortEnumType>;
  readonly orderFromVendorId?: InputMaybe<SortEnumType>;
  readonly origNetDays?: InputMaybe<SortEnumType>;
  readonly phone?: InputMaybe<SortEnumType>;
  readonly poAddresses?: InputMaybe<SortEnumType>;
  readonly poSpecialInstructions1?: InputMaybe<SortEnumType>;
  readonly poSpecialInstructions2?: InputMaybe<SortEnumType>;
  readonly pricePo?: InputMaybe<SortEnumType>;
  readonly printCode?: InputMaybe<SortEnumType>;
  readonly purchaserAccount?: InputMaybe<SortEnumType>;
  readonly quickBooksNameKey?: InputMaybe<SortEnumType>;
  readonly remark?: InputMaybe<SortEnumType>;
  readonly remitToNameId?: InputMaybe<SortEnumType>;
  readonly salesperson?: InputMaybe<SortEnumType>;
  readonly salespersonFax?: InputMaybe<SortEnumType>;
  readonly salespersonPhone?: InputMaybe<SortEnumType>;
  readonly salutation?: InputMaybe<SortEnumType>;
  readonly secondaryAddress?: InputMaybe<SortEnumType>;
  readonly shipResidentialCommercial?: InputMaybe<SortEnumType>;
  readonly spare1?: InputMaybe<SortEnumType>;
  readonly spare2?: InputMaybe<SortEnumType>;
  readonly spare3?: InputMaybe<SortEnumType>;
  readonly spare4?: InputMaybe<SortEnumType>;
  readonly spare5?: InputMaybe<SortEnumType>;
  readonly spare6?: InputMaybe<SortEnumType>;
  readonly spare7?: InputMaybe<SortEnumType>;
  readonly spare9?: InputMaybe<SortEnumType>;
  readonly spare10?: InputMaybe<SortEnumType>;
  readonly spare11?: InputMaybe<SortEnumType>;
  readonly spare12?: InputMaybe<SortEnumType>;
  readonly spare13?: InputMaybe<SortEnumType>;
  readonly spare14?: InputMaybe<SortEnumType>;
  readonly streetAddress?: InputMaybe<SortEnumType>;
  readonly subcontract?: InputMaybe<SortEnumType>;
  readonly taxable?: InputMaybe<SortEnumType>;
  readonly terms?: InputMaybe<SortEnumType>;
  readonly township?: InputMaybe<SortEnumType>;
  readonly zipCode?: InputMaybe<ZipCodeSortInput>;
  readonly zipCodeId?: InputMaybe<SortEnumType>;
  readonly zipExt?: InputMaybe<SortEnumType>;
};

export type VendorTop = {
  readonly __typename: 'VendorTop';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly qtyTransactions: Scalars['Int']['output'];
  readonly totalBills: Scalars['Decimal']['output'];
};

export type VendorUpdateInput = {
  readonly attention?: InputMaybe<Scalars['String']['input']>;
  readonly autoPay?: InputMaybe<Scalars['String']['input']>;
  readonly county?: InputMaybe<Scalars['String']['input']>;
  readonly creditLimit?: InputMaybe<Scalars['Decimal']['input']>;
  readonly deletePo?: InputMaybe<VendorDeletePo>;
  readonly discountDays?: InputMaybe<Scalars['String']['input']>;
  readonly discountPercent?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly fax?: InputMaybe<Scalars['String']['input']>;
  readonly fedId?: InputMaybe<Scalars['String']['input']>;
  readonly ficaId?: InputMaybe<Scalars['String']['input']>;
  readonly first?: InputMaybe<Scalars['String']['input']>;
  readonly fob?: InputMaybe<Scalars['String']['input']>;
  readonly glAccounts?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly incorporated?: InputMaybe<Scalars['String']['input']>;
  readonly last?: InputMaybe<Scalars['String']['input']>;
  readonly listForPrepayment?: InputMaybe<Scalars['String']['input']>;
  readonly middle?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly newNetDays?: InputMaybe<Scalars['String']['input']>;
  readonly oldId?: InputMaybe<Scalars['String']['input']>;
  readonly orderFromVendorId?: InputMaybe<Scalars['String']['input']>;
  readonly origNetDays?: InputMaybe<Scalars['Int']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly poAddresses?: InputMaybe<Scalars['String']['input']>;
  readonly poSpecialInstructions1?: InputMaybe<Scalars['String']['input']>;
  readonly poSpecialInstructions2?: InputMaybe<Scalars['String']['input']>;
  readonly pricePo?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly purchaserAccount?: InputMaybe<Scalars['String']['input']>;
  readonly quickBooksNameKey?: InputMaybe<Scalars['String']['input']>;
  readonly remark?: InputMaybe<Scalars['String']['input']>;
  readonly remitToNameId?: InputMaybe<Scalars['String']['input']>;
  readonly salesperson?: InputMaybe<Scalars['String']['input']>;
  readonly salespersonFax?: InputMaybe<Scalars['String']['input']>;
  readonly salespersonPhone?: InputMaybe<Scalars['String']['input']>;
  readonly salutation?: InputMaybe<Scalars['String']['input']>;
  readonly secondaryAddress?: InputMaybe<Scalars['String']['input']>;
  readonly shipResidentialCommercial?: InputMaybe<ResidentialCommercial>;
  readonly spare1?: InputMaybe<Scalars['String']['input']>;
  readonly spare2?: InputMaybe<Scalars['String']['input']>;
  readonly spare3?: InputMaybe<Scalars['String']['input']>;
  readonly spare4?: InputMaybe<Scalars['String']['input']>;
  readonly spare5?: InputMaybe<Scalars['String']['input']>;
  readonly spare6?: InputMaybe<Scalars['String']['input']>;
  readonly spare7?: InputMaybe<Scalars['String']['input']>;
  readonly spare9?: InputMaybe<Scalars['String']['input']>;
  readonly spare10?: InputMaybe<Scalars['String']['input']>;
  readonly spare11?: InputMaybe<Scalars['String']['input']>;
  readonly spare12?: InputMaybe<Scalars['String']['input']>;
  readonly spare13?: InputMaybe<Scalars['String']['input']>;
  readonly spare14?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly subcontract?: InputMaybe<Scalars['String']['input']>;
  readonly taxable?: InputMaybe<Scalars['String']['input']>;
  readonly terms?: InputMaybe<Scalars['String']['input']>;
  readonly township?: InputMaybe<Scalars['String']['input']>;
  /** When populated with a new zip code, it will be created. Existing zip codes will not be modified. */
  readonly zipCode?: InputMaybe<ZipCodeInput>;
  readonly zipCodeId?: InputMaybe<Scalars['String']['input']>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
};

export type VendorValidateInput = {
  readonly attention?: InputMaybe<Scalars['String']['input']>;
  readonly autoPay?: InputMaybe<Scalars['String']['input']>;
  readonly county?: InputMaybe<Scalars['String']['input']>;
  readonly creditLimit?: InputMaybe<Scalars['Decimal']['input']>;
  readonly deletePo?: InputMaybe<VendorDeletePo>;
  readonly discountDays?: InputMaybe<Scalars['String']['input']>;
  readonly discountPercent?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly fax?: InputMaybe<Scalars['String']['input']>;
  readonly fedId?: InputMaybe<Scalars['String']['input']>;
  readonly ficaId?: InputMaybe<Scalars['String']['input']>;
  readonly first?: InputMaybe<Scalars['String']['input']>;
  readonly fob?: InputMaybe<Scalars['String']['input']>;
  readonly glAccounts?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly incorporated?: InputMaybe<Scalars['String']['input']>;
  readonly last?: InputMaybe<Scalars['String']['input']>;
  readonly listForPayment?: InputMaybe<Scalars['String']['input']>;
  readonly middle?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly newNetDays?: InputMaybe<Scalars['String']['input']>;
  readonly oldId?: InputMaybe<Scalars['String']['input']>;
  readonly orderFromVendorId?: InputMaybe<Scalars['String']['input']>;
  readonly origNetDays?: InputMaybe<Scalars['ID']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly poAddresses?: InputMaybe<Scalars['String']['input']>;
  readonly poSpecialInstructions1?: InputMaybe<Scalars['String']['input']>;
  readonly poSpecialInstructions2?: InputMaybe<Scalars['String']['input']>;
  readonly pricePo?: InputMaybe<Scalars['String']['input']>;
  readonly printCode?: InputMaybe<Scalars['String']['input']>;
  readonly purchaserAccount?: InputMaybe<Scalars['String']['input']>;
  readonly quickBooksNameKey?: InputMaybe<Scalars['String']['input']>;
  readonly remark?: InputMaybe<Scalars['String']['input']>;
  readonly remitToNameId?: InputMaybe<Scalars['String']['input']>;
  readonly salesperson?: InputMaybe<Scalars['String']['input']>;
  readonly salespersonFax?: InputMaybe<Scalars['String']['input']>;
  readonly salespersonPhone?: InputMaybe<Scalars['String']['input']>;
  readonly salutation?: InputMaybe<Scalars['String']['input']>;
  readonly secondaryAddress?: InputMaybe<Scalars['String']['input']>;
  readonly shipResidentialCommercial?: InputMaybe<ResidentialCommercial>;
  readonly spare1?: InputMaybe<Scalars['String']['input']>;
  readonly spare2?: InputMaybe<Scalars['String']['input']>;
  readonly spare3?: InputMaybe<Scalars['String']['input']>;
  readonly spare4?: InputMaybe<Scalars['String']['input']>;
  readonly spare5?: InputMaybe<Scalars['String']['input']>;
  readonly spare6?: InputMaybe<Scalars['String']['input']>;
  readonly spare7?: InputMaybe<Scalars['String']['input']>;
  readonly spare9?: InputMaybe<Scalars['String']['input']>;
  readonly spare10?: InputMaybe<Scalars['String']['input']>;
  readonly spare11?: InputMaybe<Scalars['String']['input']>;
  readonly spare12?: InputMaybe<Scalars['String']['input']>;
  readonly spare13?: InputMaybe<Scalars['String']['input']>;
  readonly spare14?: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  readonly subcontract?: InputMaybe<Scalars['String']['input']>;
  readonly taxable?: InputMaybe<Scalars['String']['input']>;
  readonly terms?: InputMaybe<Scalars['String']['input']>;
  readonly township?: InputMaybe<Scalars['String']['input']>;
  readonly zipCode?: InputMaybe<ZipCodeValidateInput>;
  readonly zipCodeId?: InputMaybe<Scalars['String']['input']>;
  readonly zipExt?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type VendorsConnection = {
  readonly __typename: 'VendorsConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<VendorsEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Vendor>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VendorsEdge = {
  readonly __typename: 'VendorsEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Vendor;
};

/** A connection to a list of items. */
export type VendorsListConnection = {
  readonly __typename: 'VendorsListConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<VendorsListEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<Vendor>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VendorsListEdge = {
  readonly __typename: 'VendorsListEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: Vendor;
};

export enum VendorsTopPeriod {
  Month = 'MONTH',
  Quarter = 'QUARTER',
  TwoYears = 'TWO_YEARS',
  Year = 'YEAR'
}

export enum VendorsTopSort {
  Count = 'COUNT',
  Total = 'TOTAL'
}

export type Verification = {
  readonly __typename: 'Verification';
  readonly date: Scalars['DateTime']['output'];
  readonly provider?: Maybe<Scalars['String']['output']>;
  readonly verificationId?: Maybe<Scalars['String']['output']>;
};

export type VerificationFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<VerificationFilterInput>>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<VerificationFilterInput>>;
  readonly provider?: InputMaybe<StringOperationFilterInput>;
  readonly verificationId?: InputMaybe<StringOperationFilterInput>;
};

export type WebStore = {
  readonly __typename: 'WebStore';
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type WebStoreAddInput = {
  readonly name: Scalars['String']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
};

export type WebStoreFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<WebStoreFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly notes?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<WebStoreFilterInput>>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type WebStoreItem = {
  readonly __typename: 'WebStoreItem';
  readonly item: Item;
  readonly itemId: Scalars['String']['output'];
  readonly storeItemId?: Maybe<Scalars['Int']['output']>;
  readonly visible: Scalars['Boolean']['output'];
  readonly webStore: WebStore;
  readonly webStoreId: Scalars['UUID']['output'];
};

export type WebStoreItemFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<WebStoreItemFilterInput>>;
  readonly item?: InputMaybe<ItemFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<WebStoreItemFilterInput>>;
  readonly storeItemId?: InputMaybe<IntOperationFilterInput>;
  readonly visible?: InputMaybe<BooleanOperationFilterInput>;
  readonly webStore?: InputMaybe<WebStoreFilterInput>;
  readonly webStoreId?: InputMaybe<UuidOperationFilterInput>;
};

export type WebStoreSortInput = {
  readonly added?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<SortEnumType>;
  readonly updated?: InputMaybe<SortEnumType>;
};

export type WebStoreUpdateInput = {
  readonly id: Scalars['UUID']['input'];
  readonly name: Scalars['String']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
};

export type WorkOrder = SalesTransaction & {
  readonly __typename: 'WorkOrder';
  readonly allowLateCharge?: Maybe<Scalars['String']['output']>;
  readonly backOrderAmountHeld: Scalars['Decimal']['output'];
  readonly billCode?: Maybe<Scalars['String']['output']>;
  readonly customer?: Maybe<Customer>;
  readonly customerId?: Maybe<Scalars['String']['output']>;
  readonly customerPoNumber?: Maybe<Scalars['String']['output']>;
  readonly customerType?: Maybe<CustomerType>;
  readonly customerTypeId?: Maybe<Scalars['String']['output']>;
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly dateBackOrdered?: Maybe<Scalars['DateTime']['output']>;
  readonly dates: ReadonlyArray<WorkOrderDate>;
  readonly deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  readonly details: ReadonlyArray<WorkOrderDetail>;
  readonly discountAmount: Scalars['Decimal']['output'];
  readonly discountDate?: Maybe<Scalars['DateTime']['output']>;
  readonly dueDate?: Maybe<Scalars['DateTime']['output']>;
  readonly forms?: Maybe<ReadonlyArray<FormDataGroup>>;
  readonly freeFreight?: Maybe<Scalars['String']['output']>;
  readonly fulfilled: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly invoiceType?: Maybe<Scalars['String']['output']>;
  readonly job?: Maybe<Job>;
  readonly jobId?: Maybe<Scalars['String']['output']>;
  readonly operator?: Maybe<Scalars['String']['output']>;
  readonly orderDate?: Maybe<Scalars['DateTime']['output']>;
  readonly origOrderId: Scalars['ID']['output'];
  readonly pickList: ReadonlyArray<WorkOrderPickItem>;
  readonly remark?: Maybe<Scalars['String']['output']>;
  readonly salesperson?: Maybe<Salesperson>;
  readonly salespersonId?: Maybe<Scalars['String']['output']>;
  readonly shipToName?: Maybe<Name>;
  readonly shipToNameId?: Maybe<Scalars['String']['output']>;
  readonly shipVia?: Maybe<Scalars['String']['output']>;
  readonly shipments: ReadonlyArray<WorkOrderShipment>;
  readonly slipNumber?: Maybe<Scalars['String']['output']>;
  readonly spare0?: Maybe<Scalars['String']['output']>;
  readonly spare1?: Maybe<Scalars['String']['output']>;
  readonly taxStatus?: Maybe<Scalars['String']['output']>;
  readonly terms?: Maybe<Scalars['String']['output']>;
  readonly totalCost: Scalars['Decimal']['output'];
  readonly totalInvoice: Scalars['Decimal']['output'];
};

export type WorkOrderDate = BaseDate & {
  readonly __typename: 'WorkOrderDate';
  readonly added: Scalars['DateTime']['output'];
  readonly endDate: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly startDate: Scalars['DateTime']['output'];
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly weight?: Maybe<Scalars['Decimal']['output']>;
  readonly workOrderDetail?: Maybe<Item>;
  readonly workOrderDetailId?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderDateFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<WorkOrderDateFilterInput>>;
  readonly endDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<WorkOrderDateFilterInput>>;
  readonly startDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly type?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
  readonly weight?: InputMaybe<DecimalOperationFilterInput>;
  readonly workOrderDetail?: InputMaybe<ItemFilterInput>;
  readonly workOrderDetailId?: InputMaybe<StringOperationFilterInput>;
};

export type WorkOrderDetail = {
  readonly __typename: 'WorkOrderDetail';
  readonly amount: Scalars['Decimal']['output'];
  readonly cost: Scalars['Decimal']['output'];
  readonly fulfilled: Scalars['Boolean']['output'];
  readonly glAccountId: Scalars['ID']['output'];
  readonly id: Scalars['ID']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly jobClassId?: Maybe<Scalars['String']['output']>;
  readonly lineNum: Scalars['Int']['output'];
  readonly listPrice: Scalars['Decimal']['output'];
  readonly lotNumbers?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly orderedQuantity: Scalars['Decimal']['output'];
  readonly quotePricing?: Maybe<Scalars['String']['output']>;
  readonly sellPrice: Scalars['Decimal']['output'];
  readonly sellQuantity: Scalars['Decimal']['output'];
  readonly serialNumbers?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly taxable: Scalars['Boolean']['output'];
  readonly unit?: Maybe<Scalars['String']['output']>;
  readonly weight: Scalars['Decimal']['output'];
};

export type WorkOrderDetailFilterInput = {
  readonly amount?: InputMaybe<DecimalOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<WorkOrderDetailFilterInput>>;
  readonly cost?: InputMaybe<DecimalOperationFilterInput>;
  readonly fulfilled?: InputMaybe<BooleanOperationFilterInput>;
  readonly glAccountId?: InputMaybe<IdOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly item?: InputMaybe<ItemFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly jobClass?: InputMaybe<JobClassFilterInput>;
  readonly lineNum?: InputMaybe<IntOperationFilterInput>;
  readonly listPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<WorkOrderDetailFilterInput>>;
  readonly orderedQuantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly quotePricing?: InputMaybe<StringOperationFilterInput>;
  readonly sellPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly sellQuantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly taxableAndWeight?: InputMaybe<StringOperationFilterInput>;
  readonly unit?: InputMaybe<StringOperationFilterInput>;
};

export type WorkOrderFilterInput = {
  readonly allowLateCharge?: InputMaybe<StringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<WorkOrderFilterInput>>;
  readonly backOrderAmountHeld?: InputMaybe<DecimalOperationFilterInput>;
  readonly billCode?: InputMaybe<StringOperationFilterInput>;
  readonly customer?: InputMaybe<CustomerFilterInput>;
  readonly customerId?: InputMaybe<StringOperationFilterInput>;
  readonly customerPoNumber?: InputMaybe<StringOperationFilterInput>;
  readonly customerType?: InputMaybe<CustomerTypeFilterInput>;
  readonly customerTypeId?: InputMaybe<StringOperationFilterInput>;
  readonly date?: InputMaybe<DateTimeOperationFilterInput>;
  readonly dateBackOrdered?: InputMaybe<DateTimeOperationFilterInput>;
  readonly dates?: InputMaybe<ListFilterInputTypeOfWorkOrderDateFilterInput>;
  readonly deliveryDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly details?: InputMaybe<ListFilterInputTypeOfWorkOrderDetailViewFilterInput>;
  readonly discountAmount?: InputMaybe<DecimalOperationFilterInput>;
  readonly discountDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly dueDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly forms?: InputMaybe<ListFilterInputTypeOfFormDataGroupFilterInput>;
  readonly freeFreight?: InputMaybe<StringOperationFilterInput>;
  readonly fulfilled?: InputMaybe<BooleanOperationFilterInput>;
  readonly id?: InputMaybe<IdOperationFilterInput>;
  readonly invoiceType?: InputMaybe<StringOperationFilterInput>;
  readonly job?: InputMaybe<JobFilterInput>;
  readonly jobId?: InputMaybe<StringOperationFilterInput>;
  readonly operator?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<WorkOrderFilterInput>>;
  readonly orderDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly origOrderId?: InputMaybe<IdOperationFilterInput>;
  readonly remark?: InputMaybe<StringOperationFilterInput>;
  readonly salesperson?: InputMaybe<SalespersonFilterInput>;
  readonly salespersonId?: InputMaybe<StringOperationFilterInput>;
  readonly shipToName?: InputMaybe<NameFilterInput>;
  readonly shipToNameId?: InputMaybe<StringOperationFilterInput>;
  readonly shipVia?: InputMaybe<StringOperationFilterInput>;
  readonly shipments?: InputMaybe<ListFilterInputTypeOfWorkOrderShipmentFilterInput>;
  readonly slipNumber?: InputMaybe<StringOperationFilterInput>;
  readonly spare0?: InputMaybe<StringOperationFilterInput>;
  readonly spare1?: InputMaybe<StringOperationFilterInput>;
  readonly taxStatus?: InputMaybe<StringOperationFilterInput>;
  readonly terms?: InputMaybe<StringOperationFilterInput>;
  readonly totalCost?: InputMaybe<DecimalOperationFilterInput>;
  readonly totalInvoice?: InputMaybe<DecimalOperationFilterInput>;
};

export type WorkOrderPickItem = {
  readonly __typename: 'WorkOrderPickItem';
  readonly fulfilled: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId: Scalars['String']['output'];
  readonly orderedQuantity: Scalars['Decimal']['output'];
  readonly pickedQuantity: Scalars['Decimal']['output'];
};

/** A connection to a list of items. */
export type WorkOrderSearchConnection = {
  readonly __typename: 'WorkOrderSearchConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<WorkOrderSearchEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<WorkOrder>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WorkOrderSearchEdge = {
  readonly __typename: 'WorkOrderSearchEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: WorkOrder;
};

export type WorkOrderShipment = SalesTransactionShipment & {
  readonly __typename: 'WorkOrderShipment';
  readonly added: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly item?: Maybe<Item>;
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly salesTransaction: WorkOrder;
  readonly shipment?: Maybe<Shipment>;
  readonly shipmentId?: Maybe<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type WorkOrderShipmentFilterInput = {
  readonly added?: InputMaybe<DateTimeOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<WorkOrderShipmentFilterInput>>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly item?: InputMaybe<ItemFilterInput>;
  readonly itemId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<WorkOrderShipmentFilterInput>>;
  readonly salesTransaction?: InputMaybe<ISalesTransactionFilterInput>;
  readonly shipment?: InputMaybe<ShipmentFilterInput>;
  readonly shipmentId?: InputMaybe<StringOperationFilterInput>;
  readonly updated?: InputMaybe<DateTimeOperationFilterInput>;
};

export type WorkOrderSortInput = {
  readonly allowLateCharge?: InputMaybe<SortEnumType>;
  readonly backOrderAmountHeld?: InputMaybe<SortEnumType>;
  readonly billCode?: InputMaybe<SortEnumType>;
  readonly customer?: InputMaybe<CustomerSortInput>;
  readonly customerId?: InputMaybe<SortEnumType>;
  readonly customerPoNumber?: InputMaybe<SortEnumType>;
  readonly customerType?: InputMaybe<CustomerTypeSortInput>;
  readonly customerTypeId?: InputMaybe<SortEnumType>;
  readonly date?: InputMaybe<SortEnumType>;
  readonly dateBackOrdered?: InputMaybe<SortEnumType>;
  readonly deliveryDate?: InputMaybe<SortEnumType>;
  readonly discountAmount?: InputMaybe<SortEnumType>;
  readonly discountDate?: InputMaybe<SortEnumType>;
  readonly dueDate?: InputMaybe<SortEnumType>;
  readonly freeFreight?: InputMaybe<SortEnumType>;
  readonly fulfilled?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly invoiceType?: InputMaybe<SortEnumType>;
  readonly job?: InputMaybe<JobSortInput>;
  readonly jobId?: InputMaybe<SortEnumType>;
  readonly operator?: InputMaybe<SortEnumType>;
  readonly orderDate?: InputMaybe<SortEnumType>;
  readonly origOrderId?: InputMaybe<SortEnumType>;
  readonly remark?: InputMaybe<SortEnumType>;
  readonly salesperson?: InputMaybe<SalespersonSortInput>;
  readonly salespersonId?: InputMaybe<SortEnumType>;
  readonly shipToName?: InputMaybe<NameSortInput>;
  readonly shipToNameId?: InputMaybe<SortEnumType>;
  readonly shipVia?: InputMaybe<SortEnumType>;
  readonly slipNumber?: InputMaybe<SortEnumType>;
  readonly spare0?: InputMaybe<SortEnumType>;
  readonly spare1?: InputMaybe<SortEnumType>;
  readonly taxStatus?: InputMaybe<SortEnumType>;
  readonly terms?: InputMaybe<SortEnumType>;
  readonly totalCost?: InputMaybe<SortEnumType>;
  readonly totalInvoice?: InputMaybe<SortEnumType>;
};

export type WorkOrderUpdateInput = {
  readonly forms?: InputMaybe<ReadonlyArray<FormDataGroupInput>>;
  readonly id: Scalars['ID']['input'];
};

export type WorkOrderUpdatedEvent = {
  readonly __typename: 'WorkOrderUpdatedEvent';
  readonly companyId: Scalars['String']['output'];
  readonly eventId: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type WorkOrdersConnection = {
  readonly __typename: 'WorkOrdersConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<WorkOrdersEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<WorkOrder>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WorkOrdersEdge = {
  readonly __typename: 'WorkOrdersEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: WorkOrder;
};

export type ZipCode = {
  readonly __typename: 'ZipCode';
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly county?: Maybe<Scalars['String']['output']>;
  readonly geographicalArea?: Maybe<Scalars['String']['output']>;
  readonly groupEnd?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly spare7?: Maybe<Scalars['String']['output']>;
  readonly state?: Maybe<Scalars['String']['output']>;
  readonly taxStatus?: Maybe<Scalars['String']['output']>;
};

export type ZipCodeFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ZipCodeFilterInput>>;
  readonly city?: InputMaybe<StringOperationFilterInput>;
  readonly country?: InputMaybe<StringOperationFilterInput>;
  readonly county?: InputMaybe<StringOperationFilterInput>;
  readonly geographicalArea?: InputMaybe<StringOperationFilterInput>;
  readonly groupEnd?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ZipCodeFilterInput>>;
  readonly spare7?: InputMaybe<StringOperationFilterInput>;
  readonly state?: InputMaybe<StringOperationFilterInput>;
  readonly taxStatus?: InputMaybe<StringOperationFilterInput>;
};

export type ZipCodeInput = {
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly country?: InputMaybe<Scalars['String']['input']>;
  readonly county?: InputMaybe<Scalars['String']['input']>;
  readonly geographicalArea?: InputMaybe<Scalars['String']['input']>;
  readonly groupEnd?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly spare7?: InputMaybe<Scalars['String']['input']>;
  readonly state?: InputMaybe<Scalars['String']['input']>;
  readonly taxStatus?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type ZipCodeSearchConnection = {
  readonly __typename: 'ZipCodeSearchConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<ZipCodeSearchEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<ZipCode>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ZipCodeSearchEdge = {
  readonly __typename: 'ZipCodeSearchEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: ZipCode;
};

export type ZipCodeSortInput = {
  readonly city?: InputMaybe<SortEnumType>;
  readonly country?: InputMaybe<SortEnumType>;
  readonly county?: InputMaybe<SortEnumType>;
  readonly geographicalArea?: InputMaybe<SortEnumType>;
  readonly groupEnd?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly spare7?: InputMaybe<SortEnumType>;
  readonly state?: InputMaybe<SortEnumType>;
  readonly taxStatus?: InputMaybe<SortEnumType>;
};

export type ZipCodeValidateInput = {
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly country?: InputMaybe<Scalars['String']['input']>;
  readonly county?: InputMaybe<Scalars['String']['input']>;
  readonly geographicalArea?: InputMaybe<Scalars['String']['input']>;
  readonly groupEnd?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly spare7?: InputMaybe<Scalars['String']['input']>;
  readonly state?: InputMaybe<Scalars['String']['input']>;
  readonly taxStatus?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type ZipCodesConnection = {
  readonly __typename: 'ZipCodesConnection';
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<ZipCodesEdge>>;
  /** A flattened list of the nodes. */
  readonly nodes?: Maybe<ReadonlyArray<ZipCode>>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ZipCodesEdge = {
  readonly __typename: 'ZipCodesEdge';
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  readonly node: ZipCode;
};
