import { lazy } from 'react';
import { WidgetGroupIds } from '../dashboards/widgetManager/widgetGroups';
import { widgetManager } from '../dashboards/widgetManager/widgetManager';

const BankBalanceHistory = lazy(
  () => import('./dashboardWidgets/BankBalanceHistory')
);

const BankBalanceHistoryConfig = lazy(
  () => import('./dashboardWidgets/BankBalanceHistory/ConfigPage')
);

widgetManager.addWidgetLoader((e) => {
  e.addWidget(
    {
      id: 'BankBalanceHistory',
      groupId: WidgetGroupIds.Financial,
      title: 'Bank Balance History',
    },
    BankBalanceHistory,
    { minW: 5, minH: 6 },
    BankBalanceHistoryConfig
  );
});
