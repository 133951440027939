import { lazy } from 'react';
import { WidgetGroupIds } from 'modules/dashboards/widgetManager/widgetGroups';
import { widgetManager } from 'modules/dashboards/widgetManager/widgetManager';

const ItemsWidget = lazy(() => import('./dashboardWidgets/Items'));
const ItemsConfig = lazy(() => import('./dashboardWidgets/Items/ConfigPage'));
const SerialNumbersWidget = lazy(
  () => import('./dashboardWidgets/SerialNumbers')
);
const SerialNumbersConfig = lazy(
  () => import('./dashboardWidgets/SerialNumbers/ConfigPage')
);

const TopItemsWidget = lazy(() => import('./dashboardWidgets/TopItemsGraph'));
const TopItemsConfig = lazy(
  () => import('./dashboardWidgets/TopItemsGraph/ConfigPage')
);

widgetManager.addWidgetLoader((e) => {
  e.addWidget(
    {
      id: 'Items',
      title: 'Items',
      groupId: WidgetGroupIds.Inventory,
    },
    ItemsWidget,
    { minW: 6, minH: 4 },
    ItemsConfig
  );
  e.addWidget(
    {
      id: 'SerialNumbers',
      title: 'SerialNumbers',
      groupId: WidgetGroupIds.Inventory,
    },
    SerialNumbersWidget,
    { minW: 6, minH: 4 },
    SerialNumbersConfig
  );
  e.addWidget(
    {
      id: 'TopItems',
      title: 'Top Items Graph',
      groupId: WidgetGroupIds.Inventory,
    },
    TopItemsWidget,
    { minW: 6, minH: 4 },
    TopItemsConfig
  );
});
