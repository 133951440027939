import { lazy } from 'react';
import { WidgetGroupIds } from '../dashboards/widgetManager/widgetGroups';
import { widgetManager } from '../dashboards/widgetManager/widgetManager';

const ContactStats = lazy(() => import('./dashboardWidgets/ContactStats'));
const ContactStatsConfig = lazy(
  () => import('./dashboardWidgets/ContactStats/ConfigPage')
);
const CustomerType = lazy(() => import('./dashboardWidgets/CustomerTypeGraph'));
const CustomerTypeConf = lazy(
  () => import('./dashboardWidgets/CustomerTypeGraph/ConfigPage')
);

const CustomerRegion = lazy(
  () => import('./dashboardWidgets/CustomerRegionGraph')
);
const CustomerRegionConfig = lazy(
  () => import('./dashboardWidgets/CustomerRegionGraph/ConfigPage')
);
const CustomerRepeatsGraph = lazy(
  () => import('./dashboardWidgets/CustomerRepeatsCount')
);
const CustomerRepeatsGraphConfig = lazy(
  () => import('./dashboardWidgets/CustomerRepeatsCount/ConfigPage')
);

widgetManager.addWidgetLoader((e) => {
  e.addWidget(
    {
      id: 'ContactStats',
      title: 'Contact Stats',
      groupId: WidgetGroupIds.Statistics,
      description:
        'Can be configured to show a table of multiple contact types or only a single contact type.',
    },
    ContactStats,
    {
      minH: 3,
      minW: 3,
    },
    ContactStatsConfig
  );

  e.addWidget(
    {
      id: 'CustomerTypeGraph',
      title: 'Customer Types',
      groupId: WidgetGroupIds.Customer,
    },
    CustomerType,
    {
      minH: 6,
      minW: 4,
    },
    CustomerTypeConf
  );

  e.addWidget(
    {
      id: 'CustomerRegionGraph',
      title: 'Customer Regions',
      groupId: WidgetGroupIds.Customer,
    },
    CustomerRegion,
    {
      minH: 6,
      minW: 4,
    },
    CustomerRegionConfig
  );

  e.addWidget(
    {
      id: 'CustomerRepeatNewGraph',
      title: 'New/Repeat Customers',
      groupId: WidgetGroupIds.Customer,
      description:
        'Can be configured to show both new and repeat customers or just one of them.',
    },
    CustomerRepeatsGraph,
    {
      minH: 4,
      minW: 4,
    },
    CustomerRepeatsGraphConfig
  );
});
