import DashboardIcon from '@mui/icons-material/Dashboard';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import { Box, List } from '@mui/material';
import { FC } from 'react';
import { routes } from 'app/routes';
import { dashboardsRoutes } from 'app/routes/dashboards';
import Contacts from '../ModuleLinks/Contacts';
import Finances from '../ModuleLinks/Finances';
import Inventory from '../ModuleLinks/Inventory';
import Jobs from '../ModuleLinks/Jobs';
import Payroll from '../ModuleLinks/Payroll';
import Purchases from '../ModuleLinks/Purchases';
import Reports from '../ModuleLinks/Reports';
import Sales from '../ModuleLinks/Sales';
import ChildContainer from './ChildContainer';
import MenuItem from './MenuItem';
import { SidebarContextProvider } from './SidebarContext';

interface Props {}

const ModulesSection: FC<Props> = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <SidebarContextProvider>
        <List disablePadding>
          <MenuItem
            to={routes.dashboardsHome()}
            group={dashboardsRoutes}
            label="Dashboards"
            icon={<DashboardIcon />}
            isParent
          />
          <Sales />
          <Purchases />
          <Inventory />
          <Contacts />
          <Finances />
          <Payroll />
          <Jobs />
          <Reports />
          <MenuItem
            reload
            to={routes.client5()}
            label="Client5"
            icon={<TabletAndroidIcon />}
            isParent
          />
        </List>
        <ChildContainer />
      </SidebarContextProvider>
    </Box>
  );
};

export default ModulesSection;
