import { createGroup } from 'type-route';
import { routes } from './index';

export const configurationCompaniesWizardsRoutes = createGroup([
  routes.configurationCompaniesWizardsDashboard,
  routes.configurationCompaniesWizardsDuplicate,
  routes.configurationCompaniesWizardsRestore,
  routes.configurationCompaniesWizardsNew,
]);

export const configurationCompaniesFormTemplatesRoutes = createGroup([
  routes.configurationCompaniesFormTemplates,
  routes.configurationCompaniesFormTemplatesNew,
  routes.configurationCompaniesFormTemplatesEdit,
]);

export const configurationCompaniesIntegrationsRoutes = createGroup([
  routes.configurationCompaniesConnectionsNew,
  routes.configurationCompaniesConnectionsEdit,
  routes.configurationCompaniesIntegrations,
  routes.configurationCompaniesIntegrationsEdit,
]);

export const configurationCompaniesReportsStylesheetsRoutes = createGroup([
  routes.configurationCompaniesReportsStylesheets,
  routes.configurationCompaniesReportsStylesheetsEdit,
]);

export const configurationCompaniesReportsCategoriesRoutes = createGroup([
  routes.configurationCompaniesReportsCategories,
  routes.configurationCompaniesReportsCategoriesEdit,
]);

export const configurationCompaniesReportsReportsRoutes = createGroup([
  routes.configurationCompaniesReportsReports,
  routes.configurationCompaniesReportsReportsEdit,
]);

export const configurationCompaniesReportsRoutes = createGroup([
  { ...configurationCompaniesReportsStylesheetsRoutes },
  { ...configurationCompaniesReportsCategoriesRoutes },
  { ...configurationCompaniesReportsReportsRoutes },
]);

export const configurationCompaniesConnectionsRoutes = createGroup([
  routes.configurationCompaniesConnectionsNew,
  routes.configurationCompaniesConnectionsEdit,
]);

export const configurationCompaniesUserGroupRoutes = createGroup([
  routes.configurationCompaniesUserGroups,
  routes.configurationCompaniesUserGroupsEdit,
]);

export const configurationDevicesRoutes = createGroup([
  routes.configurationDevicesDashboard,
  routes.configurationDevicesEdit,
  routes.configurationDevicesNew,
]);

export const configurationWebStoresRoutes = createGroup([
  routes.configurationCompaniesWebStores,
  routes.configurationCompaniesWebStoresEdit,
]);

export const configurationValidationRoutes = createGroup([
  routes.configurationCompaniesValidation,
  routes.configurationCompaniesValidationList,
  routes.configurationCompaniesValidationEdit,
]);

export const configurationPrintChannelRoutes = createGroup([
  routes.configurationPrintChannels,
  routes.configurationPrintChannelEdit,
]);

export const configurationSearchRoutes = createGroup([
  routes.configurationCompaniesSearch,
  routes.configurationCompaniesSearchEdit,
]);

export const configurationDocGenRoutes = createGroup([
  routes.configurationDocumentGeneration,
  routes.configurationDocumentGenerationEntity,
  routes.configurationDocumentGenerationGenerator,
]);

export const configurationSystemConfig = createGroup([
  routes.configurationCompaniesSystemConfig,
  { ...configurationValidationRoutes },
  { ...configurationSearchRoutes },
  { ...configurationDocGenRoutes },
  { ...configurationPrintChannelRoutes },

]);

export const configurationCompaniesEditRoutes = createGroup([
  routes.configurationCompaniesDatabase,
  { ...configurationCompaniesIntegrationsRoutes },
  { ...configurationCompaniesConnectionsRoutes },
  { ...configurationCompaniesUserGroupRoutes },
  { ...configurationCompaniesReportsRoutes },
  routes.configurationCompaniesProfile,
  { ...configurationCompaniesFormTemplatesRoutes },
  { ...configurationSystemConfig },
  { ...configurationWebStoresRoutes },
]);

export const configurationCompaniesRoutes = createGroup([
  routes.configurationCompaniesDashboard,
  routes.configurationCompaniesImport,
  routes.configurationCompaniesLegacyServerEdit,
  routes.configurationCompaniesLegacyServerAdd,
  routes.configurationCompaniesLegacyServerLogs,
  routes.configurationCompaniesTaxFormsImport,
  { ...configurationCompaniesEditRoutes },
  { ...configurationCompaniesWizardsRoutes },
]);

export const configurationUsersEditRoutes = createGroup([
  routes.configurationUsersEdit,
  routes.configurationUsersEditReports,
  routes.configurationUsersEditPermissions,
  routes.configurationUsersEditProfile,
  routes.configurationUsersEditAuthorization,
]);

export const configurationUsersRoutes = createGroup([
  routes.configurationUsersDashboard,
  { ...configurationUsersEditRoutes },
]);

export const configurationLicenseKeysRoutes = createGroup([
  routes.configurationLicenseKeys,
]);

export const configurationRoutes = createGroup([
  routes.configurationDashboard,
  routes.configurationBackups,
  routes.configurationNlog,
  { ...configurationLicenseKeysRoutes },
  { ...configurationCompaniesRoutes },
  { ...configurationDevicesRoutes },
  { ...configurationUsersRoutes },
]);
