import { PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { typography } from './Blue';

const teal = (mode: PaletteMode) =>
  createTheme({
    palette: {
      mode: mode,
      primary: {
        light: '#52c7b8',
        main: '#009688',
        dark: '#00675b',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#b8cc8a',
        main: '#879b5c',
        dark: '#596d31',
        contrastText: '#ffffff',
      },
    },
    typography: typography,
  });

export default teal;
