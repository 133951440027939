import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  ClickAwayListener,
  Divider,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { FC, MouseEvent, useContext, useState } from 'react';
import { ErrorBoundary } from 'lib/components/ErrorBoundary';
import { useNavDrawerWidth } from 'lib/hooks/useNavDrawerWidth';
import { AppContext } from 'app/AppContext';
import { routes } from 'app/routes';
import { configurationRoutes } from 'app/routes/configuration';
import { profileRoutes } from 'app/routes/profile';
import { CompanySelect } from './CompanySelect';
import MenuItem from './MenuItem';
import ModulesSection from './ModulesSection';
import {
  collapseTransition,
  iconStyle,
  listItemColors,
  toolbarHeight,
} from './styles';

const Sidebar: FC = () => {
  const {
    isSystemAdmin,
    isCompanyAdmin,
    setSidebarState,
    sidebarState,
    sidebarDefaultState,
    userWhoAmI,
  } = useContext(AppContext);

  const [changingCompanies, setChangingCompanies] = useState<boolean>(false);

  const drawerWidth = useNavDrawerWidth();

  return (
    <ErrorBoundary>
      <ClickAwayListener
        onClickAway={() => {
          if (
            sidebarState === 'open' &&
            sidebarDefaultState !== 'open' &&
            !changingCompanies
          ) {
            setSidebarState(sidebarDefaultState);
          }
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            boxShadow:
              sidebarState === 'closed'
                ? ''
                : '0px 0px 5px 0px rgb(0 0 0 / 75%)',
            zIndex: 'drawer',
            width: sidebarState === 'closed' ? 0 : drawerWidth,
            borderRadius: 0,
            transition: `width ${collapseTransition}`,
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
          }}
        >
          <List disablePadding>
            {sidebarState === 'mini' ? (
              <ListItemButton
                sx={{ ...listItemColors, height: toolbarHeight }}
                onClick={() => setSidebarState('open')}
              >
                <ListItemIcon sx={iconStyle} aria-label="open drawer">
                  <MenuIcon />
                </ListItemIcon>
              </ListItemButton>
            ) : (
              <CompanySelect
                onOpen={() => setChangingCompanies(true)}
                onClose={() => setChangingCompanies(false)}
              />
            )}
          </List>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              width: drawerWidth,
              transition: `width ${collapseTransition}`,
              overflowX: 'hidden',
              top: toolbarHeight,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <ModulesSection />
              <Divider />
              <List>
                {(isSystemAdmin || isCompanyAdmin) && (
                  <MenuItem
                    group={configurationRoutes}
                    to={routes.configurationDashboard()}
                    label="Configuration"
                    icon={<SettingsIcon />}
                  />
                )}
                <MenuItem
                  group={profileRoutes}
                  to={routes.profile()}
                  label={userWhoAmI?.userName ?? 'Loading'}
                  icon={<AssignmentIndIcon />}
                />
                <MenuItem
                  to={routes.logOut()}
                  label="Log Out"
                  icon={<ExitToAppIcon />}
                />
              </List>
            </Box>
            <CollapseButton />
          </Box>
        </Box>
      </ClickAwayListener>
    </ErrorBoundary>
  );
};

export default Sidebar;

const CollapseButton: FC = () => {
  const { appBarVisible, setSidebarState, sidebarState } =
    useContext(AppContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '0px',
        justifyContent: 'center',
      }}
    >
      {sidebarState !== 'closed' && (
        <IconButton
          id="sidebar-collapse-button"
          sx={{
            padding: '5px 0px 5px 0px',
            borderRadius: '15px 0 0 15px',
          }}
          aria-label="close drawer"
          onClick={(event: MouseEvent<HTMLElement>) => {
            setSidebarState(
              sidebarState === 'open'
                ? appBarVisible
                  ? 'closed'
                  : 'mini'
                : 'open'
            );
            // Don't let the event bubble up, or it will trigger the click away listener
            // and cause the sidebar drawer to immediately revert to its former mode.
            event.stopPropagation();
          }}
          edge="start"
          size="large"
        >
          {sidebarState !== 'open' ? (
            <ChevronRightIcon sx={{ width: '16px' }} />
          ) : (
            <ChevronLeftIcon sx={{ width: '16px' }} />
          )}
        </IconButton>
      )}
    </Box>
  );
};
