import React, {
  FC,
  StrictMode,
  useContext,
  createRef,
  useEffect,
  useRef,
  PropsWithChildren,
} from 'react';
import { ErrorBoundary } from 'lib/components/ErrorBoundary';
import DocumentTitle from 'react-document-title';
import { client } from 'lib/com/GraphQL';
import { RouteProvider } from '../routes';
import { AppContext, AppContextProvider } from 'app/AppContext';
import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseLine from '@mui/material/CssBaseline';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import NotificationHandler from './NotificationHandler';
import { Button } from '@mui/material';
import Rose from 'app/Themes/Rose';
import Teal from 'app/Themes/Teal';
import Grey from 'app/Themes/Grey';
import Coffee from 'app/Themes/Coffee';
import Plum from 'app/Themes/Plum';
import Blue from 'app/Themes/Blue';
import { ThemeColors } from 'app/Themes/types';
import { PaletteMode } from '@mui/material';

const snackbarRef = createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey) => () => {
  snackbarRef.current?.closeSnackbar(key);
};

const Providers: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ErrorBoundary>
      <DocumentTitle title="ABC">
        <ApolloProvider client={client}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AppContextProvider>
              <ABCThemeProvider>
                <CssBaseLine>
                  <SnackbarProvider
                    ref={snackbarRef}
                    action={(key) => (
                      <Button
                        onClick={onClickDismiss(key)}
                        style={{ color: 'white' }}
                      >
                        'Dismiss'
                      </Button>
                    )}
                    maxSnack={3}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    <RouteProvider>
                      {/* We can't use strict mode until this but is resolved: https://docs.telerik.com/reporting/knowledge-base/react-viewer-does-not-load-dispose-error */}
                      {/*<StrictMode>*/}
                      <NotificationHandler />
                      {children}
                      {/*</StrictMode>*/}
                    </RouteProvider>
                  </SnackbarProvider>
                </CssBaseLine>
              </ABCThemeProvider>
            </AppContextProvider>
          </LocalizationProvider>
        </ApolloProvider>
      </DocumentTitle>
    </ErrorBoundary>
  );
};

export default Providers;

const kendoThemes: Record<ThemeColors, Record<PaletteMode, string>> = {
  teal: {
    light: '/styles/kendo-themes/light-theme-teal.css',
    dark: '/styles/kendo-themes/dark-theme-teal.css',
  },
  blue: {
    light: '/styles/kendo-themes/light-theme-blue.css',
    dark: '/styles/kendo-themes/dark-theme-blue.css',
  },
  coffee: {
    light: '/styles/kendo-themes/light-theme-coffee.css',
    dark: '/styles/kendo-themes/dark-theme-coffee.css',
  },
  grey: {
    light: '/styles/kendo-themes/light-theme-grey.css',
    dark: '/styles/kendo-themes/dark-theme-grey.css',
  },
  plum: {
    light: '/styles/kendo-themes/light-theme-plum.css',
    dark: '/styles/kendo-themes/dark-theme-plum.css',
  },
  rose: {
    light: '/styles/kendo-themes/light-theme-rose.css',
    dark: '/styles/kendo-themes/dark-theme-rose.css',
  },
};

const ABCThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const state = useContext(AppContext);

  const { mode, color } = state.theme;

  const kendoStyleRef = useRef(
    document.getElementById('kendo-theme') as HTMLLinkElement,
  );

  useEffect(() => {
    if (!kendoStyleRef.current) return;

    let theme: string = '/styles/kendo-themes/mui-clone.css';
    if (kendoThemes[color] !== undefined && kendoThemes[color][mode]) {
      theme = kendoThemes[color][mode];
    }

    kendoStyleRef.current.href = theme;
  }, [mode, color]);

  return (
    <ThemeProvider
      theme={
        color === 'teal'
          ? Teal(mode)
          : color === 'plum'
            ? Plum(mode)
            : color === 'rose'
              ? Rose(mode)
              : color === 'coffee'
                ? Coffee(mode)
                : color === 'grey'
                  ? Grey(mode)
                  : Blue(mode)
      }
    >
      {children}
    </ThemeProvider>
  );
};
