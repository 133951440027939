import {
  ConfigPage,
  LayoutConfig,
  WidgetInfo,
  WidgetType,
} from '../types/widget';

export class LoadWidgetsEvent {
  constructor(
    private adder: (
      info: WidgetInfo,
      widget: WidgetType,
      layoutConfig?: LayoutConfig,
      configPage?: ConfigPage
    ) => void
  ) {}

  /**
   *
   * @param info
   * @param widget The widget component loaded with React.lazy.
   * @param layoutConfig The static layout conf
   * @param configPage A configuration page that the widget can optionally provide to configure the dashboard view.
   */
  public addWidget(
    info: WidgetInfo,
    widget: WidgetType,
    layoutConfig?: LayoutConfig,
    configPage?: ConfigPage
  ): void {
    this.adder(info, widget, layoutConfig, configPage);
  }
}
