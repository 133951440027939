import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { AppContext } from 'app/AppContext';
import { useContext, FC, useMemo } from 'react';
import { toolbarHeight } from './styles';

interface CompanySelectProps {
  onOpen: () => void;
  onClose: () => void;
}

export const CompanySelect: FC<CompanySelectProps> = (props) => {
  const { companies, companyId } = useContext(AppContext);

  const sortedCompanies = useMemo(() => {
    const comp = Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
      usage: 'sort',
    });

    return [...companies].sort((a, b) => comp.compare(a.name, b.name));
  }, [companies]);

  return (
    <Select
      id="app-company-select"
      sx={{
        '&.MuiFilledInput-root': {
          width: '100%',
          height: toolbarHeight,
          backgroundColor: 'primary.dark',
          color: 'primary.contrastText',
          borderRadius: 0,
          border: 'none',
          '&:hover': {
            backgroundColor: 'primary.main',
          },
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
          '&.Mui-focused': {
            backgroundColor: 'primary.dark',
          },
        },
        '.MuiSelect-icon': {
          color: 'primary.contrastText',
        },
        '.MuiFilledInput-input': {
          paddingTop: 0,
          paddingBottom: 0,
        },
        '&.MuiFilledInput-icon': {
          color: 'primary.contrastText',
        },
      }}
      displayEmpty
      variant="filled"
      color="primary"
      value={companyId}
      onChange={(event: SelectChangeEvent) => {
        const company = companies.find((c) => c.id === event.target.value)!;

        let url = company.publicUrl;

        if (!url) {
          alert('The public url is not configured for this company.');
          return;
        }

        window.location.href = url;
      }}
      onOpen={props.onOpen}
      onClose={props.onClose}
    >
      {companyId === '' ||
      companies.find((c) => c.id === companyId) === undefined ? (
        <MenuItem key="" value="">
          Select a Company
        </MenuItem>
      ) : null}
      {sortedCompanies.map((c) => (
        <MenuItem key={c.id} value={c.id}>
          {c.name}
        </MenuItem>
      ))}
    </Select>
  );
};
