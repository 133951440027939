import { FC } from 'react';
import { routes } from 'app/routes';
import { reportsRoutes } from 'app/routes/reports';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MenuItem from '../Sidebar/MenuItem';

export const Inventory: FC = () => {
  return (
    <MenuItem
      to={routes.reportsDashboard()}
      group={reportsRoutes}
      label="Reports"
      icon={<AssessmentIcon />}
      isParent
    />
  );
};

export default Inventory;
