import MenuIcon from '@mui/icons-material/Menu';
import { AppBar as MuiAppBar, Toolbar, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { AppContext } from 'app/AppContext';
import React, { useContext } from 'react';

interface AppBarProps {
  title?: string;
}

const AppBar: React.VFC<AppBarProps> = ({ title }) => {
  const { setSidebarState, sidebarState } = useContext(AppContext);

  return (
    <MuiAppBar>
      <Toolbar color="inherit" disableGutters variant="dense">
        {sidebarState === 'closed' && (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setSidebarState('open');
                //don't let the event bubble up or it will trigger the click away listener
                //and cause the sidebar drawer to immediately revert to its former mode
                event.stopPropagation();
              }}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Typography>{title}</Typography>
          </>
        )}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
