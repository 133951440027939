/// <reference path="../custom-types.d.ts" />

import 'lib/com/Sentry';
// Required for Telerik charts.
import 'hammerjs';
import 'modules/contacts/init';
import 'modules/dashboards/init';
import 'modules/sales/init';
import 'modules/payroll/init';
import 'modules/purchases/init';
import 'modules/jobs/init';
import 'modules/finances/init';
import 'modules/inventory/init';

import { createRoot } from 'react-dom/client';

import App from './app/App';
import './core.scss';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
