import { FC, PropsWithChildren, ReactNode, useContext } from 'react';
import { Box, Divider, Toolbar, Typography } from '@mui/material';
import { Loading } from '../Loading';
import DocumentTitle from 'react-document-title';
import PageSection from '../PageSection';
import { AppContext } from 'app/AppContext';
import { useSpacing } from '../../hooks/useSpacing';
import { ErrorBoundary } from '../ErrorBoundary';
import AppBar from '../AppBar';
import ThemeSwitcher from './ThemeSwitcher';

export interface PageProps extends PropsWithChildren {
  breadcrumbs?: ReactNode;
  direction?: 'column' | 'row';
  loading?: boolean;
  showTitlebar?: boolean;
  title?: string;
  toolbar?: ReactNode;
  noScroll?: boolean;
  disableAppBar?: boolean;
}

export const wrapperStyles = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export const Page: FC<PageProps> = ({
  breadcrumbs,
  children,
  direction,
  loading,
  noScroll,
  showTitlebar,
  title,
  toolbar,
  disableAppBar = false,
}) => {
  const { appBarVisible } = useContext(AppContext);
  const { spacing } = useSpacing();

  return loading ? (
    <Loading title={title} />
  ) : (
    <DocumentTitle title={'ABC' + (title ? ' - ' + title : '')}>
      <Box
        sx={{
          ...wrapperStyles,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!disableAppBar && appBarVisible && <AppBar title={title} />}
        {toolbar && (
          <ErrorBoundary message="Error rendering toolbar.">
            <Toolbar sx={{ zIndex: 1000 }} disableGutters>
              {toolbar}
            </Toolbar>
          </ErrorBoundary>
        )}
        <Box
          sx={{
            display: showTitlebar || breadcrumbs ? 'initial' : 'none',
            padding: spacing,
            paddingBottom: 0,
          }}
        >
          {!appBarVisible && showTitlebar && (
            <Typography variant="h1">{title}</Typography>
          )}
          {breadcrumbs && (
            <ErrorBoundary message="Error rendering breadcrumbs.">
              {breadcrumbs}
              <Divider sx={{ marginTop: spacing }} />
            </ErrorBoundary>
          )}
        </Box>
        <PageSection
          direction={direction}
          sx={{ height: '100%' }}
          noScroll={noScroll}
          disableGutters
        >
          {children}
        </PageSection>
        <ThemeSwitcher />
      </Box>
    </DocumentTitle>
  );
};

export default Page;
