export const drawerWidthOpen = 240;
export const drawerWidthMini = 54;
export const childMenuWidth = drawerWidthOpen - 50;
export const listItemHeight = 48;
export const toolbarHeight = 64;
export const appBarHeight = 48;
export const collapseTransition = '400ms cubic-bezier(0, 0.75, 0.5, 1)';

export const listItemColors = {
  '&.MuiListItem-button, .MuiListItemIcon-root': {
    color: 'primary.contrastText',
  },
  '&.MuiListItemButton-root': {
    backgroundColor: 'primary.dark',
    '&:hover': {
      backgroundColor: 'primary.main',
    },
    '&.Mui-focused': {
      backgroundColor: 'primary.dark',
    },
  },
} as const;

export const iconStyle = {
  minWidth: 41,
  ...listItemColors,
} as const;
