import HailIcon from '@mui/icons-material/Hail';
import { FC, useContext } from 'react';
import { routes } from 'app/routes';
import {
  salesInvoicesRoutes,
  salesWorkOrdersRoutes,
  salesPaymentsRoutes,
  salesDispatcherRoutes,
  salesRoutes,
  salesSalespeopleRoutes,
} from 'app/routes/sales';
import CreateIcon from '@mui/icons-material/Create';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PaymentIcon from '@mui/icons-material/Payment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DashboardIcon from '@mui/icons-material/Dashboard';
import List from '@mui/material/List';
import { AppContext } from 'app/AppContext';
import MenuItem from '../Sidebar/MenuItem';

export const Sales: FC = () => {
  const {
    canViewDispatcher,
    canViewInvoices,
    canViewPayments,
    canViewRentals,
    canViewWorkOrders,
    canViewSalespeople,
  } = useContext(AppContext);

  if (
    !canViewDispatcher &&
    !canViewInvoices &&
    !canViewPayments &&
    !canViewRentals &&
    !canViewWorkOrders
  )
    return null;

  return (
    <MenuItem
      group={salesRoutes}
      label="Sales"
      icon={<MonetizationOnIcon />}
      isParent
    >
      <List disablePadding dense>
        <MenuItem
          label="Dashboard"
          icon={<DashboardIcon />}
          to={routes.salesDashboard()}
        />
        {canViewWorkOrders && (
          <MenuItem
            label="Work Orders"
            icon={<CreateIcon />}
            to={routes.salesWorkOrdersDashboard()}
            group={salesWorkOrdersRoutes}
          />
        )}
        {canViewInvoices && (
          <MenuItem
            label="Invoices"
            icon={<DescriptionIcon />}
            to={routes.salesInvoicesDashboard()}
            group={salesInvoicesRoutes}
          />
        )}
        {canViewPayments && (
          <MenuItem
            label="Payments"
            icon={<PaymentIcon />}
            to={routes.salesPaymentsDashboard()}
            group={salesPaymentsRoutes}
          />
        )}
        {canViewRentals && (
          <MenuItem
            label="Rentals"
            reload
            icon={<DateRangeIcon />}
            to={routes.rentals()}
          />
        )}
        {canViewDispatcher && (
          <MenuItem
            label="Dispatcher"
            icon={<LocalShippingIcon />}
            to={routes.salesDispatcherDashboard()}
            group={salesDispatcherRoutes}
          />
        )}
        {canViewSalespeople && (
          <MenuItem
            label="Salespeople"
            icon={<HailIcon />}
            to={routes.salesSalespeopleEdit()}
            group={salesSalespeopleRoutes}
          />
        )}
      </List>
    </MenuItem>
  );
};

export default Sales;
