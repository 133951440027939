import { param, ValueSerializer, noMatch } from 'type-route';

export type WidgetState<Config> = [widgetId: string, config: Config];

const dateReviver = (key: string, value: unknown) => {
  let dt;
  if (typeof value === 'string' && value.length === 24) {
    dt = new Date(value);
    // isNaN will be true when the date is invalid. Weird I know, welcome to JS!
    if (!isNaN(dt as unknown as number) && dt instanceof Date) {
      return dt;
    }
  }

  return value;
};
const delimiter = '=';

const dashboardWidgetState: ValueSerializer<WidgetState<{}>> = {
  parse(raw: string): typeof noMatch | WidgetState<{}> {
    const parts = raw.split(delimiter, 2);
    if (!raw || parts.length !== 2) {
      return noMatch;
    }

    return [parts[0], JSON.parse(parts[1], dateReviver)];
  },
  stringify(value: WidgetState<unknown>): string {
    return `${value[0]}${delimiter}${JSON.stringify(value[1])}`;
  },
};

export const dashboardParams = {
  id: param.path.optional.string,
  state: param.query.optional.array.ofType(dashboardWidgetState),
};

export const dashboardPath = (p: { id: string }) => `/dashboard/${p.id}`;
