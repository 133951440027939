import { createGroup } from 'type-route';
import { routes } from './index';

export const contactsCustomersRoutes = createGroup([
  routes.contactsCustomersDashboard,
  routes.contactsCustomersList,
  routes.contactsCustomersEdit,
  routes.contactsCustomersNew,
]);

export const contactsNamesRoutes = createGroup([
  routes.contactsNamesDashboard,
  routes.contactsNamesEdit,
  routes.contactsNamesNew,
  routes.contactsNamesList,
]);

export const contactsVendorsRoutes = createGroup([
  routes.contactsVendorsDashboard,
  routes.contactsVendorsEdit,
  routes.contactsVendorsList,
  routes.contactsVendorsNew,
]);

export const contactsRoutes = createGroup([
  routes.contactsDashboard,
  { ...contactsCustomersRoutes },
  { ...contactsNamesRoutes },
  { ...contactsVendorsRoutes },
]);
