import { PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { typography } from './Blue';

const Rose = (mode: PaletteMode) =>
  createTheme({
    palette: {
      mode: mode,
      primary: {
        light: '#e5a0c1',
        main: '#b27191',
        dark: '#814463',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#a98274',
        main: '#795548',
        dark: '#4b2c20',
        contrastText: '#FFFFFF',
      },
    },
    typography: typography,
  });

export default Rose;
