import { useMediaQuery, useTheme } from '@mui/material';

interface SpacingValues {
  spacing: number;
} 

export function useSpacing(): SpacingValues {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const spacing = isMobile ? 2 : 4;
  return { spacing };
}
