import { lazy } from 'react';
import { WidgetGroupIds } from './widgetManager/widgetGroups';
import { widgetManager } from './widgetManager/widgetManager';

export enum WidgetNames {
  ServerVersion = 'ServerVersion',
  CompanyInfo = 'CompanyInfo',
  InvoiceCashProjection = 'InvoiceCashProjection',
  InvoiceCashProjectionDetail = 'InvoiceCashProjectionDetail',
  CustomerMap = 'CustomerMap',
}

widgetManager.addWidgetLoader((event) => {
  event.addWidget(
    {
      id: WidgetNames.ServerVersion,
      title: 'Server Version',
      groupId: WidgetGroupIds.Misc,
    },
    lazy(() => import('./widgets/app/ServerVersion')),
    {
      minW: 2,
      minH: 2,
    },
    lazy(() => import('./widgets/app/configPages/ServerVersion'))
  );

  event.addWidget(
    {
      id: WidgetNames.CompanyInfo,
      title: 'Company Info',
      groupId: WidgetGroupIds.Misc,
    },
    lazy(() => import('./widgets/app/CompanyInfo')),
    {
      minW: 3,
      minH: 3,
    }
  );

  event.addWidget(
    {
      id: WidgetNames.InvoiceCashProjection,
      title: 'Incoming Cash Projection',
      description: 'Incoming cash projection from open customer invoices.',
      groupId: WidgetGroupIds.Financial,
    },
    lazy(() => import('./widgets/cashProjections/InvoiceCashProjection')),
    {
      minW: 4,
      minH: 6,
    },
    lazy(
      () => import('./widgets/cashProjections/InvoiceCashProjection/ConfigPage')
    )
  );

  // DISABLING THIS ONE BECAUSE IT ISN'T NEAR PRODUCTION READY.

  // const IncomingCashProjectionCustomerDetail = lazy(
  //   () => import('./widgets/cashProjections/InvoiceCashProjectionCustomerDetail')
  // );
  // event.addWidget(
  //   {
  //     id: WidgetNames.InvoiceCashProjectionDetail,
  //     title: 'Incoming Cash Projection Detail',
  //     description: 'Incoming cash projection from open customer invoices.',
  //     groupId: WidgetGroupIds.Financial,
  //   },
  //   IncomingCashProjectionCustomerDetail,
  //   {
  //     minW: 7,
  //     minH: 7,
  //   }
  // );
});
