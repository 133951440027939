import { createGroup } from 'type-route';
import { routes } from './index';

export const inventorySerialNumbersRoutes = createGroup([
  routes.inventorySerialNumbersDashboard,
]);

export const inventoryItemRoutes = createGroup([
  routes.inventoryItemsNew,
  routes.inventoryItemsEdit,
]);

export const inventoryRoutes = createGroup([
  routes.inventoryDashboard,
  { ...inventorySerialNumbersRoutes },
  { ...inventoryItemRoutes },
]);
