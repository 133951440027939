import { lazy } from 'react';
import { WidgetGroupIds } from 'modules/dashboards/widgetManager/widgetGroups';
import { widgetManager } from 'modules/dashboards/widgetManager/widgetManager';
const OpenPOsWidget = lazy(() => import('./OpenPOs'));
const OpenPOsConfig = lazy(() => import('./OpenPOs/ConfigPage'));

widgetManager.addWidgetLoader((e) => {
  e.addWidget(
    {
      id: 'OpenPOs',
      title: 'Open POs',
      groupId: WidgetGroupIds.Purchases,
    },
    OpenPOsWidget,
    { minW: 6, minH: 4 },
    OpenPOsConfig
  );
});
