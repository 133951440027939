import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { useContext } from 'react';
import { AppContext } from 'app/AppContext';
import { ThemeColors } from 'app/Themes/types';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { Divider } from '@mui/material';

export const themes = [
  { color: '#1565c0', tooltip: 'Blue', themeColor: 'blue' },
  { color: '#b27191', tooltip: 'Rose', themeColor: 'rose' },
  { color: '#00675b', tooltip: 'Teal', themeColor: 'teal' },
  { color: '#355664', tooltip: 'Grey', themeColor: 'grey' },
  { color: '#896347', tooltip: 'Coffee', themeColor: 'coffee' },
  { color: '#4a004d', tooltip: 'Plum', themeColor: 'plum' },
];

export default function ThemeSwitcher() {
  const { isDevelopMode, theme, setTheme } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return !isDevelopMode ? null : (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip placement="left" title="Theme Switcher">
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              setAnchorEl(event.currentTarget)
            }
            size="small"
            sx={{ position: 'absolute', bottom: 20, right: 20 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            color="primary"
            tabIndex={-1}
          >
            <ColorLensIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: -6,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bottom: 0,
                right: 11,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {themes.map((t, k) => (
          <MenuItem
            key={k}
            sx={{ width: 165 }}
            value={t.themeColor}
            selected={t.themeColor === theme.color}
            onClick={() =>
              setTheme({ ...theme, color: t.themeColor as ThemeColors })
            }
          >
            {t.tooltip}{' '}
            <FormatPaintIcon sx={{ marginLeft: 'Auto', color: t.color }} />
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          onClick={() =>
            setTheme({
              ...theme,
              mode: theme.mode === 'light' ? 'dark' : 'light',
            })
          }
          color="inherit"
        >
          <Box sx={{ marginRight: 'auto' }}>
            {theme.mode === 'dark' ? 'Light' : 'Dark'}
          </Box>
          {theme.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </MenuItem>
      </Menu>
    </>
  );
}
